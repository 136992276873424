import React from 'react';
import { table as Colors } from '../Colors'

export default class ErrorRow extends React.Component {
  render() {
    const { label, hidden } = this.props;
    if (!label || hidden) { return null; }
    const error_style = {
      backgroundColor: Colors.errorBg,
      border: '1px solid' + Colors.errorBoder,
      color: 'rgb(203, 82, 82)',
      // fontSize: '1.2rem',
      // fontWeight: 'bold',
      padding: 8,
      whiteSpace: 'pre-line'
      // width:30,
    }
    //______________________________________________________________________________________________________
    return (
      <div style={error_style}>{label}</div>
    )
  }
}
