import React from 'react';
import Icon from './Icon';

export default class ArrowRightCurve extends React.Component {
  render() {
    return (
      <Icon
        {...this.props}
        viewBox="0 0 512 512"
        d="M477.694,262.331C450.839,157.565,355.775,80.137,242.645,80.137C108.637,80.137,0,188.766,0,322.782
		        l117.984-8.92c-2.226-51.185,37.105-113.516,57.879-123.169c-28.193-3.702-60.104-2.114-60.104-2.114
		        c21.476-8.346,45.081-12.968,69.863-12.968c90.637,0,165.581,61.775,177.733,142.025l-57.637,25.177l166.21,89.048l40.072-187
		        L477.694,262.331z"
      />
    )
  }
}