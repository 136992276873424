import React from 'react';
import { radioList as Colors } from './Colors'
import { getPMasterName } from '../util/commonInput';

export default class RadioLisit extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props
    this.state = {
      value: value,
    }
  }
  // ________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const { value } = this.props
    if (value !== nextProps.value) {
      this.setState({ value: nextProps.value })
    }
    return true;
  }
  // ________________________________________________________________________________
  handleOnClick = (value) => {
    const { name, onChange, setData } = this.props;
    let nextValue = value;
    // setDataにブランクが設定されていたら、選んだ項目を再クリックでnullにすることができる
    if(!setData[0].value && this.state.value === value){
      nextValue = null
    }
    this.setState({value: nextValue})
    onChange && onChange(name, nextValue)
  }
  // ________________________________________________________________________________

  render() {
    // 使用する変数を定義
    const { setData, disabled } = this.props
    const { value } = this.state
    const list_arr = [];
    // ________________________________________________________________________________

    for (let i in setData) {
      list_arr.push(
        <RadioButton
          key={i}
          selected={setData[i].value === value}
          onClick={() => this.handleOnClick(setData[i].value)}
          disabled={disabled}
          label={setData[i].label}
        />
      )

    }
    // ________________________________________________________________________________

    return (
      <div>
        {list_arr}
      </div>
    )
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class RadioButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    }
  }
  // ________________________________________________________________________________________________________________

  render() {
    const { hover } = this.state
    const {
      selected,
      disabled,
      onClick,
      label,
    } = this.props
    let Label = label && getPMasterName(label);
    if (Label === 'NONE') { return null }
    else if (label && !Label) { Label = label } // ブランクは表示しない。
    if(!Label){return null};
    const button_style = {              // <button> 
      backgroundColor:
        selected ? Colors.btn :
          "transparent",
      color:
        selected ? "#fff" :
          null,
      border: 
        hover ? '1px solid' + Colors.btn :
        selected ? '1px solid transparent':
        '1px solid #dddddd',
      margin: 2,
      cursor:
        disabled ? null :
          "pointer",
      padding: "2px 4px",
      borderRadius: 20,
      // fontSize: 11,
      transition: "0.2s",
      outlineStyle: 'none',
    }
    return (
      <button
        style={button_style}
        onClick={onClick}
        disabled={disabled}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        {Label}
      </button>
    )
  }
}
