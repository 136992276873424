import React from 'react';
import * as Icons from "../Icons";

export default class DownCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false,
    }
  }
  render() {
    const { onClick, disabled, color, children } = this.props;
    const { hover, focus } = this.state;
    const { rowSpan } = children;
    const td_style = {                // <td> 各セル
      width: 30,
      border: "1px solid #ddd",
      textAlign: "center",
      verticalAlign: "middle",
      paddingTop: 4,
      transition: '0.2s',
      backgroundColor:
        disabled ? color :
          hover || focus ? "#Cf8080" :
            color,
      pointerEvents:
        disabled ? "none" :
          "auto",
      cursor:
        disabled ? null : 'pointer',
    }
    const iconColor = hover || focus ? "#FFFFFF" : "#Cf8080";
    return (
      <td
        style={td_style}
        rowSpan={rowSpan || 1000}
        onClick={onClick}
        tabIndex={0}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >
        <Icons.AngleDoubleDown width={16} height={16} color={iconColor} hidden={disabled} />
      </td>
    )
  }
}
