import React from 'react';

export default class HideBtn extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hover:false,
    }
  }
  render(){
    const { checkPage,hidden,children } = this.props
    const { hover } = this.state
    let btn_label
    // 検索した場合
    if (hidden) {
      btn_label = "検索条件を開く";
    }else{
      btn_label = "検索条件を閉じる";
    }

    const btn_style = {
      margin:'5px 16px 16px 5px',
      height: 35,
      padding:"0px 24px",
      fontWeight: "bold",
      fontSize: 14,
      borderRadius: 5,
      // boxShadow:" 2px 2px 2px #aaa",
      cursor: "pointer",
      border:  "3px solid #47AD16",
      color: 
        hover ? '#47AD16' :
        "#fff",
      backgroundColor: 
        hover ? "#fff" :
        '#47AD16', 
      transition:"0.3s",
      userSelect:'none',
    }

    return(
      <button 
        style        = { btn_style }
        onClick      = { this.props.onClick } 
        onKeyDown    = { this.props.onKeyDown }
        onMouseOver  = { () => this.setState({ hover : true  }) }
        onMouseLeave = { () => this.setState({ hover : false }) }
        onFocus      = { () => this.setState({ hover : true }) }
        onBlur       = { () => this.setState({ hover : false }) }
      >
        { btn_label }
        {/* { children } */}
      </button>
    )
  }
}