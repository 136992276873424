import React from 'react';
import { formatNumber, getPMasterName } from '../../util/commonInput';
import BasicTD from './BasicTD';

export default class LabelCell extends React.Component {
  constructor(props) {
    super(props);
    this.td_style = {                // <td> 各セル
      padding: '0px 4px',
      overflow: 'hidden',
    }
    this.unit_style = {
      padding: "0px 2px",
    }
    this.pre_style = {
      whiteSpace: "pre-wrap",
    }
  }
  //______________________________________________________________________________________________________
  render() {
    const { value, unit } = this.props;
    const { format } = this.props.children
    const number = this.props.number || this.props.children.number
    let setValue = format ? formatNumber(value, format) : value // 数値フォーマットの適用
    const pUnit = getPMasterName(unit) || unit;
    const valueUnit = <>{setValue}{pUnit && <span style={this.unit_style}>{pUnit}</span>}</>
    //______________________________________________________________________________________________________
    return (
      <BasicTD {...this.props} disabled config={this.props.children} cellStyle={this.td_style}>
        {number && value < 0 &&
          // number型で数値がマイナスなら赤文字にする
          <pre title={value}>
            <font color="red">{valueUnit}</font>
          </pre>
        }
        {(typeof number == 'undefined' || value >= 0) && <pre title={value} style={this.pre_style}>{valueUnit}</pre>}
      </BasicTD>
    )
  }
}
