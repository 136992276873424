export const zindex = {
  popupLoading: 3000,
  confirm: 2500,
  filter: 2500,
  header: 2000,
  loding: 1800,
  modal: 2500,
  fullScreenImage: 2500,
  sidebar: 1000,
  tableFooter: 900,
  calendar: 2500,
}