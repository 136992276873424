import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import Loading from './Loading';
import { getPdfByte } from '../util/outputUtil';
import { getComponentLabelName } from '../util/commonInput'
import SystemBtn from './SystemBtn'
import NoDataWarning from '../image/SVGIcons/NoData'

/**
 * PDFファイルを表示するコンポーネント。
 * 
 * @component
 * @example
 * const file = "ファイルのデータ"
 * const url = "PDFファイルのURL"
 * const dataArray = [データ配列]
 * const fileName = "ダウンロード時のファイル名"
 * const addBtns = <追加のボタン要素>
 * return (
 *   <PdfViewer file={file} url={url} dataArray={dataArray} fileName={fileName} addBtns={addBtns} />
 * )
 */
export default class PdfViewer extends React.Component {
  // コンポーネントの`props`の型を宣言
  static propTypes = {
    file: PropTypes.string,       // PDFファイルのデータ
    url: PropTypes.string,        // PDFファイルのURL
    dataArray: PropTypes.array,   // PDF生成に必要なデータの配列
    fileName: PropTypes.string,   // ダウンロード時のファイル名
    addBtns: PropTypes.element,   // 追加のボタン要素
  };

  constructor(props) {
    super(props);
    this.state = {
      src: null,
      loading: true,
    }
    this.objectUrl = null
  }

  /**
   * コンポーネントがマウントされた後、PDFファイルを取得して表示します。
   */
  async componentDidMount() {
    if (!!this.props.file) {
      // 静的なファイルをプレビュー
      let mimeType = 'data:application/pdf,';
      this.setState({ src: mimeType + this.props.file });
    }
    if (!!this.props.url) {
      // getSource
      await getPdfByte(this.props.url, this.props.dataArray).then(res => {
        // 失敗時の処理
        if (!res) {
          this.setState({ src: '' });
          return;
        }
        if (!!res.headers && res.headers.error) {
          alert(decodeURIComponent(res.headers.msg) || "pdf 作成失敗しました")
          return
        }
        let blobFile = new File([res], '234.pdf', { type: 'application/pdf' })
        this.objectUrl = window.URL.createObjectURL(blobFile)
        this.setState({ src: this.objectUrl + '#toolbar=0' });
      });
    }
    this.setState({ loading: false });
  }

  /**
   * コンポーネントがアンマウントされる前に行うクリーンアップ処理。
   */
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    }
  }

  /**
   * PDFをダウンロードするためのハンドラ。
   */
  handleDownload = e => {
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none');
    a.setAttribute('href', this.objectUrl);
    a.setAttribute('download', this.props.fileName || 'download file');
    a.click();
  }

  /**
   * PDFを印刷するためのハンドラ。
   */
  handlePrint = () => {
    this.frame_ref.contentWindow.print()
  }

  /**
   * コンポーネントの描画処理。
   */
  render() {
    const { src, loading } = this.state;
    const { addBtns } = this.props;
    if (loading) {
      return <Loading popup />
    }
    return (
      <div style={{ height: "100%", display: "flex", textAlign: "center", justifyContent: "center", flexDirection: "column" }}>
        {
          !src ?
            <NoDataWarning />
            :
            <Fragment>
              <div style={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                backgroundColor: "#5f5f5f",
                padding: '10px 16px',
              }}>
                {addBtns}
                <SystemBtn label={getComponentLabelName("comm.download")} type="download" color="transparent" onClick={this.handleDownload} />
                <SystemBtn label={getComponentLabelName("comm.print")} type="print" color="transparent" marginLeft={8} onClick={this.handlePrint} />
              </div>
              <object
                type="application/pdf"
                data={src}
                style={{
                  height: "calc(100% - 50px)"
                }}
              >
                <iframe title={"unic"} src={src} width="100%" height="100%" style={{ border: 'none' }} ref={el => this.frame_ref = el}>
                  <p>This browser does not support PDFs.Please download it..</p>
                </iframe>
              </object>
            </Fragment>
        }
      </div>
    );
  }
}
