import React from 'react';

export default class FilterBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
  }
  // ______________________________________________________________________________
  render() {
    const { color, label, onClick } = this.props;
    const { hover } = this.state;
    const wrap_style = {
      border: 'none',
      padding: '4px 12px',
      // fontSize: '1.1rem',
      cursor: "pointer",
      userSelect: 'none',
      backgroundColor: hover ? color :
        '#FFF',
      textAlign: 'left',
      color: '#404040',
    }
    // ______________________________________________________________________________
    return (
      <button
        style={wrap_style}
        onClick={onClick}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        {label}
      </button>
    )
  }
}

