import React from 'react';
import * as Icons from "../Icons";
import { table as Colors } from '../Colors'

export default class DetailSwitchCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    }
  }
  // ______________________________________________________________________

  handleOnClick = (e) => {
    const { onClick } = this.props;
    e.stopPropagation(); // 親に伝播しないように
    onClick && onClick()
  }
  // ______________________________________________________________________

  render() {
    const { render, iconRender, detailRender, color, fixedWidth, zIndex } = this.props;
    const { hover } = this.state;
    const td_style = {
      width: 30,
      // borderBottom: "1px solid #bbb",
      textAlign: "center",
      verticalAlign: "middle",
      // fontSize: '1.1rem',
      padding: "2px 5px",
      height: 25,
      border: '1px solid' + Colors.border,
      transition: "0.2s",
      backgroundColor:
        hover ? Colors.basic :
          color,
      position:
        fixedWidth || fixedWidth === 0 ? 'sticky' :
          null,
      left: fixedWidth,
      zIndex: zIndex || null,
    }
    if (!render) {
      return null;
    } else if (!iconRender) {
      // 明細件数が1件以下ならiconは表示しない。
      return <td style={td_style} rowSpan={1000} >
        <Icons.PlusSquare width={15} height={15} color={"transparent"} />
      </td>
    }

    const iconColor = hover ? '#fff' : Colors.basic;
    return (
      <td
        style={td_style}
        onClick={this.handleOnClick}
        rowSpan={1000}
        tabIndex={0}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        <Icons.PlusSquare width={15} height={15} color={iconColor} hidden={detailRender} />
        <Icons.MinusSquare width={15} height={15} color={iconColor} hidden={!detailRender} />
      </td>     // type指定したもの（コンポーネントなど）

    )
  }
}