import React from 'react';
import Icon from './Icon';

export default class Unlocked extends React.Component {
  render() {
    return (
      <Icon
        {...this.props}
        viewBox="0 0 1024 1024"
        d="M768 64c105.856 0 192 86.144 192 192v192H832V256c0-35.264-28.736-64-64-64H640c-35.264 0-64 28.736-64 64v192h16c26.368 0 48 21.632 48 48v480c0 26.368-21.632 48-48 48H48a48.192 48.192 0 0 1-48-48V496c0-26.368 21.632-48 48-48h400V256c0-105.856 86.144-192 192-192h128z"
      />
    )
  }
}