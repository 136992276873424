import React from 'react';

export default class FilterInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      hover: false,
    }
  }
  // ______________________________________________________________________________
  handleOnChange = (e) => {
    const { onChange } = this.props;
    this.setState({ value: e.target.value })
    onChange && onChange(e.target.value)
  }
  // ______________________________________________________________________________

  render() {
    const { number, hidden } = this.props;
    const { hover, value } = this.state;
    if (hidden) { return null }
    const input_style = {
      margin: 8,
      color: '#838383',
      padding: '4px',
      letterSpacing: 0.55,
      // fontSize: '1.1rem',
      border: '1px solid #BEBEBE',
      boxShadow: hover ? '0px 0px 4px #5BBA6A' :
        null,
      textAlign: number ? 'right' :
        'left',
    }
    // ______________________________________________________________________________
    return (
      <input
        value={value}
        style={input_style}
        onChange={this.handleOnChange}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      />
    )
  }
}

