import React from 'react';
import { table as Colors } from '../Colors'
import BasicTD from './BasicTD';

export default class DecimalCell extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      focus: false,
      value: value || '',
      oldValue: value || '',
    }
    this.getDefaultValue = false;
    this.td_style = {                // <td> 各セル
      width: "100%",
      border: "1px solid #ddd",
      textAlign: "center",
      verticalAlign: "middle",
      // fontSize: '1.1rem',
      height: 25,
    }
    this.text_style = {  // <input> テキストボックスのスタイル
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      // fontSize: '1.1rem',
      border: "none",
      outline: "none",
      padding: '0px 4px',
      boxSizing: "border-box",
      textAlign: "right",   // 数値型なら文字を右寄せに
    }
    this.innerWrap_style = {
      width: "100%",
      height: "100%",
      display: 'flex',
      alignItems: 'center',
    }
  }
  //______________________________________________________________________________________________________
  shouldComponentUpdate(nextProps) {
    const { value } = this.props;
    if (value !== nextProps.value) {
      if (isNaN(value) && isNaN(nextProps.value)) { return true } // NaNは判断に引っかからなかったので
      if (!this.getDefaultValue) {
        this.setState({ oldValue: nextProps.value })
        this.getDefaultValue = true;
      }
      this.setState({ value: nextProps.value || '' })
    }
    return true;
  }
  //______________________________________________________________________________________________________
  // Component State管理
  handleOnChange = (e) => {
    const { maxLength } = this.props;
    const old = this.state.value;
    const inputValue = e.target.value
    const maxLength_arr = maxLength ? maxLength.split(',') : [15, 5]
    const decimal_maxLength = maxLength_arr[1]

    let replaceValue = inputValue.replace(/[^\d||.]/g, '');
    let integer = replaceValue.split('.')[0]
    let decimal = replaceValue.split('.')[1] // ピリオドがあるかどうか 無し:-1, 先頭:0, それ以外:1以上

    if (decimal) {
      decimal = decimal.substr(0, decimal_maxLength)
      replaceValue = (integer || 0) + '.' + decimal
    }
    // 入力値の先頭がマイナスなら、マイナスを付与
    if (inputValue.indexOf('-') === 0) { replaceValue = '-' + replaceValue }

    this.setState({
      oldValue: old,
      value: replaceValue
    });
  }
  // ________________________________________________________________________________________________________________

  //Onblur時　InputTableに最新状態を渡す
  handleOnBlur = (fowardData) => {
    this.setState({ focus: false });
    const { onBlur } = this.props.children
    this.props.cellOnBlur && this.props.cellOnBlur(fowardData); // 共通CelllOnBlurのEvent
    onBlur && onBlur(fowardData);//Cellごとの特別なOnblurのEvent
  }
  // ________________________________________________________________________________________________________________
  handleOnFocus = (fowardData) => {
    this.setState({ focus: true });
    const { onFocus } = this.props.children
    onFocus && onFocus(fowardData)
  }
  // ________________________________________________________________________________________________________________
  render() {
    const {
      disabled,
      index,
      must,
      color,
      onClick,
    } = this.props
    const {
      width,
      minWidth,
      align,
      name,
      rowSpan,
      colSpan,
      mustCell,
      maxLength = "",
    } = this.props.children
    const {
      value,
      focus,
      oldValue,
    } = this.state;

    const fowardData = { index: index, name: name, value: value, oldValue: oldValue } //　一番親に送るデータ
    Object.assign(this.td_style, {            // <td> 各セル
      boxShadow:
        disabled ? color :
          focus ? "0px 0px 5px 0px #000000 inset" :
            null,
      backgroundColor:
        disabled ? null :
          must || mustCell ? Colors.mustCell :
            null,
      cursor:
        disabled ? null :
          "pointer",
      width: width,
      minWidth: minWidth,
      textAlign: align,
    })
    //______________________________________________________________________________________________________
    let _maxLength = maxLength ? String(maxLength).split(',')[0] : 15
    _maxLength = Number(_maxLength)
    if (String(value).indexOf('-') === 0) { _maxLength += 1 }
    if (String(value).indexOf('.') >= 0) { _maxLength += 1 }

    return (
      <BasicTD {...this.props} config={this.props.children}>
        <div style={this.innerWrap_style}>
          <input
            type={"tel"}
            value={value || ""}
            style={this.text_style}
            disabled={disabled}
            maxLength={_maxLength}
            onChange={this.handleOnChange}
            onFocus={() => this.handleOnFocus(fowardData)}
            onBlur={() => this.handleOnBlur(fowardData)}
          />
        </div>
      </BasicTD>
    )
  }
}
