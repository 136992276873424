import React from 'react';
import { table as Colors } from '../Colors'
import BasicTD from './BasicTD';

export default class TextAreaCell extends React.Component {
  constructor(props) {
    super(props);
    const { number, value, children, } = this.props;
    this.state = {
      value: value || '',
      oldValue: value || '',
    }
    let rowSpan = children.rowSpan || 1;
    this.getDefaultValue = false;
    this.text_style = {  // <input> テキストボックスのスタイル
      width: "100%",
      minHeight: 25 * rowSpan,
      height: children.height || 25 * rowSpan,
      // height: "100%",
      backgroundColor: "transparent",
      // fontSize: '1.1rem',
      border: "none",
      outline: "none",
      padding: '4px 0px 4px 4px',
      boxSizing: "border-box",
      verticalAlign: "middle",
      // resize: "vertical", // 縦のみリサイズ可能
      textAlign:
        number ? "right" :   // 数値型なら文字を右寄せに
          null,
    }
  }
  //______________________________________________________________________________________________________
  shouldComponentUpdate(nextProps, nextState) {
    const { number, value } = this.props;
    if (value !== nextProps.value) {
      if (number) if (isNaN(value) && isNaN(nextProps.value)) { return false } // NaNは判断に引っかからなかったので
      if (!this.getDefaultValue) {
        this.setState({ oldValue: nextProps.value })
        this.getDefaultValue = true;
      }
      this.setState({ value: nextProps.value === 0 ? 0 : nextProps.value || '' })
    }
    return true;
  }
  //______________________________________________________________________________________________________
  // Component State管理
  handleOnChange = (e) => {
    const old = this.state.value;
    const { number } = this.props;
    let inputValue = e.target.value
    if (number) { inputValue = inputValue.replace(/[^0-9]/g, ''); }

    this.setState({
      oldValue: old,
      value: inputValue
    });
  }
  // ________________________________________________________________________________________________________________

  //Onblur時　InputTableに最新状態を渡す
  handleOnBlur = (fowardData) => {
    const { onBlur } = this.props.children
    this.props.cellOnBlur && this.props.cellOnBlur(fowardData); // 共通CelllOnBlurのEvent
    onBlur && onBlur(fowardData, this.props.setData);//Cellごとの特別なOnblurのEvent
  }
  // ________________________________________________________________________________________________________________
  handleOnFocus = (fowardData) => {
    const { onFocus } = this.props.children
    this.setState({ oldValue: this.state.value });
    onFocus && onFocus(fowardData)
  }
  handleOnKeyUp = (fowardData) => {
    const { onKeyUp } = this.props.children
    this.props.cellOnKeyUp && this.props.cellOnKeyUp(fowardData); // 共通CelllOnBlurのEvent
    onKeyUp && onKeyUp(fowardData);//Cellごとの特別なOnblurのEvent
  }
  // ________________________________________________________________________________________________________________
  render() {
    const {
      number,
      disabled,
      index,
      detailIndex,
    } = this.props
    const {
      name,
      maxLength,
    } = this.props.children
    const {
      value,
      oldValue,
    } = this.state;

    const fowardData = { index: index, name: name, value: value, oldValue: oldValue, detailIndex: detailIndex } //　一番親に送るデータ

    //______________________________________________________________________________________________________
    return (
      <BasicTD {...this.props} config={this.props.children} >
        <textarea
          tabIndex={0}
          suppressContentEditableWarning
          contentEditable
          type={number ? "tel" : null}
          value={value}
          style={{ ...this.text_style, resize: disabled ? "none" : "vertical" }}
          disabled={disabled}
          maxLength={maxLength}
          onChange={this.handleOnChange}
          onFocus={() => this.handleOnFocus(fowardData)}
          onBlur={() => this.handleOnBlur(fowardData)}
          onKeyUp={() => this.handleOnKeyUp(fowardData)}
        />
      </BasicTD>
    )
  }
}
