import React from 'react';
import * as Icons from "./Icons";
import Button from './Button';
import Circle from 'react-circle';
import { axiosPost, server } from '../comRedux/axiosPost';

export default class UpdateProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkProgress: 0,
      updateProgress: 0,
      errorMsg: null,
      showCheck: false,
      showError: false,
      checkfinished: false,
      Updatefinished: false,
      checkProcessName:'',
      updateProcessName:'',
    }
    this.wrap_style = {
      height: "100vh",
      maxHeight: "100%",
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding:"30px 30px 0px 30px",
    }
  }
  // _____________________________________________________________________________
  progressTimer = (type) => {
    if (type === 'check') {
      this.setState({ showCheck: true })
    }

    const interval = setInterval(() => {
      axiosPost.post(server + '/progress/getProcessing').then((res) => {
        if (res) {
          const { errorMsg, percentage, processTime, processName, status, } = res.data
          if (type === 'check') {
            this.setState({ checkProcessName: processName, })
          } else if (type === 'update') {
            this.setState({ updateProcessName: processName, })
          }
          switch (status.name) {
            case 'running': {
              if (type === 'check') {
                this.setState({ checkProgress: percentage, })
              } else if (type === 'update') {
                this.setState({ updateProgress: percentage, })
              }
              break;
            }
            case 'checkfinished': {
              this.setState({
                checkProgress: 1,
                checkfinished: true,
              })
              clearInterval(interval);
              this.handleOnUpdate()
              break;
            }
            case 'finished': {
              this.setState({
                updateProgress: 1,
                Updatefinished: true,
              })
              clearInterval(interval);
              break;
            }
            case 'error': {
              this.setState({
                showError: true,
                errorMsg: errorMsg,
              })
              clearInterval(interval);
              break;
            }
            default: { break; }
          }
          // this.props.setProgressInfo(data)
        }
      }).catch((error) => { 
        this.setState({
          showError: true,
          errorMsg: 'レスポンスエラー',
        })
        clearInterval(interval);
        console.log(error)
      })
    }, 500);
  }

  //___________________________________________________________________
  handleOnUpdateCheckOrUpdate = () => {
    const { checkUrl, url } = this.props;
    if (!!checkUrl) {
      this.handleUpdateCheck();
    } else if (!!url) {
      this.setState({
        showCheck: true,
        checkfinished: true,
      });
      this.handleOnUpdate()
    }
  }

  handleUpdateCheck = () => {
    const { checkUrl, pgmId, formData } = this.props;
    axiosPost.post(server + checkUrl, { ...formData, 'id': pgmId }).then(
      (res) => {
        console.log(res)
        this.progressTimer('check');
      }).catch(err => {
        console.log(err)
        clearInterval(this.progressTimer())
      })
  }
  //___________________________________________________________________

  handleOnUpdate = async () => {
    const { url, formData } = this.props;
    axiosPost.post(server + url, formData).then(
      (res) => {
        console.log(res)
        this.progressTimer('update');
      }).catch(err => {
        clearInterval(this.progressTimer())
        console.log(err)
      });
  }
  //___________________________________________________________________

  handleOnCancelUpdate = () => {
    const { onCancel, } = this.props;
    this.setState({
      checkProgress: 0,
      updateProgress: 0,
      errorMsg: null,
      showCheck: false,
      showError: false,
      checkfinished: false,
      Updatefinished: false,
      checkProcessName:'',
      updateProcessName:'',
    })
    onCancel && onCancel()
  }
  //___________________________________________________________________
  render() {
    const { onFinish, label} = this.props;
    const {
      checkProgress,
      updateProgress,
      errorMsg,
      showCheck,
      showError,
      checkfinished,
      Updatefinished,
      checkProcessName,
      updateProcessName,
    } = this.state
    const _checkProgress = parseInt(checkProgress * 100, 10)
    const _updateProgress = parseInt(updateProgress * 100, 10)
    let renderType = null
    if (showError) { renderType = 'Error' }
    else if (!showCheck) { renderType = 'willCheck' }
    else if (showCheck && !checkfinished) { renderType = 'nowChecking' }
    else if (checkfinished && !Updatefinished) { renderType = 'nowUpdateing' }
    else if (Updatefinished) { renderType = 'complete' }
    switch (renderType) {
      case 'willCheck': {
        return (
          <div style={this.wrap_style}>
            <UpdateOrCancel onUpdate={this.handleOnUpdateCheckOrUpdate} onCancel={onFinish} label={label}/>
          </div>
        )
      }
      case 'nowChecking': {
        return (
          <div style={this.wrap_style}>
            <ProgressComponent label={checkProcessName} progress={_checkProgress} />
          </div>
        )
      }
      case 'nowUpdateing': {
        return (
          <div style={this.wrap_style}>
            <ProgressComponent label={updateProcessName} progress={_updateProgress} />
          </div>
        )
      }
      case 'complete': {
        return (
          <div style={this.wrap_style}>
            <DidUpdate onClick={onFinish} label={updateProcessName}/>
          </div>
        )
      }
      case 'Error': {
        return (
          <div style={this.wrap_style}>
            <ErrorComponent
              onClick={this.handleOnCancelUpdate}
              errorMsg={errorMsg}
            />
          </div>
        )
      }
      default: { return null }
    }
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////
class UpdateOrCancel extends React.Component {
  constructor(props) {
    super(props);
    this.wrap_style = {
      border: '2px solid #92A9C7',
      borderRadius: 2,
      backgroundColor: '#DDECFF',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 40,
    }
    this.heading_style = {
      fontSize: 13,
      letterSpacing: 0.65,
      color: '#557298',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
      fontWeight: 'bold',
    }
    this.inputWrap_style = {
      backgroundColor: '#557298',
      borderRadius: 2,
      letterSpacing: 0.6,
      color: '#FFFFFF',
      fontSize: 12,
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
    }
    this.buttons_wrap = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
    this.cancel_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#CB5252',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    this.cancelHover_style = {
      opacity: 0.8,
    }
    this.ok_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#557298',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    this.okHover_style = {
      opacity: 0.8,
    }

  }

  render() {
    const { onCancel, onUpdate, label} = this.props;
    return (
      <div
        style={this.wrap_style}
        onDragOver={(e) => e.preventDefault()}
        onDrop={this.handleOnDrop}
      >
        <div style={this.heading_style}>
          <span>{label ? label :"更新を開始してもよろしいですか？"}</span>
        </div>
        <div style={this.buttons_wrap}>
          <Button
            label='キャンセル'
            style={this.cancel_style}
            hoveredStyle={this.cancelHover_style}
            onClick={onCancel}
          />
          <Button
            label='OK'
            style={this.ok_style}
            hoveredStyle={this.okHover_style}
            onClick={onUpdate}
          />
        </div>
      </div>
    );
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////

class ProgressComponent extends React.Component {

  render() {
    const { progress, label, } = this.props;
    const wrap_style = {
      backgroundColor: '#F0F0F0',
      border: '2px solid #BABABA',
      borderRadius: 2,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 40,
    }
    const label_style = {
      fontSize: 13,
      fontWeight: 'bold',
      letterSpacing: 0.65,
      color: '#868686',
      marginTop: 8,
    }
    return (
      <div style={wrap_style}>
        <Circle
          size={100}
          progress={progress}
        />
        <p style={label_style}>{label}</p>
      </div>

    )
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////

class DidUpdate extends React.Component {

  render() {
    const { onClick, label } = this.props;
    const wrap_style = {
      border: '2px solid #5BBA6A',
      borderRadius: 2,
      backgroundColor: '#CEF5D4',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 40,
    }
    const check_style = {
      backgroundColor: '#5BBA6A',
      marginBottom: 16,
      padding: 12,
      borderRadius: '50%',
    }
    const label_style = {
      fontSize: 13,
      letterSpacing: 0.65,
      color: '#5BBA6A',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 32,
      fontWeight: 'bold',
    }
    const ok_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#5BBA6A',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    const okHover_style = {
      opacity: 0.8,
    }

    return (
      <div style={wrap_style}>
        <Icons.Check1 size={32} style={check_style} color='#CEF5D4' />
        <p style={label_style}>{label}</p>
        <Button
          label='OK'
          style={ok_style}
          hoveredStyle={okHover_style}
          onClick={onClick}
        />
      </div>

    )
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////

class ErrorComponent extends React.Component {

  render() {
    const { onClick, errorMsg, onDownload } = this.props;
    const wrap_style = {
      border: '2px solid #CB5252',
      borderRadius: 2,
      backgroundColor: '#FFEBEB',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 40,
    }
    const label_style = {
      fontSize: 13,
      letterSpacing: 0.65,
      color: '#CB5252',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 32,
      marginTop: 16,
      fontWeight: 'bold',
    }
    const buttons_wrap = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const ok_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#CB5252',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    const UpdateButton_style = {
      borderRadius: 2,
      border: '1px solid #CB5252',
      backgroundColor: '#FFFFFF',
      color: '#CB5252',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    const buttonHover_style = {
      opacity: 0.8,
    }

    return (
      <div style={wrap_style}>
        <Icons.ExclamationTriangle size={64} color='#CB5252' />
        <p style={label_style}>{errorMsg}</p>
        <div style={buttons_wrap}>
          <Button
            label='OK'
            style={ok_style}
            hoveredStyle={buttonHover_style}
            onClick={onClick}
          />
          <Button
            label='詳細'
            style={UpdateButton_style}
            hoveredStyle={buttonHover_style}
            onClick={onDownload}
            hidden={!onDownload}
          />
        </div>
      </div>

    )
  }
}
