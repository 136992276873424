import React, { Fragment } from 'react';

export default class InputArea extends React.Component{
  render(){
    const area_style = {
      display: "flex",
      flexDirection: "column",
    }
  
    return(
      <div style={ area_style }>
        {this.props.children}
      </div>
    )
  }
}