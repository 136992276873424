import React from 'react';
import Icon from './Icon';

export default class Cog extends React.Component {
  render() {
    return (
      <Icon
        {...this.props}
        viewBox="0 0 474.82 496"
        d="M487.4,315.7l-42.6-24.6a192.62,192.62,0,0,0,0-70.2l42.6-24.6a12.09,12.09,0,0,0,5.5-14,249.06,249.06,0,0,0-54.7-94.6,12,12,0,0,0-14.8-2.3L380.8,110A188.72,188.72,0,0,0,320,74.9V25.8a12,12,0,0,0-9.4-11.7,251.39,251.39,0,0,0-109.2,0A12,12,0,0,0,192,25.8V75a194.68,194.68,0,0,0-60.8,35.1L88.7,85.5a11.88,11.88,0,0,0-14.8,2.3,247.56,247.56,0,0,0-54.7,94.6,12,12,0,0,0,5.5,14L67.3,221a192.62,192.62,0,0,0,0,70.2L24.7,315.8a12.09,12.09,0,0,0-5.5,14,249.06,249.06,0,0,0,54.7,94.6,12,12,0,0,0,14.8,2.3l42.6-24.6a188.72,188.72,0,0,0,60.8,35.1v49.2a12,12,0,0,0,9.4,11.7,251.39,251.39,0,0,0,109.2,0,12,12,0,0,0,9.4-11.7V437.2a194.68,194.68,0,0,0,60.8-35.1l42.6,24.6a11.88,11.88,0,0,0,14.8-2.3A247.56,247.56,0,0,0,493,329.8,12.35,12.35,0,0,0,487.4,315.7ZM256,336a80,80,0,1,1,80-80A80.11,80.11,0,0,1,256,336Z"
      />
    )
  }
}