import React from 'react';
import ReactDOM from 'react-dom';
import { options as color } from './Colors'

export default class Options extends React.Component {
  constructor(props) {
    super(props);
    this.idName = Math.random().toString(36).substring(7); // idを乱数生成
  }
  handleOnMouseDown = () => {
    this.filterClick = true;
  }
  windowClick = () => {
    if (this.filterClick) {
      this.filterClick = false;
    } else {
      const { unmount } = this.props;
      unmount && unmount()
    }
  }
  //______________________________________________________________________

  shouldComponentUpdate(nextProps) {
    if (nextProps.render) {
      if (!document.getElementById(this.idName)) {
        window.addEventListener('mouseup', this.windowClick)    // windowイベントwindowClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
        const wrapper = document.body.appendChild(document.createElement('div')); // div要素を作成するための変数を宣言
        wrapper.id = this.idName  // そのdiv要素のidに'modal'を設定

        const { target, setData, onClick, value } = nextProps;
        const positionLeft = target.getBoundingClientRect().left + window.pageXOffset;
        const positionBottom = target.getBoundingClientRect().bottom + window.pageYOffset;
        const box_style = {
          position: "absolute",
          top: positionBottom,
          left: positionLeft,
          zIndex: 1000,
          display: 'inline-flex',
          flexDirection: 'column',
          boxShadow: '0px 2px 5px #00000080',
          border: '1px solid #D9D9D9',
          backgroundColor: "#fff",
          padding: '8px 0px',
          borderRadius: 10,
          maxHeight: 300,
          overflow: 'auto',
        }
        const option_arr = [];
        //______________________________________________________________________
        for (let i in setData) {
          option_arr.push(
            <Option
              key={'option' + i}
              onClick={() => onClick && onClick(setData[i])}
              label={setData[i].label}
              selected={value === setData[i].value}
            />
          )
        }
        // レンダー
        ReactDOM.render(
          <div style={box_style} onMouseDown={this.handleOnMouseDown}>
            {option_arr}
          </div>
          , wrapper
        );
        //______________________________________________________________________

      }
    } else {
      const filterBox = document.getElementById(this.idName); // 作ったid('modal')のdiv要素を取得
      if (filterBox) {
        ReactDOM.unmountComponentAtNode(filterBox);       // 作ったreactDomを削除
        filterBox.parentNode.removeChild(filterBox);            // 一度親に戻って子供を参照して作ったdiv要素を削除（IE対応）
        window.removeEventListener('mouseup', this.windowClick)    // windowイベントwindowClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
      }
    }
    return true;
  }
  render() {
    return null;
  }
}
// ________________________________________________________________________________________________________________

class Option extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
    }
  }
  render() {
    const { label, selected, onClick } = this.props;
    const { hover } = this.state;
    const option_style = {
      backgroundColor:
        hover ? color.hover :
          selected ? color.selected :
            'transparent',
      border: 'none',
      letterSpacing: 0.55,
      color: color.font,
      padding: '8px 16px',
      marginRight: 20,
      // fontSize: 11,
      textAlign: 'left',
      width: '100%',
      minHeight:
        !label ? 28 :
          null,
    }
    return (
      <button
        style={option_style}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
        onClick={onClick}
      >
        {label}
      </button>
    )
  }
}