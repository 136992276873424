import React from 'react';
import ReactDOM from 'react-dom';
import { zindex } from './Styles';

const body = document.getElementById("body")
const confirm = (...arr) => {
  let message = "";
  if (!arr || arr.length == 0) {
    message = "Confirm";
  } else if (typeof arr[0] === 'object') {
    for (let item in arr[0]) {
      message += item + ' : ' + arr[0][item] + '\n';
    }
  } else {
    message = arr[0]
  }

  body.style['overflow'] = 'hidden';
  const wrapper = document.body.appendChild(document.createElement('div')); // div要素を作成するための変数を宣言
  wrapper.id = 'confirm'  // そのdiv要素のidに'confirm'を設定

  const cleanup = () => {
    ReactDOM.unmountComponentAtNode(wrapper); // 作ったreactDomを削除
    const confirm = document.getElementById('confirm'); // 作ったdiv要素のid('confirm')を取得
    confirm.parentNode.removeChild(confirm); // そのconfirmの親に戻って子供を参照して'confirm'を削除（IE対応）
    body.style['overflow'] = null;
  };

  const promise = new Promise((resolve, reject) => {
    try {
      ReactDOM.render(
        <ConfirmationModal
          cleanup={cleanup}
          resolve={resolve}
          message={message}
        />,
        wrapper
      );
    } catch (e) {
      cleanup();
      reject(e);
      throw e;
    }
  });
  return promise;
};

const wrap = {
  position: "fixed",
  display: 'flex',
  zIndex: zindex.confirm,
  width: '100vw',
  height: '100vh',
  left: '0px',
  top: '0px',
  right: '0px',
  bottom: '0px',
  backgroundColor: "rgba(255,255,255,0.3)",
}
const modal = {
  position: "relative",
  minWidth: 500,
  maxWidth: '65%',
  minHeight: 150,
  margin: 'auto',
  backgroundColor: 'white',
  borderRadius: 5,
  border: "1px solid transparent",
  boxShadow: "8px 6px 32px 5px #DCDCE2",
}

const message_style = {
  height: "77%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 16,
  padding: "16px 16px",
  fontWeight: "bold",
  overflow: 'hidden'
}

const message_content = {
  minHeight: 22,
  maxHeight: '50vh',
  overflowY: 'auto',
  width: '100%',
  textAlign: 'center',
  whiteSpace: 'pre-line',
  padding: '5px 5px'
}
const btn_area = {
  height: '15%',
  display: "flex",
  justifyContent: "center",
  width: "100%",
  bottom: 0,
  marginBottom: 16,
}

const header = {
  height: '8%',
  padding: "16px 16px",
  fontWeight: "bold",
  fontSize: 13,
}

const result_btn = {            // <button> : 『はい』、『いいえ』ボタン
  height: 30,
  width: 100,
  fontSize: 14,
  borderRadius: 5,
  backgroundColor: "transparent",
  color: "rgb(34, 20, 120)",
  fontWeight: "bold",
  // boxShadow: "rgb(170, 170, 170) 2px 2px 2px",
  border: "none",
  cursor: "pointer",
  transition: " all 0.3s ease 0s",
  userSelect: 'none',
}
class ConfirmationModal extends React.Component {

  componentDidMount() {
    this.cancelRef.focus();
  }
  // ____________________________________________________________________________________________

  cancel = () => {
    this.props.resolve(false);
    this.props.cleanup();
  };
  // ____________________________________________________________________________________________

  apply = () => {
    this.props.resolve(true);
    this.props.cleanup();
  };
  // ____________________________________________________________________________________________

  btnHover = (action, e) => {
    const style = e.target.style
    switch (action) {
      case "in":
        style.backgroundColor = "rgb(34, 20, 120)";
        style.color = "rgb(255, 255, 255)";
        style.boxShadow = "rgb(34, 20, 180) 0px 0px 1px 1px";
        break
      case "out":
        style.backgroundColor = "transparent";
        style.color = "rgb(34, 20, 120)";
        style.boxShadow = null;
        break
      default: break;
    }
  }
  // ____________________________________________________________________________________________

  handleOnKeyPress = (name, e) => {
    if (e.keyCode === 9 || e.keyCode === 37 || e.keyCode === 39) {
      e.preventDefault();
      switch (name) {
        case "apply": this.cancelRef.focus(); break;
        case "cancel": this.applyRef.focus(); break;
        default:
      }
    }
  }

  render() {
    // ____________________________________________________________________________________________
    return (
      <div style={wrap}>
        <div style={modal}>
          <div style={header}>
            System Message
          </div>
          <div style={message_style}>
            <div style={message_content}>
              {this.props.message}
            </div>
          </div>

          <div style={btn_area}>
            <button
              style={Object.assign({}, result_btn, { marginRight: 16 })}
              ref={(el) => this.applyRef = el}
              onClick={this.apply}
              onMouseOver={(e) => this.btnHover("in", e)}
              onFocus={(e) => this.btnHover("in", e)}
              onMouseLeave={(e) => this.btnHover("out", e)}
              onBlur={(e) => this.btnHover("out", e)}
              onKeyDown={(e) => this.handleOnKeyPress("apply", e)}
            >
              はい
            </button>
            <button
              style={result_btn}
              ref={(el) => this.cancelRef = el}
              onClick={this.cancel}
              onMouseOver={(e) => this.btnHover("in", e)}
              onFocus={(e) => this.btnHover("in", e)}
              onMouseLeave={(e) => this.btnHover("out", e)}
              onBlur={(e) => this.btnHover("out", e)}
              onKeyDown={(e) => this.handleOnKeyPress("cancel", e)}
            >
              いいえ
            </button>
          </div>

        </div>
      </div>
    );
  }
}

export default confirm;