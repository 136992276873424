import React, { Fragment } from 'react';
import * as SKY from "component/SmartComponent";
import { axiosPost, server } from 'comRedux/axiosPost';
import * as smartUtil from 'util/commonInput';
import FacilitySearch from './FacilitySearch';

export default class FacilityInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableOpen: false,
      loading: true,
      currentPage: '1',
      //ItemFormBeanの必要項目をstateで一時管理
      facilityInfoData: {
        facilityCode: '',//  施設コード
        facilityShortName: '',//  施設略名
        facilityName: '',//  施設名
        facilityKanaName: '',//  施設名カナ名
        facilityZipCode: '',//  郵便番号
        kenName: '',//  都道府県
        facilityAddress1: '',//  住所１
        facilityAddress2: '',//  住所２
        facilityTelNo: '',//  電話番号
        facilityFaxNno: '',//  ＦＡＸ番号
        facilityTanto: '',//  担当者1
        wakoOffice: '',//  和光営業所
        wakoTanto: '',//  和光営業担当者
        wakoMail: '',//  和光メールアドレス
        wakoMailcc: '',//  和光ＣＣアドレス
        dealerName: '',//  代理店名
        dealerTanto: '',//  代理店担当者
        dealerTantoTel: '',//  代理店連絡先
        remark: '',//  コメント
        wakoFacilityCode: '',//  和光施設コード
      },
    };
    this.kenCodes = [];
  }
  // ______________________________________________________________________________________________________________________
  // 画面ロード時
  async componentDidMount() {
    //コンボのOptions準備
    // await smartUtil.getOptions("/MName/getCombo?key=KEN").then(res => this.kenCodes = res, err => console.log(err));
    // 参照中の施設があれば、紹介ページを開く
    const facilityCode = sessionStorage.getItem('facilityCode') || this.props.facilityCode
    if (facilityCode) {
      await this.getFacilityInfoData(facilityCode);
    } else {
      if (this.props.setData) {
        this.setState({ ...this.state, ...this.props.setData });
      }
      this.setState({ currentPage: '0' })
      this.setState({ loading: false });
    }
  }
  // _______________________________________________________________________________________
  setFacilityInfo = (rowData) => {
    const { facilityCode } = rowData;
    if (facilityCode) {
      this.setState({ currentPage: '1' })
      this.getFacilityInfoData(facilityCode);
    }
  }
  // _______________________________________________________________________________________
  getFacilityInfoData = async (facilityCode) => {
    this.setState({ loading: true });
    const json = { 'facilityCode': facilityCode }
    await axiosPost.post(server + '/FacilityInfo/searchList', json)
      .then((res) => {
        console.log(res)
        this.setState({
          facilityInfoData: res.data,
          loading: false,
        });
      })
      .catch(err => {
        this.setState({ loading: false });
        // セッションエラー
        console.log(err);
      });
  }
  // _______________________________________________________________________________________
  // 一覧を選択した時
  clickBack = () => {
    this.setState({ currentPage: '0' })
  };
  // _______________________________________________________________________________________
  // テキストボックスの内容が変更された時にstateを更新
  handleOnChangeValue = (name, value) => {
    this.setState({ [name]: value });
  };
  // ______________________________________________________________________________________________________________________
  render() {
    const { tableOpen, loading, facilityInfoData, currentPage } = this.state;
    console.log(facilityInfoData?.searchList)
    if (currentPage === '0') {
      return <FacilitySearch onClick={this.setFacilityInfo} />
    } else {
      return (
        <Fragment>
          {loading && <SKY.Loading popup />}
          <SKY.ModalBody label='施設情報参照'>
            <SKY.Row marginBottom={4} end>
              <SKY.SystemBtn type='back' label="検索画面へ" onClick={this.clickBack} />
            </SKY.Row>
            <SKY.HideableArea>
              {/* ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ */}
              <SKY.TextWrapper label="施設コード">
                <SKY.TextLabel type='alphanum' value={facilityInfoData.facilityCode} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="施設名略名">
                <SKY.TextLabel col={10} value={facilityInfoData.facilityShortName} />
              </SKY.TextWrapper>

            </SKY.HideableArea>

            <SKY.HideableArea>
              <SKY.TextWrapper label='施設情報' colL={24} colR={0} />

              <SKY.TextWrapper label="施設名" colR={20}>
                <SKY.TextLabel col={10} value={facilityInfoData.facilityName} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="施設名カナ名" colR={20}>
                <SKY.TextLabel col={10} value={facilityInfoData.facilityKanaName} />
              </SKY.TextWrapper>


              <SKY.TextWrapper label="郵便番号">
                <span>〒</span>
                <SKY.TextLabel type='text' value={facilityInfoData.facilityZipCode} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="都道府県" >
                <SKY.TextLabel value={facilityInfoData.kenName} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="住所1">
                <SKY.TextLabel col={10} value={facilityInfoData.facilityAddress1} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="住所2">
                <SKY.TextLabel col={10} value={facilityInfoData.facilityAddress2} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="電話番号">
                <SKY.TextLabel type='text' value={facilityInfoData.facilityTelNo} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="FAX番号">
                <SKY.TextLabel type='text' value={facilityInfoData.facilityFaxNo} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="施設担当者" colR={20}>
                <SKY.TextLabel value={facilityInfoData.facilityTanto} />
              </SKY.TextWrapper>

            </SKY.HideableArea>

            <SKY.HideableArea>
              <SKY.TextWrapper label='和光情報' colL={24} colR={0} />

              <SKY.TextWrapper label="和光施設コード">
                <SKY.TextLabel type='alphanum' value={facilityInfoData.wakoFacilityCode} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="和光営業所" >
                <SKY.TextLabel value={facilityInfoData.wakoOffice} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="和光営業担当者" >
                <SKY.TextLabel value={facilityInfoData.wakoTanto} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="和光メールアドレス">
                <SKY.TextLabel type='alphanum' col={10} value={facilityInfoData.wakoMail} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="和光CCアドレス">
                <SKY.TextLabel type='alphanum' col={10} value={facilityInfoData.wakoMailcc} />
              </SKY.TextWrapper>

              <SKY.TextWrapper label="代理店名">
                <SKY.TextLabel value={facilityInfoData.dealerName} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="代理店担当者">
                <SKY.TextLabel value={facilityInfoData.dealerTanto} />
              </SKY.TextWrapper>
              <SKY.TextWrapper label="代理店連絡先">
                <SKY.TextLabel type='text' value={facilityInfoData.dealerTantoTel} />
              </SKY.TextWrapper>

              <SKY.TextWrapper label="コメント" row={2} colR={20}>
                <SKY.Textarea col={5} value={facilityInfoData.remark} disabled />
              </SKY.TextWrapper>
              {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
            </SKY.HideableArea>

            <SKY.TextWrapper label='導入装置' colL={24} colR={0} />

            <SKY.Row>
              <SKY.ListTable
                setData={facilityInfoData.searchList}
                maxRows={smartUtil.getTableRows()}
                headerFixedLength='-56px'
              >
                {[
                  { label: " ", type: "index" },
                  // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
                  { label: "導入年月日", type: "date", name: "introductDate", width: 120 },
                  // { label: "装置コード", type: "label", name: "analyzerCode", width: 100 },
                  { label: "装置名", type: "label", name: "analyzerName", width: 200 },
                  { label: "製造番号", type: "label", name: "serialNo", width: 100 },
                  { label: "呼称／号機", type: "number", name: "machineName", width: 50 },
                  { label: "ｿﾌﾄｳｪｱﾊﾞｰｼﾞｮﾝ", type: "label", name: "softwareVersion", width: 100 },
                  { label: "前回点検日", type: "date", name: "preMainteDate", width: 120 },
                  { label: "保守満了日", type: "date", name: "contractEndDate", width: 120 },
                  // { label: "次回点検月", type: "date", name: "nextMainteYm", width: 100 },
                  { label: "契約タイプ", type: "label", name: "contractTypeName", width: 100 },
                  { label: "和光修理担当", type: "label", name: "extString2", width: 100 },
                  { label: "和光修理連絡先", type: "label", name: "maintePersonTel", width: 100 },
                  { label: "製造元担当", type: "label", name: "extString1", width: 100 },
                  // { label: "契約種類", type: "label", name: "contractKind", width: 100 },
                  // { label: "契約状態", type: "label", name: "contractStatus", width: 100 },
                  // { label: "ｿﾌﾄｳｪｱﾊﾞｰｼﾞｮﾝ2", type: "label", name: "softwareVersion2", width: 100 },
                  // { label: "ｿﾌﾄｳｪｱﾊﾞｰｼﾞｮﾝ(その他)", type: "label", name: "softwareVersionOther", width: 100 },
                  // { label: "測定項目", type: "label", name: "measureItem", width: 100 },
                  // ______________________________________________________________________
                ]}
              </SKY.ListTable>
            </SKY.Row>
          </SKY.ModalBody>
        </Fragment>
      );
    }
  }
}
