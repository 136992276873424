import React, { Fragment } from "react";
import ReactDOM from 'react-dom';
import styled, { keyframes } from "styled-components";
import { loading as Colors } from './Colors'
import { zindex } from './Styles';

export default class LoadingWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.idName = Math.random().toString(36).substring(7); // idを乱数生成
  }
  componentDidMount() {
    const wrapper = document.body.appendChild(document.createElement('div')); // div要素を作成するための変数を宣言
    wrapper.id = this.idName  // そのdiv要素のidに'modal'を設定
    // レンダー
    ReactDOM.render(
      <Loading {...this.props} />
      , wrapper
    );
  }
  componentWillUnmount() {
    const renderItem = document.getElementById(this.idName); // 作ったid('modal')のdiv要素を取得
    if (renderItem) {
      ReactDOM.unmountComponentAtNode(renderItem);       // 作ったreactDomを削除
      renderItem.parentNode.removeChild(renderItem);            // 一度親に戻って子供を参照して作ったdiv要素を削除（IE対応）
      window.removeEventListener('mouseup', this.windowClick)    // windowイベントwindowClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
    }
  }
  render() {
    return null;
  }
}
// ________________________________________________________________________________________________________________

class Loading extends React.Component {
  render() {
    // popupが開いていたら、zIndexをpopupより上位に配置します
    const isModal = document.getElementById('modal') ? true : false
    const wrap_style = {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex:
        isModal ? zindex.popupLoading :
          zindex.loding,
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      width: '100vw',
      height: '100vh',
      background: Colors.bg,
    }
    const rectWrap_style = {
      display: 'flex',
      marginBottom: 12,
    }
    const stretch = keyframes`
      0%,
      40%,
      100% {
        transform: scaleY(0.4);
      }
      20% {
        transform: scaleY(1.0);
      }
    `;
    const Rect = styled.div`
      background-color: #FFFFFF;
      margin-right: 4px;
      height: 68px;
      width: 5px;
      display: inline-block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      animation: ${stretch} 1.2s infinite ease-in-out;
    `;
    const rect2_style = {
      animationDelay: '-1.1s',
    }
    const rect3_style = {
      animationDelay: '-1.0s',
    }
    const rect4_style = {
      animationDelay: '-0.9s',
    }
    const rect5_style = {
      animationDelay: '-0.8s',
    }
    const rect6_style = {
      animationDelay: '-0.7s',
    }
    const text_style = {
      fontWeight: 'bold',
      fontSize: 12,
      letterSpacing: 1.44,
      color: '#FFFFFF',
      userSelect: 'none',
    }
    return (
      <div style={wrap_style}>
        <div style={rectWrap_style}>
          <Rect />
          <Rect style={rect2_style} />
          <Rect style={rect3_style} />
          <Rect style={rect4_style} />
          <Rect style={rect5_style} />
          <Rect style={rect6_style} />
        </div>
        <div style={text_style}>LOADING</div>
      </div>
    )
  }
}