import React, { Fragment } from 'react';
import * as Icons from "../Icons";
import CheckBox from '../CheckBox';
import SystemBtn from '../SystemBtn';
import Row from '../Row';
import { table as Colors } from '../Colors'
import { getComponentLabelName } from '../../util/commonInput'
import FilterInput from './FilterInput';
import Link from '../Link';

const ITEM_HEIGHT = 32;
const WRAP_HEIGHT = 200;
const VIEW_CNT = Math.floor(WRAP_HEIGHT / ITEM_HEIGHT) // 画面上に見える最大の項目数（約8個）
const CHANGE_DOWN_CNT = VIEW_CNT * 14; // リストを更新するスクロール地点
const CHANGE_UP_CNT = VIEW_CNT * 3; // リストを更新するスクロール地点
const SET_CNT = VIEW_CNT * 8; // 追加、削除する項目数
const DEF_VIEWSIZE = VIEW_CNT * 20;

export default class TableFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setList: {}, // チェックの状態を補完するデータリスト。 {name: value}からなる
      viewData: [], // 表示用のデータリスト
      filterValue: '',
      allChecked: '1',
      startCnt: 0,
      endCnt: DEF_VIEWSIZE,
      scrollSize: 0, // スクロールされているサイズ
    }
    this.wrap_style = {
      display: 'inline-flex',
      flexDirection: 'column',
      borderRadius: 10,
      backgroundColor: "#fff",
      maxHeight: WRAP_HEIGHT,
      overflow: 'auto',
      paddingLeft: 4,
    }


  }
  // ______________________________________________________________________________
  componentDidMount() {
    const { setList } = this.props;
    console.log(setList)
    if (setList) {
      const setList_copy = Object.assign({}, setList)
      this.setState({
        setList: setList_copy,
        viewData: Object.keys(setList_copy)
      })
    }
  }
  // ______________________________________________________________________________
  // フィルターリストのチェックボックスを変更したときに、ON/OFFをリストから切り替えます。
  handleOnCheckItem = (name, value) => {
    const { setList } = this.state; // 省略宣言
    const setList_copy = Object.assign({}, setList) // setDataのコピー
    setList_copy[name] = value; // チェックボックスの変更後の値をセット
    this.setState({ setList: setList_copy })  // リストを更新
    console.log(name, value, setList_copy)
  }
  // ______________________________________________________________________________
  // 「全てにチェック」の値が変更された時に、チェックリストを一括更新します。
  handleOnAllCheck = (value) => {
    const { setList, viewData } = this.state; // 省略宣言
    let setList_copy = Object.assign({}, setList); // setListをディープコピーします
    // setListのvalue情報を一括でチェックの値に変更します。（'1' または ''）
    // 更新する情報はフィルタリングされているもののみとします。
    for (let i in viewData) {
      const key = viewData[i]
      setList_copy[key] = value;
    }

    this.setState({ setList: setList_copy, }) // setListの更新
  }
  // ______________________________________________________________________________
  // 「OK」ボタンを押した時に、値の変更があれば、チェックの結果を親に渡します。
  handleResult = () => {
    const { onResult, onClose } = this.props // 省略宣言
    // 値の変更があれば、チェックの結果を親に渡します。
    // リストの性質が、重複なし、ソート済みを想定しているので JSON.stringify で比較しています。
    if (JSON.stringify(this.props.setList) === JSON.stringify(this.state.setList)) {
      onClose && onClose() // フィルターボックスを閉じる
    } else {
      onResult && onResult(this.state.setList, this.state.orderByChecked) // 親に返す
      onClose && onClose() // フィルターボックスを閉じる
    }
  }
  // ______________________________________________________________________________
  //キャンセルボタンを押した時に、setListの状態をリセットして、フィルターを閉じます。
  handleCancel = () => {
    const { onClose, setList } = this.props // 省略宣言
    this.setState({ setList: Object.assign({}, setList) }) // 新しく表示したsetListの状態（チェック）をリセットします
    onClose && onClose() // フィルターを閉じます
  }
  // ______________________________________________________________________________
  // フィルターリスト内でスクロールした時に、必要な分のみのリストを表示します。
  handleOnScrollFilter = (e) => {
    const { startCnt, endCnt, scrollSize } = this.state;
    // スクロール量にアイテムの高さを割ることで、リストアイテム何個分のスクロールか計算します
    const scrollCnt = Math.floor(e.target.scrollTop / ITEM_HEIGHT)
    // console.log(scrollCnt, CHANGE_DOWN_CNT)
    // リストを更新する地点を超えたら更新します。
    if (scrollCnt > CHANGE_DOWN_CNT) {
      // console.log(scrollCnt, CHANGE_DOWN_CNT)
      this.setState({
        startCnt: startCnt + SET_CNT,
        endCnt: endCnt + SET_CNT,
      })

      // 更新する地点を戻ったら逆更新します。
    } else if (startCnt !== 0 && scrollCnt < CHANGE_UP_CNT) {
      this.setState({
        startCnt: startCnt - SET_CNT,
        endCnt: endCnt - SET_CNT,
      })
    }
  }
  // ______________________________________________________________________________
  // リストのフィルタリングの入力値が変更された時
  handleChangeFilterInput = (value) => {
    const { setList } = this.state; // 省略宣言
    let filterData = [];
    filterData = Object.keys(setList); // key情報を取得して配列可
    filterData = filterData.filter(data => {
      // 検索する値と、検索されるデータを、String文字列に変更して、英数字は大文字に変更します。
      const searchData = String(data).toLowerCase();
      const searchValue = String(value).toLowerCase();
      const result = searchData.search(searchValue) !== -1 ? true : false; // 検索結果
      return result;
    });
    console.log(filterData)
    this.setState({
      viewData: filterData, // 入力値の状態をセット
      startCnt: 0,
      endCnt: DEF_VIEWSIZE,
    })
  }
  // ______________________________________________________________________________

  // get
  render() {
    const { setList, startCnt, endCnt, viewData } = this.state;
    const filterItem_arr = [];
    let _viewSize = viewData.length < endCnt ? viewData.length : endCnt
    for (let i = startCnt; i < _viewSize; i++) {
      filterItem_arr.push(
        <CheckBox
          key={'setList_' + i}
          name={viewData[i]}
          label={viewData[i]}
          value={setList[viewData[i]]}
          onChange={(name, value) => this.handleOnCheckItem(name, value)}
          fontSize={12}
        />
      )
    }

    return (
      <Fragment>
        <FilterInput onChange={this.handleChangeFilterInput} />
        <Row margin="8px 0px 12px 8px">
          <Link label="全てにチェック" onClick={() => this.handleOnAllCheck('1')} style={{ textDecoration: 'none' }} />
        </Row>
        <Row margin="0px 0px 12px 8px">
          <Link label="全てのチェックを外す" onClick={() => this.handleOnAllCheck('')} style={{ textDecoration: 'none' }} />
        </Row>
        <div style={this.wrap_style} onScroll={this.handleOnScrollFilter}>
          {filterItem_arr}
        </div>
        <Row end padding={8}>
          <SystemBtn label={getComponentLabelName('comm.cancel')} invColor onClick={this.handleCancel} />
          <SystemBtn label={getComponentLabelName('comm.ok')} marginLeft={8} onClick={this.handleResult} />
        </Row>
      </Fragment>
    )
  }
}
