import React from 'react';
import { table as Colors } from '../Colors'

export default class IndexCell extends React.Component {
  constructor(props) {
    super(props);
    this.td_style = {                // <td> 各セル
      border: "1px solid" + Colors.border,
      // fontWeight: "bold",
      // fontSize: '1.1rem',
      height: 25,
      width: 30,
      minWidth: 30,
      transition: "0.3s",
      textAlign: "center",
      verticalAlign: "middle",
      zIndex: this.props.zIndex || null,
    }
  }
  // _____________________________________________________________________________
  componentDidUpdate() {
    const { didUpdate } = this.props;
    didUpdate && didUpdate();
  }
  // _____________________________________________________________________________
  render() {
    const { index, disabled, color, children, style = {}, fixedWidth, rowSpan, colSpan } = this.props;
    Object.assign(this.td_style, {
      backgroundColor:
        disabled === true ? "#f7f7f7" :
          color,
      position:
        fixedWidth || fixedWidth === 0 ? 'sticky' :
          null,
      left: fixedWidth,
      height: children?.height || 25,
      // 3桁は40px, 4桁は50px...
      width:
        !index ? 30 :
          (String(index + 1).length + 1) * 10 < 30 ? 30 :
            (String(index + 1).length + 1) * 10
    })
    // console.log(index, '：', (String(index + 1).length + 1) * 10)
    // _____________________________________________________________________________
    return (
      <td style={Object.assign({}, this.td_style, style)} rowSpan={rowSpan || 1000} colSpan={colSpan}>
        {index + 1}
      </td>
    )
  }
}
