import React from 'react';
import * as Icons from "../Icons";
import { table as Colors } from '../Colors'

export default class UpCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false,
    }
    this.td_style = {                // <td> 各セル
      width: 30,
      minWidth: 30,
      border: "1px solid #ddd",
      textAlign: "center",
      verticalAlign: "middle",
      paddingTop: 4,
      transition: '0.2s',
    }
  }
  render() {
    const { onClick, disabled, color, children, minWidth = 30, fixedWidth, rowSpan, icon, tabIndex } = this.props;
    const { hover, focus } = this.state;
    const td_style = {                // <td> 各セル
      width: 30,
      height: children?.height || 25,
      minWidth: minWidth,
      border: `1px solid ${Colors.border}`,
      textAlign: "center",
      verticalAlign: "middle",
      paddingTop: 4,
      transition: '0.2s',
      backgroundColor:
        hover || focus ? "#Cf8080" :
          color,
      pointerEvents:
        disabled ? "none" :
          "auto",
      cursor:
        disabled ? null : 'pointer',
      position:
        fixedWidth || fixedWidth == 0 ? 'sticky' :
          null,
      left: fixedWidth,
    }

    const iconProps = {
      color: hover || focus ? "#FFFFFF" : "#Cf8080",
      size: 16,
    }
    let iconComponent = null;
    if (!disabled) {
      switch (icon) {
        case 'delete': {
          iconComponent = <Icons.TimesCircle {...iconProps} />
          break;
        }
        case 'up': {
          iconComponent = <Icons.AngleDoubleUp {...iconProps} />
          break;
        }
        case 'down': {
          iconComponent = <Icons.AngleDoubleDown {...iconProps} />
          break;
        }
        case 'add': {
          iconComponent = <Icons.PlusSquare {...iconProps} />
          break;
        }
        case 'copy': {
          iconComponent = <Icons.Copy {...iconProps} />
          break;
        }
        default: {
          break;
        }
      }
    }
    return (
      <td
        style={td_style}
        rowSpan={rowSpan || 1000}
        onClick={onClick}
        tabIndex={!disabled ? tabIndex || 0 : null}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >
        {iconComponent}
      </td>
    )
  }
}
