import React, { Fragment } from 'react';
import '../css/common-tool.css';
import classNames from "classnames";

export default class Switcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      on: props.on
    }
    this.init = true;
  }

  componentDidMount() {
    const { ref } = this.props
    if (!!ref) {
      ref(this)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.on !== nextProps.on) {
      this.setState({ on: nextProps.on })
      return true
    }
    if (this.props.disabled !== nextProps.disabled) {
      return true
    }
    if (this.state !== nextState) {
      return true
    }
    return false
  }

  handleSwitch = e => {
    if (this.props.disabled) return;
    this.init = false;
    this.setState({ on: !this.state.on }, () => {
      this.props.onClick && this.props.onClick(e, this.state.on)
    })
  }

  render() {
    const { on } = this.state
    return (
      <div className="smart-switch-wrapper" onClick={this.handleSwitch}>
        <div className={classNames({
          'smart-switch-bar': true,
          'smart-switch-on-init': on && this.init,
          'smart-switch-off-init': !on && this.init,
          'smart-switch-on': on && !this.init,
          'smart-switch-off': !on && !this.init,
          'smart-switch-disabled': this.props.disabled,
        })} >
          <div className={classNames({ 'smart-switch-button': true, 'smart-switch-button-shadow': on, 'smart-switch-button-disabled': this.props.disabled })} >
          </div>
        </div>
      </div>
    )
  }
}