import React from 'react';
import Icon from './Icon';

export default class CaretLeft extends React.Component {
  render() {
    return (
      <Icon
        {...this.props}
        viewBox="0 0 192 512"
        d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"
      />
    )
  }
}