import React from 'react';
import Icon from './Icon';

export default class ChartBar extends React.Component {
  render() {
    return (
      <Icon
        {...this.props}
        viewBox="0 0 512 384"
        d="M396.8,352h22.4c6.4,0,12.8-6.4,12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8H396.8c-6.4,0-12.8,6.4-12.8,12.8V339.2C384,345.6,390.4,352,396.8,352Zm-192,0h22.4c6.4,0,12.8-6.4,12.8-12.8V140.8c0-6.4-6.4-12.8-12.8-12.8H204.8c-6.4,0-12.8,6.4-12.8,12.8V339.2C192,345.6,198.4,352,204.8,352Zm96,0h22.4c6.4,0,12.8-6.4,12.8-12.8V204.8c0-6.4-6.4-12.8-12.8-12.8H300.8c-6.4,0-12.8,6.4-12.8,12.8V339.2C288,345.6,294.4,352,300.8,352ZM496,400H48V80A16,16,0,0,0,32,64H16A16,16,0,0,0,0,80V416a32,32,0,0,0,32,32H496a16,16,0,0,0,16-16V416A16,16,0,0,0,496,400ZM108.8,352h22.4c6.4,0,12.8-6.4,12.8-12.8V268.8c0-6.4-6.4-12.8-12.8-12.8H108.8c-6.4,0-12.8,6.4-12.8,12.8v70.4C96,345.6,102.4,352,108.8,352Z"
      />
    )
  }
}