export default{
  title:"管理業務をさらにスマートに",
  subTitle:"smart 管理システム  \r\n 業務を快適にできるWEBアプリケーションです。",
  lang:{
    chinese:'ZH',
    japanese:'JA',
    english:'EN',
    thai:'TH',
    korean:'KO',
    taiwan:'KO',
    hongkong:'KO',
    french:'FR',
    german:'DE',
    italian:'IT',
    spanish:'ES',
    vietnames:'VI',
    hindi:'HI',
    indonesian:'ID',
  },
  lang_JA:{
      chinese:'中国語',
      japanese:'日本語',
      english:'英語',
      thai:'タイ語',
      korean:'韓国語',
      taiwan:'台湾語',
      hongkong:'香港語',
      french:'フランス語',
      german:'ドイツ語',
      italian:'イタリア語',
      spanish:'スペイン語',
      vietnames:'ベトナム語',
      hindi:'ヒンディ語',
      indonesian:'インドネシア語',
  },
  lang_ZH:{
    chinese:'汉语',
    japanese:'日语',
    english:'英语',
    thai:'泰语',
    korean:'韩语',
    taiwan:'繁体汉语（台）',
    hongkong:'繁体汉语（港）',
    french:'法语',
    german:'德语',
    italian:'意大利语',
    spanish:'西班牙语',
    vietnames:'越南语',
    hindi:'印地语',
    indonesian:'印尼语',
},
lang_EN:{
  chinese:'chinese',
  japanese:'japanese',
  english:'english',
  thai:'thai',
  korean:'korean',
  taiwan:'taiwan',
  hongkong:'hongkong',
  french:'french',
  german:'german',
  italian:'italian',
  spanish:'spanish',
  vietnames:'vietnames',
  hindi:'hindi',
  indonesian:'indonesian',
},

}