import React from 'react';
import * as Icons from "../Icons";

export default class DetailSwitchTH extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
  }
  // ______________________________________________________________________

  render() {
    const { render, detailOpen, onClick } = this.props;
    const { hover } = this.state;
    if (!render) { return null; }

    const td_style = {
      width: 30,
      textAlign: "center",
      verticalAlign: "middle",
      transition: "0.2s",
      backgroundColor:
        hover ? '#5BBA6A' :
          "#F5F5F5",
      padding: "8px 5px",
      border: "1px solid #ddd",
      // fontWeight: "bold",
      // fontSize: '1.1rem',
      height: 28,
      left: 0,
      position: 'sticky',
    }

    const iconColor = hover ? '#fff' : "#5BBA6A";
    return (
      <td
        style={td_style}
        onClick={onClick}
        rowSpan={1000}
        tabIndex={0}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        <Icons.PlusSquare width={15} height={15} color={iconColor} hidden={detailOpen} />
        <Icons.MinusSquare width={15} height={15} color={iconColor} hidden={!detailOpen} />
      </td>
    )
  }
}