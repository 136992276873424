import React from 'react';
import { table as Colors } from '../Colors'
import * as Icons from "../Icons";
import BasicTD from './BasicTD';
import { formatNumber, getPMasterName, } from '../../util/commonInput';

export default class InputCell extends React.Component {
  constructor(props) {
    super(props);
    const { number, decimal, value, } = this.props;
    this.state = {
      value: value === null ? '' : value,
      oldValue: value === null ? '' : value,
      initValue: value === null ? '' : value,
      focus: false,
    }
    this.getDefaultValue = false;
    this.text_style = {  // <input> テキストボックスのスタイル
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      // fontSize: '1.1rem',
      border: "none",
      outline: "none",
      padding: "0px 4px",
      boxSizing: "border-box",
      verticalAlign: "middle",
      color: 'inherit', // rowStyle適用の為
      textAlign:
        number || decimal ? "right" :   // 数値型なら文字を右寄せに
          null,
    }
    this.unit_style = {
      padding: "0px 2px",
    }
    this.innerWrap_style = {
      width: "100%",
      height: "100%",
      display: 'flex',
      alignItems: 'center',
    }
  }
  //______________________________________________________________________________________________________
  shouldComponentUpdate(nextProps, nextState) {
    const { number, decimal, value } = this.props;
    if (value !== nextProps.value) {
      if (number || decimal) if (isNaN(value) && isNaN(nextProps.value)) { return false } // NaNは判断に引っかからなかったので
      if (!this.getDefaultValue) {
        this.setState({ oldValue: nextProps.value, initValue: nextProps.value })
        this.getDefaultValue = true;
      }
      this.setState({ value: nextProps.value === 0 ? 0 : nextProps.value || '' })
    }
    return true;
  }
  //______________________________________________________________________________________________________
  // Component State管理
  handleOnChange = (e) => {
    // const old = this.state.value;
    const { number, alphanum, decimal, upperCase } = this.props;
    let inputValue = e.target.value;
    let replaceValue = inputValue;
    if (number) {
      replaceValue = inputValue.replace(/[^0-9]/g, '');
    }
    else if (alphanum) {
      replaceValue = inputValue.replace(/[^a-zA-Z0-9!#$%&\()*.+/:;=?@\[\]^_{}-]/g, '');
    }
    else if (upperCase) {
      replaceValue = inputValue.replace(/[^a-zA-Z0-9!#$%&\()*.+/:;=?@\[\]^_{}-]/g, '');
      replaceValue = String(replaceValue).toUpperCase();
    }
    else if (decimal) {
      const { maxLength = "15,5" } = this.props.children;
      const maxLength_arr = String(maxLength).split(',');
      const dec_maxLength = maxLength_arr[1];
      replaceValue = inputValue.replace(/[^\d||.]/g, ''); // 入力制限：数値とマイナス、ピリオドのみ適応
      let int = replaceValue.split('.')[0]
      let dec = replaceValue.split('.')[1] // ピリオドがあるかどうか 無し:-1, 先頭:0, それ以外:1以上
      if (dec) {
        dec = dec.substr(0, dec_maxLength) //　最初のピリオドからdec_maxLengthまでの入力値のみ適応
        replaceValue = (int || 0) + '.' + dec
      }
      // 入力値の先頭がマイナスなら、マイナスを付与
      if (inputValue.indexOf('-') === 0) { replaceValue = '-' + replaceValue }
    }
    // console.log(replaceValue)
    this.setState({
      // oldValue: old,
      value: replaceValue,
      showList: e.target.value === "" ? false : true
    });
  }
  // ________________________________________________________________________________________________________________

  //Onblur時　InputTableに最新状態を渡す
  handleOnBlur = (fowardData) => {
    const { onBlur, forceBlur } = this.props.children
    this.setState({ focus: false, showList: false })
    this.props.cellOnBlur && this.props.cellOnBlur(fowardData); // 共通CelllOnBlurのEvent
    if (!!forceBlur || this.state.oldValue !== this.state.value) {
    }
    onBlur && onBlur(fowardData, this.props.setData);//Cellごとの特別なOnblurのEventを先に実行
  }
  // ________________________________________________________________________________________________________________
  handleOnFocus = (e, fowardData) => {
    const { onFocus } = this.props.children
    e.target.select();
    this.setState({ focus: true, initValue: this.state.value, oldValue: this.state.value });
    onFocus && onFocus(fowardData, e)
  }
  handleOnKeyUp = (fowardData) => {
    const { onKeyUp } = this.props.children
    this.props.cellOnKeyUp && this.props.cellOnKeyUp(fowardData); // 共通CelllOnBlurのEvent
    onKeyUp && onKeyUp(fowardData);//Cellごとの特別なOnblurのEvent
  }

  handleOnMouseOver = (e, fowardData) => {
    const { onMouseOver } = this.props.children
    onMouseOver && onMouseOver(e, fowardData)
  }

  handleOnMouseOut = (e, fowardData) => {
    const { onMouseOut } = this.props.children
    onMouseOut && onMouseOut(e, fowardData)
  }

  // Itemを選択したとき
  handleOnSelectItem = (item) => {
    if (this.popUp && this.popUp.disposePopUp) this.popUp.disposePopUp();
    this.handleOnMouseMove(true);
    this.setState({
      oldValue: this.state.value,
      value: item,
      showList: false,
      focus: true,
    })
    const {
      index,
    } = this.props
    const {
      oldValue,
      initValue,
    } = this.state;
    const fowardData = { index: index, name: this.props.children.name, value: item, oldValue: oldValue, initValue: initValue } //　一番親に送るデータ
    this.handleOnBlur(fowardData);
  }
  handleOnMouseMove = (value) => {
    this.doBlur = value;
  }
  // _______________________________________________________________________________________________________
  updateIsPopUpClick = value => {
    this.setState({ isPopUpClick: value })
  }
  // ________________________________________________________________________________________________________________
  render() {
    const {
      decimal,
      number,
      disabled,
      index,
      onClickModal,
      arrayIndex,
      detailIndex,
      text,
      unit,
      tabIndex,
    } = this.props
    const {
      name,
      maxLength,
      modal,
      format,
      autoComplete,
      dataDiv,
      fixed
    } = this.props.children
    const {
      value,
      oldValue,
      initValue,
      focus,
      showList,
    } = this.state;

    const zero = this.props.zero || this.props.children.zero
    let pUnit = getPMasterName(unit) || unit;

    const fowardData = { index: index, name: name, value: value, oldValue: oldValue, initValue: initValue, arrayIndex: arrayIndex, detailIndex: detailIndex } //　一番親に送るデータ
    let _maxLength = maxLength
    // decimalの場合、maxLengthを変動させる必要がある
    if (decimal) {
      _maxLength = maxLength ? String(maxLength).split(',')[0] : 15 // デフォルトは15桁
      _maxLength = Number(_maxLength)
      // マイナス、ピリオドがついていたら+1桁
      if (String(value).indexOf('-') === 0) { _maxLength += 1 }
      if (String(value).indexOf('.') >= 0) { _maxLength += 1 }
    }
    let setValue = value;
    if (!focus && format) {
      setValue = formatNumber(value, format);
    }
    if (!zero && value === 0) setValue = '';
    //______________________________________________________________________________________________________
    return (
      <BasicTD {...this.props} config={this.props.children}>
        <div style={this.innerWrap_style}>
          <input
            type={!text ? "tel" : null}
            autoComplete="off"
            name={name}
            value={setValue}
            ref={(el) => this.input_ref = el}
            //style={{ ...this.text_style, backgroundColor: fixed ? 'white' : this.text_style.backgroundColor }}
            //style={this.text_style}
            style={{ ...this.text_style, color: ((decimal || number) && value < 0 ? 'red' : null) }}
            disabled={disabled}
            maxLength={_maxLength}
            onChange={this.handleOnChange}
            onFocus={(e) => this.handleOnFocus(e, fowardData)}
            onBlur={() => this.handleOnBlur(fowardData)}
            onKeyUp={() => this.handleOnKeyUp(fowardData)}
            onMouseOver={(e) => this.handleOnMouseOver(e, fowardData)}
            onMouseOut={(e) => this.handleOnMouseOut(e, fowardData)}
            tabIndex={tabIndex}
          />
          {pUnit && <span style={this.unit_style}>{pUnit}</span>}
          <ModalIcon onClick={onClickModal} hidden={disabled || !modal} />

        </div>
      </BasicTD>
    )
  }
}
// _______________________________________________________________________
// 虫メガネのアイコン
class ModalIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false,
    }
  }
  //____________________________________________________
  handleOnMouseOver = () => {
    this.setState({ hover: true })
    const { onMouseOver } = this.props;
    onMouseOver && onMouseOver()
  }
  //____________________________________________________
  handleOnMouseLeave = () => {
    this.setState({ hover: false })
    const { onMouseLeave } = this.props;
    onMouseLeave && onMouseLeave()
  }
  //____________________________________________________
  handleOnFocus = () => {
    this.setState({ focus: true })
    const { onFocus } = this.props;
    onFocus && onFocus()
  }
  //____________________________________________________
  handleOnBlur = () => {
    this.setState({ focus: false })
    const { onBlur } = this.props;
    onBlur && onBlur()
  }
  //____________________________________________________
  render() {
    const { onClick, hidden } = this.props;
    const { focus, hover } = this.state;
    if (hidden) { return null }
    const wrap_style = {
      display: "flex",
      backgroundColor:
        hover || focus ? Colors.modal :
          "transparent",
      borderRadius: 5,
      padding: 2,
      border: "none",
      transition: "0.2s",
      cursor: "pointer",
    }
    const iconColor = hover || focus ? "#FFF" : Colors.modal;
    return (
      // eslint-disable-next-line no-useless-concat
      <button
        type='button'
        onClick={onClick}
        style={wrap_style}
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
        onMouseOver={this.handleOnMouseOver}
        onMouseLeave={this.handleOnMouseLeave}
      >
        <Icons.Search1 width={16} height={16} color={iconColor} />
      </button>
    );
  }
}

