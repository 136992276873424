import React, { Fragment } from 'react';
import BasicWrap from './BasicWrap';
import CalendarItem from './CalendarItem';
import { convertDate } from '../util/commonInput';
import { calendar as Colors } from './Colors'
import * as Icons from "./Icons";

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);
    const {
      value,
      type,
      disabled,
    } = this.props;
    this.state = {
      value: value || '',
      showCalendar: false,
    };
    this.wrap_style = {            // <div>：テキストのwrap
      display: "inline-flex",
      flexDirection: "column",
    }
    this.input_style = {
      fontSize: 'inherit',
      height: '100%',
      width: '100%',
      paddingLeft: 4,
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      paddingRight: 20,
      pointerEvents:
        disabled === true ? "none" :
          "auto",
    }
    this.icon_style = {
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 4,
    }
  }
  // _____________________________________________________________________
  shouldComponentUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      const fixedValue = nextProps.value ? nextProps.value : ""; // null や undifinedを''に修正
      this.setState({ value: fixedValue });
    }
    return true
  }
  // ______________________________________________________________________
  handleOnChange = (e) => {
    const replaceValue = e.target.value.replace(/[^\d|/]/g, '');  // "0~9"と"/"以外を取り除きます。
    this.setState({ value: replaceValue })
  }
  // _______________________________________________________________________________________________________
  // 日付を選択したとき
  handleOnSelectDate = (date) => {
    const { onChange, name } = this.props;
    this.setState({
      value: date,
      showCalendar: false,
    })
    onChange && onChange(name, date); // redux対応
  }
  // _______________________________________________________________________________________________________
  handleOnKeyDown = (e) => {
    if (e.keyCode === 9) {
      this.setState({ showCalendar: false, })
    } else if (e.keyCode === 13) {
      this.setState({ showCalendar: true, })
    }
  }
  // _______________________________________________________________________________________________________
  handleOnBlur = (e) => {
    const { type, onChange, name } = this.props
    const inputValue = e.target.value.replace(/[^\d|/]/g, '');  // "0~9"と"/"以外を取り除きます。
    const thisYear = new Date().getFullYear();　// 今年
    const thisMonth = ("0" + (new Date().getMonth() + 1)).slice(-2) // 今月
    const lastDate = new Date(thisYear, thisMonth, 0).getDate();          // 今月の最終日
    let replaceValue = "";
    let DD = null;
    let MM = null;
    let YYYY = null;
    let inputLastDate = null; // 入力された月の最終日
    let checkMM = null;


    if (type === "YYYY/MM") {     // 年月カレンダーの場合
      switch (inputValue.length) {                     //  入力した値の桁数に対して分岐しています
        case 1: case 2: // 1桁 または ２桁 の場合
          if (inputValue >= 1 && inputValue <= 12) {    // ０ではなく、今月の最終日より値が下だったら
            MM = ("0" + inputValue).slice(-2);
            replaceValue = thisYear + "/" + MM
          }
          break;
        case 6: {
          MM = inputValue[4] + inputValue[5];
          YYYY = inputValue[0] + inputValue[1] + inputValue[2] + inputValue[3]    // ５桁目～８桁目
          if (MM >= 1 && MM <= 12) {
            replaceValue = YYYY + "/" + MM
          }
          break
        }
        case 7: {
          if (inputValue[4] === "/") {
            replaceValue = inputValue
          }
          break;
        }
        default: { break; }
      }
      if (!replaceValue && inputValue && new Date(inputValue) !== 'Invalid Date') {
        replaceValue = convertDate({ type: 'YYYY/MM', date: new Date(inputValue) })
      }
    }
    // ____________________________________________________________________________________

    else {
      switch (inputValue.length) {                                        //  入力した値の桁数に対して分岐しています
        case 1: case 2: { // 1桁 または ２桁 の場合
          if (inputValue !== "0" && inputValue <= lastDate) {         // ０ではなく、今月の最終日より値が下だったら
            DD = ("0" + inputValue).slice(-2);
            replaceValue = thisYear + "/" + thisMonth + "/" + DD
          }
          else if (inputValue[1] !== "0" && inputValue > lastDate) {   // １桁目が０ではなく、今月の最終日より値が上だったら
            DD = ("0" + inputValue[1]).slice(-2);
            MM = ("0" + inputValue[0]).slice(-2);
            replaceValue = thisYear + "/" + MM + "/" + DD
          }
          break;
        }
        case 3: {
          if (inputValue[1] === "/") {                      // ２桁目に”／”が入っていたら
            DD = ("0" + inputValue[2]).slice(-2);          // １桁目に０を追加して日付とします
          } else {                                           // それ以外なら  
            DD = inputValue[1] + inputValue[2];             // １桁目と２桁目を結合して日付とします
          }

          MM = ("0" + inputValue[0]).slice(-2);            // ３桁目に０を追加して月とします
          inputLastDate = new Date(thisYear, MM, 0).getDate();    // 入力した月の最終日

          if (inputValue[0] !== "0" && DD > 0 && DD <= inputLastDate) {  // MM（月）が０ではなく、DD（日付）が０より上、かつ入力した月の最終日より小さかったら
            replaceValue = thisYear + "/" + MM + "/" + DD             // valueをYYYY/MM/DDに置き換えます
          }
          break;
        }
        case 4: case 5: {
          if (inputValue[2] === "/") {
            DD = inputValue[3] + inputValue[4];
            MM = inputValue[0] + inputValue[1];
          } else {
            DD = inputValue[2] + inputValue[3];
            MM = inputValue[0] + inputValue[1];
          }
          inputLastDate = new Date(thisYear, MM, 0).getDate();          // 入力した月の最終日
          if (MM !== "00" && MM <= 12 && DD > 0 && DD <= inputLastDate) {
            replaceValue = thisYear + "/" + MM + "/" + DD
          }
          break;
        }
        case 8: {
          DD = inputValue[6] + inputValue[7]                                    // １桁目と２桁目
          MM = inputValue[4] + inputValue[5]                                    // ３桁目と４桁目
          YYYY = inputValue[0] + inputValue[1] + inputValue[2] + inputValue[3]    // ５桁目～８桁目
          checkMM = new Date(YYYY, MM - 1, DD).getMonth() + 1               // 日付が正当かどうか確認するための宣言（日付が32だったりすると次の月に移動するので不正当）
          checkMM = ("0" + checkMM).slice(-2);
          if (MM === checkMM) {
            replaceValue = YYYY + "/" + MM + "/" + DD
          }
          break
        }
        case 10: {
          if (inputValue[4] === "/" && inputValue[7] === "/") {
            DD = inputValue[8] + inputValue[9]                                    // 
            MM = inputValue[5] + inputValue[6]                                    // 
            YYYY = inputValue[0] + inputValue[1] + inputValue[2] + inputValue[3]    // 
            checkMM = new Date(YYYY, MM - 1, DD).getMonth() + 1               // 日付が正当かどうか確認するための宣言（日付が32だったりすると次の月に移動するので不正当）
            checkMM = ("0" + checkMM).slice(-2);
            if (MM === checkMM) {
              replaceValue = inputValue
            }
          }
          break
        }
        default: { break; }
      }
      if (!replaceValue && inputValue && new Date(inputValue) !== 'Invalid Date') {
        replaceValue = convertDate({ date: new Date(inputValue) })
      }
    }
    if (MM === "00" || DD === "00") {
      replaceValue = ""
    }
    this.setState({
      value: replaceValue,
    })
    onChange && onChange(name, replaceValue); // redux対応
  }
  // _________________________________________________________________________________________________________________
  render() {
    const { disabled, style, autoFocus, } = this.props
    const { value, showCalendar, } = this.state
    // _______________________________________________________________________________________________________
    return (
      <BasicWrap {...this.props} style={this.wrap_style} value={value}>
        <input
          autoFocus={autoFocus}
          type="tel"
          ref={(el) => this.input_ref = el}
          style={Object.assign({}, this.input_style, style)}
          value={value}
          disabled={disabled}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          onKeyDown={this.handleOnKeyDown}
          onClick={() => this.setState({ showCalendar: true })}
        />
        <Icons.Calendar style={this.icon_style} width={14} height={14} color={Colors.icon} hidden={disabled} onClick={() => this.setState({ showCalendar: true })}/>
        <CalendarItem
          render={showCalendar}
          target={this.input_ref}
          unmount={() => this.setState({ showCalendar: false })}
          value={value}
          onChange={this.handleOnSelectDate}
        />
      </BasicWrap>
    )
  }
}
