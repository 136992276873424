/* eslint-disable jsx-a11y/no-access-key */
import React from 'react';
import { getPMasterName, hasPermission, getCurrentMenuId } from 'util/commonInput';
import * as Icons from "./Icons";
import { systemBtn as Colors } from './Colors'

export default class SystemBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false,
    }
  }
  // ______________________________________________________________________________
  shouldComponentUpdate(nextProps) {
    const { hidden } = this.props;
    if (hidden !== nextProps.hidden) {
      this.setState({
        hover: false,
        focus: false,
      })
    }
    return true;
  }
  // ______________________________________________________________________________
  handleOnClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { onClick } = this.props;
    onClick && onClick(e);
  }
  // ______________________________________________________________________________

  render() {
    const {
      onKeyDown,
      label,
      children,
      type,
      hidden,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      plabel,
      plabel2,
      color,
      width,
      height,
      fontSize,
      fontColor,
      invColor, // 文字色と背景色の反転
      accessKey,
    } = this.props
    const { hover, focus } = this.state

    // hidden設定
    let Plabel = plabel && getPMasterName(plabel);
    let Plabel2 = plabel2 && getPMasterName(plabel2);
    if (Plabel === 'NONE') { return null }
    if (hidden) { return null }

    let typeIcon = null;
    let btnColor = null;
    let _accessKey = null;
    let _fontColor = fontColor || Colors.font;
    let hoverColor = invColor ? Colors.invHover : Colors.hover; // hoverした時の変色
    // ______________________________________________________________________________
    let reverse = false // アイコンを左に置くか右に置くか
    // typeによって表示するボタンを制御
    switch (type) {
      case 'add': {
        if (hasPermission(getCurrentMenuId()) === false) { return null } // 権限のないものはボタンを表示しない
        typeIcon = <Icons.PlusCircle size={14} color={invColor ? Colors.add : _fontColor} />
        btnColor = Colors.add
        break;
      }
      case 'edit': {
        if (hasPermission(getCurrentMenuId()) === false) { return null } // 権限のないものはボタンを表示しない
        typeIcon = <Icons.Edit2 size={14} color={invColor ? Colors.add : _fontColor} />
        btnColor = Colors.add
        break;
      }
      case 'back': {
        typeIcon = <Icons.ArrowLeftCircle size={14} color={invColor ? Colors.back : _fontColor} />
        btnColor = Colors.back
        _accessKey = 'b' // Alt + b でクリックイベント発火
        break;
      }
      case 'submit': {
        if (hasPermission(getCurrentMenuId()) === false) { return null } // 権限のないものはボタンを表示しない
        typeIcon = <Icons.ClipboardCheck size={14} color={invColor ? Colors.submit : _fontColor} />
        btnColor = Colors.submit
        _accessKey = 's' // Alt + s でクリックイベント発火
        break;
      }
      case 'search': {
        typeIcon = <Icons.Search1 size={14} color={invColor ? Colors.search : _fontColor} />
        btnColor = Colors.search
        break;
      }
      case 'confirm': {
        if (hasPermission(getCurrentMenuId()) === false) { return null } // 権限のないものはボタンを表示しない
        typeIcon = <Icons.Eye size={14} color={invColor ? Colors.confirm : _fontColor} />
        btnColor = Colors.confirm
        _accessKey = 's' // Alt + s でクリックイベント発火
        break;
      }
      case 'delete': {
        if (hasPermission(getCurrentMenuId()) === false) { return null } // 権限のないものはボタンを表示しない
        typeIcon = <Icons.Trash size={14} color={invColor ? Colors.delete : _fontColor} />
        btnColor = Colors.delete
        break;
      }
      case 'camera': {
        typeIcon = <Icons.Camera size={14} color={invColor ? Colors.calc : _fontColor} />
        btnColor = Colors.calc
        break;
      }
      case 'calc': {
        typeIcon = <Icons.Calculator size={14} color={invColor ? Colors.calc : _fontColor} />
        btnColor = Colors.calc
        break;
      }
      case 'import': {
        if (hasPermission(getCurrentMenuId()) === false) { return null }
        typeIcon = <Icons.FileDownload size={14} color={invColor ? Colors.import : _fontColor} />
        btnColor = Colors.import
        break;
      }
      case 'download': {
        typeIcon = <Icons.Download size={14} color={invColor ? Colors.basic : _fontColor} />
        btnColor = Colors.basic
        break;
      }
      case 'mail': {
        typeIcon = <Icons.Envelope size={14} color={invColor ? Colors.mail : _fontColor} />
        btnColor = Colors.mail
        break;
      }
      case 'next': {
        typeIcon = <Icons.AngleDoubleRight size={14} color={invColor ? Colors.add : _fontColor} />
        btnColor = Colors.basic
        reverse = true;
        break;
      }
      case 'pre': {
        typeIcon = <Icons.AngleDoubleLeft size={14} color={invColor ? Colors.basic : _fontColor} />
        btnColor = Colors.basic
        break;
      }

      case 'locked': {
        typeIcon = <Icons.Locked size={14} color={invColor ? Colors.basic : _fontColor} />
        btnColor = Colors.basic
        break;
      }

      case 'unlocked': {
        typeIcon = <Icons.Unlocked size={14} color={invColor ? Colors.basic : _fontColor} />
        btnColor = Colors.basic
        break;
      }

      case 'show': {
        typeIcon = <Icons.Show size={18} color={invColor ? Colors.basic : _fontColor} />
        btnColor = Colors.basic
        break;
      }

      case 'hide': {
        typeIcon = <Icons.Hide size={18} color={invColor ? Colors.basic : _fontColor} />
        btnColor = Colors.basic
        break;
      }

      case 'print': {
        typeIcon = <Icons.Print size={14} color={invColor ? Colors.basic : _fontColor} />
        btnColor = Colors.basic
        break;
      }

      default: {
        btnColor = Colors.basic
        break;
      }
    }
    // propsでカラーが設定されていたら優先
    if (color) btnColor = color;
    if (accessKey) _accessKey = accessKey;

    // style設定
    const wrap_style = {
      border:
        '1px solid' + btnColor,
      padding: 0,
      height: height || 40,
      borderRadius: 2,
      letterSpacing: 0.6,
      cursor: "pointer",
      userSelect: 'none',
      backgroundColor:
        invColor ? _fontColor :
          btnColor,
      marginLeft: marginLeft,
      marginRight: marginRight,
      marginTop: marginTop,
      marginBottom: marginBottom,
      outline: 'none',
    }
    const inner_style = {
      padding:
        reverse ? '0px 12px 0px 16px' :
          '0px 16px 0px 12px',
      width: '100%',
      height: '100%',
      borderRadius: 'inherit',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: "0.2s",
      flexDirection:
        reverse ? 'row-reverse' :
          null,
      backgroundColor:
        hover || focus ? hoverColor :
          null,
      whiteSpace: 'nowrap'
    }
    const label_style = {
      color:
        invColor ? btnColor :
          _fontColor,
      margin:
        !type ? 0 :
          reverse ? '0px 8px 0px 0px' :
            '0px 0px 0px 8px',
    }
    // ______________________________________________________________________________

    // ______________________________________________________________________________

    return (
      <button
        style={wrap_style}
        onClick={this.handleOnClick}
        onKeyDown={onKeyDown}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
        accessKey={_accessKey}
      >
        <div style={inner_style}>
          {typeIcon}
          <span style={label_style}>{Plabel}{Plabel2}{label}</span>
          {children}
        </div>
      </button>
    )
  }
}

