import React, { Fragment } from 'react';
import BasicWrap from './BasicWrap';
import * as Icons from './Icons';
import {textPass as Colors} from './Colors'

export default class TextPass extends React.Component {
  constructor(props) {
    super(props);
    const {
      value,
      type,
      disabled,
      align,
    } = this.props;

    this.state = {
      value: value || '',
      type: type || "password",
    };
    this.text_style = {
      fontSize: 'inherit',
      height: '100%',
      width: '100%',
      padding: '0px 4px',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      pointerEvents:
        disabled === true ? "none" :
          "auto",
      textAlign:
        align ? align :
        type === "number" ? 'right' :
        type === "decimal" ? 'right' :
          null,
    }
    this.icon_style = {
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 4,
      cursor:'pointer',
    }

  }
  // _____________________________________________________________________
  shouldComponentUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      const fixedValue = nextProps.value ? nextProps.value : ""; // null や undifinedを''に修正
      this.setState({ value: fixedValue });
    }
    return true
  }
  // ______________________________________________________________________
  handleOnBlur = (e) => {
    const { name, onBlur, } = this.props;
    onBlur && onBlur(name, e.target.value);
  }
  // ______________________________________________________________________________________________________
  handleOnChange = (e) => {
    const { name, onChange} = this.props;
    this.setState({ value: e.target.value })
    onChange && onChange(name, e.target.value);
  }
  // _______________________________________________________________________________________________________
  handleOnClickIcon = () =>{
    const {type} = this.state;
    this.setState({type: type !== "password" ? "password": "text"})
  }
  render() {
    const {
      disabled,
      style,
      readOnly,
      maxLength,
      autoFocus,
    } = this.props
    const {
      value,    // 入力されている値
      type,
    } = this.state
    // _______________________________________________________________________________________________________
    return (
      <BasicWrap {...this.props} value={value}>
        <input
          autoComplete = "off"
          autoFocus={autoFocus}
          type={type}
          style={Object.assign({}, this.text_style, style)}
          maxLength={maxLength}
          value={value}
          onChange={e => this.handleOnChange(e)}
          onBlur={e => this.handleOnBlur(e)}
          readOnly={readOnly}
          disabled={disabled}
        />
        <Icons.Eye color={Colors.icon} style={this.icon_style} width={14} height={14} onClick={this.handleOnClickIcon} hidden={type !== "password"}/>
        <Icons.EyeSlash color={Colors.icon} style={this.icon_style} width={14} height={14} onClick={this.handleOnClickIcon} hidden={type === "password"}/>
      </BasicWrap>
    )
  }
}
