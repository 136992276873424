import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import * as Icons from "./Icons";
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import { store } from '../index';
import { zindex } from './Styles';
import { modal as Colors } from './Colors'

// モーダルを開く

export const openModal = (json) => {
  const body = document.getElementById("body")
  body.style['overflow'] = 'hidden';
  const wrapper = document.body.appendChild(document.createElement('div')); // div要素を作成するための変数を宣言
  wrapper.id = 'modal'  // そのdiv要素のidに'modal'を設定
  Object.assign(wrapper.style, {
    position: "fixed",
    top: 0,
    height: "100vh",
    width: "100vw",
    zIndex: zindex.modal,
    backgroundColor: Colors.wrap,
  })
  let props = {}
  if (!json['component']) {
    props['component'] = json
  } else {
    props = json
  }
  //ModalをReduxと結合
  ReactDOM.render(
    <Provider store={store}>
      <ModalWithReducer close={() => closeModal()} {...props} />
    </Provider>
    , wrapper // <Modal />を表示
  );
}
// ________________________________________________________________________________________________________________

// モーダルを閉じる

export const closeModal = () => {
  const body = document.getElementById("body")
  body.style['overflow'] = null;
  const modal = document.getElementById('modal'); // 作ったid('modal')のdiv要素を取得
  if (modal) {
    ReactDOM.unmountComponentAtNode(modal);       // 作ったreactDomを削除
    modal.parentNode.removeChild(modal);            // 一度親に戻って子供を参照して作ったdiv要素を削除（IE対応）
  }
}
// ________________________________________________________________________________________________________________
class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: null,  // モーダルの幅の初期値
      height: null, // モーダルの高さの初期値
      get_X: "",   // マウスを動かした時の X座標
      get_Y: "",   // マウスを動かした時の Y座標
      top: 0,
      left: 0,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      resize: false,　// trueの時にリサイズができる
      move: false,　// trueの時にモーダルを移動できる
      transition: null,
      maxmize: false,
    }
  }
  // __________________________________________________________________________________________________________________
  componentDidMount() {
    const { width, height } = this.props;
    const { windowWidth, windowHeight } = this.state;
    this.col = windowWidth / 12
    this.row = windowHeight / 6

    if (width) {
      this.setState({
        left: (windowWidth / 2) - (width / 2),
        width: width,
      })
    } else {
      this.setState({
        left: this.col * 2,
        width: this.col * 8,
      })
    }
    if (height) {
      this.setState({
        top: (windowHeight / 2) - (height / 2),
        height: height,
      })
    } else {
      this.setState({
        top: this.row * 1,
        height: this.row * 4,
      })
    }

    window.addEventListener('mousemove', this.handleMouseMove)
    window.addEventListener('resize', this.reportWindowSize);
  }
  // __________________________________________________________________________________________________________________
  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove)
    window.removeEventListener('resize', this.reportWindowSize);
  }
  // __________________________________________________________________________________________________________________
  reportWindowSize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })
  }
  // __________________________________________________________________________________________________________________
  resizeStart = (e) => {
    e.preventDefault();
    this.setState({
      get_X: e.nativeEvent.pageX,
      get_Y: e.nativeEvent.pageY,
      start_W: this.state.width,
      start_H: this.state.height,
      resize: true,
      transition: null,
    })
  }
  // __________________________________________________________________________________________________________________
  moveStart = (e) => {
    this.setState({
      get_X: e.nativeEvent.pageX,
      get_Y: e.nativeEvent.pageY,
      move: true,
      transition: null,
    })
  }
  // __________________________________________________________________________________________________________________
  handleMouseMove = (e) => {
    e.stopPropagation()
    e.preventDefault();
    const {
      top,
      left,
      width,
      height,
      get_X,
      get_Y,
      resize,
      move,
    } = this.state
    const pageX = e.pageX;
    const pageY = e.pageY;
    const moveY = pageY - get_Y;
    const moveX = pageX - get_X;

    if (resize) {
      if (height >= 200 || moveY >= 0) {
        this.setState({
          height: height + moveY,
          get_Y: pageY,
        })
      }
      if (width >= 200 || moveX >= 0) {
        this.setState({
          width: width + moveX,
          get_X: pageX,
        })
      }
    } else if (move) {
      if (top >= 1 || moveY >= 0) {
        this.setState({ top: top + moveY, })
      }
      if (left >= 1 || moveX >= 0) {
        this.setState({ left: left + moveX, })
      }
      this.setState({
        get_Y: pageY,
        get_X: pageX,
      })
    }
  }
  // __________________________________________________________________________________________________________________
  actionStop = (e) => {
    e.preventDefault();
    this.setState({
      resize: false,
      move: false
    });
  }
  // __________________________________________________________________________________________________________________
  handleMouseMoveModal = (e) => {
    const { resize, move } = this.state;
    if (!resize && !move) {
      e.stopPropagation()
    }
  }
  // __________________________________________________________________________________________________________________
  handleMaxmize = () => {
    const { windowWidth, windowHeight } = this.state;
    this.setState({
      top: 0,
      left: 0,
      width: windowWidth,
      height: windowHeight,
      transition: '0.1s',
      maxmize: true,
    })
  }
  // __________________________________________________________________________________________________________________
  handleRestore = () => {
    const { width, height } = this.props;
    const { windowWidth, windowHeight } = this.state;
    this.col = windowWidth / 12
    this.row = windowHeight / 6

    if (width) {
      this.setState({
        left: (windowWidth / 2) - (width / 2),
        width: width,
      })
    } else {
      this.setState({
        left: this.col * 2,
        width: this.col * 8,
      })
    }
    if (height) {
      this.setState({
        top: (windowHeight / 2) - (height / 2),
        height: height,
      })
    } else {
      this.setState({
        top: this.row * 1,
        height: this.row * 4,
      })
    }
    this.setState({
      transition: '0.1s',
      maxmize: false
    })
  }
  // __________________________________________________________________________________________________________________
  render() {
    const {
      close,
      component,
      unclosable,
      color,
    } = this.props
    const {
      width,
      height,
      top,
      left,
      transition,
      maxmize,
    } = this.state
    // __________________________________________________________________________________________________________________
    const wrap_style = {
      height: '100vh',
      width: "100vw",
      position: 'relative',
    }
    const modal_style = {
      width: width,
      maxWidth: "100vw",
      height: height,
      maxHeight: "100vh",
      position: 'absolute',
      top: top,
      left: left,
      backgroundColor: color || 'rgb(230, 230, 230)',
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      transition: transition,
    }
    const header_style = {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      minHeight: 30,
      cursor: 'move',
      borderRadius: '8px 8px 0px 0px',
      backgroundColor: Colors.header,
      alignItems: 'center',
    }
    const icon_style = {
      marginRight: 8,
    }
    const body_style = {
      overflow: "auto",
      borderRadius: '0px 0px 8px 8px',
      // minHeight: '100%',
      // paddingBottom: 30,
      minHeight: "calc(100% - 30px)"
      // backgroundColor: "rgb(230, 230, 230)",
    }
    const resize_style = {
      position: "absolute",
      height: 25,
      width: 25,
      bottom: -5,
      right: -5,
      cursor: "nw-resize",
    }
    // ______________________________________________________________________________________________
    return (
      <div
        style={wrap_style}
        // onMouseMove={this.handleOnmouseMove}
        onMouseUp={this.actionStop}
        onMouseLeave={this.actionStop}
      >
        <div style={modal_style} onMouseMove={this.handleMouseMoveModal} ref={el => this.modal_ref = el}>
          <div style={header_style} onMouseDown={this.moveStart}>
            <Icons.WindowMaxmize color={Colors.icon} color2={Colors.window} size={20} style={icon_style} onClick={this.handleMaxmize} hidden={maxmize} />
            <Icons.WindowRestore color={Colors.icon} color2={Colors.window} size={20} style={icon_style} onClick={this.handleRestore} hidden={!maxmize} />
            <Icons.Times color={Colors.icon} color2={Colors.close} size={25} style={icon_style} onClick={close} hidden={unclosable} />
          </div>
          <div style={body_style}> {component} </div>
          <div style={resize_style} onMouseDown={this.resizeStart} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownPropS) => {
  return {
    ...state,
    ...ownPropS
  }
}

const ModalWithReducer = connect(mapStateToProps, null)(Modal);