import React, { Fragment } from 'react';
import BasicWrap from './BasicWrap';
import { formatNumber, } from '../util/commonInput';

export default class Text extends React.Component {
  constructor(props) {
    super(props);
    const {
      value,
      type,
      disabled,
      align,
    } = this.props;

    this.state = {
      value: value || '',
      oldValue: value || '',
      focus: false,
    };
    this.text_style = {
      fontSize: 'inherit',
      height: '100%',
      width: '100%',
      padding: '0px 4px',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      pointerEvents: "auto",
      textAlign:
        align ? align :
          type === "number" ? 'right' :
            type === "decimal" ? 'right' :
              null,
    }
    this.ref = React.createRef();
  }
  // _____________________________________________________________________
  componentDidMount() {
    const { type, autoFocus = false } = this.props;

    switch (type) {
      case "password": {
        this.setState({ type: "password" });
        break;
      }
      case "number": case "decimal": case "alphanum": case "upperCase": case "tel": {
        this.setState({ type: "tel" });
        break
      }
      default: {
        this.setState({ type: "text" });
        break;
      }
    }

    if (!!autoFocus && !!this.ref) {
      this.ref.current.focus();
    }
  }
  // ______________________________________________________________________
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.value !== nextProps.value) {
      const fixedValue = nextProps.value ? nextProps.value : ""; // null や undifinedを''に修正
      // TextのPropsが準備していない時も、直接Renderすることの対応
      this.setState({
        // oldValue: (this.state.value === this.state.oldValue && this.state.oldValue === '') ? fixedValue : this.state.value,
        oldValue: this.state.value,
        value: fixedValue
      });
      return true
    }

    if (this.state.value !== nextState.value) {
      const fixedValue = nextState.value ? nextState.value : ""; // null や undifinedを''に修正
      // TextのPropsが準備していない時も、直接Renderすることの対応
      this.setState({
        // oldValue: (this.state.value === this.state.oldValue && this.state.oldValue === '') ? fixedValue : this.state.value,
        value: fixedValue
      });
      return true
    }

    if (this.props !== nextProps) { return true }
    if (this.state.focus !== nextState.focus) { return true }
    return false
  }
  // ______________________________________________________________________
  handleOnBlur = e => {
    const { email, name, onBlur, forceBlur } = this.props;
    if (this.state.value) {
      onBlur && onBlur(name, this.state.value, this.state.oldValue);
    }
    // onblur時　oldValue記憶
    this.setState({
      oldValue: this.state.value,
      focus: false,
    })
  }
  // ______________________________________________________________________________________________________
  handleOnChange = (e) => {
    const { type, name, onChange, maxLength } = this.props;
    const inputValue = e.target.value
    let replaceValue = '';
    // typeによって文字列変換をするためのswitch文です
    switch (type) {
      case "number": {
        replaceValue = inputValue.replace(/[^0-9]/g, '');
        break;
      }
      case "tel": {
        replaceValue = inputValue.replace(/[^0-9-+]/g, '');
        break;
      }
      case "alphanum": {
        replaceValue = inputValue.replace(/[^a-zA-Z0-9!#$%&\()*.+/:;=?@\[\]^_{}-]/g, '');
        break;
      }
      case "upperCase": {
        replaceValue = inputValue.replace(/[^a-zA-Z0-9!#$%&\()*.+/:;=?@\[\]^_{}-]/g, '');
        replaceValue = String(replaceValue).toUpperCase();
        break;
      }
      case "decimal": {
        const maxLength_arr = maxLength ? String(maxLength).split(',') : [15, 5]
        const decimal_maxLength = maxLength_arr[1]

        replaceValue = inputValue.replace(/[^\d||.]/g, ''); // 数値とピリオド以外は除外
        let integer = replaceValue.split('.')[0] // 整数部分
        let dec = replaceValue.split('.')[1] // 小数部分

        if (dec) {
          dec = dec.substr(0, decimal_maxLength)
          replaceValue = (integer || 0) + '.' + dec
        }
        // 入力値の先頭がマイナスなら、マイナスを付与
        if (inputValue.indexOf('-') === 0) { replaceValue = '-' + replaceValue }

        break;
      }
      default: {
        replaceValue = inputValue;
        break;
      }
    }
    this.setState({
      // oldValue: this.state.value,
      value: replaceValue
    })
    onChange && onChange(name, replaceValue, this.state.oldValue);
  }
  // ______________________________________________________________________________________________________
  handleOnFocus = (e) => {
    this.setState({ focus: true })
    e.target.select();
  }
  // _______________________________________________________________________________________________________

  render() {
    const {
      name,
      disabled,
      style,
      readOnly,
      maxLength,
      // autoFocus,
      format,
      placeholder,
    } = this.props
    const {
      value,    // 入力されている値
      type,
      focus,
    } = this.state
    // _______________________________________________________________________________________________________
    // typeがdecimalなら、ピリオドとハイフンがあるかによって最大入力可能数を変動する必要がある
    let _maxLength = maxLength
    if (this.props.type === 'decimal') {
      _maxLength = maxLength ? String(maxLength).split(',')[0] : 15
      _maxLength = Number(_maxLength)
      if (!!value && String(value).indexOf('-') === 0) { _maxLength += 1 }
      if (!!value && String(value).indexOf('.') >= 0) { _maxLength += 1 }
    }
    let setValue = value;
    if (!focus && format) { setValue = formatNumber(value, format); }
    return (
      <BasicWrap {...this.props} value={value}>
        <input
          // autoComplete = "off"
          // autoFocus={autoFocus}
          name={name}
          ref={this.ref}
          type={type}
          style={Object.assign({}, this.text_style, style)}
          maxLength={_maxLength}
          value={setValue}
          onFocus={this.handleOnFocus}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
        />
      </BasicWrap>
    )
  }
}
