import React from 'react';
import * as Icons from "../Icons";
import { table as Color } from '../Colors'
import Switcher from '../Switcher'

export default class SwitchCell extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      hover: false,
      value: value,
    }
    this.td_style = {                // <td> 各セル
      width: 30,
      border: "1px solid" + Color.border,
      textAlign: "center",
      verticalAlign: "middle",
      transition: '0.2s',
      paddingTop: 2,
    }

  }
  //___________________________________________________________________
  shouldComponentUpdate(nextProps, nextState) {
    const { value } = this.props;
    if (this.state !== nextState) {
      return true
    }
    if (value !== nextProps.value) {
      this.setState({ value: nextProps.value });
      return true
    }
    return false;
  }
  //___________________________________________________________________
  handleOnClick = (e, v) => {
    const { index, detailIndex, onChange, children, setData, disabled } = this.props;
    if (disabled) { return };
    let nextValue = (!!v) ? '1' : '';
    this.setState({ value: v });
    const fowardData = { 'index': index, 'name': children.name, 'value': nextValue, 'detailIndex': detailIndex, 'rowData': setData };
    onChange && onChange(fowardData);
    children.onChange && children.onChange(fowardData);
  }
  //___________________________________________________________________
  handleOnTDClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }
  render() {
    const { children, disabled, checkBox } = this.props;
    const {
      rowSpan,
      colSpan,

      width,
      minWidth,
    } = children

    const {
      value,
      hover,
    } = this.state;
    Object.assign(this.td_style, {            // <td> 各セル
      backgroundColor:
        disabled ? null :
          !checkBox ? null :
            hover ? Color.check :
              null,
      cursor:
        disabled ? null :
          'pointer',
      width: width,
      minWidth: minWidth,
    })

    return (
      <td
        style={Object.assign({}, this.td_style)}
        rowSpan={rowSpan === 'max' ? 1000 : rowSpan}
        colSpan={colSpan}
        onClick={this.handleOnTDClick}
        tabIndex={0}
        disabled={disabled}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        <div style={{ display: "inline-flex" }}>
          <Switcher on={value === '1' || value === true} onClick={this.handleOnClick} disabled={disabled} />
        </div>
      </td>
    )
  }
}