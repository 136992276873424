import React from 'react';
import * as Icons from "../Icons";
import { table as Colors } from '../Colors'


const iconWrapStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}
export default class IconCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false,
    }
  }
  //_____________________________________________________________________
  render() {
    const { onClick, disabled, children, type, color, fixedWidth } = this.props;
    const { hover, focus } = this.state;
    const {
      rowSpan,
      colSpan,
      width,
      minWidth,
      title,
      icon,
    } = children
    const cellColor = children.color || Colors.basic // セルの色。カラム設定にcolor設定があれば使用
    const iconColors = hover || focus ? "#FFFFFF" : cellColor;　//　アイコンの色。イベントによって切り替わる
    const bgColor = color // 行ごとの背景色

    const td_style = {                // <td> 各セル
      width: width || 30,
      minWidth: minWidth || 30,
      border: "1px solid #ddd",
      textAlign: "center",
      verticalAlign: "middle",
      paddingTop: 1,
      transition: '0.2s',
      backgroundColor:
        disabled ? bgColor :
          hover || focus ? cellColor :
            bgColor,
      pointerEvents:
        disabled ? "none" :
          "auto",
      cursor:
        disabled ? null : 'pointer',
      // fontSize: '1.2rem',
      // fontWeight: 'bold',
      color:
        hover || focus ? '#FFFFFF' :
          null,
      position:
        fixedWidth || fixedWidth === 0 ? 'sticky' :
          null,
      left: fixedWidth,
      zIndex: fixedWidth || fixedWidth === 0 ? 1 : null,
    }
    // iconセットのswitch文
    let iconComponent = null;
    // disabledならアイコン表示なし
    if (!disabled) {
      switch (icon) {
        case 'modal': {
          iconComponent = <Icons.Search1 width={16} height={16} color={iconColors} />
          break;
        }
        case 'edit': {
          iconComponent = <Icons.Edit2 width={16} height={16} color={iconColors} />
          break;
        }
        case 'save': {
          iconComponent = <Icons.Save width={16} height={16} color={iconColors} />
          break;
        }
        case 'delete': {
          iconComponent = <Icons.TimesCircle width={16} height={16} color={iconColors} />
          break;
        }
        case 'copy': {
          iconComponent = <Icons.Copy width={16} height={16} color={iconColors} />
          break;
        }
        case 'calc': {
          iconComponent = <Icons.Calculator width={16} height={16} color={iconColors} />
          break;
        }
        case 'mail': {
          iconComponent = <Icons.Envelope width={16} height={16} color={iconColors} />
          break;
        }
        case 'plus': {
          iconComponent = <Icons.PlusSquare width={16} height={16} color={iconColors} />
          break;
        }
        case 'minus': {
          iconComponent = <Icons.MinusSquare width={16} height={16} color={iconColors} />
          break;
        }
        case 'link': {
          iconComponent = <Icons.ExternalLink width={16} height={16} color={iconColors} />
          break;
        }
        case 'translate': {
          iconComponent = <Icons.Dictionary width={16} height={16} color={iconColors} />
          break;
        }
        case 'eye': {
          iconComponent = <Icons.Eye size={16} color={iconColors} />
          break;
        }
        case 'arrowRightCurve': {
          iconComponent = <Icons.ArrowRightCurve size={16} color={iconColors} />
          break;
        }
        case 'arrowAltCircleUp': {
          iconComponent = <Icons.ArrowAltCircleUp size={16} color={iconColors} />
          break;
        }
        case 'list': {
          iconComponent = <Icons.List size={16} color={iconColors} />
          break;
        }
        case 'history': {
          iconComponent = <Icons.History size={16} color={iconColors} />
          break;
        }
        case 'calendarCheck': {
          iconComponent = <Icons.CalendarCheck size={16} color={iconColors} />
          break;
        }
        case 'fileDownload': {
          iconComponent = <Icons.FileDownload size={16} color={iconColors} />
          break;
        }
        case 'warehouse': {
          iconComponent = <Icons.Warehouse size={16} color={iconColors} />
          break;
        }
        case 'receipt': {
          iconComponent = <Icons.Receipt size={16} color={iconColors} />
          break;
        }
        // 同じようにcaseを追加したら表示されます
        default: {
          iconComponent = <Icons.Search1 width={16} height={16} color={iconColors} />
          break;
        }
      }
    }
    return (
      <td
        style={td_style}
        rowSpan={rowSpan === 'max' ? 1000 : rowSpan}
        colSpan={colSpan}
        onClick={onClick}
        tabIndex={!disabled ? 0 : null}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >
        <div style={iconWrapStyle}>
          {iconComponent}
          {!disabled && title}
        </div>
      </td>
    )
  }
}
