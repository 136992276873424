import React, { Fragment } from 'react';
import * as Icons from "../Icons";
import { table as Colors } from '../Colors'
import styled from 'styled-components';
import BasicTD from './BasicTD';

export default class SelectCell extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      value: value || '',
      option: [],
    }
    this.select_style = {
      fontSize: 'inherit',
      height: '100%',
      width: '100%',
      paddingLeft: 4,
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      color: "inherit",
      cursor: "pointer",
      position: 'relative',
      appearance: 'none',
    }
    this.icon_style = {
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
    }
    this.option_style = {
      color: "#000",
    }
    this.Select = styled.select`::-ms-expand {display: none;}`;

  }
  //______________________________________________________________________________________________________
  componentDidMount() {
    const { option } = this.props;
    const option_arr = this.createOptions(option);
    this.setState({ option: option_arr });
    this.setState({ option: option_arr });
  }
  //______________________________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const { value, option, disabled } = this.props;
    if (value !== nextProps.value) {
      this.setState({ value: nextProps.value || '' })
    }
    // 選択リストの生成
    if (option !== nextProps.option) {
      const option_arr = this.createOptions(nextProps.option)
      this.setState({ value: nextProps.value || '', option: option_arr })
    }
    return true;
  }
  //______________________________________________________________________________________________________
  // 選択リストの生成
  createOptions = (option) => {
    const option_arr = []
    for (let i in option) {
      const { value, label, color } = option[i]
      if (color) {
        option_arr.push(
          <option key={'option' + i} value={value} style={{ color: color, }}>
            {label}
          </option>
        )
      } else {
        option_arr.push(
          <option key={'option' + i} value={value} style={this.option_style}>
            {label}
          </option>
        )
      }
    }
    return option_arr;
  }
  //______________________________________________________________________________________________________
  handleOnChange = (e) => {
    this.setState({ value: e.target.value });
    const { index, onChange, children, setData } = this.props;
    const fowardData = { 'index': index, 'name': children.name, 'value': e.target.value, 'rowData': setData }
    onChange && onChange(fowardData)
    children.onChange && children.onChange(fowardData)
    // this.select_ref.focus()
  }
  // ________________________________________________________________________________________________________________
  handleOnBlur = (fowardData) => {
    const { onBlur } = this.props.children
    console.log("onBlur", fowardData)
    onBlur && onBlur(fowardData) // column設定用のonBlur
  }
  // ________________________________________________________________________________________________________________
  handleOnFocus = (fowardData) => {
    console.log("onFocus", fowardData)
    const { onFocus } = this.props.children
    onFocus && onFocus(fowardData)
  }
  // ________________________________________________________________________________________________________________
  handleOnKeyDown = (e) => {
    if (e.keyCode === 40 || e.keyCode === 38) {
      e.preventDefault()
    }
  }
  // ________________________________________________________________________________________________________________
  render() {
    const { disabled, children, index, tabIndex } = this.props
    const { value, } = this.state;
    const { name } = children
    const fowardData = { 'index': index, 'name': name, 'value': value } //　一番親に送るデータ

    Object.assign(this.select_style, {
      paddingRight:
        disabled ? 4 :
          20,
      pointerEvents:
        disabled ? 'none' :
          'auto',
    })
    //______________________________________________________________________________________________________
    return (
      <BasicTD {...this.props} config={this.props.children}>
        <Icons.TriangleDown style={this.icon_style} width={20} height={20} color={Colors.selectIcon} hidden={disabled} />
        <this.Select
          style={Object.assign({}, this.select_style)}
          name={name}
          onChange={this.handleOnChange}
          value={value}
          disabled={disabled}
          onKeyDown={this.handleOnKeyDown}
          onFocus={() => this.handleOnFocus(fowardData)}
          onBlur={() => this.handleOnBlur(fowardData)}
          tabIndex={tabIndex}
        >
          {this.state.option}
        </this.Select>
      </BasicTD>
    )
  }
}

