import React, { Fragment } from 'react';

export default class MessageBar extends React.Component {

  render() {
    const { label, type = 'ERROR' } = this.props;

    if (!label) { return null }

    let bar_style = {
      borderRadius: 2,
      // fontSize: '1.1rem',
      fontWeight: 'bold',
      padding: "8px",
      margin: "4px 0px",
      width: '100%',
      display:
        !label ? 'none' :
          null
    };
    const TYPE = type.toUpperCase() // 大文字化して、大文字小文字の判断を省略
    // 各type毎でスタイルを変更する
    switch (TYPE) {
      case 'SUCCESS': {
        bar_style = Object.assign(bar_style, {
          backgroundColor: "#CEF5D4",
          border: "1px solid #5BBA6A",
          color: '#5BBA6A',
        });
        break
      }
      case 'ERROR': default: {
        bar_style = Object.assign(bar_style, {
          backgroundColor: "#FFEBEB",
          border: "1px solid #CB5252",
          color: '#CB5252',
        });
        break
      }
    }

    return (
      <div style={bar_style}>
        <pre>{label}</pre>
      </div>
    );
  };
}

