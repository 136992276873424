import React from 'react';
import { textFile as Colors } from './Colors';
import * as Icons from "./Icons";
import ReactDOM from 'react-dom';
import { zindex } from './Styles';

// インポートされたファイルのサムネイル
export default class Thumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      hover: false,
      pcDeviceFlg: true,
    }
    this.width = 100;
    this.height = 100;
    this.idName = Math.random().toString(36).substring(7); // idを乱数生成
  }
  // ______________________________________________________________________________
  componentDidMount() {
    // モバイル端末の場合は、初めからカバーを表示
    const ut = navigator.userAgent;
    if (ut.indexOf('iPhone') > 0 || ut.indexOf('iPod') > 0 || ut.indexOf('Android') > 0 || ut.indexOf('iPad') > 0) {
      this.setState({ pcDeviceFlg: false });
    }
  }
  // ______________________________________________________________________________
  shouldComponentUpdate(nextProps, nextState) {
    const { src, fileName } = this.props;
    const { hover, pcDeviceFlg } = this.state;
    if (hover !== nextState.hover) { return true; }
    if (pcDeviceFlg !== nextState.pcDeviceFlg) { return true; }
    if (src !== nextProps.src) { return true; }
    if (fileName !== nextProps.fileName) { return true; }
    return false
  }
  // ______________________________________________________________________________
  handleClick = () => {
    const { fileType } = this.props;
    if (fileType === "IMG" || fileType === "PDF") {
      const wrapper = document.body.appendChild(document.createElement('div')); // div要素を作成するための変数を宣言
      const body = document.getElementById("body")
      body.style['overflow'] = 'hidden';
      wrapper.id = this.idName; // idを乱数生成
      // レンダー
      ReactDOM.render(
        <FullScreenImage {...this.props} onClose={this.closeFullScreen} />
        , wrapper
      );
    }
  }
  // ______________________________________________________________________________
  closeFullScreen = () => {
    const body = document.getElementById("body")
    body.style['overflow'] = null;
    const wrapper = document.getElementById(this.idName); // 作ったid('modal')のdiv要素を取得
    if (wrapper) {
      ReactDOM.unmountComponentAtNode(wrapper);       // 作ったreactDomを削除
      wrapper.parentNode.removeChild(wrapper);            // 一度親に戻って子供を参照して作ったdiv要素を削除（IE対応）
    }
  }
  // ________________________________________________________________________________________________________________
  render() {
    const { hidden, fileName, fileType, src, clickDelete } = this.props;
    const { hover, pcDeviceFlg } = this.state;
    // if (!fileName || hidden) { return null }
    const wrap_style = {
      position: 'relative',
      width: this.width,
      height: this.height,
      display: 'flex',
      margin: 4,
      border: `1px solid ${Colors.imgBorder}`,
      cursor: 'pointer',
      overflow: 'hidden',
    }
    const img_style = {
      width: '100%',
      height: '100%',
      margin: 'auto',
      objectFit: 'cover',
    }
    const icon_style = {
      margin: 'auto',
    }
    const object_style = {
      width: this.width + 14,
    }
    const imgFooter_style = {
      backgroundColor: Colors.imgFooter,
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: 24,
      display:
        pcDeviceFlg === false ? 'none' :
          hover ? 'none' :
            'flex',
      alignItems: 'center',
      overflow: 'hidden',
    }
    const fileName_style = {
      fontSize: '0.9rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: "nowrap",
      margin: "0px 4px",
    }

    let content = null;
    switch (fileType) {
      case "IMG": {
        content = <img alt={fileName} src={src} style={img_style} />;
        break;
      }
      case "XLS": case "XLSX": {
        content = <Icons.FileExcel style={icon_style} size={this.height - 28} />;
        break;
      }
      case "CSV": {
        content = <Icons.FileCsv style={icon_style} size={this.height - 28} />;
        break;
      }
      case "DOCX": case "DOC": {
        content = <Icons.FileWord style={icon_style} size={this.height - 28} />;
        break;
      }
      case "PDF": {
        content = <object data={src} aria-label={fileName} style={object_style} />;
        break;
      }
      default: {
        content = <Icons.FileAlt style={icon_style} size={this.height - 28} />;
        break;
      }
    }

    return (
      <div style={wrap_style}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={this.handleClick}
      >
        <ThumbnailCover {...this.props} hidden={!pcDeviceFlg} render={hover} clickDelete={clickDelete} />
        {content}
        <div style={imgFooter_style}>
          <span style={fileName_style}>{fileName}</span>
        </div>
      </div>
    )
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////
// サムネイルをホバーした時に表示するカバー
class ThumbnailCover extends React.Component {
  componentDidMount() {
    // const { src } = this.props;
    // this.img = new Image();
    // // this.img.onload = function () {
    // //   let width = this.img.naturalWidth ;
    // //   let height = this.img.naturalHeight ;
    // // }
    // this.img.src = src;
  }
  // ____________________________________________________________________
  handleDelete = (e) => {
    const { clickDelete } = this.props;
    e.stopPropagation();
    clickDelete && clickDelete()
  }
  // ____________________________________________________________________
  handleDownload = (e) => {
    e.stopPropagation();
    const { src, fileName } = this.props;
    const a = document.createElement("a");
    a.download = fileName;
    a.href = src;
    a.click();
  }
  // ____________________________________________________________________

  render() {
    const { hidden, render = true, fileName, src, disabled, clickDelete } = this.props;
    if (hidden || !render) { return null }

    const wrap_style = {
      backgroundColor: Colors.imgCover,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
    }
    const fileName_style = {
      fontSize: '1.1rem',
      lineHeight: 1.1,
      padding: "4px",
      color: "#FFF",
      wordWrap: "break-word",
    }
    const footer_style = {
      position: 'absolute',
      bottom: 0,
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      padding: 4,
    }
    return (
      <div style={wrap_style}>
        <div style={fileName_style}>{fileName}</div>
        <div style={footer_style}>
          <a ref={el => this.link_ref = el} onClick={this.handleDownload} >
            <Icons.FileDownload color="#FFF" color2={Colors.downloadHover} size={25} />
          </a>
          <Icons.Trash color="#FFF" color2={Colors.trashHover} size={25} onClick={this.handleDelete} hidden={disabled || !clickDelete} />
        </div>
      </div>
    )
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////
class FullScreenImage extends React.Component {
  constructor(props) {
    super(props);
    this.times = 1;
    this.width = 0;
    this.height = 0;
  }
  resizing = (e) => {
    if (this.width === 0) {
      this.width = e.target.width;
      this.height = e.target.height;
    }
    if (e.deltaY > 0) {
      this.times = this.times - 0.1;
    }
    if (e.deltaY < 0) {
      this.times = this.times + 0.1;
    }
    if (this.times < 1) {
      return;
    }
    e.target.width = this.width * (this.times);
    e.target.height = this.height * (this.times);
  }
  render() {
    const { fileName, fileType, src, onClose } = this.props;

    const wrap_style = {
      backgroundColor: Colors.imgCover,
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: '100vw',
      height: '100vh',
      zIndex: zindex.fullScreenImage,
    }
    const header_style = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: 40,
      backgroundColor: Colors.header,
      position: 'absolute',
      padding: "0px 16px"
    }
    const innerWrap_style = {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '64px 24px 24px 24px',
      overflow: 'auto'
    }
    const fileName_style = {
      fontSize: '1.4rem',
      color: "#FFF",
      wordWrap: "break-word",
    }
    const object_style = {
      width: "100%",
    }
    const image_style = {
      borderRadius: "9px",
    }

    let content = null;
    switch (fileType) {
      case "IMG": {
        content = <img alt={fileName} src={src} style={image_style} onWheel={(e) => this.resizing(e)} />;
        break;
      }
      case "PDF": {
        content = <object data={src} aria-label={fileName} style={object_style} />;
        break;
      }
      default: {
        content = "この拡張子のファイルはサポートしておりません。";
        break;
      }
    }

    return (
      <div style={wrap_style}>
        <div style={header_style}>
          <div style={fileName_style}>{fileName}</div>
          <Icons.Times color={Colors.close} color2={Colors.closeHover} size={25} onClick={onClose} />
        </div>
        <div style={innerWrap_style}>
          {/* <div style={fileName_style}>{file.name}</div> */}
          {content}
        </div>
      </div>
    )
  }
}