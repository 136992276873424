import React, { Fragment } from 'react';
import { table as Colors } from '../Colors';

export default class TBodyL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    }
  }
  // ________________________________________________________________________________________________________________
  handleHoverOn = () => {
    const { disabled } = this.props;
    const { hover } = this.state;
    if (!disabled && !hover) {
      this.setState({ hover: true })
    }
  }
  // ________________________________________________________________________________________________________________
  handleHoverOut = () => {
    const { disabled } = this.props;
    const { hover } = this.state;
    if (!disabled && hover) {
      this.setState({ hover: false })
    }
  }
  // ________________________________________________________________________________________________________________
  render() {
    const { hover } = this.state           // 省略宣言
    const { color, children, rowOnClick, disabled } = this.props  // 省略宣言
    const tr_style = {
      backgroundColor:
        disabled === true ? "#eee" :
          hover ? " rgb(233, 245, 235)" : // ホバー中の行
            color, // 偶数行なら指定してある色  
      cursor:
        rowOnClick ? "pointer" :
          null,
      transition: "0.1s",
    }

    return (
      <tbody
        style={tr_style}
        onClick={rowOnClick}
        onMouseOver={this.handleHoverOn}
        onMouseLeave={this.handleHoverOut}
        onFocus={this.handleHoverOn}
        onBlur={this.handleHoverOut}
      >
        {children}
      </tbody>
    )
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
