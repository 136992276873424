import React from 'react';

export default class Row extends React.Component {
  render() {
    const { style = {}, className, children, hidden } = this.props;
    if (hidden) { return null };

    const {
      top,
      bottom,
      middle,
      stretch,
      center,
      between,
      end,
      noWrap,
      width,
      minWidth,
      maxWidth,
      height,
      maxHeight,
      overflow,
      backgroundColor,
      border,
      borderRadius,
      padding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      margin,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      flexDirection,
      alignItems
    } = this.props;

    const wrap_style = {
      display: "flex",
      flexDirection: flexDirection || "row",
      alignItems:
        top ? "flex-start" :
          bottom ? "flex-end" :
            middle ? "center" :
              stretch ? "stretch" :
                alignItems || "flex-end",
      justifyContent:
        center ? "center" :
          between ? "space-between" :
            end ? "flex-end" :
              null,
      flexWrap:
        noWrap ? null :
          "wrap",
      minWidth: minWidth || null,
      width: width || '100%',
      maxWidth: maxWidth || '100%',
      height: height || null,
      maxHeight: maxHeight || null,
      padding: padding,
      margin: margin,
      // fontSize: '1.1rem',
      overflow: overflow,
      backgroundColor: backgroundColor,
      border: border,
      borderRadius: borderRadius,

    }
    // console.log(style)
    if (!style.padding && !padding) {
      Object.assign(wrap_style, {
        paddingTop: paddingTop,
        paddingRight: paddingRight,
        paddingBottom: paddingBottom,
        paddingLeft: paddingLeft,
      })
    }
    if (!style.margin && !margin) {
      Object.assign(wrap_style, {
        marginTop: marginTop,
        marginRight: marginRight,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
      })
    }

    //__________________________________________________________________________________________________________
    return (
      <div style={Object.assign({}, wrap_style, style)} className={className}>
        {children}
      </div>
    );
  }
}