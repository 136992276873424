import { axiosPost, server } from '../comRedux/axiosPost';
import { store } from '../index'
import content from '../constant/ComponentConst'
import LoginPageInfo from '../constant/LoginPageInfo';
import update from 'immutability-helper';

export const hasPermission = (permissionCode) => {
  let result = false;
  const router = store.getState()['router']
  const root = router.location.pathname
  const currentMenuId = root.substring(root.lastIndexOf("/") + 1)
  const { permission } = store.getState()['COMMON'].Login
  if (permission && permission.permMap) {
    //特定権限check
    if (permissionCode && permissionCode !== '') {
      result = Boolean(permission.permMap[permissionCode])
    } else {
      //Default権限check
      result = Boolean(permission.permMap[currentMenuId.toUpperCase()])
    }
  }
  return result;
}
//_____________________________________________________________________________
export const toJson = (props) => {
  let returnJson = {};
  for (let v in props) {
    //項目がNullじゃない　且つ　ValueはUndefinedじゃない時、継続
    if (props[v] && typeof (props[v]['value']) !== 'undefined') {
      returnJson[v] = props[v].value;
    }
  }
  return returnJson;
}
//_____________________________________________________________________________
/**
* フォーマットにしたがって、カンマ、小数点編集を行います
* @param {string, number} num - 
* @param {string} pattern - 
* formatNumber(12345.999,'#,##0.00'); 
* formatNumber(12345.999,'#,##0.##'); 
* formatNumber(123,'000000'); 
*/
export const formatNumber = (num, pattern) => {
  let _num = num;
  if (_num !== 0 && !_num) { return "" } // numに値が無ければ処理を中止します。
  _num = _num.toString();
  _num = _num.replace(/[,]/, ''); // 既にカンマが入っている場合は、除外します。
  let str_arr = _num.split('.');
  let fmt_arr = pattern ? pattern.split('.') : [''];
  let retstr = '';
  // 整数部分の処理
  let str = str_arr[0];
  let fmt = fmt_arr[0];
  let i = str.length - 1;
  let comma = false;
  for (let f = fmt.length - 1; f >= 0; f--) {
    switch (fmt.substr(f, 1)) {
      case '#':
        if (i >= 0) retstr = str.substr(i--, 1) + retstr;
        break;
      case '0':
        if (i >= 0) retstr = str.substr(i--, 1) + retstr;
        else retstr = '0' + retstr;
        break;
      case ',':
        comma = true;
        retstr = ',' + retstr;
        break;
      default:
        break;
    }
  }
  if (i >= 0) {
    if (comma) {
      let l = str.length;
      for (; i >= 0; i--) {
        retstr = str.substr(i, 1) + retstr;
        if (i > 0 && ((l - i) % 3) === 0) retstr = ',' + retstr;
      }
    }
    else retstr = str.substr(0, i + 1) + retstr;
  }
  retstr = retstr + '.';
  // 小数部分の処理  
  str = str_arr.length > 1 ? str_arr[1] : '';
  fmt = fmt_arr.length > 1 ? fmt_arr[1] : '';
  i = 0;
  for (let f = 0; f < fmt.length; f++) {
    switch (fmt.substr(f, 1)) {
      case '#':
        if (i < str.length) retstr += str.substr(i++, 1);
        break;
      case '0':
        if (i < str.length) retstr += str.substr(i++, 1);
        else retstr += '0';
        break;
      default:
        break;
    }
  }
  retstr = retstr.replace(/^,+/, '').replace(/\.$/, '');
  // -,123,456の先頭の , を取り除く処理
  if (retstr[0] === '-' && retstr[1] === ',') {
    retstr = '-' + retstr.substr(2)
  }
  return retstr
}
//_________________________________________________________________________________________________
/**
 * バイトデータをサーバーからダウンロードする関数
 *
 * @param {string} url - ファイルをダウンロードするAPIのURL
 * @param {string} fileName - ダウンロードされるファイルの名前
 * @param {object} json - APIに渡すJSONデータ
 */
export const downloadByte = (url, json, fileName) => {
  axiosPost.post(server + url, json, { responseType: 'arraybuffer' })
    .then((res) => {
      // ダウンロードしたいコンテンツ、MIMEType、ファイル名
      let mimeType = 'application/octet-stream';

      // BOMは文字化け対策
      let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      let blob = new Blob([res.data], { type: mimeType });

      let a = document.createElement('a');
      a.download = fileName;
      a.target = '_blank';

      if (window.navigator.msSaveBlob) {
        // for IE
        window.navigator.msSaveBlob(blob, fileName);
      } else if (window.URL && window.URL.createObjectURL) {
        // for Firefox
        a.href = window.URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else if (window.webkitURL && window.webkitURL.createObject) {
        // for Chrome
        a.href = window.webkitURL.createObjectURL(blob);
        a.click();
      } else {
        // for Safari
        window.open('data:' + mimeType + ';base64,' + window.Base64.encode(res.data), '_blank');
      }
    })
    .catch(err => {
      alert(err);
      console.log('JSON読み込みエラー');
      return;
    });
}

//_________________________________________________________________________________________________

// functionと通常の代入の両方がが来ても対応できるようにする機能
export const funcAdapter = (func, arg) => {
  if (typeof (func) === 'function') {
    return funcValidate(() => { return func(arg); })
  }
  else if (func) { return func }
  else { return '' }
}
//_____________________________________________________________________________
// MUSTがあるコンポーネントの必須チェック(検索画面)
export const checkProps = (props) => {
  for (let v in props) {
    if (!props[v]) continue; //項目がNull時Continue
    const { value, must } = props[v]; // 省略宣言
    if (!must || must.length === 0) { continue; } // must が設定されていなければ ループをスキップ

    //  エラーチェック　type : { value :[], must: true } 型に対応するため value.length <= 0 で対応しています
    if (!value || value.length <= 0) {
      console.log('エラーログ', v);
      return false; // エラー
    }

    //  type : { value :[], must: [] } 型のエラーチェック
    let atLeastOneCheck = false
    if (Array.isArray(value) && Array.isArray(must) && must.length >= 1) { // valueがArray型なら 
      const checkName = must[0]; // 値をチェックする
      for (let i in value) { // 行ループ
        if (!value[i][checkName]) { break; }
        let inValue = false; // 値が入っているかチェックするための変数を宣言
        for (let j in value[i]) { // 列ループ
          let result = must.indexOf(j) // 配列の中身を検索してmust指定したものを検知
          if (result !== -1 && value[i][j] && typeof value[i][j] !== 'boolean' && j.toLowerCase() !== 'rowno' && j !== 'type') { // 値が入っていたら
            inValue = true; // 値が入っていることを認識
            atLeastOneCheck = true;
            break;
          }
        }
        if (inValue) { // 行に値が入っていたら
          for (let j in value[i]) { // 列ループ
            let result = must.indexOf(j) // 配列の中身を検索してmust指定したものを検知
            if (result !== -1 && !value[i][j]) { // そのセルがmustでかつ値がなければ
              console.log('エラーログ', value[i]);
              return false; // エラー
            }
          }
        }
      }
      if (!atLeastOneCheck) { console.log(v + ' 行にデータが入っていない'); return false }
    }
  }
  return true;
};


/****************************************↓↓↓↓↓Reducerデータ更新共通Function↓↓↓↓↓**********************************************/
/****************************************↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓*********************************************/
export const loadInputJson = (state, json, clearArr, key) => {
  loadJson(state, json, 'inputValues', clearArr)
  // state.inputValues.checked == false は　Loadinput時　とConfirm失敗時両方あるので、さらにdoNotUpdateHistoryを加えて制御する
  if (!state.inputValues.checked && !json['doNotUpdateHistory']) {
    //編集時初回目のLoadinputの処理
    saveIntoSessionstorage(key + "_inputValues", state.inputValues)
  } else {
    //サーバー側Confirmの結果をLoadした後のRenderを起こすため
    state.inputValues['randomkey'] = !!state.inputValues['randomkey'] ? state.inputValues['randomkey'] + 1 : Math.random();// 
  }
}

export const loadSearchJson = (state, json) => {
  loadJson(state, json, 'searchValues');
}

export const loadDetailJson = (state, json) => {
  return loadJson(state, json, 'detailValues');
}

export const loadJson = (state, json, to, clearArr) => {
  if (!to) return;
  if (!state[to]) state[to] = {};
  //jsonを遍歴
  for (let v in json) {
    if (v === 'doNotUpdateHistory') continue;//退避項目
    if (typeof (state[to][v]) !== 'undefined' && state[to][v] !== null) {
      if (to === 'inputValues' && Array.isArray(clearArr) && clearArr.indexOf(v) > -1) {
        state[to][v].value = ''; // クリア
        continue;
      }
      //項目の値はValueに管理する場合
      if (state[to][v].hasOwnProperty('value')) {
        state[to][v].value = json[v];
        //Tableの初期値Row対応
        if (state[to][v].hasOwnProperty('initRow') && json[v] && json[v].length > 0) {
          let maxLine = Number(state[to][v].initRow);
          let jsonLength = json[v].length;
          let _blankModel = makeObjectEmpty({ ...json[v][0] }); //一行目のModelをCopyしてからClear
          //ITEMKBNだけはクリアしない
          _blankModel['itemKbn'] = json[v][0]['itemKbn']
          for (; maxLine > jsonLength; maxLine--) {
            json[v].push({ ..._blankModel });
          }
          state[to][v].rowTemplate = _blankModel
        }
      } else {
        //項目の直下に値を管理する場合
        state[to][v] = json[v];
      }
    } else {
      state[to][v] = { value: json[v] };
    }
  }
}
/****************************************↑↑↑↑↑Reducerデータ更新共通Function↑↑↑↑↑**********************************************/
/****************************************↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑*********************************************/
export const updateReducer = (state, json) => {
  return update(state, json)
}

export const searchEdit = (state, action) => {
  if (state.searchValues[action.name] && state.searchValues[action.name].hasOwnProperty('value')) {
    //値は変わっていない場合、Renderしない
    if (state.searchValues[action.name].value === action.value) return state;
    return update(state, {
      searchValues: {
        [action.name]: {
          value: { $set: action.value }
        }
      }
    });
  } else {
    // return state
    return update(state, {
      searchValues: {
        [action.name]: { $set: action.value }
      }
    })
  }
}

export const formEdit = (state, action) => {
  if (state.inputValues[action.name] && state.inputValues[action.name].hasOwnProperty('value')) {
    if (state.inputValues[action.name].hasOwnProperty('must') && (state.inputValues[action.name].must === true || state.inputValues[action.name].must.length > 0)) state.inputValues.isChanged = true;
    // return state
    return update(state, {
      inputValues: {
        [action.name]: {
          value: { $set: action.value }
        }
      }
    })
  } else {
    // return state
    return update(state, {
      inputValues: {
        [action.name]: { $set: action.value }
      }
    })
  }
}

export const formPropEdit = (state, action) => {
  if (state.inputValues[action.name].hasOwnProperty('must') && (state.inputValues[action.name].must === true || state.inputValues[action.name].must.length > 0)) state.inputValues.isChanged = true;
  // return state
  return update(state, {
    inputValues: {
      [action.name]: {
        [action.propaty]: { $set: action.value }
      }
    }
  })
}

export const searchListEdit = (state, action) => {
  if (state.searchListValues[action.name] && state.searchListValues[action.name].hasOwnProperty('value')) {
    //値は変わっていない場合、Renderしない
    if (state.searchListValues[action.name].value === action.value) return state;
    return update(state, {
      searchListValues: {
        [action.name]: {
          value: { $set: action.value }
        }
      }
    });
  } else {
    return update(state, {
      searchListValues: {
        [action.name]: { $set: action.value }
      }
    })
  }
}

export const mapIndexFromArr = (fromArr, fieldName, toObj) => {
  if (typeof fromArr === 'undefined') return
  for (let i = 0; i < fromArr.length; i++) {
    toObj[fromArr[i][fieldName]] = i;
  }
}

export const mapDataKeyFromJson = (state, json, dataKeys, fromName) => {
  if (isEmptyObject(json)) return null;
  for (let i = 0; i < dataKeys.length; i++) {
    let name = dataKeys[i];
    let _from_name = (fromName ? fromName[i] || dataKeys[i] : name) || name;
    //項目の値はValueに管理する場合
    if (typeof (state.inputValues[name]) === 'undefined') {
      state.inputValues[name] = json[_from_name];
    } else {
      if (state.inputValues[name].hasOwnProperty('value')) {
        state.inputValues[name].value = json[_from_name];
      } else {
        //項目の直下に値を管理する場合
        state.inputValues[name] = json[_from_name];
      }
    }
  }
}
// payloadにPropsの中のdataKeysに合致する値を自動セット
export const mapDataKeyToPayload = (payload, props, dataKeys) => {
  if (isEmptyObject(props)) return null;
  // dataKeysがない時、Propsを丸ごとMapする
  if (dataKeys == null || dataKeys.length === 0) {
    let _props = toJson(props);
    for (let item in _props) {
      if (!_props[item]) continue;
      if (_props[item].hasOwnProperty('value')) {
        payload[item] = _props[item].value;
      } else {
        payload[item] = _props[item];
      }
    }
  } else {
    // dataKeysがある場合、指定したKetだけをMapする
    for (let i = 0; i < dataKeys.length; i++) {
      let name = dataKeys[i];
      if (typeof (props[name]) === 'undefined' || props[name] == null) {
        payload[name] = '';
      } else {
        if (props[name].hasOwnProperty('value')) {
          //項目は定義していない　もしくは　項目の直下に値を管理する場合
          payload[name] = props[name].value;
        } else {
          //項目の値はValueに管理する場合
          payload[name] = props[name];
        }
      }
    }
  }
}
//ObjectはNullかどうか
export const isEmptyObject = (obj) => {
  for (let key in obj) {
    return false
  };
  return true
}

// 二つのObjectの値を対比するFucntion（ShallowCompare）
const hasOwn = Object.prototype.hasOwnProperty
// 判断是否为obj对象
export const isObj = (object) => {
  return object && typeof (object) === 'object' && Object.prototype.toString.call(object).toLowerCase() === "[object object]";
}
// 判断是否为数组对象
export const isArray = (object) => {
  return object && typeof (object) === 'object' && object.constructor === Array;
}

//获取obj对象的长度
export const getLength = (object) => {
  return Object.keys(object).length;
}

// 下面就是进行浅比较了, 有不了解的可以提issue, 到时可以写一篇对比的文章。
export const is = (x, y, must) => {
  // === 严格判断适用于对象和原始类型。但是有个例外，就是NaN和正负0。
  if (x === y) {
    //这个是个例外，为了针对0的不同，譬如 -0 === 0 : true
    // (1 / x) === (1 / y)这个就比较有意思，可以区分正负0, 1 / 0 : Infinity, 1 / -0 : -Infinity
    return x !== 0 || y !== 0 || 1 / x === 1 / y
  } else {
    // 这个就是针对上面的NaN的情况
    return x !== x && y !== y
  }
}

const numberKeys = ['add', 'sub', 'mul', 'div'];
export const CompareObj = (objA, objB, flag = true) => {
  // 子Nodeがない時、全部文字列で比較
  if (objA === 'null' || objA === null || typeof objA == 'undefined') {
    flag = objB === 'null' || objB === null || typeof objB == 'undefined';
    return flag;
  }
  if (objB === 'null' || objB === null || typeof objB == 'undefined') {
    flag = objA === 'null' || objA === null || typeof objA == 'undefined';
    return flag;
  }
  if (Object.keys(objA).length !== Object.keys(objB).length) {
    flag = false;
    return flag;
  }

  for (let key in objA) {
    // Number型の時、Propertyに追加したKeyを除外する
    if (numberKeys.indexOf(key) >= 0) continue;
    if (!flag) //跳出整个循环
      break;
    if (isArray(objA[key])) { //子级是数组时,
      console.log("array..........");
      if (!isArray(objB[key])) {
        console.log("B not array..........");
        flag = false;
        break;
      }
      let oA = objA[key],
        oB = objB[key];
      if (oA.length !== oB.length) {
        console.log("A and B array.length not eq..........");
        flag = false;
        break;
      }
      for (let k in oA) {
        if (!flag) //这里跳出循环是为了不让递归继续
          break;
        flag = CompareObj(oA[k], oB[k], flag);
      }

    }
    else if (isObj(objA[key])) {//子级是obj时,
      if (!isObj(objB[key])) {
        flag = false;
        break;
      };
      if (getLength(objA[key]) !== getLength(objB[key]))
        flag = false; //判断长度是否一致
      if (!flag) //这里跳出循环是为了不让递归继续
        break;
      flag = CompareObj(objA[key], objB[key], true); //默认为true
    }
    else {
      // 値の照合
      //　undefined\Null\''の場合、合致と認識する
      if (isNull_Undefined_Blank(objB[key]) && isNull_Undefined_Blank(objA[key])) {
        continue
      }
      if (objB[key] !== objA[key]) {
        console.log("diff", objB[key], objA[key], key);
        flag = false;
        break;
      };
    }
  }

  return flag;
}

export const isNull_Undefined_Blank = (v) => {
  return typeof v === 'undefined' || v === null || v === ''
}
export const shallowEqual = (objA, objB) => {
  if (is(objA, objB)) return true //这个就是实行了Object.is的功能。实行的是SameValue策略。
  // is方法之后，我们认为他不相等。不相等的情况就是排除了(+-0, NaN)的情况以及可以证明:
  // 原始类型而言： 两个不是同类型或者两个同类型，值不同。
  // 对象类型而言： 两个对象的引用不同。


  //下面这个就是，如果objA和objB其中有个不是对象或者有一个是null, 那就认为不相等。
  //不是对象，或者是null.我们可以根据上面的排除来猜想是哪些情况:
  //有个不是对象类型或者有个是null,那么我们就直接返回，认为他不同。其主要目的是为了确保两个都是对象，并且不是null。
  if (typeof objA !== 'object' || objA === null ||
    typeof objB !== 'object' || objB === null) {
    return false
  }

  //如果上面没有返回，那么接下来的objA和objB都是对象了。

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  //两个对象不同，有可能是引用不同，但是里面的内容却是相同的。例如：{a: 'a'} ==~ {a: 'a'}
  //所以先简单粗暴的判断一级的keys是不是相同的长度。，不是那就肯定不相等，就返回false。
  if (keysA.length !== keysB.length) return false

  //下面就是判断相同长度的key了
  // 可以发现，遍历的是objA的keysA。
  //首先判断objB是否包含objA的key,没有就返回false。注意这个是采用的hasOwnPrperty来判断，可以应付大部分的情况。
  //如果objA的key也在ObjB的key里，那就继续判断key对应的value，采用is来对比。哦，可以发现，只会对比到第一级。

  for (let i = 0; i < keysA.length; i++) {
    // valueが付いていない項目は比較対象外
    if (objA[keysA[i]] === null || objA[keysA[i]] === '' || typeof objA[keysA[i]] === 'undefined') continue;
    if (!objA[keysA[i]].hasOwnProperty('value') || objA[keysA[i]].hasOwnProperty('nocheck')) continue;

    if (!hasOwn.call(objB, keysA[i]) ||
      !CompareObj(objA[keysA[i]]['value'], objB[keysA[i]]['value'])) {
      return false
    }
  }

  return true
}

export const makeObjectEmpty = (obj) => {
  for (let key in obj) {
    switch (typeof obj[key]) {
      case 'string': obj[key] = ''; break;
      case 'number': obj[key] = 0; break;
      case 'object':
        obj[key] = null; break;
      default: obj[key] = null; break;
    }
  };
  return obj;
}
export const getOptions = async (url) => {
  //IEのエラー対応
  if (!!url && url.indexOf("<>") >= 0) {
    url = url.replace("<>", "%3C%3E")
  }
  let options = [{ 'value': '', 'label': '' }];
  return new Promise((resolve, reject) => {
    axiosPost.get(server + url)
      .then((res) => {
        if (res.data != null) {
          resolve(res.data);
        } else {
          resolve(options);
        }
      }).catch(
        err => {
          reject(err)
        }
      );
  })
  // return options;
}

//同期Request
export const getJSON = async (url, json = {}) => {
  console.log(url);
  console.log('post-json', json);
  return new Promise((resolve, reject) => {
    axiosPost.post(server + url, json).then((res) => {
      if (!res) return

      console.log('res-json', res);
      resolve(res.data);
    }).catch(
      err => {
        reject(err)
      }
    );
  })
};
export const getName = async (url, key, name) => {
  let rtnData = '';
  if (key) {
    key = key.replace('#', '%23');
  }
  await axiosPost.get(server + url + '?key=' + key).then(res => {
    console.log('getName_' + url, res.data)
    rtnData = res.data;
  });
  return rtnData;
}

//stateの内部で、orgReducer領域のデータを戻す
//orgReducer：COMMON.COMM / COMM.Login
export const selectImportantData = (state, orgReducer) => {
  if (orgReducer === null || orgReducer === '' || typeof orgReducer === 'undefined') return {};
  const paths = orgReducer.split('.');
  let data = state;
  for (let i = 0; i < paths.length; i++) {
    data = data[paths[i]];
  }
  return data
}

export const messageFormatter = (pMaster, name, para) => {
  let msg = pMaster[name];
  return msg.replace(/\{(\d+)\}/g,
    (m, i) => {
      return getPMasterName(para[i]);
    });
}

export const getPMasterName = (name, para = null) => {
  const COMMON = store.getState()['COMMON']
  const pMaster = COMMON.Common.pMaster
  if (pMaster && pMaster[name]) {
    if (para) {
      return messageFormatter(pMaster, name, para)
    } else {
      return pMaster[name];
    }
  }
  return null
}
// 現在のmenuIDを取得
export const getCurrentMenuId = () => {
  let currentPath = getCurrentPath();
  let menuId = currentPath.split('/')[currentPath.split('/').length - 1];
  if (menuId) {
    menuId = menuId.toUpperCase()
  }
  return menuId;
}

//menu用現在PathをReturn
export const getCurrentPath = () => {
  const router = store.getState()['router']
  let path = ''
  if (!!router && !!router.location) {
    path = router.location.pathname
    if (isNull_Undefined_Blank(path)) {
      path = sessionStorage.getItem('LAST_LOCATION') || ''
    } else {
      if (path.indexOf('error') >= 0 || path.indexOf('Error') >= 0) {
        path = ''
      } else {
        if (path.charAt(path.length - 1) === '/') {
          path = path.substr(0, path.length)
        }
      }
    }
  }
  if (path === '/') path = ''
  return path
}


export const isTopOpened = () => {
  return store.getState()['COMMON'].isTopOpened
}

export const isLeftOpened = () => {
  return store.getState()['COMMON'].isLeftOpened
}

export const getTableRows = () => {
  return 15;
}
/**
 * #,###,###.00でフォーマット化
*/
export const getQtyFormat = () => {
  return '#,###,###';
}

/**
 * #,###,###.00でフォーマット化
*/
export const getPriceFormat = () => {
  return '#,###,###';
}

/**
* 日付を計算する処理
* calcDate({date:"2021/08/23", d: -1 }) →  "2021/08/22"
* calcDate({type:"YYYY/MM" date:"2021/08/31", d: 1 }) →  "2021/09"
* calcDate({type:"YYYY/MM/DD" date:"2021/08/23",m: 1, d: 1 }) →  "2021/09/23"
*/
export const calcDate = (obj) => {
  const { type, date, y, m, d } = obj // 引数はObjectで受け取る

  let newDate = date ? new Date(date) : new Date();
  let _year = newDate.getFullYear();
  let _month = newDate.getMonth();
  let _date = newDate.getDate();

  _year = y ? safeEval(_year + y) : _year;
  _month = m ? safeEval(_month + m) : _month;
  _date = d ? safeEval(_date + d) : _date;
  if (m && !d) {
    //小月
    if (_month === 3 || _month === 5 || _month === 8 || _month === 10) {
      if (_date > 30) {
        _date = 30
      }
    }
    //2月
    else if (_month === 1) {
      if (isLeapYear(newDate.getFullYear())) {
        if (_date > 29) {
          _date = 29
        } else {
          _date = 28
        }
      }
      if (_date > 28) {
        _date = 28
      }
    }
    //大月
    else {
      if (_date > 31) {
        _date = 31
      }
    }
  }


  const calcNewDate = new Date(_year, _month, _date);
  return convertDate({ type: type, date: calcNewDate });
}
//JS判断闰年代码
export const isLeapYear = (Year) => {
  if (((Year % 4) === 0) && ((Year % 100) !== 0) || ((Year % 400) === 0)) {
    return (true);
  } else { return (false); }
}
//__________________________________________________________________
export const safeEval = (val) => {
  if (val) {
    return Function('"use strict";return (' + val + ')')();
  } else {
    return;
  }
}
//__________________________________________________________________
/**
* 日付の表記を整理する処理
* convertDate({type:'YYYY/MM/DD', y:2020, m:11, d:10}) →　return '2020/11/10'
* convertDate({type:'YYYY/MM', y:2020, m:11, d:10}) →　return '2020/11'
* convertDate({type:'YYYY/MM/DD', date: new Date()}) →　return '2020/11/10'
* @param {object} obj - 
*/
export const convertDate = (obj) => {
  const { type = '', date, y, m, d } = obj // 引数はObjectで受け取る
  const newDate = date ? date : new Date(y, m, d);
  const newYear = newDate.getFullYear();
  const newMonth = newDate.getMonth() + 1;
  const localDate = newDate.getDate();
  const MM = ("0" + newMonth).slice(-2); // sliceメソッド で頭に '0' を追加
  const DD = ("0" + localDate).slice(-2); // sliceメソッド で頭に '0' を追加
  if (newDate === 'Invalid Date') { return '' }
  // typeによって変換する形式を変更する
  switch (type.toUpperCase()) {
    case 'YYYY/MM/DD': {
      return (newYear + "/" + MM + "/" + DD); // YYYY/MM/DD で返す
    }
    case 'YYYY/MM': {
      return (newYear + "/" + MM); // YYYY/MM で返す
    }
    case 'MM/DD': {
      return MM + '/' + DD;
    }
    case 'YYYYMMDD': {
      return newYear + "" + MM + "" + DD
    }
    default: {
      return (newYear + "/" + MM + "/" + DD); // YYYY/MM/DD で返す
    }
  }
}

export const funcValidate = (fn) => {
  if (typeof (fn) === 'function') {
    let v;
    try {
      v = fn();
    } catch (err) {
      // error 発生
      v = "ERROR!!"
    } finally {
      return v;
    }
  } else {
    return fn;
  }
}

export const validateInputTable = (detailList) => {
  const must = detailList.must;
  let detailList_copy = Object.assign([], detailList.value);
  if (!must || must.length <= 0) { return { error: false, data: detailList_copy } } // mustが設定されていなかったら処理をしない

  const mustKey = must[0]
  let tableError = false;
  let tableValue = false; //　テーブル内にデータが1つでもあればtrue;

  for (let i in detailList_copy) {
    let rowsError = false;
    if (detailList_copy[i][mustKey]) { // mustKeyに値がなければその行のエラーチェック処理はスキップする
      tableValue = true;
      for (let j = 1; j < must.length; j++) {
        const mustName = must[j]
        if (!detailList_copy[i][mustName]) {
          detailList_copy[i] = Object.assign({}, detailList_copy[i], { itemErrorMsg: '必須項目を入力して下さい' })　// 行にエラーがあれば'itemErrorMsg'を追加
          tableError = true;
          rowsError = true;
          break;
        }
      }
    }
    if (!rowsError) {
      detailList_copy[i] = Object.assign({}, detailList_copy[i], { itemErrorMsg: '' })　// 行に1つもエラーがなければ'itemErrorMsg'を削除
    }
  }
  if (!tableValue) {
    detailList_copy[0] = Object.assign({}, detailList_copy[0], { itemErrorMsg: '一行以上入力してください' })　// テーブルに1つも値がなければ'itemErrorMsg'を追加
    return { error: true, data: detailList_copy };
  } if (tableError) {
    return { error: true, data: detailList_copy }; // エラーがあれば'itemErrorMsg'を追加してエラー返す
  } else {
    return { error: false, data: detailList_copy };
  }
}

export const saveIntoSessionstorage = (key, obj) => {
  sessionStorage.setItem(key, typeof obj === 'object' ? JSON.stringify(obj) : obj)
}

// connectStr({connecter:'-', strs:[1234,0,0]})
export const connectStr = (obj) => {
  let connecter = obj['connecter'] || ''
  let strs = [];
  let retrunStr = ''

  // 引数を
  if (Array.isArray(obj)) {
    strs = obj;
  } else if (obj) {
    strs = obj['strs'];
  }

  for (let i = 0; i < strs.length; i++) {
    if (strs[i] && !retrunStr) {
      retrunStr += strs[i];
    } else if (strs[i]) {
      retrunStr += connecter + strs[i];
    }
  }
  return retrunStr
}

// js label name helper
export const getComponentLabelName = (code) => {
  if (!!content) {
    return content[`${code}_JA`] || '-'
  }
}
/**
 * object型をコンボ用のリストに変換します
 * {value: label, value: label, ....} -> [{value: a, label: b}, {value: c, label: d}, ...]
 * @param {object} obj - 
 */
export const convertToComboList = (obj) => {
  let rtnList = [];
  if (!isEmptyObject(obj)) {
    for (let i in obj) {
      rtnList.push({ value: i, label: obj[i] });
    }
  }
  return rtnList
}
/**
 * テーブルにて、チェックされたデータのkey情報を配列で取得します。
 * {value: label} -> [{value: a, label: b}, {value: c, label: d}, ...]
 * @param {ArrayObject} data - テーブルのリスト
 * @param {string} checkName - チェックボックスのname
 * @param {string} keyName1 - keyとなる情報
 * @param {string} keyName2 - keyとなる情報2
 */
export const getCheckTableData = (data, checkName, keyName1, keyName2) => {
  const returnArr = [];
  const returnData = [];

  for (let i in data) {
    if (data[i][checkName] === '1') {
      returnData.push(data[i]);
    };
  };
  if (returnData.length === 0) {
    alert("チェックをしてください");
    return [];
  };
  for (let i = 0; i < returnData.length; i++) {
    if (keyName1 && !keyName2) {
      returnArr.push(returnData[i][keyName1]);
    } else if (keyName1 && keyName2) {
      returnArr.push(returnData[i][keyName1] + '_' + returnData[i][keyName2]);
    }
  };
  return returnArr;
}
/**
 * 時間(h)のコンボデータを作成します。
 */
export const createHourOption = () => {
  let rtnData = []
  for (let i = 0; i <= 23; i++) {
    let hour = String(i);
    if (hour.length === 1) {
      hour = "0" + hour;
    }
    rtnData.push({ value: hour, label: hour });
  }
  return rtnData
}
/**
 * 分数(m)のコンボデータを作成します。
 */
export const createMinOption = () => {
  let rtnData = []
  for (let i = 0; i <= 59; i++) {
    let min = String(i);
    if (min.length === 1) {
      min = "0" + min;
    }
    rtnData.push({ value: min, label: min });
  }
  return rtnData
}

export const getToken = () => {
  return getParaFromSessionstorage('token');
}

export const removeToken = () => {
  removeParaFromSessionstorage('token');
}

// 内部用
export const getParaFromSessionstorage = (paraName) => {
  return sessionStorage.getItem(paraName);
}

export const removeParaFromSessionstorage = (paraName) => {
  sessionStorage.removeItem(paraName);
}