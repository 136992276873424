import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

export default class Balloon extends React.Component{
  shouldComponentUpdate(nextProps){
    const {label, x, y, top, color} = nextProps;
    if(!label) { return false}
    if(nextProps.render) {
      if(!document.getElementById('balloon')) {
        const wrapper = document.body.appendChild(document.createElement('div')); // div要素を作成するための変数を宣言
        wrapper.id = 'balloon'  // そのdiv要素のidに'modal'を設定
        const wrap_style = {
          position: "fixed",
          top: y,
          left : x,
          display: "flex",
          alignItems: "center",
          zIndex: 2,
          height: 0,
          paddingLeft: 4,
          whiteSpace: "nowrap",
          width:0,
          flexDirection : 
            top ? 'column' :
              null,
        }
        const triangle_style = {                            // <div> 吹き出し上部の三角形
          borderRightWidth: "8px",
          borderRightStyle : 'solid',
          borderRightColor : color ,
          borderBottom: "4px solid transparent",
          borderTop: "4px solid transparent",
          transform: top ? 'rotate(90deg)' :
           null,
        }
        const label_style = {                             // <div> 吹き出しの言葉
          padding: "8px 16px",
          color: "#fff",
          display: "inline-flex",
          alignItems: "center",
          backgroundColor: color,
          borderRadius: 4,
          fontWeight: 'bolder',
          fontSize: 12,
        }
        //______________________________________________________________________

        ReactDOM.render(
          <div style={wrap_style}>
            <div style={triangle_style}></div>
            <div style={label_style}>
              {label}
            </div>
          </div>
          ,wrapper
        );
      }
    }else {
      const balloon = document.getElementById('balloon'); // 作ったid('modal')のdiv要素を取得
      if (balloon) {
        ReactDOM.unmountComponentAtNode(balloon);       // 作ったreactDomを削除
        balloon.parentNode.removeChild(balloon);            // 一度親に戻って子供を参照して作ったdiv要素を削除（IE対応）
      }
    }
    return true;
  }
  render(){
    return null;
  }
}
// ________________________________________________________________________________________________________________
