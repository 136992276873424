import React from 'react';
import IconLoading from './IconLoading';
import '../css/loadableButton.css';
import { connect } from 'react-redux';
import { getPMasterName } from '../util/commonInput';
import * as Icons from "./Icons";


class LoadableButton extends React.Component {
  handleClick = (e) => {
    const { loading, onClick } = this.props;
    if (!!loading) {
      // loadingしている場合、OnClickを中断
      return;
    }
    if (onClick) {
      onClick(e);
    }
  }

  render() {
    const { loading, label, plabel, plabel2, type } = this.props;
    let Plabel = plabel && getPMasterName(plabel);
    let Plabel2 = plabel2 && getPMasterName(plabel2);
    if (Plabel === 'NONE') { return null }

    let typeIcon = null;
    switch (type) {
      case 'excel': {
        typeIcon = <Icons.FileExcel size={14} color="#FFF" style={{ marginRight: 8 }} />
        break;
      }
      case 'pdf': {
        typeIcon = <Icons.PdfFile size={14} color="#FFF" style={{ marginRight: 8 }} />
        break;
      }
      default: {
        break;
      }
    }

    const iconNode = loading ? <IconLoading /> : typeIcon;
    const loddingClass = loading ? ' loadable-button-loading' : '';

    return (
      <div onClick={this.handleClick} className={"loadable-button" + loddingClass}>
        {iconNode}
        {Plabel}
        {Plabel2}
        {label}
      </div>
    );
  }
}

const mapStateToProp = (state, props) => {
  return {
    loading: state.COMMON.Common[props.id] || false,
  }
}
export default connect(mapStateToProp)(LoadableButton)