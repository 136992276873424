import React from 'react';
import * as Icons from "../Icons";
import { table as Color } from '../Colors'

export default class CheckCell extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      hover: false,
      value: value,
    }
    this.td_style = {                // <td> 各セル
      width: 30,
      border: "1px solid" + Color.border,
      textAlign: "center",
      verticalAlign: "middle",
      transition: '0.2s',
      paddingTop: 2,
      zIndex: this.props.zIndex || null,

    }
    this.checkWrap_style = {
      height: 16,
      width: 16,
      border: '1px solid #707070',
      backgroundColor: '#FFFFFF',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }
  //___________________________________________________________________
  shouldComponentUpdate(nextProps) {
    const { value } = this.props;
    if (value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
    return true;
  }
  //___________________________________________________________________
  handleOnClick = (e) => {
    e.stopPropagation();
    const { value } = this.state;
    const { index, detailIndex, onChange, children, setData, disabled, checkBox } = this.props;
    if (disabled || !checkBox || !children.name) { return };
    let nextValue = (value !== '1') ? '1' : '';
    this.setState({ value: nextValue });
    const fowardData = { 'index': index, 'name': children.name, 'value': nextValue, 'detailIndex': detailIndex, 'rowData': setData };
    onChange && onChange(fowardData);
    children.onChange && children.onChange(fowardData);
  }
  //___________________________________________________________________

  render() {
    const { children, disabled, checkBox, color, fixedWidth, style, tabIndex } = this.props;
    const {
      rowSpan,
      colSpan,

      width,
      minWidth,
    } = children
    const {
      value,
      hover,
    } = this.state;
    Object.assign(this.td_style, {            // <td> 各セル
      backgroundColor:
        // disabled ? color :
        // !checkBox ? color :
        hover ? Color.check :
          fixedWidth || fixedWidth == 0 ? color : // 固定が設定されていなかったら背景色はつけない
            null,
      cursor:
        disabled ? null :
          'pointer',
      width: width,
      minWidth: minWidth,
      position:
        fixedWidth || fixedWidth == 0 ? 'sticky' :
          null,
      left: fixedWidth,
    })
    Object.assign(this.checkWrap_style, {            // <td> 各セル
      display:
        !checkBox ? 'none' :
          'inline-flex',
    })
    return (
      <td
        style={Object.assign({}, this.td_style, style)}
        rowSpan={rowSpan === 'max' ? 1000 : rowSpan}
        colSpan={colSpan}
        onClick={this.handleOnClick}
        tabIndex={tabIndex || 0}
        disabled={disabled}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        <div style={Object.assign({}, this.checkWrap_style)}>
          <Icons.Check1 width={15} height={15} color={Color.check} hidden={value != '1'} />
        </div>
      </td>
    )
  }
}