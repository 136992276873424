import React, { Fragment } from 'react';
import BasicWrap from './BasicWrap';

export default class Textarea extends React.Component {
  constructor(props) {
    super(props);
    const {
      value,
    } = this.props;

    this.state = {
      value: value || '',
    };
    this.basicWrap_style = {
      width: '100%',
      height: '100%',
      maxHeight: 'inherit',
      display: 'inline-flex',
      padding: 2,
    }
    this.textarea_style = {
      fontSize: 'inherit',
      width: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      resize: 'none',
      height: '100%',
    }

  }
  // _____________________________________________________________________

  shouldComponentUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      const fixedValue = nextProps.value ? nextProps.value : ""; // null や undifinedを''に修正
      this.setState({ value: fixedValue });
    }
    return true;
  }
  // ______________________________________________________________________

  handleOnChange = (e) => {
    this.setState({ value: e.target.value });
  }
  // ______________________________________________________________________

  handleOnBlur = (e) => {
    const { name, onChange } = this.props;
    onChange && onChange(name, e.target.value);
    this.setState({ focus: false })
  }
  // _______________________________________________________________________

  render() {
    const {
      disabled,
      style,
      readOnly,
      maxLength,
      autoFocus,
    } = this.props;

    const { value } = this.state;
    // _______________________________________________________________________________________________________
    return (
      <BasicWrap {...this.props} value={value} style={this.basicWrap_style}>
        <textarea
          autoFocus={autoFocus}
          style={Object.assign({}, this.textarea_style, style)}
          maxLength={maxLength}
          value={value}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          readOnly={readOnly}
          disabled={disabled}
        />
      </BasicWrap>
    )
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
