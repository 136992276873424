/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment } from 'react';
import { funcAdapter, connectStr } from '../../util/commonInput';

// _________________________________________________________________________________
export const allCheck = (value, name, detail, config, data) => {
  let rtnData = data;
  let type = "check";
  let disabled = false;
  let checkBox = true;
  // 明細のチェックかどうか
  if (detail) {
    // "check"を設定しているカラム設定を取得
    for (let i in config) {
      if (config[i]["type"] === "detailVModels") {
        for (let j in config[i]["detailConf"]) {
          if (config[i]["detailConf"][j]["name"] === name) {
            type = config[i]["detailConf"][j]["type"] // typeの設定を取得
            disabled = config[i]["detailConf"][j]["disabled"]　// disabledの設定を取得
            checkBox = config[i]["detailConf"][j].hasOwnProperty("checkBox") ? config[i]["detailConf"][j]["checkBox"] : true; // checkBoxの設定を取得
            break;
          }
        }
      }
    }
    //  setDataの明細内容にcheckの変更を反映
    for (let i in data) {
      for (let j in data[i]["detailVModels"]) {
        let setType = funcAdapter(type, data[i]["detailVModels"][j]);
        let setDisabled = funcAdapter(disabled, data[i]["detailVModels"][j]);
        let setCheckBox = funcAdapter(checkBox, data[i]["detailVModels"][j]);
        // checkに設定しているnameの値を変更
        if (setType === "check" && !setDisabled && setCheckBox) {
          data[i]["detailVModels"][j][name] = value;
        }
      }
    }

  } else {
    // "check"を設定しているカラム設定を取得
    for (let i in config) {
      if (config[i]["name"] === name) {
        type = config[i]["type"] // typeの設定を取得
        disabled = config[i]["disabled"]　// disabledの設定を取得
        checkBox = config[i].hasOwnProperty("checkBox") ? config[i]["checkBox"] : true; // checkBoxの設定を取得
        break;
      }
    }
    //  setDataの内容にcheckの変更を反映
    for (let i in data) {
      let setType = funcAdapter(type, data[i]);
      let setDisabled = funcAdapter(disabled, data[i]);
      let setCheckBox = funcAdapter(checkBox, data[i]);
      // checkに設定しているnameの値を変更
      if (setType === "check" && !setDisabled && setCheckBox) {
        data[i][name] = value;
      }
    }
  }
  return rtnData;
}
// _________________________________________________________________________________
/**
* null, undefined, '', falseを統一します
* @param {any} value - 
*/
export const consistentBlankValue = (value) => {
  if (!value && value !== 0) {
    return '（Blank）';
  } else {
    return value;
  }
}
// _________________________________________________________________________________

/**
* フィルタリングのチェックリスト情報を読み取り、setDataにフィルターをかけます
* @param {arrayObject} setData - 
* @param {object} filterList - 
*/
export const doFilter = (setData, filterList) => {
  // setDataをフィルタリング
  let filterdData = []
  filterdData = setData.filter((item) => {
    // nameが設定してあるプロパティのみ処理します
    for (let name in filterList) {
      const value = String(consistentBlankValue(item[name])); // nameごとの各セルの値をString型にします
      // 各フィルタリングリストからcheckがついていないものを探り、checkが外れている値があるデータは除外します
      if (filterList[name].indexOf(value) !== -1) {
        return false
      };
    }
    return true; // 除外されなかったデータのみ表示
  });
  return filterdData // フィルターされたsetDataを返します
}
// _________________________________________________________________________________
/**
* 関数の説明
// 配列型nameから値を結合した取得する
* @param {json} rowData - 
* @param {array} names - 
* @param {string} connecter - 
*/
export const getConnectValue = (rowData, names, connecter) => {
  let rtnStr = ""
  let strs = [];
  for (let i in names) {
    const name = names[i];
    const value = rowData[name];
    strs.push(value);
  }
  rtnStr = connectStr({ "connecter": connecter, "strs": strs });
  return rtnStr
}
// _________________________________________________________________________________
/**
* フィルター用のチェックリストを作成
* @param {arrayObject} setData - 
* @param {string} name - 
* @param {boolean} isArray - 
*/
export const getNameDataList = (setData, name, isArray) => {
  let rtnList = {}; // nameの設定してあるデータを配列化してグルーピングするためのリスト
  for (let i in setData) {
    // 配列型が設定されているテーブルの場合
    if (isArray) {
      for (let j in setData[name]) {
      }

      // 配列型が設定されていない基本のテーブルの場合
    } else {
      // 重複データを除外して、それ以外であればリストに追加します
      // undifinedや、null、空値等は'(Blank)'とします
      const setNameKey = consistentBlankValue(setData[i][name])
      if (!rtnList.hasOwnProperty(setNameKey)) {
        rtnList[setNameKey] = '1' // '1'を初期値として、1ならリストに表示、1以外なら表示しません。
      }
    }
  }

  // フィルターリストで表示するため、並び替え処理を行います。
  if (Object.keys(rtnList).length > 0) {
    let sortList = Object.entries(rtnList)
    sortList.sort((a, b) => {
      const aKey = a[0];
      const bKey = b[0];
      if (aKey < bKey) { return -1; }
      if (aKey > bKey) { return 1; }
      return 0;
    })
    rtnList = Object.fromEntries(sortList);
  }

  return rtnList;
}
// _________________________________________________________________________________
/**
* フィルタリングのチェックリスト情報を読み取り、setDataにフィルターをかけます
* @param {object} filterList - 
*/
export const getUseFilterLists = (filterList) => {
  // フィルターの範囲を最低限に抑え、処理を軽くするためのリストを作成します
  let rtnList = {}
  for (let i in filterList) {
    let noCheckList = []; // チェックの外れているデータを格納
    for (let j in filterList[i]) {
      if (filterList[i][j] !== '1') {
        noCheckList.push(j);
      }
    }
    if (Object.keys(noCheckList).length > 0) {
      rtnList[i] = noCheckList;
    }
  }

  return rtnList // フィルターされたsetDataを返します
}
// _________________________________________________________________________________

/**
* 関数の説明
* フィルター用のチェックリストを作成
* @param {arrayObject} setData - 
* @param {arrayObject} config - 
*/
export const initFilterLists = (setData, config) => {
  const rtnData = {};
  for (let i in config) { // childrenで設定されている数だけループ
    const { name, type, secondConf, array } = config[i]  // 省略宣言

    // 2行型テーブルのチェックリストの取得
    if (type === 'secondRow') {
      for (let j in secondConf) {
        const { name } = secondConf[j];
        const setName = funcAdapter(name, setData);
        if (setName) {
          rtnData[setName] = getNameDataList(setData, setName);
        }
      }
    } else {
      const setName = funcAdapter(name, setData);
      if (setName) {
        rtnData[setName] = getNameDataList(setData, setName, array);
      }
    }

  }
  return rtnData
}
// _________________________________________________________________________________
export const orderByAsc = (key, arrIndex, data) => {
  let sortedData = data;
  // 結合タイプは配列で判断
  if (Array.isArray(key) === true) {
    // 一旦、逆ソートをかけてから、順ソートをかける
    sortedData.sort(function (a, b) {
      // 配列型用
      if (arrIndex) {
        if (a[key[0]][arrIndex] < b[key[0]][arrIndex]) { return 1; }
        else { return -1 }
      } else {
        if (a[key[0]] < b[key[0]]) { return 1; }
        else { return -1 }
      }
    })
    sortedData.sort((a, b) => {
      for (let i = 0; i < key.length; i++) {
        // 配列型用
        if (arrIndex) {
          if (a[key[i]][arrIndex] > b[key[i]][arrIndex]) { return 1; }
          else { return -1; }
        } else {
          if (a[key[i]] > b[key[i]]) { return 1; }
          else { return -1; }
        }
      }
    })

  } else {
    // 一旦、逆ソートをかけてから、順ソートをかける
    sortedData.sort(function (a, b) {
      // 配列型用
      if (arrIndex) {
        if (!a[key][arrIndex] && b[key][arrIndex]) {
          return 1; // aが空白でbが空白でない場合、aを後ろに
        } else if (a[key][arrIndex] && !b[key][arrIndex]) {
          return -1; // aが空白でbが空白でない場合、bを後ろに
        } else if (a[key][arrIndex] < b[key][arrIndex]) {
          return 1; // 通常の比較
        } else {
          return -1;
        }
      } else {
        if (!a[key] && b[key]) {
          return 1; // aが空白でbが空白でない場合、aを後ろに
        } else if (a[key] && !b[key]) {
          return -1; // aが空白でbが空白でない場合、bを後ろに
        } else if (a[key] < b[key]) {
          return 1; // 通常の比較
        } else {
          return -1;
        }
      }
    });

    sortedData.sort(function (a, b) {
      // 配列型用
      if (arrIndex) {
        if (!a[key][arrIndex] && b[key][arrIndex]) {
          return 1; // aが空白でbが空白でない場合、aを後ろに
        } else if (a[key][arrIndex] && !b[key][arrIndex]) {
          return -1; // aが空白でbが空白でない場合、bを後ろに
        } else if (a[key][arrIndex] > b[key][arrIndex]) {
          return 1; // 通常の比較
        } else {
          return -1;
        }
      } else {
        if (!a[key] && b[key]) {
          return 1; // aが空白でbが空白でない場合、aを後ろに
        } else if (a[key] && !b[key]) {
          return -1; // aが空白でbが空白でない場合、bを後ろに
        } else if (a[key] > b[key]) {
          return 1; // 通常の比較
        } else {
          return -1;
        }
      }
    });

  }
  console.log(sortedData);
  return sortedData;
}
// _________________________________________________________________________________
export const orderByDesc = (key, arrIndex, data) => {
  let sortedData = data;
  if (Array.isArray(key) === true) {
    // 一旦、逆ソートをかけてから、順ソートをかける
    sortedData.sort(function (a, b) {
      // 配列型用
      if (arrIndex) {
        if (a[key[0]][arrIndex] > b[key[0]][arrIndex]) { return 1; }
        else { return -1 }
      } else {
        if (a[key[0]] > b[key[0]]) { return 1; }
        else { return -1 }
      }
    })
    sortedData.sort((a, b) => {
      for (let i = 0; i < key.length; i++) {
        // 配列型用
        if (arrIndex) {
          if (a[key[i]][arrIndex] < b[key[i]][arrIndex]) { return 1; }
          else { return -1; }
        } else {
          if (a[key[i]] < b[key[i]]) { return 1; }
          else { return -1; }
        }
      }
    })

  } else {
    // 一旦、逆ソートをかけてから、順ソートをかける
    sortedData.sort(function (a, b) {
      // 配列型用
      if (arrIndex) {
        if (!a[key][arrIndex] && b[key][arrIndex]) {
          return 1; // aが空白でbが空白でない場合、bを後ろに
        } else if (a[key][arrIndex] && !b[key][arrIndex]) {
          return -1; // aが空白でbが空白でない場合、aを後ろに
        } else if (a[key][arrIndex] > b[key][arrIndex]) {
          return -1; // 通常の比較
        } else {
          return 1;
        }
      } else {
        if (!a[key] && b[key]) {
          return 1; // aが空白でbが空白でない場合、bを後ろに
        } else if (a[key] && !b[key]) {
          return -1; // aが空白でbが空白でない場合、aを後ろに
        } else if (a[key] > b[key]) {
          return -1; // 通常の比較
        } else {
          return 1;
        }
      }
    });
  }

  console.log(sortedData);
  return sortedData;
}

// _________________________________________________________________________________

export const rowFilter = (value, key, connecter, arrIndex, data) => {
  let filterdData = data.filter(data => {
    if (Array.isArray(key) === true) {
      let strs = [];
      for (let i = 0; i < key.length; i++) {
        // arrIndexは配列型テーブル用
        if (arrIndex) {
          strs.push(String(data[key[i]][arrIndex]));
        } else {
          strs.push(String(data[key[i]]));
        }
      }
      let val = connectStr({ "connecter": connecter, "strs": strs });
      return (val.toLowerCase().search(String(value).toLowerCase()) !== -1);

    } else {
      let val = ""
      // arrIndexは配列型テーブル用
      if (arrIndex) {
        val = String(data[key][arrIndex])
      } else {
        val = String(data[key])
      }

      return (val.toLowerCase().search(String(value).toLowerCase()) !== -1);
    }
  })
  return filterdData;
}