import React from 'react';
import { systemBtn as Colors } from './Colors'

export default class TextBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false,
    }
  }
  // ______________________________________________________________________________

  handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { onClick } = this.props;
    onClick && onClick(e);
  }
  // ______________________________________________________________________________

  render() {
    const {
      onKeyDown,
      label,
      children,
      hidden,
      color,
    } = this.props
    const { hover, focus } = this.state
    if (hidden) { return null }
    // ______________________________________________________________________________

    const wrap_style = {
      border: 'none',
      padding: 0,
      margin: '0px 4px',
      height: 32,
      borderRadius: 2,
      letterSpacing: 0.6,
      // fontWeight: "bold",
      // fontSize: 12,
      cursor: "pointer",
      userSelect: 'none',
      backgroundColor: color || Colors.basic, // propsでカラーが設定されていたら優先
    }
    const inner_style = {
      padding:
          '0px 12px',
      width: '100%',
      height: '100%',
      borderRadius: 'inherit',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: "0.2s",
      backgroundColor:
        hover || focus? 'rgba(255, 255, 255, 0.4)' :
          null,
    }
    const label_style = {
      color: '#fff',
    }
    // ______________________________________________________________________________

    // ______________________________________________________________________________

    return (
      <button
        style={wrap_style}
        onClick={e => this.handleOnClick(e)}
        onKeyDown={onKeyDown}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >
        <div style={inner_style}>
          <span style={label_style}>{label}</span>
          {children}
        </div>
      </button>
    )
  }
}

