import React, { Fragment } from 'react';
import * as Sky from "../../component/SmartComponent";
import * as Icons from "../../component/Icons";
import sky_logo from '../../image/FUJIFILM_logo.png';
import { getCurrentMenuId } from '../../util/commonInput'
import { Link } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';
import { zindex } from '../../component/Styles';
import { topPage as Colors } from '../../component/Colors';
import { store } from '../../index';
import { replace } from 'connected-react-router'　//Logout用

export default class TopPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: null,
      headerToggle: true,
      menuToggle: true,
    }
    this.header_style = {
      width: '100%',
      position: 'fixed',
      top: 0,
      zIndex: zindex.header,
    }
  }

  handleMenuBtnOnClick = (menuData, url) => {
    const { onClickHeadMenu } = this.props;
    const { menuCate } = menuData
    this.setState({ selectedMenu: menuCate })
    onClickHeadMenu && onClickHeadMenu(menuData);
  }
  // _________________________________________________________________________________
  // 外部利用
  toggleMenu = (status) => {
    this.setState({ menuToggle: status })
  }
  // _________________________________________________________________________________
  render() {
    const { children, setData, pathName, userId, userName } = this.props;
    const { selectedMenu, menuToggle, headerToggle, } = this.state;
    const menu_arr = [];
    const subMenu_arr = [];
    for (let i in setData) {
      const { menuCateName, menuList, menuCate } = setData[i]
      menu_arr.push(
        <MenuBtn
          key={'menuBtn' + i}
          onClick={() => this.handleMenuBtnOnClick(setData[i], '/')}
          select={selectedMenu === menuCate} // ture or falseを渡す
          label={menuCateName}
        />
      )
      if (selectedMenu === menuCate) {
        for (let j in menuList) {
          subMenu_arr.push(
            <SubMenuBtn
              key={'subMenuBtn' + j}
              icon={menuList[j].icon}
              label={menuList[j].menuName}
              title={menuList[j].title}
              menuOpen={menuToggle}
              href={menuList[j].link}
              select={menuList[j].link === pathName}
            />
          )
        }
      }
    }
    // ___________________________________________________________________________________
    // const key = getCurrentMenuId().toUpperCase();
    // console.log(store.getState()[key]);
    return (
      <Fragment>
        <div style={this.header_style}>
          <Navbar headerOpen={headerToggle}>
            <SmartTitle headerOpen={headerToggle} />
            <div style={{ display: 'flex' }}>
              <MenuSystemBtn type='facInfo' onClick={this.props.onClickFacInfo} />
              <MenuSystemBtn type='chgPawd' onClick={this.props.onClickChgPswd} />
              <SelectUserBtn headerOpen={headerToggle} userId={userId} userName={userName} />
              <LogoutBtn headerOpen={headerToggle} onClick={this.props.logoutEvent} />
              <SkyLogo headerOpen={headerToggle} />
              {/* <HeaderToggleBtn headerOpen={headerToggle} onClick={() => this.setState({ headerToggle: !headerToggle })} /> */}
            </div>
          </Navbar>
          <MenuBar open={headerToggle}>
            <Scrollbars>
              {menu_arr}
            </Scrollbars>
          </MenuBar>
        </div>
        <SideBar menuOpen={menuToggle} headerOpen={headerToggle}>
          <MenuToggleBtn label={selectedMenu} menuOpen={menuToggle} onClick={() => this.setState({ menuToggle: !menuToggle })} />
          <Scrollbars>
            {subMenu_arr}
          </Scrollbars>
        </SideBar>
        <MainContents menuOpen={menuToggle} headerOpen={headerToggle}>
          {children}
        </MainContents>
      </Fragment>
    );
  }
}
// ________________________________________________________________________________________________________________

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.wrap_style = {
      display: 'flex',
      justifyContent: 'space-between',
      background: Colors.header,
      borderBottom: '1px solid #E6E6E6',
      transition: '0.2s',
    }
  }
  // ______________________________________________________________________________________________________

  render() {
    const { children, headerOpen } = this.props;
    // 状態によって変更されるスタイルの記述
    Object.assign(this.wrap_style, {
      height:
        headerOpen ? 50 :
          40,
    })
    // _______________________________________________________________________________________________________

    return (
      <div style={Object.assign({}, this.wrap_style)}>
        {children}
      </div>
    );
  }
}
// ________________________________________________________________________________________________________________

class SmartTitle extends React.Component {
  constructor(props) {
    super(props);
    this.logo_style = {
      paddingRight: 25,
      paddingLeft: 20,
      display: 'flex',
      alignItems: 'center',
      transition: '0.2s',
      color: Colors.basic,
      // fontWeight: 'bold',
      fontFamily: "'Georgia', serif",
      textDecorationLine: 'none',
      fontSize: 20,
    }
    this.title_style = {
      letterSpacing: 0.65,
      color: Colors.basic,
      paddingTop: 4,
      alignSelf: 'center',
      transition: '0.2s',
    }
  }
  // ______________________________________________________________________________________________________

  render() {
    const { headerOpen } = this.props;
    // 状態によって変更されるスタイルの記述
    Object.assign(this.title_style, {
      fontSize:
        headerOpen ? 13 :
          11,
    })
    Object.assign(this.logo_style, {
      fontSize:
        headerOpen ? 27 :
          19,
    })
    // _______________________________________________________________________________________________________

    return (
      <div style={{ display: 'flex' }}>
        <Link style={Object.assign({}, this.logo_style)} to='/qa/qa00'>WAKOCSS</Link>
        <span style={Object.assign({}, this.title_style)}>富士フイルム和光純薬株式会社　顧客管理システム</span>
      </div>
    );
  }
}
// ________________________________________________________________________________________________________________
// 施設情報
class SelectUserBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
    this.wrap_style = {
      backgroundColor: '#fff',
      border: 'none',
      borderRight: '1px solid #E6E6E6',
      borderLeft: '1px solid #FFF',
      marginTop: 2,
      textAlign: 'center',
      letterSpacing: 0.65,
      padding: '0px 12px',
      // fontSize: 13,
      display: 'flex',
      alignItems: 'center',
      transition: '0.2s',
    }
  }
  // ______________________________________________________________________________________________________

  render() {
    const { headerOpen, userId, userName } = this.props;
    // _______________________________________________________________________________________________________

    return (
      <div style={Object.assign({}, this.wrap_style)}>
        <Icons.User1
          width={15}
          height={15}
          color='#808080'
        />
        {headerOpen && <span style={{ paddingLeft: 12 }}>{userId}｜{userName}</span>}
      </div>
    );
  }
}
// ________________________________________________________________________________________________________________
//  ログアウトボタン
class LogoutBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
    this.wrap_style = {
      border: 'none',
      borderRight: '1px solid #E6E6E6',
      borderLeft: '1px solid #FFF',
      marginTop: 2,
      textAlign: 'center',
      color: '#808080',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      transition: '0.2s',
      flexDirection: 'column',
      justifyContent: 'center',
    }
    this.label_style = {
      marginTop: 2,
      fontSize: 10,
      letterSpacing: 0.65,
      // fontWeight: 'bold',
    }
  }
  // ______________________________________________________________________________________________________

  render() {
    const { hover } = this.state;
    const { headerOpen } = this.props;
    // 状態によって変更されるスタイルの記述
    Object.assign(this.wrap_style, {
      backgroundColor:
        hover ? Colors.basic :
          '#fff',
      color:
        hover ? '#fff' :
          null,
      padding:
        headerOpen ? '0px 20px' :
          '0px 16px',
    })
    const iconColor = hover ? '#fff' : '#808080';
    // _______________________________________________________________________________________________________

    return (
      <button style={Object.assign({}, this.wrap_style)}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={(e) => { this.props.onClick && this.props.onClick(e) }}
      >
        <Icons.SignOutAlt
          width={19}
          height={19}
          color={iconColor}
        />
        {headerOpen && <span style={this.label_style}>ログアウト</span>}
      </button>
    );
  }
}
// ________________________________________________________________________________________________________________
//  SkySystemのロゴ
class SkyLogo extends React.Component {
  constructor(props) {
    super(props);
    this.wrap_style = {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff',
      marginTop: 2,
      transition: '0.2s',
    }
  }
  // ______________________________________________________________________________________________________

  render() {
    const { headerOpen } = this.props
    // 状態によって変更されるスタイルの記述
    Object.assign(this.wrap_style, {
      padding:
        headerOpen ? '0px 25px' :
          '0px 20px'
    })
    const logoW = headerOpen ? null : 96;
    const logoH = headerOpen ? null : 16;
    // _______________________________________________________________________________________________________

    return (
      <div style={Object.assign({}, this.wrap_style)}>
        <img src={sky_logo} width={logoW} height={logoH} alt='スカイシステムのロゴ' />
      </div>
    );
  }
}
// ________________________________________________________________________________________________________________
//  大分類メニューのバー
class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.wrap_style = {
      width: '100%',
      padding: '4px 8px',
      backgroundColor: '#fff',
      borderBottom: '1px solid #E6E6E6',
      transition: '0.2s',
    }
  }
  // ______________________________________________________________________________________________________

  render() {
    const { children, open } = this.props;

    Object.assign(this.wrap_style, {
      height:
        !open ? 0 :
          40,
      overflow:
        !open ? 'hidden' :
          null,
      opacity:
        !open ? 0 :
          1,
    })
    // _______________________________________________________________________________________________________

    return (
      <menu style={Object.assign({}, this.wrap_style)}>
        {children}
      </menu>
    );
  }
}
// ________________________________________________________________________________________________________________
//  大分類メニュー
class MenuBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
    this.wrap_style = {
      fontSize: 16,
      // fontWeight: 'bold',
      padding: '0px 16px',
      border: `2px solid ${Colors.basic}`,
      // borderBottom: `2px solid ${Colors.basic}`,
      height: '100%',
      cursor: ' pointer',
      transition: '0.2s',
      margin: '0px 4px',
      borderRadius: 5,
    }
  }
  // ______________________________________________________________________________________________________

  render() {
    const { hover } = this.state;
    const { label, onClick, select } = this.props;
    // 状態によって変更されるスタイルの記述
    Object.assign(this.wrap_style, {
      backgroundColor:
        select || hover ? '#FFF' :
          Colors.basic,
      color:
        select || hover ? Colors.basic :
          // Colors.selectedHeaderMenuFont,
          "#fff",
    })
    // _______________________________________________________________________________________________________

    return (
      <button
        style={Object.assign({}, this.wrap_style)}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={onClick}
      >
        {label}
      </button>
    );
  }
}
// ________________________________________________________________________________________________________________
//  サイドバー
class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
    this.wrap_style = {
      height: '100%',
      position: 'fixed',
      background: Colors.sideBar,
      display: 'flex',
      flexDirection: 'column',
      top: 0,
      transition: '0.2s',
      zIndex: zindex.sidebar,
    }
  }
  // ______________________________________________________________________________________________________

  render() {
    const { children, menuOpen, headerOpen, } = this.props;
    // 状態によって変更されるスタイルの記述
    Object.assign(this.wrap_style, {
      width:
        !menuOpen ? 50 :
          200,
      paddingTop:
        !headerOpen ? 40 :
          90,
    })
    // _______________________________________________________________________________________________________

    return (
      <div style={Object.assign({}, this.wrap_style)} >
        {children}
      </div>
    );
  }
}
// ________________________________________________________________________________________________________________
//  小分類メニュー
class SubMenuBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
    this.wrap_style = {
      // fontSize: 13,
      letterSpacing: 1,
      border: 'none',
      cursor: ' pointer',
      display: 'flex',
      padding: '4px 0px',
      alignItems: 'center',
      // fontWeight: 'bold',
      transition: '0.2s',
      position: 'relative',
      textDecoration: 'none',
      minHeight: 30,
    }
    this.icon_style = {
      width: 50,
      minWidth: 50,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }
    this.label_style = {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
    }
  }
  // ______________________________________________________________________________________________________

  componentDidMount() {
    const { label } = this.props;
    if (label) { this.label_ref.innerHTML = label; }
  }
  //_______________________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const { label } = nextProps
    if (this.props.label !== label) {
      this.label_ref.innerHTML = label
    }
    return true;
  }
  // ______________________________________________________________________________________________________

  handleOnHover = (e) => {
    e.preventDefault();
    const TOP = e.target.getBoundingClientRect().top + 20;
    this.setState({
      hover: true,
      x: 50,
      y: TOP,
    })
  }
  // ______________________________________________________________________________________________________

  render() {
    const { hover, x, y } = this.state;
    const { label, icon, onClick, select, menuOpen, href, title } = this.props;
    // 状態によって変更されるスタイルの記述
    Object.assign(this.wrap_style, {
      backgroundColor:
        select ? Colors.selectedSubMenu :
          hover ? Colors.hoveredSubMenu :
            'transparent',
      color:
        hover ? Colors.hoveredSubMenuFont :
          Colors.subMenuFont,
    })
    Object.assign(this.label_style, {
      display:
        menuOpen ? null :
          'none',
    })
    const iconColor = hover ? Colors.hoveredSubMenuFont : Colors.subMenuFont;
    // _______________________________________________________________________________________________________

    return (
      <Link
        style={Object.assign({}, this.wrap_style)}
        onFocus={(e) => this.handleOnHover(e)}
        onBlur={() => this.setState({ hover: false })}
        onMouseOver={(e) => this.handleOnHover(e)}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={onClick}
        to={href}
      >
        <div style={this.icon_style}>
          <Icon icon={icon} width={20} height={20} color={iconColor} />
        </div>
        <div style={Object.assign({}, this.label_style)} ref={(el) => this.label_ref = el} />
        <Sky.Balloon label={title} render={!menuOpen && hover} x={x} y={y} color="rgba(0, 0, 0,0.8)" />
      </Link>
    );
  }
}
// ________________________________________________________________________________________________________________
//  サイドバーの開閉ボタン
class MenuToggleBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
    this.wrap_style = {
      // fontSize: 12,
      border: 'none',
      height: 40,
      cursor: ' pointer',
      color: '#fff',
      display: 'flex',
      padding: 0,
      alignItems: 'center',
      // fontWegiht: 'bold',
      transition: '0.2s',
      outline: 'none',
    }
    this.label_style = {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      transition: '0.2s'
    }
    this.angle_style = {
      width: 50,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      transition: '0.5s'
    }
  }
  // ______________________________________________________________________________________________________

  render() {
    const { hover } = this.state;
    const { label, onClick, menuOpen } = this.props;
    // 状態によって変更されるスタイルの記述
    Object.assign(this.wrap_style, {
      backgroundColor:
        hover ? Colors.hoveredToggleBtnLabel :
          Colors.toggleBtnLabel,
    })
    Object.assign(this.label_style, {
      width:
        menuOpen ? '100%' :
          0,
      paddingLeft:
        menuOpen ? 15 :
          0,
      opacity:
        menuOpen ? 1 :
          0,
      visibility:
        menuOpen ? null :
          'hidden',
      lineHeight:
        menuOpen ? null :
          0,
    })
    Object.assign(this.angle_style, {
      transform:
        menuOpen ? null :
          'rotateY(-180deg)',
    })
    // _______________________________________________________________________________________________________

    return (
      <button
        style={Object.assign({}, this.wrap_style)}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={onClick}
      >
        <div style={Object.assign({}, this.label_style)}>
          {/* {label} */}
        </div>
        <Icons.AngleDoubleLeft
          style={this.angle_style}
          width={20}
          height={20}
          color={"#fff"}
        />
      </button>
    );
  }
}
// ________________________________________________________________________________________________________________
//  メインのコンテンツが入るスペース
class MainContents extends React.Component {
  constructor(props) {
    super(props);
    this.wrap_style = {
      transition: '0.2s',
      minWidth: 1200,
    }
  }
  // ______________________________________________________________________________________________________

  render() {
    const { children, menuOpen, headerOpen } = this.props;
    // 状態によって変更されるスタイルの記述
    Object.assign(this.wrap_style, {
      paddingLeft:
        !menuOpen ? 50 :
          200,
      paddingTop:
        headerOpen ? 90 :
          40,
    })
    // _______________________________________________________________________________________________________

    return (
      <div style={Object.assign({}, this.wrap_style)}>
        {children}
      </div>
    );
  }
}
// ________________________________________________________________________________________________________________
// サイドメニューのアイコン
class Icon extends React.Component {
  render() {
    const { icon } = this.props;
    switch (icon) {
      case 'Barcode': { return <Icons.Barcode {...this.props} /> }
      case 'Calendar': { return <Icons.Calendar {...this.props} /> }
      case 'ChartBar': { return <Icons.ChartBar {...this.props} /> }
      case 'ChartPie': { return <Icons.ChartPie {...this.props} /> }
      case 'Check': { return <Icons.Check1 {...this.props} /> }
      case 'ClipboardCheck': { return <Icons.ClipboardCheck {...this.props} /> }
      case 'ClipboardList': { return <Icons.ClipboardList {...this.props} /> }
      case 'Clock': { return <Icons.Clock1 {...this.props} /> }
      case 'Cog': { return <Icons.Cog {...this.props} /> }
      case 'Database': { return <Icons.Database {...this.props} /> }
      case 'Edit': { return <Icons.Edit1 {...this.props} /> }
      case 'FileDownload': { return <Icons.FileDownload {...this.props} /> }
      case 'Globe': { return <Icons.Globe {...this.props} /> }
      case 'List': { return <Icons.List {...this.props} /> }
      case 'PdfFile': { return <Icons.PdfFile {...this.props} /> }
      case 'Receipt': { return <Icons.Receipt {...this.props} /> }
      case 'Search': { return <Icons.Search1 {...this.props} /> }
      case 'SearchBoard': { return <Icons.SearchBoard {...this.props} /> }
      case 'SyncAlt': { return <Icons.SyncAlt {...this.props} /> }
      case 'Table': { return <Icons.Table1 {...this.props} /> }
      default: return null;
    }

  }
}
////////////////////////////////////////////////////////////////////////////////////////
//　メニュー上部のボタン
class MenuSystemBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
    this.wrap_style = {
      border: 'none',
      borderLeft: '1px solid rgba(0,0,0, 0.16)',
      textAlign: 'center',
      letterSpacing: 0.65,
      fontSize: '1.2rem',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      cursor: 'pointer',
      transition: '0.2s',
      textDecoration: 'none',
    }
    this.top_style = {
      marginTop: 4,
      fontSize: '10px',
    }
  }
  // ______________________________________________________________________________________________________

  render() {
    const { hover } = this.state;
    const { headerOpen, type } = this.props;

    // 状態によって変更されるスタイルの記述
    Object.assign(this.wrap_style, {
      backgroundColor:
        hover ? "#FFF" :
          " rgb(232, 118, 148)",
      color:
        hover ? " rgb(232, 118, 148)" :
          '#fff',
      padding:
        headerOpen ? '0px 20px' :
          '0px 16px',

    })
    const iconColor = hover ? ' rgb(232, 118, 148)' : '#fff';
    let IconComponent = null
    switch (type) {
      // 施設情報ボタンの表示
      case 'facInfo': {
        IconComponent = <>
          <Icons.BldgCircleExcl
            width={25}
            height={25}
            color={iconColor}
          />
          <span style={this.top_style}>施設情報</span>
        </>
        break;
      }
      // パス変更ボタンの表示
      case 'chgPawd': {
        IconComponent = <>
          <Icons.Locked
            width={24}
            height={24}
            color={iconColor}
          />
          <span style={this.top_style}>パス変更</span>
        </>
        break;
      }
      default:
        break;
    }
    // _______________________________________________________________________________________________________

    return (
      <button
        style={Object.assign({}, this.wrap_style)}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={this.props.onClick}
      >
        {IconComponent}
      </button>
    );
  }
}
// ______________________________________________________________________________________________________
