import React, { Fragment } from 'react';
import { wideSelect as Colors } from './Colors'

export default class WideSelect extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      value: value || '',    // valueの状態
      selectValue: value || '',    // 選択されているアイテムのvalue
    }

    this.wrap_style = {
      height: '100%',
      width: 'auto',
      maxWidth: 300,
      display: 'flex',
      flexDirection: 'column',
    }
    this.listWrap_style = {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      background: '#FFF',
      border: '1px solid #ccc'
    }
  }
  // ________________________________________________________________________________________________________________
  shouldComponentUpdate(nextProps) {
    const { value, setData } = this.props;
    if (value !== nextProps.value) {
      this.setState({
        value: nextProps.value || '',
        selectValue: nextProps.value || '',
      })
    }
    if (setData !== nextProps.setData) {
      this.setState({ setData: nextProps.setData || [] })
    }
    return true;
  }
  //______________________________________________________________________________________________________
  handleOnChange = (e) => {
    this.setState({ value: e.target.value })
  }
  // ________________________________________________________________________________________________________________
  handleOnBlur = () => {
    const { selectValue } = this.state;
    this.setState({ value: selectValue })
  }
  // ________________________________________________________________________________________________________________
  handleOnClick = (e) => {
    const { onClick, onChange, name, } = this.props
    const { selectValue } = this.state;
    // 値に変更があれば親に返す
    if (selectValue !== e.target.value) {
      onClick && onClick(name, e.target.value)
      onChange && onChange(name, e.target.value)
      this.setState({ selectValue: e.target.value })

    }
  }
  // ________________________________________________________________________________________________________________
  handleOnKeyDown = (e) => {
    // Enterキーが押された場合のみ処理
    if (e.keyCode === 13) {
      this.handleOnClick(e);
    }
  }
  // ________________________________________________________________________________________________________________
  render() {
    // state と props の省略宣言
    const { value } = this.state
    const { setData, disabled, title, children } = this.props

    // リスト一覧を作成
    const option_arr = []
    for (let i in setData) {
      const { value, label } = setData[i]
      option_arr.push(
        <option key={'option' + i} value={value}>
          {label}
        </option>
      )
    }
    // console.log(setData, option_arr);
    return (
      <div style={this.wrap_style}>
        <h4>{title}</h4>
        {children}
        <select
          size="10"
          style={this.listWrap_style}
          value={value}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          onClick={this.handleOnClick}
          onKeyDown={this.handleOnKeyDown}
          disabled={disabled}
        >
          {option_arr}
        </select>
      </div>
    )
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////