import React from 'react';
import ReactDOM from 'react-dom';
import { zindex } from './Styles';

const body = document.getElementById("body")
const message = (...arr) => {
  let msg = "";
  if (!arr || arr.length == 0) {
    msg = "alert";
  } else if (typeof arr[0] === 'object') {
    for (let item in arr[0]) {
      msg += item + ' : ' + arr[0][item] + '\n';
    }
  } else {
    msg = arr[0]
  }


  body.style['overflow'] = 'hidden';
  const wrapper = document.body.appendChild(document.createElement('div')); // div要素を作成するための変数を宣言
  wrapper.id = 'sky-msg'  // そのdiv要素のidに'msg'を設定

  const cleanup = () => {
    ReactDOM.unmountComponentAtNode(wrapper); // 作ったreactDomを削除
    const node = document.getElementById('sky-msg'); // 作ったdiv要素のid('confirm')を取得
    node.parentNode.removeChild(node); // そのconfirmの親に戻って子供を参照して'confirm'を削除（IE対応）
    body.style['overflow'] = null;
  };

  const promise = new Promise((resolve, reject) => {
    try {
      ReactDOM.render(
        <MessageModal
          cleanup={cleanup}
          resolve={resolve}
          message={msg}
        />,
        wrapper
      );
    } catch (e) {
      cleanup();
      reject(e);
      throw e;
    }
  });
  return promise;
};

const wrap = {
  position: "fixed",
  display: 'flex',
  zIndex: zindex.confirm,
  width: '100vw',
  height: '100vh',
  left: '0px',
  top: '0px',
  right: '0px',
  bottom: '0px',
  backgroundColor: "rgba(255,255,255,0.3)",
}
const modal = {
  position: "relative",
  minWidth: 500,
  maxWidth: '65%',
  minHeight: 150,
  margin: 'auto',
  backgroundColor: 'white',
  borderRadius: 5,
  border: "1px solid transparent",
  boxShadow: "8px 6px 32px 5px #DCDCE2",
}

const message_style = {
  height: "77%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 16,
  padding: "16px 16px",
  fontWeight: "bold",
  overflow: 'hidden'
}

const message_content = {
  minHeight: 22,
  maxHeight: '50vh',
  overflowY: 'auto',
  width: '100%',
  textAlign: 'center',
  whiteSpace: 'pre-line',
  padding: '5px 5px'
}
const btn_area = {
  height: '15%',
  display: "flex",
  justifyContent: "center",
  width: "100%",
  bottom: 0,
  marginBottom: 16,
}
const result_btn = {
  height: 30,
  width: 100,
  fontSize: 14,
  borderRadius: 5,
  backgroundColor: 'rgb(34, 20, 180)',
  color: "#fff",
  fontWeight: "bold",
  border: "none",
  cursor: "pointer",
  transition: " all 0.3s ease 0s",
  userSelect: 'none',
  outline: 'none',
}

const header = {
  height: '8%',
  padding: "16px 16px",
  fontWeight: "bold",
  fontSize: 13,
}
class MessageModal extends React.Component {

  componentDidMount() {
    this.applyRef.focus();
  }
  // ____________________________________________________________________________________________

  cancel = () => {
    this.props.resolve(false);
    this.props.cleanup();
  };
  // ____________________________________________________________________________________________

  apply = () => {
    this.props.resolve(true);
    this.props.cleanup();
  };
  // ____________________________________________________________________________________________

  handleOnKeyPress = (e) => {
    if (e.keyCode === 9 || e.keyCode === 37 || e.keyCode === 39) {
      e.preventDefault();
    }
  }

  btnHover = (action, e) => {
    const style = e.target.style
    switch (action) {
      case "in":
        style.boxShadow = "5px 5px 10px 5px #DCDCE2";
        break
      case "out":
        style.boxShadow = null;
        break
      default: break;
    }
  }

  render() {

    // ____________________________________________________________________________________________

    return (
      <div style={wrap}>
        <div style={modal}>
          <div style={header}>
            System Message
          </div>
          <div style={message_style}>
            <div style={message_content}>
              {this.props.message}
            </div>
          </div>

          <div style={btn_area}>
            <button
              style={result_btn}
              ref={(el) => this.applyRef = el}
              onClick={this.apply}
              onMouseOver={(e) => this.btnHover("in", e)}
              onFocus={(e) => this.btnHover("in", e)}
              onMouseLeave={(e) => this.btnHover("out", e)}
              onBlur={(e) => this.btnHover("out", e)}
              onKeyDown={(e) => this.handleOnKeyPress(e)}
            >OK</button>
          </div>

        </div>
      </div>
    );
  }
}

export default message;


