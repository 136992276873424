import React, { Fragment } from 'react';
import * as SKY from "component/SmartComponent";
import { axiosPost, server } from 'comRedux/axiosPost';

export default class PswdInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableOpen: false,
      loading: true,
      msgType: '',
      // 項目名
      loginID: '',
      password: '',
      newPassword: '',
      newPasswordChk: '',
      lastUpDate: '',
      pswdLimitDate: '',
      formErrorMsg: '',
    };
  }
  // ______________________________________________________________________________________________________________________
  // 画面ロード時
  async componentDidMount() {
    let json = {};
    axiosPost.post(server + '/PSWD/search', json)
      .then((res) => {
        console.log(res)
        if (res.data != null) {
          // 状態を一括更新
          this.setState(res.data);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ loading: false });
        // セッションエラー
        console.log(err);
      });
  }
  // _______________________________________________________________________________________
  // 登録ボタンをクリックした時
  clickSubmit = () => {
    const { password, newPassword, newPasswordChk } = this.state;
    // 必須項目の入力チェック
    if (!password || !newPassword || !newPasswordChk) {
      this.setState({
        formErrorMsg: '必須項目を入力してください',
        msgType: 'error',
      })
      return
    } else {
      this.setState({
        loading: true, // ローディング開始
        formErrorMsg: '' // エラーのクリア
      })
    }

    let json = this.state;
    axiosPost.post(server + '/PSWD/complete', json)
      .then((res) => {
        console.log(res)
        if (res.data != null) {
          // エラーの有無
          if (res.data.hasError === true) {
            // 状態を一括更新
            this.setState(res.data);
            this.setState({ msgType: 'error' });
          } else {
            // 状態を一括更新
            this.setState(res.data);
            this.setState({
              formErrorMsg: 'パスワードが更新されました',
              msgType: 'success',
            });
          }
        }
        this.setState({ loading: false }); // ローディング終了
      })
      .catch(err => {
        this.setState({ loading: false });
        // セッションエラー
        console.log(err);
      });
  }
  // _______________________________________________________________________________________
  // テキストボックスの内容が変更された時にstateを更新
  handleOnChangeValue = (name, value) => {
    this.setState({ [name]: value });
  };
  // ______________________________________________________________________________________________________________________

  render() {
    const { loading, formErrorMsg, msgType } = this.state;
    const { onClickBack } = this.props;
    return (
      <Fragment>
        {loading && <SKY.Loading popup />}
        <SKY.ModalBody label='パスワード変更'>
          <SKY.MessageBar label={formErrorMsg} type={msgType} />
          <SKY.HideableTitle popup col={24}>
            <SKY.SystemBtn type='submit' label="登録" onClick={this.clickSubmit} />
            <SKY.SystemBtn type='back' label="戻る" onClick={onClickBack} marginLeft={8} />
          </SKY.HideableTitle>
          <SKY.HideableArea>

            <SKY.TextWrapper label="ログインID" colL={8} colR={16} >
              <SKY.TextLabel name="userCode" col='auto' value={this.state.loginID} />
            </SKY.TextWrapper>
            <SKY.TextWrapper label="現在のパスワード" colL={8} colR={16} >
              <SKY.Text type='alphanum' name="password" col={10} value={this.state.password} must onChange={this.handleOnChangeValue} maxLength={15} />
            </SKY.TextWrapper>
            <SKY.TextWrapper label="新しいパスワード" colL={8} colR={16} >
              <SKY.Text type='alphanum' name="newPassword" col={10} value={this.state.newPassword} must onChange={this.handleOnChangeValue} maxLength={15} />
            </SKY.TextWrapper>
            <SKY.TextWrapper label="新しいパスワード（確認）" colL={8} colR={16} >
              <SKY.Text type='alphanum' name="newPasswordChk" col={10} value={this.state.newPasswordChk} must onChange={this.handleOnChangeValue} maxLength={15} />
            </SKY.TextWrapper>
            <SKY.TextWrapper label="パスワードの最終変更日" colL={8} colR={16} >
              <SKY.TextLabel name="passwordUpDate" col='auto' value={this.state.lastUpDate} />
            </SKY.TextWrapper>
            <SKY.TextWrapper label="パスワードの有効期限" colL={8} colR={16} >
              <SKY.TextLabel name="pswdLimitDate" col='auto' value={this.state.pswdLimitDate} />
            </SKY.TextWrapper>

          </SKY.HideableArea>
        </SKY.ModalBody>
      </Fragment>
    );
  }
}
