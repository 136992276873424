import React, { Fragment } from 'react';
import BasicWrap from './BasicWrap';
import CalendarYMItem from './CalendarYMItem';
import { convertDate } from '../util/commonInput';
import { calendar as Colors } from './Colors'
import * as Icons from "./Icons";

export default class CalendarYM extends React.Component {
  constructor(props) {
    super(props);
    const {
      value,
      disabled,
    } = this.props;
    this.state = {
      value: value || '',
      showCalendar: false,
      // CalendarItemのClickイベントはCalendarのOnBlurを呼び出すので、外層のOnchangeを二回発火するとこを制御するため
      //　isPopUpClickで状態管理する
      isPopUpClick: false,
    };
    this.wrap_style = {            // <div>：テキストのwrap
      display: "inline-flex",
      flexDirection: "column",
    }
    this.input_style = {
      fontSize: 'inherit',
      height: '100%',
      width: '100%',
      paddingLeft: 4,
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      paddingRight: 20,
      pointerEvents:
        disabled === true ? "none" :
          "auto",
    }
    this.icon_style = {
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 4,
    }
  }
  // _____________________________________________________________________
  shouldComponentUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      const fixedValue = nextProps.value ? nextProps.value : ""; // null や undifinedを''に修正
      this.setState({ value: fixedValue, isPopUpClick: false });
    }
    return true
  }
  // ______________________________________________________________________
  handleOnChange = (e) => {
    const replaceValue = e.target.value.replace(/[^\d|/]/g, '');  // "0~9"と"/"以外を取り除きます。
    this.setState({ value: replaceValue })
  }
  // _______________________________________________________________________________________________________

  triggerPropsChange = (date) => {
    const { onChange, name } = this.props;
    if (this.popUp && this.popUp.disposePopUp) this.popUp.disposePopUp();
    onChange && onChange(name, date); // redux対応
  }

  // 日付を選択したとき
  handleOnSelectDate = (date) => {
    this.setState({
      value: date,
      showCalendar: false,
    })
    this.triggerPropsChange(date)
  }
  // _______________________________________________________________________________________________________
  handleOnKeyDown = (e) => {
    if (e.keyCode === 9) {
      this.setState({ showCalendar: false, isPopUpClick: false })
    } else if (e.keyCode === 13) {
      this.setState({ showCalendar: true, })
    } else {
      this.setState({ isPopUpClick: false })
    }
  }
  // _______________________________________________________________________________________________________
  updateIsPopUpClick = value => {
    this.setState({ isPopUpClick: value })
  }

  // _______________________________________________________________________________________________________
  handleOnBlur = (e) => {
    const { onChange, name } = this.props
    const inputValue = e.target.value.replace(/[^\d|/]/g, '');  // "0~9"と"/"以外を取り除きます。
    const thisYear = new Date().getFullYear();　// 今年
    let replaceValue = "";
    let MM = null;
    let YYYY = null;

    switch (inputValue.length) {                     //  入力した値の桁数に対して分岐しています
      case 1: case 2: // 1桁 または ２桁 の場合
        if (inputValue >= 1 && inputValue <= 12) {    // ０ではなく、今月の最終日より値が下だったら
          MM = ("0" + inputValue).slice(-2);
          replaceValue = thisYear + "/" + MM
        }
        break;
      case 6: {
        MM = inputValue[4] + inputValue[5];
        YYYY = inputValue[0] + inputValue[1] + inputValue[2] + inputValue[3]    // ５桁目～８桁目
        if (MM >= 1 && MM <= 12) {
          replaceValue = YYYY + "/" + MM
        }
        break
      }
      case 7: {
        if (inputValue[4] === "/") {
          replaceValue = inputValue
        }
        break;
      }
      default: { break; }
    }
    if (MM === "00") { replaceValue = "" }

    this.setState({ value: replaceValue});
    if (this.state.isPopUpClick !== true) this.triggerPropsChange(replaceValue);
  }
  // _________________________________________________________________________________________________________________
  render() {
    const { disabled, style, autoFocus, } = this.props
    const { value, showCalendar, } = this.state
    // _______________________________________________________________________________________________________
    return (
      <BasicWrap {...this.props} style={this.wrap_style} value={value}>
        <input
          autoFocus={autoFocus}
          type="tel"
          ref={(el) => this.input_ref = el}
          style={Object.assign({}, this.input_style, style)}
          value={value}
          disabled={disabled}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          onKeyDown={this.handleOnKeyDown}
          onClick={() => this.setState({ showCalendar: true })}
        />
        <Icons.Calendar style={this.icon_style} width={14} height={14} color={Colors.icon} hidden={disabled} onClick={() => this.setState({ showCalendar: true })} />
        <CalendarYMItem
          onRef={e => this.popUp = e}
          render={showCalendar}
          target={this.input_ref}
          unmount={() => this.setState({ showCalendar: false })}
          value={value}
          onChange={this.handleOnSelectDate}
          updateIsPopUpClick={this.updateIsPopUpClick}
        />
      </BasicWrap>
    )
  }
}
