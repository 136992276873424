import React from 'react';
import Icon from './Icon';

export default class Receipt extends React.Component {
  render() {
    return (
      <Icon
        {...this.props}
        viewBox="0 0 512 512"
        d="M426.489,383.022V8.311c0-2.849-1.44-5.462-3.856-7c-1.348-0.847-2.886-1.301-4.45-1.301
            c-1.214,0-2.39,0.258-3.495,0.774l-72.893,34.073L280.434,6.184l-61.309,28.673l-61.33-28.673L96.486,34.857L23.638,0.774
            C22.528,0.258,21.352,0,20.132,0c-1.574,0-3.112,0.455-4.47,1.311c-2.39,1.538-3.82,4.151-3.82,6.98l0.01,412.634
            c0.01,23.726,8.529,46.018,23.974,62.754c13.428,14.569,31.15,24.222,50.076,27.33v0.815l9.448,0.073l1.415,0.061
            c0.738,0,1.378-0.031,2.922-0.113H417.75c1.146,0.072,2.441,0.154,3.928,0.154c11.053,0,22.184-3.087,32.194-8.92
            c14.099-8.219,25.832-21.486,33.938-38.357c8.193-17.068,12.349-36.705,12.349-58.357v-23.345H426.489z M138.466,406.366
            c0.01,20.712-5.142,39.194-14.517,52.048c-3.928,5.4-8.585,9.726-13.474,12.524c-4.481,2.581-9.096,3.881-13.711,3.881
            c-0.893,0-1.797-0.03-2.757-0.165c-11.378-1.27-22.622-7.145-30.851-16.106c-9.251-10.108-14.156-23.19-14.176-37.841V53.617
            l47.504,22.218l61.309-28.661l61.33,28.661l61.309-28.661l61.361,28.661l47.566-22.218l-0.011,338.966H138.466V406.366z
            M448.594,458.393c-3.897,5.421-8.522,9.768-13.37,12.587c-4.45,2.57-9.009,3.872-13.547,3.881c-0.738,0-1.481-0.041-2.973-0.154
            H146.158c3.149-4.296,5.963-8.993,8.394-14.011c5.792-11.956,9.432-25.585,10.826-40.536h296.835
            C459.994,439.178,453.639,451.414,448.594,458.393z"
      />
    )
  }
}