// 入力用テーブルに使用しています。変更する際は気を付けてください。

import React, { Fragment } from 'react';
import { convertDate } from '../util/commonInput';
import * as Icons from "./Icons";
import { calendar as Colos } from './Colors'
import { zindex } from './Styles';
import ReactDOM from 'react-dom';

export default class CalendarItem extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = null;
    this.box_style = {};
    this.idName = Math.random().toString(36).substring(7); // idを乱数生成
  }
  //______________________________________________________________________
  handleOnMouseDown = () => {
    this.filterClick = true;
  }
  //______________________________________________________________________
  closeCalendar = () => {
    if (this.filterClick) {
      this.filterClick = false;
    } else {
      const { unmount } = this.props;
      unmount && unmount()
    }
  }
  //______________________________________________________________________
  shouldComponentUpdate(nextProps) {
    if (nextProps.render) {
      if (!document.getElementById(this.idName)) { // DOMが表示されていなければ
        window.addEventListener('mouseup', this.closeCalendar)    // windowイベントcloseCalendar関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
        this.wrapper = document.body.appendChild(document.createElement('div')); // div要素を作成するための変数を宣言
        this.wrapper.id = this.idName  // そのdiv要素のidに'modal'を設定

        const { target } = nextProps;
        const targetPost = target.getBoundingClientRect()

        let itemPost_left = null;
        let itemPost_right = null;
        let itemPost_top = null;
        let itemPost_bottom = null;

        if(targetPost.left + 250 > window.innerWidth){
          itemPost_right = window.innerWidth - targetPost.right - window.pageXOffset -15;
        }else{
          itemPost_left = targetPost.left + window.pageXOffset;
        }
        if(targetPost.top + 250 > window.innerHeight){
          itemPost_bottom = window.innerHeight - targetPost.top - window.pageYOffset;
        }else{
          itemPost_top = targetPost.bottom + window.pageYOffset;
        }
        this.box_style = {
          position: "absolute",
          top: itemPost_top,
          left: itemPost_left,
          bottom: itemPost_bottom,
          right: itemPost_right,
          zIndex: zindex.calendar,
        }
      }
      const { onChange, value } = nextProps;
      //______________________________________________________________________
      // レンダー
      ReactDOM.render(
        <div style={this.box_style} onMouseDown={this.handleOnMouseDown}>
          <CalendarComponent onChange={onChange} value={value} onClickClose={this.closeCalendar} />
        </div>
        , this.wrapper
      );
      //______________________________________________________________________
    } else {
      const filterBox = document.getElementById(this.idName); // 作ったid('modal')のdiv要素を取得
      if (filterBox) {
        ReactDOM.unmountComponentAtNode(filterBox);       // 作ったreactDomを削除
        filterBox.parentNode.removeChild(filterBox);            // 一度親に戻って子供を参照して作ったdiv要素を削除（IE対応）
        window.removeEventListener('mouseup', this.closeCalendar)    // windowイベントcloseCalendar関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
      }
    }
    return true;
  }
  render() {
    return null;
  }
}
// ________________________________________________________________________________________________________________

class CalendarComponent extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    let newDate = new Date()
    if (value && new Date(value) !== 'Invalid Date') {
      newDate = new Date(value)
    }
    this.state = {
      date: newDate.getDate(),         // 今日の日付 
      month: newDate.getMonth() + 1,   // 表示中の月
      year: newDate.getFullYear(),     // 表示中の年
      breakPoint: 6,                      // 行の最後にくる曜日。（折り返し地点）
      selectedDate: null,                 // 選択されている日付け
      cursorDate: null,                   // キーダウンでカーソル中の日付
      value: value || "",      // 最終的に送り出す値　YYYY/MM/DD 形式
    };
    this.today = convertDate({ date: new Date() }) // 今日の日付を定義

    this.wrap_style = {                   // <div>：カレンダーの親
      display: "inline-flex",
      flexDirection: "column",
      zIndex: 999,
      overflow: 'visible',
      backgroundColor: "#fff",
      boxShadow: '0px 2px 5px rgba(0,0,0,0.5)',
      border: '1px solid #D9D9D9',
      borderRadius: 10,
    }

    this.header_style = {                  // <div>：カレンダーのヘッダー
      display: "flex",
      alignItems: "center",
      borderRadius: "10px 10px 0px 0px",
      backgroundColor: '#F2F2F2',
      justifyContent: 'space-around',
      // paddingRight: 16,
    }
    this.changeMonth_style = {                       // <button>：月を切り替えるボタン
      padding: 4,
      cursor: "pointer",
    }
    this.title_style = {                // <button>：カレンダーのタイトル（年月）
      padding: 8,
      backgroundColor: "transparent",
      border: "none",
      fontSize: 12,
      whiteSpace: "nowrap",
      color: '#838383',
      fontWeight: 'bold',
    }
    this.c_style = {
      color: '#FFFFFF',
      borderRadius: 5,
      backgroundColor: Colos.items,
      border: 'none',
      cursor: "pointer",
      fontWeight: 'bold',
      fontSize: 11,
      height: 20,
      width: 20,
    }
    this.weekWrap_style = {                      // <div>：カレンダーの曜日が並ぶ列
      display: "flex",
      alignItems: "center",
      backgroundColor: '#F2F2F2',
      borderBottom: '1px solid #D9D9D9',
      paddingLeft: 8,
    }
    this.datesWrap_style = {
      padding: '8px 0px 8px 8px'
    }
  }
  // _______________________________________________________________________________________________________________________________
  componentDidMount() {
    window.addEventListener('keydown', this.moveTheCursor)
  }
  // _______________________________________________________________________________________________________________________________
  componentWillUnmount() {
    window.removeEventListener('keydown', this.moveTheCursor)    // windowイベントmoveTheCursor関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
  }
  // _______________________________________________________________________________________________________________________________
  shouldComponentUpdate(nextProps) {
    const { value } = this.props;
    if (value !== nextProps.value) {
      this.setState({ value: nextProps.value })
    }
    return true;
  }
  // 表示している月を前月へ変える
  toPreMonth = () => {
    const { year, month } = this.state
    if (month > 1) {                               // 表示月が１より大きい場合
      this.setState({ month: month - 1 })             // 表示月を－１します
    }
    else {                                           // それ以外なら（1月なら）
      this.setState({ year: year - 1 })               // 表示年を－１して
      this.setState({ month: 12 })                    // 表示月を１２月にします
    }
  }
  // _____________________________________________________________________________________________________________
  // 表示している月を次月へ変える
  toNextMonth = () => {
    const { year, month } = this.state
    if (month < 12) {                              // 表示月が１２より小さい場合
      this.setState({ month: month + 1 })             // 表示月を＋１します
    }
    else {                                          // それ以外なら(12月なら)
      this.setState({ year: year + 1 })               // 表示年を＋１して
      this.setState({ month: 1 })                    // 表示月を１月にします                      
    }
  }
  // _____________________________________________________________________________________________________________
  // 日付けカーソルを動かす処理 onKeydown
  moveTheCursor = (e) => {
    const { onChange } = this.props;
    const { year, month, cursorDate, value, } = this.state // stateの省略宣言

    let nowDate = null;                                           // 現在カーソルされている日付け
    const lastDate = new Date(year, month, 0).getDate();             // 月の最終日を取得（月ページング処理のため）

    if (cursorDate) { nowDate = new Date(cursorDate).getDate(); }     // 既にカーソルの当たっている日付けがあれば、その日を取得 
    else if (value) { nowDate = new Date(value).getDate(); }    // 日付けが選択されていれば、その日を取得
    else if (this.today) { nowDate = new Date(this.today).getDate(); }    // 今日の日付の年、月抜きで取得
    switch (e.keyCode) {
      case 37: {  // 左
        if (nowDate <= 1) { this.toPreMonth() }
        nowDate -= 1;
        break;
      }
      case 38: {　// 上
        if (nowDate - 7 < 1) { this.toPreMonth() }
        nowDate -= 7;
        break;
      }
      case 39: {　// 右
        if (nowDate >= lastDate) { this.toNextMonth() }
        nowDate += 1;
        break;
      }
      case 40: {　// 下
        if (nowDate + 7 > lastDate) { this.toNextMonth() }
        nowDate += 7;
        break;
      }
      case 13: case 32: { // Enterキー, スペースキー 
        if (!cursorDate) { return }     // カーソルされている日付がなければreturn
        e.preventDefault()
        const getDate = convertDate({ y: year, m: month - 1, d: nowDate }); // キーダウン後の日付けを保持する
        this.setState({ value: getDate })                  // カーソルされている日付けがあれば、それを選択する。
        onChange && onChange(getDate); // redux対応
        return;
      }
      default: { break; }
    }
    const nextDate = convertDate({ y: year, m: month - 1, d: nowDate }); // キーダウン後の日付けを保持する
    this.setState({ cursorDate: nextDate });// cursorDateにセットすることでビューに反映させる
  }
  // ________________________________________________________________________________________________________________
  // 曜日ボタンを押した時
  sortDate = (e) => {
    if (e.target.value <= 0) {                                                  // もし曜日ナンバーが０以下（日曜日）だったら
      this.setState({ breakPoint: 6 })                                       // 曜日ナンバー６（土曜日）をブレイクポイント（週末）にセット
    } else {                                                                    // それ以外なら
      this.setState({ breakPoint: Number(e.target.value) - 1 })               // 順当に前の日付をブレイクポイントにセット
    }
  }
  // ________________________________________________________________________________________________________________
  // 日付け<button>をクリックしたとき
  onClickDate = (val) => {
    const { onChange } = this.props;
    this.setState({ value: val });              // <button>のvalueに設定された日付けを取得（ただし、現時点ではstring型）    
    onChange && onChange(val); // redux対応
  }
  // ________________________________________________________________________________________________________________
  resetValue = () => {
    const { onChange } = this.props;
    this.setState({ value: '' });              // <button>のvalueに設定された日付けを取得（ただし、現時点ではstring型）    
    onChange && onChange(''); // redux対応
  }
  // ________________________________________________________________________________________________________________
  render() {
    const { lang, onClickClose } = this.props
    const {
      year,
      breakPoint,     // 週の最後に来る曜日を指定する変数【０～６ : 日～土】
      month,
    } = this.state
    // _________________________________________________________________________________________________________________
    // マルチ言語化（曜日、年月タイトル）

    let daysLang = [];                         // 各言語ごとに曜日を入れる配列
    let title = null;                           // 年月タイトルを表示するための変数
    let monthsLang = [];

    switch (lang) {

      case "en":
        monthsLang = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "June", "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."]
        daysLang = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        title = monthsLang[month - 1] + year;                // 例：Aug.2019
        break;

      case "ja":
      default:
        monthsLang = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"];
        daysLang = ["日", "月", "火", "水", "木", "金", "土"];
        title = year + "年" + monthsLang[month - 1];    // 例：2019年8月
        break;
    }
    // ________________________________________________________________________________________________________________

    // 曜日を作成

    let dayArray = [];             // 曜日と曜日No【０～６ : 日～土】と合わせるための配列
    let dayNameCount = null;           // 曜日と曜日No【０～６ : 日～土】と合わせて配列に入れていくためのカウント変数

    if (breakPoint === 6) {
      dayNameCount = 0;
    } else {
      dayNameCount = breakPoint + 1;
    }

    for (let i = 0; i <= 6; i++) {
      dayArray.push(
        <DateBtn
          dayOfweek
          key={dayNameCount}
          value={dayNameCount}
          onClick={this.sortDate}
          label={daysLang[dayNameCount]}
        />
      )

      if (dayNameCount === 6) {
        dayNameCount = 0;
      } else {
        dayNameCount++;
      }
    }
    // ________________________________________________________________________________________________________________
    return (
      <div style={this.wrap_style}>
        <div style={this.header_style}>
          <Icons.AngleDoubleLeft style={this.changeMonth_style} onClick={this.toPreMonth} width={14} height={14} color={Colos.items} />
          <div style={this.title_style}>
            {title}    {/* 年月タイトル */}
          </div>
          <Icons.AngleDoubleRight style={this.changeMonth_style} onClick={this.toNextMonth} width={14} height={14} color={Colos.items} />
          <button style={this.c_style} onClick={this.resetValue}>C</button>
          <Icons.TimesCircle onClick={onClickClose} width={20} height={20} color={Colos.items} />
        </div>
        <div style={this.weekWrap_style}>
          {dayArray}　      {/* 曜日 */}
        </div>
        <div style={this.datesWrap_style}>
          <Dates {...this.state} today={this.today} onClick={this.onClickDate} />
        </div>
      </div>
    )
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
class Dates extends React.Component {
  render() {
    const {
      value,
      year,
      breakPoint,     // 週の最後に来る曜日を指定する変数【０～６ : 日～土】
      month,
      cursorDate,
      today,
      onClick,
    } = this.props
    const dateArray = [];                             // 日付を入れていくための配列

    // 　先月の残りの日付けを作成
    const firstDayNo = new Date(year, month - 1, 1).getDay();         // 当月の最初の曜日番号　※getDay()で取得できるのは、0~6の番号のため
    let startDayCount = null;                                       // カレンダーの先頭に来る前月の日付けを作成するために使用するカウント変数

    if (breakPoint === 6) { startDayCount = 0 }                      // breakpointの次の曜日から週が始まるので、＋１をしています。
    else { startDayCount = breakPoint + 1 }                      // ただしbreakpointが６（土曜日）の場合、７がないので０（日曜日）に戻します。

    for (let i = 0; i < 6; i++) {

      if (startDayCount === firstDayNo) { break }                    // もしカウントが当月の1日の曜日に達したら日付作成完了です。
      else if (startDayCount === 6) { startDayCount = 0 }        // また、もしカウントが6の場合、次は0になります。
      else { startDayCount++ }        // 当月の1日に達するまでカウントしていきます

      let createdDate = null
      const DD = new Date(year, month - 1, -i).getDate()                 // forループに基づいて作成される前月の日付け
      if (month === 1) {  // 1月の場合 
        createdDate = convertDate({ y: year - 1, m: 11, d: DD })
      } else {
        createdDate = convertDate({ y: year, m: month - 2, d: DD })
      }
      dateArray.unshift(                                             // unshift()で<button>日付けを配列の手前に順に追加していきます。
        <DateBtn
          notThisMonth
          key={'preMonth' + createdDate}
          selected={createdDate === value}
          today={createdDate === today}
          onClick={() => onClick(createdDate)}
          label={DD}
        />
      )

    }
    // ________________________________________________________________________________________________________________
    //  当月の日付けを作成   
    const lastDate = new Date(year, month, 0).getDate();          // 当月の最終日

    for (let i = 1; i <= lastDate; i++) {
      const createdDate = convertDate({ y: year, m: month - 1, d: i })
      const dayNo = new Date(createdDate).getDay();                           // 曜日を取得。dayNo=０なら日曜日、dayNo=６なら土曜日となってます。
      dateArray.push(                                                  // push()で当月の日付<button>を配列の後続に入れていきます
        <DateBtn
          key={'thisMonth' + createdDate}
          selected={createdDate === value}
          focused={createdDate === cursorDate}
          today={createdDate === today}
          dayNo={dayNo}
          onClick={() => onClick(createdDate)}
          label={i}
        />
      )

      if (dayNo === breakPoint) { dateArray.push(<br key={"break" + i} />) }                 // 最後にbreakPoint変数で選択した曜日の後に改行を入れる

    }
    // ________________________________________________________________________________________________________________

    //  当月のカレンダーの末尾に来る、来月の頭の日付けを作成    

    let endDayCount = new Date(year, month, 0).getDay()          // 月末の曜日Noを取得して、カウント変数にセット

    for (let i = 1; i <= 6; i++) {

      if (endDayCount === breakPoint) { break }                      // もしカウントがカレンダーの最後の曜日に達したら日付作成完了です  
      else if (endDayCount === 6) { endDayCount = 0 }            // また、もしカウントが6の場合、次は0になります。
      else { endDayCount++ }            // 最後の曜日に達するまでカウントしていきます

      let createdDate = null
      const D = new Date(year, month, i).getDate()                 // forループに基づいて作成される次月の日付け
      if (month === 12) {  // 1月の場合 
        createdDate = convertDate({ y: year + 1, m: 0, d: D })
      } else {
        createdDate = convertDate({ y: year, m: month, d: D })
      }
      dateArray.push(
        <DateBtn
          notThisMonth
          key={createdDate}
          selected={createdDate === value}
          today={createdDate === today}
          onClick={() => onClick(createdDate)}
          label={D}
        />
      )
    }

    return (
      <Fragment>
        {dateArray}       {/* 日付け */}
      </Fragment>
    )
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class DateBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    }
  }
  // ________________________________________________________________________________________________________________

  render() {
    const { hover } = this.state
    const {
      selected,
      value,
      onClick,
      onKeyDown,
      label,
      style,
      focused,
      today,
      dayNo,
      notThisMonth,
      dayOfweek,
    } = this.props

    const button_style = {              // <button> リストアイテム
      fontSize: 11,
      outline: "none",
      height: 25,
      width: 25,
      border: "none",
      cursor: "pointer",
      transition: "0.2s",
      borderRadius: '50%',
      color:
        notThisMonth ? "#C0C0C0" : //今月じゃない日付け
          dayNo === 6 ? "#70A4EA" : //土曜日
            dayNo === 0 ? "#CE5B5B" : //日曜日
              '#838383',
      backgroundColor:
        focused ? "#D3EAD7" : //フォーカス中の日付け
          hover ? Colos.hover : //オンマウス中の日付け
            selected ? "#D9D9D9" : //選択中の日付け
              today ? "#FFF050" : //今日の日付け
                "transparent",
      fontWeight: "bold",
    }

    return (
      <button
        style={Object.assign({}, button_style, style)}
        value={value}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        {label}
      </button>
    )
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

