import React from 'react';
import { zindex } from './Styles';

export default class TableFooter extends React.Component {
  render() {
    const { children } = this.props
    const wrap_style = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: 16,
      fontWeight: 'bold',
      border: 'none',
      backgroundColor: '#FFF',
      height: 40,
      position: 'fixed',
      bottom: 0,
      right: 0,
      width: '100vw',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px -1px 10px',
      zIndex: zindex.tableFooter,
    }
    return (
      <div style={wrap_style}>
        {children}
      </div>
    );
  }
}

