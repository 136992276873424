import React from 'react';
import * as Icons from "../Icons";
import { table as Colors } from '../Colors'
import { formatNumber } from '../../util/commonInput';

export default class LabelModalCell extends React.Component {
  constructor(props) {
    super(props);
    const { modalType, value } = this.props;
    this.state = {
      focus: false,
      value: value,
      oldValue: value,
    }
    this.getDefaultValue = false;
    this.td_style = {                // <td> 各セル
      width: "100%",
      border: "1px solid #ddd",
      textAlign: "center",
      verticalAlign: "middle",
      // fontSize: '1.1rem',
      height: 25,
    }
    this.innerWrap_style = {
      width: "100%",
      height: "100%",
      display: 'flex',
      alignItems: 'center',
      paddingRight: !modalType || modalType === "" ? 19 : 4,
    }
  }
  //______________________________________________________________________________________________________
  shouldComponentUpdate(nextProps, nextState) {
    const { value } = this.props;
    if (value !== nextProps.value) {
      if (!this.getDefaultValue) {
        this.setState({ oldValue: nextProps.value })
        this.getDefaultValue = true;
      }
      this.setState({ value: nextProps.value })
    }
    return true;
  }
  //______________________________________________________________________________________________________
  // Component State管理
  handleOnChange = (e) => {
    const old = this.state.value;
    this.setState({
      oldValue: old,
      value: e.target.value
    });
    // const { index, name } = this.props;
    // const fowardData = { index: index, name: name, value: e.target.value, oldValue: old }
    // this.props.cellOnChange && this.props.cellOnChange(fowardData);//　共通CellOnChangeのEvent　一般的にはReducerに更新するEventとなる
  }
  // ________________________________________________________________________________________________________________

  //Onblur時　InputTableに最新状態を渡す
  handleOnBlur = (fowardData) => {
    this.setState({ focus: false });
    const { onBlur } = this.props.children
    this.props.cellOnBlur && this.props.cellOnBlur(fowardData); // 共通CelllOnBlurのEvent
    onBlur && onBlur(fowardData);//Cellごとの特別なOnblurのEvent
  }
  // ________________________________________________________________________________________________________________
  handleOnFocus = (fowardData) => {
    this.setState({ focus: true });
    const { onFocus } = this.props.children
    onFocus && onFocus(fowardData)
  }
  // ________________________________________________________________________________________________________________
  render() {
    const {
      disabled,
      maxLength,
      index,
      must,
      color,
      onClick,
      backgroundColor,
    } = this.props
    const {
      width,
      minWidth,
      align,
      name,
      rowSpan,
      colSpan,
      style,
      format,
    } = this.props.children
    const {
      value,
      focus,
      oldValue,
    } = this.state;
    const number = this.props.number || this.props.children.number
    const zero = this.props.zero || this.props.children.zero
    const modalType = this.props.modalType || this.props.children.modalType
    const fontColor = this.props.fontColor || this.props.children.fontColor
    const fowardData = { index: index, name: name, value: value, oldValue: oldValue } //　一番親に送るデータ
    Object.assign(this.td_style, {            // <td> 各セル
      boxShadow:
        disabled ? null :
          focus ? "0px 0px 5px 0px #000000 inset" :
            null,
      cursor:
        disabled ? null :
          "pointer",
      width: width,
      minWidth: minWidth,
      textAlign: align,
    })
    let setValue = value;
    if (format && value !== "0" && value !== 0) {
      setValue = format ? formatNumber(value, format) : value
    }
    const column_style = {
      backgroundColor: backgroundColor || color || null,
    }
    const text_style = {  // <input> テキストボックスのスタイル
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      // fontSize: '1.1rem',
      border: "none",
      outline: "none",
      paddingLeft: 4,
      boxSizing: "border-box",
      textAlign:
        number ? "right" :   // 数値型なら文字を右寄せに
          null,
      color: (number && value < 0 ? "red" :   // 数値型なら文字を右寄せに
        (fontColor || null)),
    }
    //______________________________________________________________________________________________________
    return (

      <td
        style={Object.assign({}, this.td_style, column_style, style)}
        rowSpan={rowSpan}
        colSpan={colSpan}
      >
        <div style={this.innerWrap_style}>
          {<input
            value={setValue || (zero ? setValue : '')}
            style={text_style}
            disabled={true}
            maxLength={maxLength}
            onChange={this.handleOnChange}
            onFocus={() => this.handleOnFocus(fowardData)}
            onBlur={() => this.handleOnBlur(fowardData)}
          />}
          &nbsp;
          {modalType === "add" && <Icons.PlusSquare width={16} height={16} color={Colors.modal} onClick={onClick} hidden={disabled} />}
          {value != "" && modalType === "detail" && <Icons.Eye width={16} height={16} color={Colors.modal} onClick={onClick} hidden={disabled} />}
          {value != "" && modalType === "check" && <Icons.Eye width={16} height={16} color={Colors.errorIcon} onClick={onClick} hidden={disabled} />}
        </div>
      </td>
    )
  }
}
