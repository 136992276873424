import { LOGIN, LOGOUT, LOGINEDIT, SETDIDSET, ERR, SEARCH_ERR, INPUT_ERR, FATALERR, SESSIONERR, MENU, PAGEMOVE, CLEARERR, SETPMASTER, SET_PROGRESS, SET_PROGRESS_INFO, CLEAR_PROGRESS_INFO, BUTTONSTATUS } from '../../comRedux/ActionConst';
import { mapIndexFromArr } from '../../util/commonInput';
export const key = 'COMMON';

const initLogin = {
  userCode: '',
  userName: '',
  password: '',
  sSessionId: '',
  lang: '',
  langName: '',//login submit 用
  entryType: 0, // entryType 0:loginPage 1:Menu
  isChanged: false,
  errMsg: '',
  selectMenuId: 'TEST',
  selectMenuName: 'MENU_NAME（）',
  purcCode: '',
  purcName: '',
  compCode: '',
  userType: '',
  didSet: true,
  permission: null,
}

const initMenu = {
  didSet: false,
  menuList: [],
  menuIndex: {},
}

const initCommon = {
  didSet: false,
  searchErrMsg: '',
  inputErrMsg: '',
  fatalErrMsg: '',
  currentPage: 1, // 0 → Session Error 1 → search/searchList  2 → input 3 → detail 4 → update/upload
  mode: '0', // 0初期値　1追加 2編集 3削除 4複写 5詳細
  pMaster: [],
  progressing: false,
  progressName: '',
  progressStatus: '',
  progressSize: '',
  progressCount: '',
  progressPercentage: '',
  para: null,
}

export const initialState = {
  //Loginデータの保管
  Login: JSON.parse(JSON.stringify(initLogin)),
  //Menu情報の保管
  Menu: JSON.parse(JSON.stringify(initMenu)),
  //共通パラメーターの保管
  Common: JSON.parse(JSON.stringify(initCommon)),
}

export default (state = initialState, action) => {
  switch (action.type) {
  case LOGINEDIT:
    for (let obj in action.payload) {
      state.Login[obj] = action.payload[obj];
    }
    return Object.assign({}, state, { Login: { ...state.Login } });

  case LOGIN:
    console.log(action.payload)
    return Object.assign({}, state, {
      Login: {
        ...state.Login,
        sSessionId: action.payload.sSessionId,
        password: '', //password clear
        lang: action.payload.lang || '',
        langName: action.payload.langName || '',
        entryType: 1,
        isChanged: false,
        errMsg: '', //errMsg clear
        ...action.payload,
      }
    });
  case LOGOUT:
    return Object.assign({}, state, {
      Login: {
        ...state.Login,
        sSessionId: '',
        userCode: '',
        userName: '',
        password: '',
        lang: '',
        entryType: 0,
        isChanged: false,
        permission: null,
      },
      Menu: {},
      Common: {...initCommon},
    });
  case MENU:
    state.Menu['menuIndex'] = {}
    mapIndexFromArr(action.menuList, 'menuCate', state.Menu.menuIndex);
    return Object.assign({}, state, {
      Menu: {
        ...state.Menu,
        didSet: true,
        menuList: action.menuList
      }
    });
    //機能内画面推移
  case PAGEMOVE:
    return Object.assign({}, state, {
      Common: {
        ...state.Common,
        currentPage: action.payload.currentPage ? action.payload.currentPage : state.Common.currentPage,
        mode: action.payload.mode,
        para: action.payload.para ? action.payload.para : null,
      }
    });

  case SETPMASTER:
    return Object.assign({}, state, {
      Common: {
        ...state.Common,
        pMaster: action.payload,
      }
    });

  case SETDIDSET:
    return Object.assign({}, state, {
      [action.name]: {
        ...state[action.name],
        didSet: action.didSet,
      }
    });

  case SET_PROGRESS:
    return Object.assign({}, state, {
      Common: {
        ...state.Common,
        progressing: action.value,
      }
    });

  case SET_PROGRESS_INFO:
    return Object.assign({}, state, {
      Common: {
        ...state.Common,
        progressName: action.payload.processName || '',
        progressStatus: action.payload.status.name || '',
        progressSize: action.payload.goukei || '',
        progressCount: action.payload.count || '',
        progressPercentage: action.payload.percentage || '',
        progressTime: action.payload.processTime || '',
      }
    });

  case CLEAR_PROGRESS_INFO:
    console.log(action)
    return Object.assign({}, state, {
      Common: {
        ...state.Common,
        progressing: !!action.progressing,
        progressName: '',
        progressStatus: '',
        progressSize: '',
        progressCount: '',
        progressPercentage: '',
      }
    });


  case ERR:
    return Object.assign({}, state, {
      Login: {
        ...state.Login,
        errMsg: action.errMsg,
      }
    });
  case SEARCH_ERR:
    return Object.assign({}, state, {
      Common: {
        ...state.Common,
        searchErrMsg: action.errMsg,
      }
    });
  case INPUT_ERR:
    return Object.assign({}, state, {
      Common: {
        ...state.Common,
        inputErrMsg: action.errMsg,
      }
    });
  case FATALERR:
    return Object.assign({}, state, {
      Common: {
        ...state.Common,
        currentPage: 9,
        fatalErrMsg: action.errMsg,
      }
    });
  case SESSIONERR:
    return Object.assign({}, state, {
      Common: {
        ...state.Common,
        currentPage: 0,
      }
    });
  case CLEARERR:
    return Object.assign({}, state, {
      Common: {
        ...state.Common,
        inputErrMsg: '',
        searchErrMsg: '',
        fatalErrMsg: ''
      }
    });
  case BUTTONSTATUS:
    return Object.assign({}, state, {
      Common: {
        ...state.Common,
        [action.id]: action.value
      }
    });

  default:
    if (action.type === '@@router/LOCATION_CHANGE') {
      return Object.assign({}, state, {
        Common: JSON.parse(JSON.stringify(initCommon)),
      });
    } else {
      return state;
    }
  }
}