import React, { Fragment } from 'react';
import PMaster from '../PMaster';
import * as Icons from "../Icons";
import * as Parts from './TableComponent';
import { table as Colors } from '../Colors';
import { formatNumber, funcAdapter, checkProps, connectStr } from '../../util/commonInput';

export default class TBodyListL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setData: this.props.setData || {},
      detailOpen: this.props.detailOpen,
    }
  }
  // ________________________________________________________________________________________________________________ 
  shouldComponentUpdate(nextProps) {
    const { detailOpen } = nextProps;
    if (this.props.detailOpen !== detailOpen) {
      this.setState({ detailOpen: detailOpen })
    }
    return true;
  }
  // ________________________________________________________________________________________________________________ 
  render() {
    const { children, color, rowOnClick, setData, disabledRow } = this.props;
    const { detailOpen } = this.state;
    const td_arr = [null];
    let detail_arr = [];
    let secondTr = [null];
    let secondTrIndex = 0;
    let endTr = [null];
    let renderDetailCell = false;
    const dataLength = setData['detailVModels'] && setData['detailVModels'].length; // detailVModelsのサイズ
    let loopSize = detailOpen ? dataLength : 1;
    if (dataLength === 0) {
      loopSize = 0;
    }
    for (let j in children) {
      const { type, name, detailConf, secondConf, hidden, array } = children[j];
      if (hidden) { continue; } // カラム設定にhiddenが設定されていたら処理をスキップ
      // 明細行を作成
      if (type === 'detailVModels') {
        renderDetailCell = true;
        // 明細行ループ
        for (let k = 0; k < loopSize; k++) {
          let detailTd_arr = [null];
          let detailSecond_arr = [];
          // 明細セルループ
          for (let v in detailConf) {
            const setHidden = funcAdapter(detailConf[v]?.hidden, setData['detailVModels'][k]) || detailConf[v]?.hidden;
            if (setHidden === true) { continue }
            if (detailConf[v].type === 'secondRow') {
              let secondTd_arr = [];

              for (let kk in detailConf[v].secondConf) {
                secondTd_arr.push(
                  <Parts.TDL key={"detailTd" + secondTrIndex + kk} {...this.props} setData={setData['detailVModels'][k]} children={detailConf[v].secondConf[kk]} color={color} detailIndex={k} />
                )
              }
              if (secondTd_arr) {
                detailSecond_arr.push(
                  <tr key={"detailTr" + k + secondTrIndex}>{secondTd_arr}</tr>
                )
                secondTrIndex++;
              }
            } else {
              detailTd_arr.push(
                <Parts.TDL
                  key={"detailTd" + k + v}
                  {...this.props}
                  setData={setData['detailVModels'][k]}
                  children={detailConf[v]}
                  detailIndex={k}
                />
              )
            }
          }
          // 行を作成する処理
          detail_arr.push(
            <tr key={"detailTr" + k}>{detailTd_arr}</tr>
          )
          if (detailSecond_arr) {
            detail_arr = detail_arr.concat(detailSecond_arr)
          }
        }
      }
      // 2行目を生成
      else if (type === 'secondRow') {
        secondTrIndex = secondTrIndex + 1;
        let secondTd_arr = [null]
        for (let k in secondConf) {
          if (secondConf[k].array) {
            for (let indexArray = 0; indexArray < setData[secondConf[k].name].length; indexArray++) {
              secondTd_arr.push(
                <Parts.TDL key={"secondArrayTd" + secondTrIndex + k} {...this.props} children={Object.assign({}, secondConf[k], { "value": setData[secondConf[k].name][indexArray] }, { "arrayIndex": indexArray })} color={color} />
              )
            }
          } else {
            secondTd_arr.push(
              <Parts.TDL key={"secondTd" + secondTrIndex + k} {...this.props} children={secondConf[k]} color={color} />
            )
          }
        }
        // 行を作成する処理
        secondTr.push(<tr key={"secondTr" + secondTrIndex}>{secondTd_arr}</tr>)
      }
      // 最終行目を生成
      else if (type === 'endRow') {
        let endTd_arr = [null]
        for (let k in detailConf) {
          endTd_arr.push(
            <Parts.TDL key={"endTd" + k} {...this.props} children={detailConf[k]} color={color} />
          )
        }
        // 行を作成する処理
        endTr.push(<tr key={"detailTr3"}>{endTd_arr}</tr>)
      }
      // 配列の列を生成
      else if (array) {
        let indexArray = 0;
        for (let k in setData[name]) {
          td_arr.push(
            <Parts.TDL key={"arrayTd" + k} {...this.props} children={Object.assign({}, children[j], { "value": setData[name][k] }, { "arrayIndex": indexArray })} />
          )
          indexArray++;
        }
      }
      // 1行目を生成
      else {
        td_arr.push(
          <Parts.TDL key={"td" + j} {...this.props} children={children[j]} />
        )
      }
    }

    // ________________________________________________________________________________________________________________
    return (
      <Parts.TBodyL color={color} rowOnClick={rowOnClick ? () => rowOnClick(setData) : null} disabled={disabledRow && disabledRow(setData)}>
        <tr>
          <Parts.DetailSwitchCell
            onClick={() => this.setState({ detailOpen: !detailOpen })}
            render={renderDetailCell}
            iconRender={dataLength > 1}
            detailRender={detailOpen}
          />
          {td_arr}
        </tr>
        {secondTr}
        {detail_arr}
        {endTr}
      </Parts.TBodyL>
    )
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
