import React, { Fragment } from 'react';
import { textWrapper as Colors } from './Colors'
import { getPMasterName } from '../util/commonInput';
import * as Icons from './Icons';

export default class TextWrapper extends React.Component {
  constructor(props) {
    super(props);
    const {
      colL = 4,
      colR = 8,
      row = 1,
      vertical,
      onClickModal,
      height,
      type,
      scroll,
      disabled,
      grid,
      backgroundColor,
      borderColor,
      overflow
    } = this.props;
    const colspanSum = vertical ? colL : colL + colR
    const wrapperWidth = grid ? "100%" : colspanSum / 24 * 100 + '%';
    const widthL = colL / colspanSum * 100 + '%';
    const widthR = colR / colspanSum * 100 + '%';

    this.defHeight = 40;

    this.wrap_style = {
      maxWidth: wrapperWidth,
      width: wrapperWidth,
      minWidth: wrapperWidth,
      marginTop: -1,
      display: "inline-flex",
      flexDirection:
        vertical ? 'column' :
          null,
      gridArea: "span " + row + " / span " + colspanSum,
    }
    this.leftArea_style = {
      width: widthL,
      maxWidth: widthL,
      minWidth: widthL,
      letterSpacing: 1,
      display:
        colL === 0 ? 'none' :
          'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // fontWeight: 'bold',
      border: `1px solid ${Colors.border}`,
      backgroundColor: Colors.label,
      color: Colors.font,
      height:
        height ? height :
          row ? row * this.defHeight :
            this.defHeight,
      minHeight:
        height === 'auto' ? this.defHeight : // heightがautoなら最小幅を設定
          height ? height :
            row ? row * this.defHeight :
              this.defHeight,
      position: 'relative',
      paddingLeft:
        disabled ? 8 :
          onClickModal ? 20 :
            8,
      paddingRight:
        disabled ? 8 :
          onClickModal ? 20 :
            8,
      overflow: 'hidden',
      textAlign: 'center',
    }

    this.rightArea_style = {
      width:
        vertical ? widthL :
          widthR,
      maxWidth:
        vertical ? widthL :
          widthR,
      height:
        height ? height :
          row ? row * this.defHeight :
            this.defHeight,
      minHeight:
        height === 'auto' ? this.defHeight : // heightがautoなら最小幅を設定
          height ? height :
            row ? row * this.defHeight :
              this.defHeight,
      display:
        colR === 0 ? 'none' :
          'flex',
      alignItems:
        scroll ? null :
          'center',
      border: `1px solid ${Colors.border}`,
      borderLeft:
        vertical ? null :
          'none',
      justifyContent:
        vertical ? 'center' :
          type === 'number' ? 'flex-end' :
            null,
      backgroundColor: Colors.back,
      paddingRight:
        type === 'number' ? 8 : null,
      overflow:
        scroll ? 'auto' :
          !!overflow ? overflow : null,
      padding: "2px 0px",
    }
    this.must_style = {
      color: Colors.must,
    }

  }
  // _____________________________________________________________________


  render() {
    const {
      children,
      label,
      must,
      plabel,
      plabel2,
      onClickModal,
      disabled,
      hidden
    } = this.props
    let Plabel = plabel && getPMasterName(plabel);
    let Plabel2 = plabel2 && getPMasterName(plabel2);
    if (Plabel === 'NONE' || hidden) { return null }
    return (
      <div style={this.wrap_style}>
        <div style={this.leftArea_style}>
          <span>{Plabel}{Plabel2}{label}</span>
          {must && <span style={this.must_style}>*</span>}
          <ModalIcon onClick={onClickModal} hidden={!onClickModal || disabled} />
        </div>
        <div style={this.rightArea_style}>
          {children}
        </div>
      </div>
    )
  }
}
// ____________________________________________________________________________________________________

// 虫メガネのアイコン
class ModalIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false,
    }
  }
  //____________________________________________________
  handleOnMouseOver = () => {
    this.setState({ hover: true })
    const { onMouseOver } = this.props;
    onMouseOver && onMouseOver()
  }
  //____________________________________________________
  handleOnMouseLeave = () => {
    this.setState({ hover: false })
    const { onMouseLeave } = this.props;
    onMouseLeave && onMouseLeave()
  }
  //____________________________________________________
  handleOnFocus = () => {
    this.setState({ focus: true })
    const { onFocus } = this.props;
    onFocus && onFocus()
  }
  //____________________________________________________
  handleOnBlur = () => {
    this.setState({ focus: false })
    const { onBlur } = this.props;
    onBlur && onBlur()
  }
  //____________________________________________________
  render() {
    const { onClick, hidden } = this.props;
    const { focus, hover } = this.state;
    if (hidden) { return null }
    const wrap_style = {
      position: 'absolute',
      right: 2,
      top: 0,
      bottom: 0,
      margin: 'auto',
      border: 'none',
      cursor: 'pointer',
      backgroundColor:
        hover || focus ? Colors.font :
          "transparent",
      borderRadius: 5,
      transition: "0.2s",
      height: 19,
      width: 19,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

    }
    const iconColor = hover || focus ? Colors.label : Colors.font;
    return (
      // eslint-disable-next-line no-useless-concat
      <button
        type='button'
        onClick={onClick}
        style={wrap_style}
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
        onMouseOver={this.handleOnMouseOver}
        onMouseLeave={this.handleOnMouseLeave}
      >
        <Icons.Search1 width={12} height={12} color={iconColor} />
      </button>
    );
  }
}