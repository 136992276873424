import React from 'react';

export default class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false,
    }
  }
  //____________________________________________________
  handleOnMouseOver = () => {
    this.setState({ hover: true })
    const { onMouseOver } = this.props;
    onMouseOver && onMouseOver()
  }
  //____________________________________________________
  handleOnMouseLeave = () => {
    this.setState({ hover: false })
    const { onMouseLeave } = this.props;
    onMouseLeave && onMouseLeave()
  }
  //____________________________________________________
  handleOnFocus = () => {
    this.setState({ focus: true })
    const { onFocus } = this.props;
    onFocus && onFocus()
  }
  //____________________________________________________
  handleOnBlur = () => {
    this.setState({ focus: false })
    const { onBlur } = this.props;
    onBlur && onBlur()
  }
  //____________________________________________________
  render() {
    const { className, onClick, style, hoveredStyle, disabled, children, label, hidden } = this.props;
    const { focus, hover } = this.state;
    if(hidden){return null}
    let _style = style
    if (focus || hover) {
      _style = Object.assign({}, _style, hoveredStyle)
    }
    return (
      // eslint-disable-next-line no-useless-concat
      <button
        type='button'
        className={className}
        onClick={onClick}
        style={_style}
        disabled={disabled}
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
        onMouseOver={this.handleOnMouseOver}
        onMouseLeave={this.handleOnMouseLeave}
      >
        {children}
        {label}
      </button>
    );
  }
}

