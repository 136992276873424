import React, { Fragment } from 'react';
import * as Icons from "../Icons";
import * as Parts from './TableComponent';
import { getComponentLabelName } from '../../util/commonInput'
import { table as Colors } from '../Colors'

export default class ChangeRowsBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      showFilter: false,
    }

    this.wrap_style = {           // <button> 全件表示ボタン
      height: 40,
      paddingRight: 16,
      paddingLeft: 10,
      backgroundColor: "#FFF",
      borderRadius: 2,
      cursor: "pointer",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // marginRight: 16,
      border: '1px solid' + Colors.paging,
    }
    this.label_style = {
      // fontSize: '1.2rem',
      // fontWeight: 'bold',
      letterSpacing: 0.6,
      marginLeft: 8,
      color: Colors.paging
    }
  }
  // ___________________________________________________________________________________________________________
  allView = () => {
    const { allView } = this.props;
    this.setState({ showFilter: false })
    allView && allView();
  }
  // ___________________________________________________________________________________________________________
  resetView = () => {
    const { resetView } = this.props;
    this.setState({ showFilter: false })
    resetView && resetView();
  }
  // ___________________________________________________________________________________________________________

  render() {
    const { countView } = this.props
    const { hover, showFilter } = this.state;
    // ___________________________________________________________________________________________________________

    Object.assign(this.wrap_style, {
      opacity:
        hover ? 0.7 :
          null,
    })
    // ___________________________________________________________________________________________________________

    return (
      <button
        style={Object.assign({}, this.wrap_style)}
        onClick={() => this.setState({ showFilter: true })}
        ref={(el) => this.wrap_ref = el}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        <Icons.List width={12} height={12} color={Colors.paging} />
        <span style={Object.assign({}, this.label_style)}>表示件数を変更する</span>
        <Parts.FilterBox label='表示件数を変更する' unmount={() => this.setState({ showFilter: false })} render={showFilter} target={this.wrap_ref}>
          <Parts.FilterInput number onChange={countView} />
          <Parts.FilterBtn label="全件を表示する" color='#D3EAD7' onClick={this.allView} />
          <Parts.FilterBtn label="初期状態に戻す" color='#D3EAD7' onClick={this.resetView} />
        </Parts.FilterBox>
      </button>
    );
  }
}
