import React, { Fragment } from 'react';
import BasicTD from './BasicTD';
import { table as Colors } from '../Colors'
import RadioList from "../RadioList";

export default class RadioCell extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      focus: false,
      value: value || '',
    }

    this.td_style = {                // <td> 各セル
      width: "100%",
      border: "1px solid" + Colors.border,
      textAlign: "center",
      verticalAlign: "middle",
      // fontSize: '1.1rem',
      height: 25,
      position: 'relative',
    }
    this.select_style = {
      fontSize: 'inherit',
      height: '100%',
      width: '100%',
      paddingLeft: 4,
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      paddingRight: 20,
      cursor: "pointer",
      position: 'relative',
      appearance: 'none',
    }
    this.icon_style = {
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
    }

  }
  //______________________________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const { value } = this.props;
    if (value !== nextProps.value) {
      this.setState({ value: nextProps.value || '' })
    }
    return true;
  }
  //______________________________________________________________________________________________________
  handleOnChange = (name, value) => {
    this.setState({ value: value });
    const { index, onChange, children, setData } = this.props;
    const fowardData = { 'index': index, 'name': children.name, 'value': value, 'rowData': setData }
    onChange && onChange(fowardData)
    children.onChange && children.onChange(fowardData)
    //this.select_ref.focus()
  }
  // ________________________________________________________________________________________________________________
  handleOnBlur = (fowardData) => {
    this.setState({ focus: false });
    const { onBlur } = this.props.children
    onBlur && onBlur(fowardData) // column設定用のonBlur
  }
  // ________________________________________________________________________________________________________________
  handleOnFocus = (fowardData) => {
    this.setState({ focus: true });
    const { onFocus } = this.props.children
    onFocus && onFocus(fowardData)
  }
  // ________________________________________________________________________________________________________________
  handleOnKeyDown = (e) => {
    if (e.keyCode === 40 || e.keyCode === 38) {
      e.preventDefault()
    }
  }
  // ________________________________________________________________________________________________________________
  render() {
    const { disabled, children, must, color, option = [], description } = this.props
    const { focus, value, } = this.state;
    const {
      rowSpan,
      colSpan,
      index,
      name,

      width,
      minWidth,
      align,
      mustCell
      // onClick,
    } = children
    const fowardData = { 'index': index, 'name': name, 'value': value } //　一番親に送るデータ

    Object.assign(this.td_style, {            // <td> 各セル
      boxShadow:
        disabled ? null :
          focus ? "0px 0px 3px 0px inset" :
            null,
      backgroundColor:
        disabled ? color :
          must || mustCell ? Colors.mustCell :
            null,
      cursor:
        disabled ? null :
          "pointer",
      pointerEvents:
        disabled ? "none" :
          "auto",
      width: width,
      minWidth: minWidth,
      textAlign: align,
    })
    Object.assign(this.select_style, {            // <td> 各セル
      color:
        disabled ? '#000' :
          null,
    })
    //______________________________________________________________________________________________________
    return (
      <BasicTD {...this.props} config={this.props.children}>
        <RadioList setData={option} description={description} value={value} onChange={this.handleOnChange} disabled={disabled} />
      </BasicTD>
    )
  }
}

