/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React, { Fragment } from 'react';
import * as Icons from "./Icons";
import * as Parts from './TableComponents/TableComponent';
import { funcAdapter, getPMasterName } from '../util/commonInput';
import { table as Colors } from './Colors';

export default class InputTable extends React.Component {
  constructor(props) {
    super(props);
    const {
      minWidth,
      setData,
      maxRows,
      startPage,
    } = this.props;
    this.state = {
      setData: setData || [],
      labelCheck: [],     // ヘッダーのチェックボックスの値
      maxRows: maxRows,   // 一度に表示する行の最大数
      pageCount: startPage || 1,   // 現在のページ数
      startRows: 0,      // 表示中の開始行数
      changeRow: "",     // 表示件数を変更するテキストボックスの値
    }
    this.tableWrap_style = {   // <div> テーブルのwapper
      minWidth: minWidth,
      marginBottom: "16px",
    }
    this.buttonArea_style = {
      paddingTop: 8,
    }
  }
  // ______________________________________________________________________________________________________

  shouldComponentUpdate(nextProps, nextState) {
    // update when children change
    const { maxRows, pageCount } = nextState;
    const { children, setData, } = nextProps;     // 省略宣言
    if (this.props.children !== children) {
      const setData_copy = setData ? setData.slice() : []  // 配列stateのコピーを作成
      for (let i in setData) {        // 新たに送られてくる配列の文だけループ
        for (let j in children) {      // childrenで設定されている数だけループ
          const { name } = children[j]                    // 省略宣言
          if (setData[i] && setData[i][name]) {                                          // nameが設定されていたら
            Object.assign(setData_copy[i], { [name]: setData[i][name] })  // Object.assign()メソッドでJson結合
          }
        }
      }
      this.setState({ setData: setData_copy }) // コピーした配列をセット
      return true
    } else {
      // update when state's setData change
      const { setData } = nextState;     // 省略宣言
      if (this.state.setData !== setData) {   // setDataが変化したら
        return true
      }
    }
    // if(this.state.labelCheck !== labelCheck){ return true };
    if (this.state.maxRows !== maxRows) { return true };
    if (this.state.pageCount !== pageCount) { return true };
    return false;
  }
  // ______________________________________________________________________________________________________
  // 「行追加」ボタンを押した時
  addRows = () => {
    const { children, disabledAll, onChange } = this.props
    const { setData } = this.state       // state省略宣言
    if (disabledAll) return;
    const setData_copy = setData.slice(); // 配列をコピー
    setData_copy[setData.length] = {}   // Jsonが入るように初期化

    // 行追加用の初期値を設定
    if (setData) {
      for (let key in setData[0]) {
        Object.assign(setData_copy[setData.length], { [key]: null })  // 初期値を設定。Object.assign()メソッドでJson結合
      }
    }

    // 行追加処理。defaultValueで初期値をセット可能
    // カラム設定が1行の場合、配列に挿入しないとループの数が制御できないので、、、
    let children_arr = []
    if (Array.isArray(children[0]) === true) {
      children_arr = children
    } else {
      children_arr[0] = children
    }
    for (let i in children_arr) {
      for (let j in children_arr[i]) {
        const { name, defaultValue = "" } = children_arr[i][j]
        // nameが設定されているもののみ処理
        if (name) {
          Object.assign(setData_copy[setData.length], { [name]: defaultValue })  // Object.assign()メソッドでJson結合。defaultValueがあればセット、なければ ""をセット
        }
      }
    }

    this.setState({
      setData: setData_copy,
    })
    onChange && onChange(setData_copy)  // 親に渡す
  }
  // _______________________________________________________________________________________________________
  // 「行削除」ボタンを押した時
  handleDelRow = (index) => {
    const { onChange, onDelete } = this.props;
    const { setData, } = this.state;
    const setData_copy = setData.slice();     // 配列をコピー
    let deleteRowData = { ...setData_copy[index] };
    setData_copy.splice(index, 1);            // spliceメソッドで配列を削除（行を削除）
    this.setState({
      setData: setData_copy,
    })
    onChange && onChange(setData_copy); // 親に渡す
    onDelete && onDelete(deleteRowData);
  }
  // _______________________________________________________________________________________________________
  // Upボタンを押した時
  handleUpRow = (index) => {
    if (index > 0) {
      const { onChange } = this.props;
      const { setData } = this.state;
      let setData_copy = setData.slice();     // 配列をコピー
      const preRowData = setData_copy[index - 1]
      const currentRowData = setData_copy[index]
      setData_copy[index - 1] = currentRowData
      setData_copy[index] = preRowData
      this.setState({ setData: setData_copy })
      onChange && onChange(setData_copy); // 親に渡す
    }
  }
  // _______________________________________________________________________________________________________
  // Downボタンを押した時
  handleDownRow = (index) => {
    if (index < this.state.setData.length - 1) {
      const { onChange } = this.props;
      const { setData } = this.state;
      let setData_copy = setData.slice();     // 配列をコピー
      const nextRowData = setData_copy[index + 1]
      const currentRowData = setData_copy[index]
      setData_copy[index + 1] = currentRowData
      setData_copy[index] = nextRowData
      this.setState({ setData: setData_copy })
      onChange && onChange(setData_copy); // 親に渡す
    }
  }
  // _______________________________________________________________________________________________________
  // copyボタンを押した時
  handleCopyRow = (children, index) => {
    const { onChange, } = this.props;
    const { setData, } = this.state;
    const { exclude = [] } = children; // カラム設定
    const setData_copy = setData.slice();     // テーブル全体をコピー
    const copyRow = Object.assign({}, setData_copy[index]) // 選択した行をコピー
    // コピーから除外する項目のリセット
    for (let i in exclude) {
      copyRow[exclude[i]] = ""
    }
    setData_copy.splice(index + 1, 0, copyRow)    // テーブルにコピーした配列を追加（行を追加）
    this.setState({
      setData: setData_copy,
    })
    onChange && onChange(setData_copy); // 親に渡す
  }
  // addボタンを押した時
  handleAddRow = (index) => {
    const { children, onChange } = this.props;
    const { setData, } = this.state;
    const setData_copy = setData.slice();     // 配列をコピー
    setData_copy.splice(index + 1, 0, {})    // pushメソッドで配列を追加（行を追加）

    // 行追加処理。defaultValueで初期値をセット可能
    // カラム設定が1行の場合、配列に挿入しないとループの数が制御できないので、、、
    let children_arr = []
    if (Array.isArray(children[0]) === true) {
      children_arr = children
    } else {
      children_arr[0] = children
    }
    for (let i in children_arr) {
      for (let j in children_arr[i]) {
        const { name, defaultValue = "" } = children_arr[i][j]
        const setDefaultValue = funcAdapter(defaultValue, setData_copy[index])

        // nameが設定されているもののみ処理
        if (name) {
          Object.assign(setData_copy[index + 1], { [name]: setDefaultValue })  // Object.assign()メソッドでJson結合。defaultValueがあればセット、なければ ""をセット
        }
      }
    }
    this.setState({
      setData: setData_copy,
    })
    onChange && onChange(setData_copy); // 親に渡す
  }
  // _______________________________________________________________________________________________________

  // セルからフォーカスが外れたとき
  cellOnBlur = (obj) => {
    const { index, name, value } = obj;
    const setData_copy = this.state.setData.slice();            // 配列をコピー
    if (!setData_copy[index]) {
      setData_copy[index] = {}
    }
    setData_copy[index][name] = value  // TDから受け取ったvalueをセット
    this.setState({ setData: setData_copy })        // 変更後のデータをセット
    this.props.onChange && this.props.onChange(setData_copy) // 親に渡す
    // this.props.onCellBlur && this.props.onCellBlur(setData_copy) // 親に渡す
  }
  // _______________________________________________________________________________________________________

  // ヘッダーのチェックボックスをクリックしたとき
  handleOnAllCheck = (value, name) => {                        // 引数・・・バインドされているKey情報
    const setData_copy = this.state.setData.slice();            // 配列をコピー
    const { children } = this.props;
    let type = "check";
    let disabled = false;
    let checkBox = true;

    // "check"を設定しているカラム設定を取得
    for (let i in children) {
      if (children[i]["name"] === name) {
        type = children[i]["type"] // typeの設定を取得
        disabled = children[i]["disabled"]　// disabledの設定を取得
        checkBox = children[i].hasOwnProperty("checkBox") ? children[i]["checkBox"] : true; // checkBoxの設定を取得
        break;
      }
    }
    //  setDataの内容にcheckの変更を反映
    for (let i in setData_copy) {
      let setType = funcAdapter(type, setData_copy[i]);
      let setDisabled = funcAdapter(disabled, setData_copy[i]);
      let setCheckBox = funcAdapter(checkBox, setData_copy[i]);
      // checkに設定しているnameの値を変更（disabledやcheckBox非表示のものはスキップ）
      if (setType === "check" && !setDisabled && setCheckBox) {
        setData_copy[i][name] = value;
      }
    }

    this.setState({ setData: setData_copy })
    this.props.onChange && this.props.onChange(setData_copy) // 親に渡す

  }
  // _______________________________________________________________________________________________________
  // ページングの"次へ"ボタンを押した時
  nextPaging = () => {
    const { pageCount, maxRows, startRows } = this.state
    this.setState({
      pageCount: pageCount + 1,           // ページ数カウントを＋１して
      startRows: startRows + maxRows,  // 現在の開始行に、現在の表示件数を追加             
    })
  }
  // ________________________________________________________________________________________________________________
  // ページングの"前へ"ボタンを押した時
  prePaging = () => {
    const { pageCount, maxRows, startRows } = this.state
    this.setState({
      pageCount: pageCount - 1,             // ページ数カウントを-１して
      startRows: startRows - maxRows,    // 現在の開始行から現在の表示件数を引く         
    })
  }
  // ________________________________________________________________________________________________________________
  // 【全件を表示する】ボタン押した時
  allView = () => {
    this.setState({
      maxRows: null,  // 最大表示件数をnull
      startRows: 0,     // 表示開始行を初期化
      pageCount: 1,     // ページ数を初期化
      changeRow: "",    // テキストボックスを初期化
    })
  }
  // ________________________________________________________________________________________________________________
  // 【初期状態に戻す】ボタンを押した時
  resetView = () => {
    this.setState({
      maxRows: this.props.maxRows,   // 最大表示件数を初期化
      startRows: 0,                    // 表示開始行を初期化
      pageCount: 1,                    // ページ数を初期化
      changeRow: "",                   // テキストボックスを初期化
      setData: this.props.setData,   // フィルタリングメニューを閉じる
    })
  }
  // ________________________________________________________________________________________________________________
  // 【表示件数を入力】テキストボックスに入力したとき
  countView = (value) => {
    let replaceValue = value.replace(/[^\d]/g, "");  // 0~9以外の入力値を空値に変換
    replaceValue = Number(replaceValue)                    // 数値型に変換( "" は 0 になる）
    this.setState({ changeRow: replaceValue })              // テキストボックスに表示
    if (replaceValue !== 0) {
      this.setState({
        maxRows: replaceValue,  // 最大表示件数を変更 
        startRows: 0,             // 表示開始行を初期化
        pageCount: 1,             // ページ数を初期化
      })
    }
  }
  // ________________________________________________________________________________________________________________

  render() {
    const {
      children,
      disabledAll,
      inableAddRow,
      hideTheader,
      addBtns,
      paging,
      addTheader,
      headerFixedLength,
      disabledRow,
      theaderStyle,
    } = this.props
    const {
      maxRows,        // 一度に表示する最大件数を設定する時に使用
      pageCount,
      setData,         // 親から渡されたデータの集合体
      startRows,
    } = this.state
    const tBodyList_arr = [];
    // _________________________________________________________________________________________________________ 
    for (let i = startRows; i < setData.length; i++) {
      const row_color = i % 2 === 0 ? '#FFF' : Colors.evenRows
      tBodyList_arr.push(
        <TBodyList
          {...this}
          {...this.props}
          key={'tbodyList' + i}
          setData={setData[i]}
          index={i}
          color={row_color}
          onClickDelete={() => this.handleDelRow(i)}
          onClickUp={() => this.handleUpRow(i)}
          onClickDown={() => this.handleDownRow(i)}
          onClickCopy={(children) => this.handleCopyRow(children, i)}
          onClickAdd={() => this.handleAddRow(i)}
        />
      )
      if (maxRows && i + 1 >= maxRows * pageCount) { break; } // （親から）maxRowsで指定した行でループを抜ける
    }
    // ___________________________________________________________________________________________________________

    return (
      <div style={this.tableWrap_style}>
        <Parts.TablePaging
          {...this.props}
          {...this.state}
          onClickPrePageBtn={this.prePaging}
          onClickNextPageBtn={this.nextPaging}
          hidden={!paging}
        >
          <Parts.ChangeRowsBtn
            countView={this.countView}
            allView={this.allView}
            resetView={this.resetView}
          />
          {addBtns}
        </Parts.TablePaging>
        {addTheader}
        {!hideTheader &&
          <Parts.THeader children={children} setData={setData[0]} style={theaderStyle} hidden={hideTheader} headerFixedLength={headerFixedLength} onChangeCheck={this.handleOnAllCheck} />
        }
        {tBodyList_arr}
        <AddRowBtn onClick={this.addRows} hidden={inableAddRow || disabledAll} />
      </div>
    );
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class TBodyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      hasErr: false,
      hover: false,
    }
    this.setData_copy = Object.assign({}, this.props.setData); // 新旧object比較用コピーを作成
  }
  //____________________________________________________________________

  shouldComponentUpdate(nextProps, nextState) {
    const { focus, hover } = nextState;
    const { children, setData, error, disabledAll } = nextProps;

    // カラム設定が1行の場合、配列に挿入しないとループの数が制御できないので、、、
    let children_arr = []
    let oldChildren_arr = [];
    if (Array.isArray(children[0]) === true) {
      children_arr = children;
      oldChildren_arr = this.props.children;
    } else {
      children_arr[0] = children;
      oldChildren_arr[0] = this.props.children;
    }
    for (let i in children_arr) {
      for (let j in children_arr[i]) {
        const { name, disabled } = children_arr[i][j]
        if (this.setData_copy[name] !== setData[name]) {
          this.setData_copy = Object.assign({}, setData);　// 新旧object比較用コピーを更新
          return true
        }
        if (typeof (disabled) !== 'function') {
          if (disabled !== oldChildren_arr[i][j]["disabled"]) {
            console.log("oldChildren_arr[i][j]", disabled, oldChildren_arr[i][j]["disabled"])
            return true
          };
        }
      }
    }
    // console.log(this.props.must, must)
    if (this.setData_copy['itemErrorMsg'] !== setData['itemErrorMsg']) {
      this.setData_copy = Object.assign({}, setData);　// 新旧object比較用コピーを更新
      return true
    }
    if (this.props.error !== error) { return true }
    if (this.props.disabledAll !== disabledAll) { return true }
    if (this.state.focus !== focus) { return true }
    if (this.state.hover !== hover) { return true }
    return false
  }
  //____________________________________________________________________
  // itemErrorMsgがあるかどうか
  checkItemErrMsg = msg => {
    // 空白の配列の時、Falseを返す
    // データのある配列の時、Trueを返す
    return (msg && !Array.isArray(msg) && msg.length > 0) || (msg && typeof (msg) === "string")
  }
  //____________________________________________________________________

  render() {
    const { setData, children, index, color, must, disabledAll, } = this.props;
    const { focus, hover } = this.state;
    const tr_arr = [];
    // const mustKey = must[0];
    let hasErr = false;
    if (setData && setData['itemErrorMsg']) {
      hasErr = this.checkItemErrMsg(setData['itemErrorMsg']);
    }
    const borderStyle = hasErr ? '1px solid' + Colors.errorBoder : null
    const table_style = {
      width: "100%",
      tableLayout: "fixed",
      borderCollapse: "separate",
      // border:
      //   // disabledAll ? null:
      //   hasErr ? '1px solid' + Colors.errorBoder :
      //     null,
      borderBottom: borderStyle,
      borderRight: borderStyle,
      borderLeft: borderStyle,
      borderTop: 'none',
      backgroundColor:
        focus ? Colors.hoverRow :
          color, // 偶数行なら指定してある色 
      transition: "0.1s",
    }

    // カラム設定が1行の場合、配列に挿入しないとループの数が制御できないので、、、
    let children_arr = []
    if (Array.isArray(children[0]) === true) {
      children_arr = children
    } else {
      children_arr[0] = children
    }

    for (let i in children_arr) {
      const td_arr = [];
      // セル生成ループ
      for (let j in children_arr[i]) {
        if (!children_arr[i][j]) continue;
        const { name, hidden } = children_arr[i][j]

        // focusされている行のmustを変色させるための処理 
        let mustResult = false
        if ((focus) && name && must) {
          mustResult = (must.indexOf(name) !== -1) ? true : false
        }
        // エラーが起こっているかどうか
        else if (hasErr) {
          mustResult = (!!must && must.indexOf(name) !== -1 && !setData[name]) ? true : false // 値があればセルの色を変更する
        }
        const setHidden = funcAdapter(hidden, setData) || hidden;
        if (setHidden === true) { continue }
        // （親から）typeによってセル内に配置する要素を設定しています
        td_arr.push(
          <TD
            {...this.props}
            color={focus ? Colors.hoverRow : color}
            key={"cell" + i + j}
            children={children_arr[i][j]}
            value={setData[name]}
            name={name}
            must={mustResult} // trueの場合、セルの色が変わる。
            index={index}
          />
        )
      }

      // 行の作成 _________________________________________
      tr_arr.push(  // 行の作成
        <tr key={"row" + i}>
          {td_arr}
        </tr>
      )
    }
    return (
      <Fragment>
        <Parts.ErrorRow label={setData['itemErrorMsg']} /*hidden={disabledAll}*/ />
        <table
          style={table_style}
          onFocus={() => this.setState({ focus: true })}
          onBlur={() => this.setState({ focus: false })}
          onMouseOver={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
        >
          <tbody>
            {tr_arr}
          </tbody>
        </table>
      </Fragment>
    )
  }

}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class TD extends React.Component {
  // セルをクリックしたとき
  handleOnClick = (e) => {
    const { setData, children, index, name, disabled, disabledAll } = this.props;
    // state省略宣言
    const { onClick } = children;
    if (disabled || disabledAll) { return; }

    onClick && onClick({   // 親に送っています
      data: setData,            // 送られてきた全体のデータ
      rowIndex: index,                // クリックしたセルの行番号
      name: name,
    }, e);
  }
  // ________________________________________________________________________________________________________________
  render() {
    const {
      disabledAll,
      disabledRow,
      children,
      setData,
      cellOnBlur,
      onClickDelete,
      onClickUp,
      onClickDown,
      onClickCopy,
      onClickAdd,
      index,
    } = this.props;

    const {
      type,
      style,
      disabled,
      name,
      value,
      option,
      checkBox = true,
      unit,
      description,
      rowSpan,
      tabIndex
    } = children

    const setDisabled = disabledAll || funcAdapter(disabledRow, { ...setData, 'index': index }) || funcAdapter(disabled, { ...setData, 'index': index }) || funcAdapter(disabled, setData, index)
    let funcValue = funcAdapter(value, setData);
    const setValue = (funcValue || funcValue === 0) ? funcValue : setData[name]
    const setType = funcAdapter(type, setData);

    const tdProps = {
      ...this.props,
      value: setValue,
      disabled: setDisabled,
      type: setType,
      style: funcAdapter(style, setData),
      unit: funcAdapter(unit, setData),
      rowSpan: funcAdapter(rowSpan, setData),
      tabIndex: funcAdapter(tabIndex, setData),
    }
    switch (setType) {
      case ("index"): {                                              // ヘッダー
        return <Parts.IndexCell {...tdProps} inputTable />
      }
      case ("check"): {
        // return <Parts.CheckCell {...this.props} value={setValue} disabled={setDisabled} onChange={(obj) => cellOnBlur(obj)} />
        const setCheckBox = funcAdapter(checkBox, setData);
        return <Parts.CheckCell {...tdProps} checkBox={setCheckBox} onChange={(obj) => cellOnBlur(obj)
        } />
      }
      case ("date"): {// 日付けカレンダー
        return <Parts.CalendarCell {...tdProps} onChange={(obj) => cellOnBlur(obj)} />
      }
      case ("text"): { //　テキストボックス
        return <Parts.InputCell text {...tdProps} onClickModal={(e) => this.handleOnClick(e)} />
      }
      case ("number"): { //　テキストボックス(数値)
        return <Parts.InputCell number {...tdProps} onClickModal={(e) => this.handleOnClick(e)} />
      }
      case ("alphanum"): { //　テキストボックス(数値)
        return <Parts.InputCell alphanum {...tdProps} onClickModal={(e) => this.handleOnClick(e)} />
      }
      case ("textarea"): { //　テキストボックス
        return <Parts.TextAreaCell {...tdProps} />
      }
      case ("decimal"): { //　テキストボックス(数値)
        return <Parts.InputCell decimal {...tdProps} onClickModal={(e) => this.handleOnClick(e)} />
      }
      case ("upperCase"): { //　テキストボックス(数値)
        return <Parts.InputCell upperCase {...tdProps} onClickModal={(e) => this.handleOnClick(e)} />
      }
      // case ("textModal"): {                                                  // モーダル
      //   return <Parts.TextModalCell {...tdProps} onClick={(e) => this.handleOnClick(e)} />
      // }
      case ("select"): {
        //Selectの場合、Option は動的に変更することの対応
        const _option = funcAdapter(option, setData);
        return <Parts.SelectCell option={_option} {...tdProps} onChange={(obj) => cellOnBlur(obj)} />
      }
      case ("radio"): {
        const _option = funcAdapter(option, setData);
        const _description = funcAdapter(description, setData);
        return <Parts.RadioCell {...tdProps} description={_description} option={_option} onChange={(obj) => cellOnBlur(obj)
        } />
      }
      case ('link'): {
        return <Parts.LinkCell {...tdProps} onClick={(e) => this.handleOnClick(e)} />
      }

      case ("switch"): {
        return <Parts.SwitchCell {...tdProps} onChange={(obj) => cellOnBlur(obj)
        } />
      }
      case ("modal"): {                                                  // モーダル
        return <Parts.IconCell {...tdProps} onClick={(e) => this.handleOnClick(e)} />
      }
      case ("icon"): {
        return <Parts.IconCell {...tdProps} onClick={(e) => this.handleOnClick(e)} />
      }
      case ("delete"): { // 行削除ボタン
        return <Parts.FuncIconCell icon='delete' {...tdProps} onClick={children.onClick ? (e) => this.handleOnClick(e) : onClickDelete} />
      }
      case ("up"): {
        return <Parts.FuncIconCell icon='up' {...tdProps} onClick={onClickUp} />
      }
      case ("down"): {
        return <Parts.FuncIconCell icon='down' {...tdProps} onClick={onClickDown} />
      }
      case ("copy"): {
        return <Parts.FuncIconCell icon='copy' {...tdProps} onClick={children.onClick ? (e) => this.handleOnClick(e) : () => onClickCopy(children)} />
      }
      case ("add"): {
        return <Parts.FuncIconCell icon='add' {...tdProps} onClick={onClickAdd} />
      }
      case ("label"): {
        return <Parts.LabelCell {...tdProps} />
      }
      case ("edit"): {
        return <Parts.EditCell {...tdProps} onClick={(e) => this.handleOnClick(e)} />
      }
      default: {
        return <Parts.LabelCell {...tdProps} />
      }
    }
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class AddRowBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    }
    this.wrap_style = {
      height: 40,
      width: "100%",
      border: "none",
      cursor: "pointer",
      transition: "0.2s",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '13px',
      fontWeight: 'bold',
      color: '#FFF',
      backgroundColor: Colors.addRow,
    }
    this.label_style = {
      marginLeft: 8,
    }
  }
  // ________________________________________________________________________________________________________________

  render() {
    const { onClick, hidden } = this.props
    const { hover } = this.state
    if (hidden) { return null }
    Object.assign(this.wrap_style, {
      opacity:
        hover ? 0.7 :
          null,
    })

    return (
      <button
        style={Object.assign({}, this.wrap_style)}
        onClick={onClick}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        <Icons.PlusSquare width={15} height={15} color={'#FFF'} />
        <span style={this.label_style}>行を追加する</span>
      </button>
    )
  }
}