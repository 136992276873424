import React, { Fragment } from 'react';
import * as Parts from './TableComponent';

export default class THeader extends React.Component {
  render() {
    const { children, hidden, style, headerFixedLength, onChangeCheck } = this.props;
    if (hidden) { return null; };
    let td_arr = [];
    let tr_arr = [];                   // 親から渡されたlabelを入れて、表示するための配列
    // カラム設定が1行の場合、配列に挿入しないとループの数が制御できないので、、、
    let children_arr = []
    if (Array.isArray(children[0]) === true) {
      children_arr = children
    } else {
      children_arr[0] = children
    }
    // 初期値用の for ループです。 親からJson形式で渡された中身によって項目を設定しています
    for (let i in children_arr) {
      td_arr = [];
      // <td>テーブルヘッダーを作成ループ
      for (let j in children_arr[i]) {
        if (!children_arr[i][j]) continue;
        const { name } = children_arr[i][j];
        td_arr.push(
          <Parts.TH key={"label" + i + j} children={children_arr[i][j]} style={style} onChangeCheck={(value) => onChangeCheck(value, name)} />
        )
      }
      tr_arr.push(<tr key={"labelTd" + i}>{td_arr}</tr>)
    }
    // _________________________________________________________________________________________________________
    const tHeader_style = {         // <table> テーブルヘッダー
      position: "sticky",
      zIndex: 1,
      width: "100%",
      top: headerFixedLength ? headerFixedLength : 'calc(var(--topMenuHeight) - 1px)',
      tableLayout: "fixed",
      borderCollapse: "separate",
    }
    return (
      <table style={{ ...tHeader_style, ...style, }} >
        <thead>
          {tr_arr}
        </thead>
      </table>
    )
  }
}

