import React from 'react';
import ReactDOM from 'react-dom';
import * as smartUtil from '../../util/commonInput';
import { zindex } from '../Styles';

export default class filterBox extends React.Component {
  constructor(props) {
    super(props);
    this.idName = Math.random().toString(36).substring(7); // idを乱数生成
  }
  handleOnMouseDown = () => {
    this.filterClick = true;
  }
  windowClick = () => {
    if (this.filterClick) {
      this.filterClick = false;
    } else {
      const { unmount } = this.props;
      unmount && unmount()
    }
  }
  //______________________________________________________________________

  shouldComponentUpdate(nextProps) {
    if (nextProps.render) {
      if (!document.getElementById(this.idName)) {
        window.addEventListener('mouseup', this.windowClick)    // windowイベントwindowClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
        const wrapper = document.body.appendChild(document.createElement('div')); // div要素を作成するための変数を宣言
        wrapper.id = this.idName  // そのdiv要素のidに'modal'を設定

        const { target, children, label, style } = nextProps;
        let positionLeft = target.getBoundingClientRect().left + window.pageXOffset;
        let positionBottom = target.getBoundingClientRect().bottom + window.pageYOffset;
        let positionRight = null;
        if (positionLeft + 200 > window.innerWidth) {
          positionLeft -= 200
        }
        const box_style = {
          position: "absolute",
          top: positionBottom,
          left: positionLeft,
          right: positionRight,
          zIndex: zindex.filter,
          display: 'inline-flex',
          flexDirection: 'column',
          boxShadow: '0px 2px 5px #00000080',
          border: '1px solid #D9D9D9',
          borderRadius: 10,
          backgroundColor: "#fff",
          paddingBottom: 8,
        }
        const label_style = {
          // fontWeight: 'Bold',
          // fontSize: '1.1rem',
          letterSpacing: 0.55,
          color: '#838383',
          borderRadius: '10px 10px 0px 0px',
          backgroundColor: "#F2F2F2",
          padding: '8px 12px',
          textAlign: 'center',
        }
        //______________________________________________________________________
        // レンダー
        ReactDOM.render(
          <div style={Object.assign({}, box_style, style)} onMouseDown={this.handleOnMouseDown}>
            <div style={label_style}>{smartUtil.getPMasterName(label)}</div>
            {children}
          </div>
          , wrapper
        );
        //______________________________________________________________________

      }
    } else {
      const filterBox = document.getElementById(this.idName); // 作ったid('modal')のdiv要素を取得
      if (filterBox) {
        ReactDOM.unmountComponentAtNode(filterBox);       // 作ったreactDomを削除
        filterBox.parentNode.removeChild(filterBox);            // 一度親に戻って子供を参照して作ったdiv要素を削除（IE対応）
        window.removeEventListener('mouseup', this.windowClick)    // windowイベントwindowClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
      }
    }
    return true;
  }
  render() {
    return null;
  }
}
// ________________________________________________________________________________________________________________
