import React from 'react';
import { radioList as Colors } from './Colors'
import { getPMasterName, isNull_Undefined_Blank } from '../util/commonInput';

export default class RadioListMulti extends React.Component {
  constructor(props) {
    super(props);
    const { value, setData, disabled } = this.props
    this.state = {
      value: value || [],
      setData: setData || [],
      disabled: disabled || false,
    }
  }
  // ________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const { value, setData, disabled } = this.props
    if (value !== nextProps.value) {
      this.setState({ value: nextProps.value })
    }
    if (setData !== nextProps.setData) {
      this.setState({ setData: nextProps.setData || [] })
    }
    if (disabled !== nextProps.disabled) {
      this.setState({ disabled: nextProps.disabled })
    }
    return true;
  }
  // ________________________________________________________________________________
  handleOnClick = (val) => {
    const { name, onChange } = this.props;
    let value_copy = this.state.value.slice()
    const result = value_copy.indexOf(val)
    if (result === -1) {
      // CheckされたButtonが無効じゃない場合、全ての選択肢を自動解除
      if (!isNull_Undefined_Blank(val)) {
        value_copy = value_copy.filter(item => !isNull_Undefined_Blank(item))
      } else if (val === '') {
        //全選択時　他の選択肢を解除
        value_copy = []
      }
      value_copy.push(val)
    } else {
      value_copy.splice(result, 1)
    }
    this.setState({ value: value_copy })
    onChange && onChange(name, value_copy)
  }
  // ________________________________________________________________________________

  render() {
    // 使用する変数を定義
    const { value, setData, disabled } = this.state
    const list_arr = [];
    // ________________________________________________________________________________
    for (let i in setData) {
      list_arr.push(
        <RadioButton
          key={i}
          selected={value && value.indexOf(setData[i].value) !== -1}
          onClick={() => this.handleOnClick(setData[i].value)}
          disabled={disabled}
          label={setData[i].label}
        />
      )

    }
    // ________________________________________________________________________________

    return (
      <div style={this.props.style}>
        {list_arr}
      </div>
    )
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class RadioButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    }
  }
  // ________________________________________________________________________________________________________________

  render() {
    const { hover } = this.state
    const {
      selected,
      disabled,
      onClick,
      label,
    } = this.props
    let Label = label && getPMasterName(label);
    if (Label === 'NONE') { return null }
    else if (label && !Label) { Label = label } // ブランクは表示しない。
    if (!Label) { return null };

    const button_style = {              // <button> 
      backgroundColor:
        selected ? Colors.btn :
          "transparent",
      color:
        selected ? Colors.font :
          null,
      border:
        hover ? '1px solid' + Colors.btn :
          selected ? '1px solid transparent' :
            '1px solid #dddddd',
      margin: "1px 2px",
      cursor:
        disabled ? null :
          "pointer",
      padding: "1px 4px",
      borderRadius: 20,
      // fontSize: '1.2rem',
      fontSize: 'inherit',
      transition: "0.2s",
      outlineStyle: 'none',
    }

    return (
      <button
        style={button_style}
        onClick={onClick}
        disabled={disabled}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        {Label}
      </button>
    )
  }
}
