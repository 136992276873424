import React, { Fragment } from 'react';

export default class HideableArea extends React.Component {
  constructor(props) {
    super(props);
    const { hidden,  } = this.props;
    this.state = {
      height: null,
      overflow: hidden ? 'hidden' : null,
      visibility: null,
      marginBottom: 16,
    }

    this.processing = false;
    this.renderCount = 1
  }
  // _________________________________________________________________________________________
  componentDidMount() {
    // const { hidden } = this.props;
    // if (hidden) {
    //   this.setState({
    //     height: 0,
    //     overflow: 'hidden',
    //     visibility: 'hidden',
    //     marginBottom: 0,
    //   })
    // }
  }
  // _________________________________________________________________________________________

  //SearchErrがある場合、自動隠ししません
  shouldComponentUpdate(nextProps) {
    // const { hidden } = nextProps;
    // if (hidden !== this.props.hidden || this.renderCount !== 1) {
    //   //　閉じる時
    //   if (hidden) {
    //     if (this.processing) { return true }
    //     switch (this.renderCount) {
    //       // 1回目は高さを図る
    //       case 1: {
    //         this.getHeight = this.inner_ref.getBoundingClientRect().height
    //         this.setState({ height: this.getHeight })
    //         this.renderCount = 2
    //         return true;
    //       }
    //       // 2回目は高さを0にして、overflow:'hidden'をセット
    //       case 2: {
    //         setTimeout(() => {
    //           this.setState({
    //             height: 0,
    //             overflow: 'hidden',
    //             visibility: 'hidden',
    //             marginBottom: 0,
    //           })
    //           this.renderCount = 1;
    //         }, 10)
    //         return true;
    //       }
    //       default: { return true }
    //     }
    //   }
    //   // 開くとき
    //   else {
    //     switch (this.renderCount) {
    //       // 1回目は高さを図る
    //       case 1: {
    //         this.getHeight = this.inner_ref.getBoundingClientRect().height
    //         this.setState({
    //           height: this.getHeight,
    //           visibility: null,
    //           marginBottom: 16,
    //         })
    //         this.renderCount = 2;
    //         return true;
    //       }
    //       // 2回目は開ききった後に overflow:null をセット
    //       case 2: {
    //         this.processing = true;
    //         setTimeout(() => {
    //           this.setState({ overflow: null, })
    //           this.renderCount = 3;
    //           this.processing = false;
    //         }, 200)
    //         return true;
    //       }
    //       // 3回目は高さをリセット
    //       case 3: {
    //         setTimeout(() => {
    //           this.setState({ height: null })
    //           this.renderCount = 1;
    //         }, 10)
    //         return true;
    //       }
    //       default: { return true }
    //     }
    //   }
    // }
    return true;
  }
  // _____________________________________________________________________________________________

  render() {
    const { children, container} = this.props;
    const { height, overflow, visibility, marginBottom } = this.state;
    const wrap_style = {
      transition: "all 0.3s ease-in-out",
      height: height,
      overflow: overflow,
      visibility: visibility,
      marginBottom: marginBottom,
    }
    const inner_style = {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: container ? 'column' :
        null,
    }
    return (
      <div style={wrap_style}>
        <div ref={(el) => this.inner_ref = el} style={inner_style}>
          {children}
        </div>
      </div>
    )
  }

}