/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { getPMasterName } from '../util/commonInput';

export default class Link extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
    }
  }

  render() {
    const {
      style,
      className,
      onClick,
      children,
      label,
      plabel,
      plabel2,
      hidden,
      padding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      margin,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
    } = this.props

    // hidden処理
    let Plabel = plabel && getPMasterName(plabel);
    let Plabel2 = plabel2 && getPMasterName(plabel2);
    if (Plabel === 'NONE' || hidden) { return null }

    const { hover } = this.state

    const link_style = {
      backgroundColor: "transparent",
      border: "none",
      color:
        hover ? "#ccc" :
          "#00a",
      cursor: "pointer",
      textDecoration:
        hover ? "underline solid #ccc" :
          "underline solid #00a",
      padding: padding,
      margin: margin,
    }

    if (!padding) {
      Object.assign(link_style, {
        paddingTop: paddingTop,
        paddingRight: paddingRight,
        paddingBottom: paddingBottom,
        paddingLeft: paddingLeft,
      })
    }
    if (!margin) {
      Object.assign(link_style, {
        marginTop: marginTop,
        marginRight: marginRight,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
      })
    }

    return (
      <a
        tabIndex={0}
        style={!className ? Object.assign({}, link_style, style) : null}
        className={className}
        onClick={onClick}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        {label}{children}
      </a>
    )
  }
}