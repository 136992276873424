// 入力用テーブルに使用しています。変更する際は気を付けてください。

import React, { Fragment } from 'react';
import { convertDate } from '../util/commonInput';
import * as Icons from "./Icons";
import { calendar as Colos } from './Colors'
import { zindex } from './Styles';
import ReactDOM from 'react-dom';

export default class CalendarItem extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = null;
    this.box_style = {};
    this.idName = Math.random().toString(36).substring(7); // idを乱数生成
  }
  //______________________________________________________________________
  handleOnMouseDown = () => {
    this.filterClick = true;
    // 親のOnblurを制御するため
    this.props.updateIsPopUpClick && this.props.updateIsPopUpClick(true)
  }
  //______________________________________________________________________
  closeCalendar = () => {
    if (this.filterClick) {
      this.filterClick = false;
    } else {
      const { unmount } = this.props;
      unmount && unmount()
    }
  }
  //______________________________________________________________________
  componentDidMount() {
    const { onRef } = this.props
    if (onRef) {
      onRef(this)
    }
  }
  //______________________________________________________________________
  componentWillUnmount() {
    this.disposePopUp();
  }
  //______________________________________________________________________
  disposePopUp = () => {
    const filterBox = document.getElementById(this.idName); // 作ったid('modal')のdiv要素を取得
    if (filterBox) {
      ReactDOM.unmountComponentAtNode(filterBox);       // 作ったreactDomを削除
      filterBox.parentNode.removeChild(filterBox);            // 一度親に戻って子供を参照して作ったdiv要素を削除（IE対応）
      window.removeEventListener('mouseup', this.closeCalendar)    // windowイベントcloseCalendar関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
    }
  }
  //______________________________________________________________________
  shouldComponentUpdate(nextProps) {
    if (nextProps.render) {
      if (!document.getElementById(this.idName)) { // DOMが表示されていなければ
        window.addEventListener('mouseup', this.closeCalendar)    // windowイベントcloseCalendar関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
        this.wrapper = document.body.appendChild(document.createElement('div')); // div要素を作成するための変数を宣言
        this.wrapper.id = this.idName  // そのdiv要素のidに'modal'を設定

        const { target } = nextProps;
        const targetPost = target.getBoundingClientRect()

        let itemPost_left = null;
        let itemPost_right = null;
        let itemPost_top = null;
        let itemPost_bottom = null;

        if (targetPost.left + 250 > window.innerWidth) {
          itemPost_right = window.innerWidth - targetPost.right - window.pageXOffset - 15;
        } else {
          itemPost_left = targetPost.left + window.pageXOffset;
        }
        if (targetPost.top + 250 > window.innerHeight) {
          itemPost_bottom = window.innerHeight - targetPost.top - window.pageYOffset;
        } else {
          itemPost_top = targetPost.bottom + window.pageYOffset;
        }
        this.box_style = {
          position: "absolute",
          top: itemPost_top,
          left: itemPost_left,
          bottom: itemPost_bottom,
          right: itemPost_right,
          zIndex: zindex.calendar,
        }
      }
      const { onChange, value } = nextProps;
      //______________________________________________________________________
      // レンダー
      ReactDOM.render(
        <div style={this.box_style} onMouseDown={this.handleOnMouseDown}>
          <CalendarComponent onChange={onChange} value={value} onClickClose={this.closeCalendar} />
        </div>
        , this.wrapper
      );
      //______________________________________________________________________
    } else {
      this.disposePopUp();
    }
    return true;
  }
  render() {
    return null;
  }
}
// ________________________________________________________________________________________________________________

class CalendarComponent extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    let newDate = new Date()
    if (value && new Date(value) !== 'Invalid Date') {
      newDate = new Date(value)
    }
    this.state = {
      date: newDate.getDate(),         // 今日の日付 
      month: newDate.getMonth() + 1,   // 表示中の月
      year: newDate.getFullYear(),     // 表示中の年
      breakPoint: 6,                      // 行の最後にくる曜日。（折り返し地点）
      selectedDate: null,                 // 選択されている日付け
      cursorMonth: null,                   // キーダウンでカーソル中の日付
      value: value || "",      // 最終的に送り出す値　YYYY/MM/DD 形式
    };
    this.today = convertDate({ type:'YYYY/MM', date: new Date() }) // 今日の日付を定義
    this.wrap_style = {                   // <div>：カレンダーの親
      display: "inline-flex",
      flexDirection: "column",
      zIndex: 999,
      overflow: 'visible',
      backgroundColor: "#fff",
      boxShadow: '0px 2px 5px rgba(0,0,0,0.5)',
      border: '1px solid #D9D9D9',
      borderRadius: 10,
    }

    this.header_style = {                  // <div>：カレンダーのヘッダー
      display: "flex",
      alignItems: "center",
      borderRadius: "10px 10px 0px 0px",
      backgroundColor: '#F2F2F2',
      justifyContent: 'space-around',
      // paddingRight: 16,
    }
    this.changeMonth_style = {                       // <button>：月を切り替えるボタン
      padding: 4,
      cursor: "pointer",
    }
    this.title_style = {                // <button>：カレンダーのタイトル（年月）
      padding: 8,
      backgroundColor: "transparent",
      border: "none",
      fontSize: 12,
      whiteSpace: "nowrap",
      color: '#838383',
      fontWeight: 'bold',
    }
    this.c_style = {
      color: '#FFFFFF',
      borderRadius: 5,
      backgroundColor: Colos.items,
      border: 'none',
      cursor: "pointer",
      fontWeight: 'bold',
      fontSize: 11,
      height: 20,
      width: 20,
    }
    this.weekWrap_style = {                      // <div>：カレンダーの曜日が並ぶ列
      display: "flex",
      alignItems: "center",
      backgroundColor: '#F2F2F2',
      borderBottom: '1px solid #D9D9D9',
      paddingLeft: 8,
    }
    this.datesWrap_style = {
      padding: '8px 0px 8px 8px'
    }
  }
  // _______________________________________________________________________________________________________________________________
  componentDidMount() {
    window.addEventListener('keydown', this.moveTheCursor)
  }
  // _______________________________________________________________________________________________________________________________
  componentWillUnmount() {
    window.removeEventListener('keydown', this.moveTheCursor)    // windowイベントmoveTheCursor関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
  }
  // _______________________________________________________________________________________________________________________________
  shouldComponentUpdate(nextProps) {
    const { value } = this.props;
    if (value !== nextProps.value) {
      this.setState({ value: nextProps.value })
    }
    return true;
  }
  // _____________________________________________________________________________________________________________
  // 表示している年を前年へ
  toPreYear = () => {
    const { year} = this.state
    this.setState({ year: year - 1 }) // 表示年を－１
  }
  // _____________________________________________________________________________________________________________
  // 表示している月を次年へ
  toNextYear = () => {
    const { year　} = this.state
    this.setState({ year: year + 1 }) // 表示年を＋１
  }
  // _____________________________________________________________________________________________________________
  // 日付けカーソルを動かす処理 onKeydown
  moveTheCursor = (e) => {
    const { onChange } = this.props;
    const { year, cursorMonth, value, } = this.state // stateの省略宣言

    let nowMonth = null;                                           // 現在カーソルされている日付け

    if (cursorMonth) { nowMonth = new Date(cursorMonth).getMonth(); }     // 既にカーソルの当たっている日付けがあれば、その日を取得 
    else if (value) { nowMonth = new Date(value).getMonth(); }    // 日付けが選択されていれば、その日を取得
    else if (this.today) { nowMonth = new Date(this.today).getMonth(); }    // 今日の日付の年、月抜きで取得

    switch (e.keyCode) {
      case 37: {  // 左
        nowMonth -= 1;
        break;
      }
      case 38: {　// 上
        nowMonth -= 3;
        break;
      }
      case 39: {　// 右
        nowMonth += 1;
        break;
      }
      case 40: {　// 下
        nowMonth += 3;
        break;
      }
      case 13: case 32: { // Enterキー, スペースキー 
        if (!cursorMonth) { return }     // カーソルされている日付がなければreturn
        e.preventDefault()
        const getMonth = convertDate({ type:'YYYY/MM', y: year, m: nowMonth, d: 1 }); // キーダウン後の日付けを保持する
        this.setState({ value: getMonth })   // カーソルされている日付けがあれば、それを選択する。
        onChange && onChange(getMonth); // redux対応
        return;
      }
      default: { break; }
    }
    const nextMonth = convertDate({ type:'YYYY/MM', y: year, m: nowMonth, d: 1 }); // キーダウン後の日付けを保持する
    this.setState({ cursorMonth: nextMonth });// cursorDateにセットすることでビューに反映させる
    if(nowMonth < 0){  this.setState({year: year - 1})
    }else if(nowMonth > 11){  this.setState({year: year + 1})}
  }
  // ________________________________________________________________________________________________________________
  // 曜日ボタンを押した時
  sortDate = (e) => {
    if (e.target.value <= 0) {                                                  // もし曜日ナンバーが０以下（日曜日）だったら
      this.setState({ breakPoint: 6 })                                       // 曜日ナンバー６（土曜日）をブレイクポイント（週末）にセット
    } else {                                                                    // それ以外なら
      this.setState({ breakPoint: Number(e.target.value) - 1 })               // 順当に前の日付をブレイクポイントにセット
    }
  }
  // ________________________________________________________________________________________________________________
  // 日付け<button>をクリックしたとき
  handleOnClickMonth = (val) => {
    const { onChange } = this.props;
    this.setState({ value: val });              // <button>のvalueに設定された日付けを取得（ただし、現時点ではstring型）    
    onChange && onChange(val); // redux対応
  }
  // ________________________________________________________________________________________________________________
  resetValue = () => {
    const { onChange } = this.props;
    this.setState({ value: '' });              // <button>のvalueに設定された日付けを取得（ただし、現時点ではstring型）    
    onChange && onChange(''); // redux対応
  }
  // ________________________________________________________________________________________________________________
  render() {
    const { lang, onClickClose } = this.props
    const { year, value, cursorMonth } = this.state
    // _________________________________________________________________________________________________________________
    // マルチ言語化（曜日、年月タイトル）

    let title = null;                           // 年月タイトルを表示するための変数
    let monthsLang = [];

    switch (lang) {
      case "en":{
        monthsLang = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "June", "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."]
        title = year;                // 例：Aug.2019
        break;
      }
      case "ja": default:{
        monthsLang = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"];
        title = year + "年" ;    // 例：2019年8月
        break;
      }
    }
    // ________________________________________________________________________________________________________________

    const months_arr = []
    for(let i = 0; i < 12; i++){
      const createdMonth = convertDate({ type:'YYYY/MM', y: year, m: i, d: 1 })
      months_arr.push(
        <MonthBtn 
          key={'month'+ i}
          label={monthsLang[i]}
          selected={createdMonth === value}
          focused={createdMonth === cursorMonth}
          today={createdMonth === this.today}
          onClick={()=>this.handleOnClickMonth(createdMonth)}
        />
      )

      if((i + 1) % 3 === 0){months_arr.push(<br key={'br'+i}/>)}
    }
    return (
      <div style={this.wrap_style}>
        <div style={this.header_style}>
          <Icons.AngleDoubleLeft style={this.changeMonth_style} onClick={this.toPreYear} width={14} height={14} color={Colos.items} />
          <div style={this.title_style}>
            {title}    {/* 年月タイトル */}
          </div>
          <Icons.AngleDoubleRight style={this.changeMonth_style} onClick={this.toNextYear} width={14} height={14} color={Colos.items} />
          <button style={this.c_style} onClick={this.resetValue}>C</button>
          <Icons.TimesCircle onClick={onClickClose} width={20} height={20} color={Colos.items} />
        </div>
        <div style={this.datesWrap_style}>
          {months_arr}
        </div>
      </div>
    )
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
class MonthBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    }
  }
  // ________________________________________________________________________________________________________________

  render() {
    const { hover } = this.state
    const {
      selected,
      value,
      onClick,
      onKeyDown,
      label,
      style,
      focused,
      today,
    } = this.props

    const button_style = {              // <button> リストアイテム
      fontSize: 11,
      outline: "none",
      height: 32,
      width: 32,
      margin:'0px 12px',
      border: "none",
      cursor: "pointer",
      transition: "0.2s",
      borderRadius: '50%',
      color:
              '#838383',
      backgroundColor:
        focused ? "#D3EAD7" : //フォーカス中の日付け
          hover ? Colos.hover : //オンマウス中の日付け
            selected ? "#D9D9D9" : //選択中の日付け
              today ? "#FFF050" : //今日の日付け
                "transparent",
      fontWeight: "bold",
    }

    return (
      <button
        style={Object.assign({}, button_style, style)}
        value={value}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        {label}
      </button>
    )
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

