import React, { Fragment } from 'react';
import * as Icons from "../Icons";
import * as Parts from './TableComponent';
import { table as Colors } from '../Colors'
import PMaster from '../PMaster';
import { getComponentLabelName } from '../../util/commonInput'

export default class TH extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      filterLabel: null,
      showFilter: false,
    }
    this.td_style = {                // <td> 各セル
      padding: '8px 4px',
      border: "1px solid" + Colors.th,
      // fontWeight: "bold",
      // fontSize: '1.1rem',
      // transition: "0.3s",
      textAlign: "center",
      verticalAlign: "middle",
      wordWrap: "break-word",
    }
    this.sortArea_style = {
      paddingTop: 8,
      display: 'inline-flex',
      flexDirection: 'column',
    }
    this.filterBox_style = {
      width: 200,
      minWidth: 200,
      maxWidth: 200,
    }
  }
  // ___________________________________________________________________
  handleOnHover = (e) => {
    const BOTTOM = e.target.getBoundingClientRect().bottom;
    const LEFT = e.target.getBoundingClientRect().left + 12;
    this.setState({
      hover: true,
      x: LEFT,
      y: BOTTOM,
    })
  }
  // ___________________________________________________________________
  orderByAsc = () => {
    const { orderByAsc } = this.props;
    orderByAsc && orderByAsc()
    this.setState({ showFilter: false })
  }
  // ___________________________________________________________________
  orderByDesc = () => {
    const { orderByDesc } = this.props;
    orderByDesc && orderByDesc()
    this.setState({ showFilter: false })
  }
  // ___________________________________________________________________
  render() {
    const {
      children,
      filterable,
      filterOnChange,
      checked,
      firstIndex,
      onChangeCheck,
      style,
      fixedWidth,
      filterList,
      filterOnResult,
      inUse
    } = this.props;
    const { showFilter, hover } = this.state;
    let {
      colSpan,
      rowSpan,
      allCheck,
      type,
      width,
      minWidth,
      minHeight = 25,
      label,
      headerRowSpan,
      hidden,
      header = {},
      component,
      noFilter
    } = children;
    // console.log(this.props)
    if (hidden || header?.hidden) { return null }
    if (rowSpan === 'max' || headerRowSpan === 'max') { rowSpan = 1000 }
    switch (type) {
      case 'add':
      case 'delete':
      case 'up':
      case 'down':
      case 'copy': {
        rowSpan = rowSpan || 1000;
        width = 30;
        minWidth = 30;
        break
      }
      case 'index': {
        rowSpan = rowSpan || 1000;
        width = !firstIndex ? 30 :
          (String(firstIndex + 1).length + 1) * 10 < 30 ? 30 :
            (String(firstIndex + 1).length + 1) * 10
        minWidth = 30;
        // colSpan = ;
        break;
      }
      case 'icon': {
        rowSpan = rowSpan || 1000;
        width = width || 30;
        minWidth = minWidth || 30;
        break
      }
      default: {
        break
      }
    }
    // console.log(firstIndex, '：', (String(firstIndex + 1).length + 1) * 10);
    width = header.hasOwnProperty("width") ? header.width : width;
    colSpan = header.hasOwnProperty("colSpan") ? header.colSpan : colSpan;
    Object.assign(this.td_style, {
      backgroundColor:
        header.backgroundColor || (hover ? "#Cf8080" : "#F5F5F5"),
      width: width,
      minWidth: minWidth,
      minHeight: minHeight,
      position:
        fixedWidth || fixedWidth === 0 ? 'sticky' :
          null,
      left: fixedWidth,
    })
    // ___________________________________________________________________
    return (
      <td
        style={Object.assign({}, this.td_style, style, children.style)}
        colSpan={colSpan}
        rowSpan={rowSpan}
        ref={(el) => this.td_ref = el}
        onClick={() => this.setState({ showFilter: true })}
      // tabIndex={0}
      // onMouseOver={this.handleOnHover}
      // onMouseLeave={() => this.setState({ hover: false })}
      // onFocus={this.handleOnHover}
      // onBlur={() => this.setState({ hover: false })}
      >
        <CheckBox value={checked} render={allCheck} onChange={onChangeCheck} />
        <PMaster name={label || ""} />
        {component}
        {label !== "" && (!noFilter) &&
          <Fragment>
            <Icons.Sort size={12} hidden={type === "check" || inUse} color='#888' style={{ marginLeft: 2 }} />
            <Icons.Filter size={12} hidden={!inUse} color={Colors.basic} style={{ marginLeft: 2 }} />
            <Parts.FilterBox label={label} unmount={() => this.setState({ showFilter: false })} render={showFilter} target={this.td_ref} style={this.filterBox_style}>
              {/* <Parts.FilterInput onChange={filterOnChange} /> */}
              <div style={this.sortArea_style}>
                <Parts.FilterBtn label={getComponentLabelName('comm.asc')} color='#D3EAD7' onClick={this.orderByAsc} />
                <Parts.FilterBtn label={getComponentLabelName('comm.desc')} color='#D3EAD7' onClick={this.orderByDesc} />
              </div>
              {filterable &&
                <Parts.TableFilter setList={filterList} onResult={filterOnResult} onClose={() => this.setState({ showFilter: false })} />
              }
            </Parts.FilterBox>
          </Fragment>
        }
      </td>
    )
  }
}
// __________________________________________________________________________________________________________
class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || "",//true:'1' false:''
    }
    this.checkWrap_style = {
      height: 16,
      width: 16,
      border: '1px solid #707070',
      backgroundColor: '#FFFFFF',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 4,
      padding: 0,
    }
  }
  // _______________________________________________________________________________
  shouldComponentUpdate(nextProps) {
    const { value } = this.props
    if (nextProps.value !== value) {
      this.setState({ value: nextProps.value })
    }
    return true;
  }
  // _______________________________________________________________________________
  handleOnChange = (e) => {
    const { onChange } = this.props;
    e.stopPropagation()
    let inputValue = (this.state.value != '1') ? '1' : '';  // チェックボックスの値が 1 だったなら''にする
    this.setState({ value: inputValue })
    onChange && onChange(inputValue);
  }
  // _______________________________________________________________________________
  render() {
    const { value } = this.state
    const { render } = this.props;
    if (!render) { return null }
    return (
      <button style={this.checkWrap_style} onClick={this.handleOnChange} >
        <Icons.Check1 width={16} height={16} color={Colors.check} hidden={value != '1'} />
      </button>
    );
  }
}