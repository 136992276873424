export default {
  // Message
  "msg-title_JA": "SMARTシステムメッセージ",
  "msg-title_ZH": "Smart系统提示信息",
  "msg-title_EN": "System Message",
  "msg-alert_JA": "エラーが発生しました",
  "msg-alert_ZH": "处理失败",
  "msg-alert_EN": "Error",

  //Notification
  "submit-succ_JA": "データ登録成功しました",
  "submit-succ_ZH": "数据提交成功",
  "submit-succ_EN": "Submit Success",
  "submit-fail_JA": "データ登録失敗しました",
  "submit-fail_ZH": "数据提交失败",
  "submit-fail_EN": "Submit Failed",
  "ERR_404_JA": "サーバー側の資源が見つかりませんでした。システム担当者に連絡してください。",
  "ERR_404_ZH": "未找到指定资源，请联系系统负责人。",
  "ERR_404_EN": "Resource is not found,please contact your system administrator. ",
  "ERR_405_JA": "リクエストの条件が満たされませんでした、データをもう一度確認してください。",
  "ERR_405_ZH": "不满足请求条件，请再次检查提交的数据。",
  "ERR_405_EN": "Request has been rejected by lack of parameter,please check again and kindly resubmit.",

  //sch
  "alert-msg-cant-move_JA": "移動できません",
  "alert-msg-cant-move_ZH": "无法移动",
  "alert-msg-cant-move_EN": "This object is unmoveable",

  //file uploader
  "upload-hint_JA": "ここに登録するファイルをドロップしてアップロードすることができます",
  "upload-hint_ZH": "可以将文件拖入此处",
  "upload-hint_EN": "You can drag your file here",
  "upload-choose_JA": "ファイルを選択する",
  "upload-choose_ZH": "选择文件",
  "upload-choose_EN": "choose your file",
  "upload-init_JA": "ファイル名【{0}】の取り込みを開始します。",
  "upload-init_ZH": "文件【{0}】导入开始。",
  "upload-init_EN": "Uploading File【{0}】。",
  "upload-fin_JA": "アップロードが完了しました",
  "upload-fin_ZH": "导入完毕",
  "upload-fin_EN": "Upload Finished",

  // mail
  "mail-confirm_JA": "メールを送信しますか？",
  "mail-confirm_ZH": "确认发送邮件？",
  "mail-confirm_EN": "Send mail?",
  // top
  "comp_info_JA": "社内連絡",
  "comp_info_ZH": "揭示板",
  "comp_info_EN": "Company Info",
  // mymenu
  "my-menu_JA": "マイメニュー",
  "my-menu_ZH": "我的菜单",
  "my-menu_EN": "My Menu",
  "delete-my-menu_JA": "マイメニューを削除しますか？",
  "delete-my-menu_ZH": "确认删除此链接吗？",
  "delete-my-menu_EN": "delete this link?",
  "my-menu-placehold_JA": "メニューをここにドロップして保存できます",
  "my-menu-placehold_ZH": "可以拖动菜单按钮进行保存",
  "my-menu-placehold_EN": "You can drag and drop favorite menu here",


  //comm
  "comm.download_JA": "ダウンロード",
  "comm.download_ZH": "下载",
  "comm.download_EN": "download",
  "comm.print_JA": "印刷",
  "comm.print_ZH": "打印",
  "comm.print_EN": "print",
  "comm.top_JA": "トップ",
  "comm.top_ZH": "首页",
  "comm.top_EN": "TOP",
  "comm.logout_JA": "ログアウト",
  "comm.logout_ZH": "退出",
  "comm.logout_EN": "Logout",
  "comm.chgpsw_JA": "パス変更",
  "comm.chgpsw_ZH": "修改密码",
  "comm.chgpsw_EN": "Change Password",
  "comm.system_JA": "管理システム",
  "comm.system_ZH": "管理系统",
  "comm.system_EN": "Management System",
  "comm.session-out_JA": "セッションタイムアウトになりました。",
  "comm.session-out_ZH": "页面已经过期",
  "comm.session-out_EN": "Session out",
  "comm.login_JA": "再度ログインしてください。",
  "comm.login_ZH": "请重新登录。",
  "comm.login_EN": "Login again please.",
  "comm.yes_JA": "はい",
  "comm.yes_ZH": "是",
  "comm.yes_EN": "Yes",
  "comm.no_JA": "いいえ",
  "comm.no_ZH": "否",
  "comm.no_EN": "No",
  "comm.ok_JA": "OK",
  "comm.ok_ZH": "OK",
  "comm.ok_EN": "OK",
  "comm.cancel_JA": "キャンセル",
  "comm.cancel_ZH": "取消",
  "comm.cancel_EN": "Cancel",
  "comm.asc_JA": "A→Zで並び替え",
  "comm.asc_ZH": "A→Z升序",
  "comm.asc_EN": "A→Z",
  "comm.desc_JA": "Z→Aで並び替え",
  "comm.desc_ZH": "Z→A降序",
  "comm.desc_EN": "Z→A",
  "comm.init_JA": "初期状態に戻す",
  "comm.init_ZH": "恢复初始状态",
  "comm.init_EN": "Init",
  "comm.allView_JA": "全件を表示する",
  "comm.allView_ZH": "全部显示",
  "comm.allView_EN": "All View",
  "comm.paging_JA": "表示件数を変更する",
  "comm.paging_ZH": "更改显示条数",
  "comm.paging_EN": "Change List Cout",
  "comm.inputChanged_JA": "入力した項目があるので、本当に戻りますか",
  "comm.inputChanged_ZH": "页面有内容已经变动，确认要返回吗?",
  "comm.inputChanged_EN": "Form has changed.Sure to back?",
  "comm.inputMust_JA": "必須項目を入力してください",
  "comm.inputMust_ZH": "请输入必填项目",
  "comm.inputMust_EN": "Please enter a required item",
  "comm.delete_JA": "削除してもいいですか",
  "comm.delete_ZH": "是否确认删除?",
  "comm.delete_EN": "Confirm to delete?",
  "comm.changeSize_JA": "サイズ変更",
  "comm.changeSize_ZH": "大小变更",
  "comm.changeSize_EN": "Change size",
  "comm.checkAll_JA": "全てにチェック",
  "comm.checkAll_ZH": "全选",
  "comm.checkAll_EN": "Check All",

}