import React from 'react';
import * as Icons from "../Icons";

export default class CopyCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
    this.td_style = {                // <td> 各セル
      width: 30,
      minWidth: 30,
      border: "1px solid #ddd",
      textAlign: "center",
      verticalAlign: "middle",
      paddingTop: 4,
      transition: '0.2s',
    }
  }
  render() {
    const { onClick, disabled, color, fixedWidth } = this.props;
    const { hover } = this.state;
    Object.assign(this.td_style, {
      backgroundColor:
        disabled ? color : hover ? "#Cf8080" :
          color,
      position:
        fixedWidth || fixedWidth == 0 ? 'sticky' :
          null,
      pointerEvents:
        disabled ? "none" :
          "auto",
      cursor:
        disabled ? null : 'pointer',
      left: fixedWidth,
    })
    const iconColor = hover ? "#FFFFFF" : "#Cf8080";
    return (
      <td
        style={Object.assign({}, this.td_style)}
        rowSpan={1000}
        onClick={onClick}
        tabIndex={0}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        <Icons.Copy width={16} height={16} color={iconColor} hidden={disabled} />
      </td>     // type指定したもの（コンポーネントなど）
    )
  }
}
