import React, { Fragment, Component } from 'react';
import { Route, Switch, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router'　//Logout用
import TopPage from './TopPage';
import { logOut } from '../../comRedux/Actions';
import { axiosPost, server } from '../../comRedux/axiosPost';
import { push } from 'connected-react-router';　//Logout用
import { delCookie } from '../../util/cookie';
import componentLoader from '../../util/componentLoader';
import { history } from '../../comRedux/createStore'
import * as SKY from "../../component/SmartComponent";

import FacilityInfo from 'pgm/pop/FacilityInfo';
import PswdInput from 'pgm/pop/PswdInput';

//Menu機能をImportするFunction（非同期）
const lazyLoader = (importComponent) => (
  class AsyncComponent extends Component {
    state = { C: null }
    async componentDidMount() {
      const { default: C } = await importComponent();
      this.setState({ C });
    }
    //アラート退避
    componentWillUnmount = () => {
      this.setState = (state, callback) => {
        return;
      };
    }
    render() {
      const { C } = this.state;
      return C ? <C {...this.props} /> : null;
    }
  }
);


class RouterContainer extends Component {
  componentDidMount() {
    // ログイン後の初期値の設定
    this.props.dispatch(replace('/qa/qa00'))
    // this.props.dispatch(replace('/mm/mm10')) // test
    this.menu_ref.toggleMenu(false); // サイドメニューを閉じる
  }

  handleLogOut = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Logoutの確認を取消
    // const result = await SKY.confirm({ message: "LogOutしますか?" });
    // if (result) {
    //backendでSessionClearのRequestを投げます
    axiosPost.get(server + '/session/remove').then(res => { console.log(res.data) }).catch(err => { console.log(err) });
    delCookie('sSessionId');
    delCookie('compCode');
    this.props.dispatch(push('/', { showPrompt: false }))
    this.props.dispatch(logOut());
    // }
  }

  handleMenuSwitch = (url) => {
    this.props.dispatch(push(url));
  }
  // ___________________________________________________________________________________________________________
  openFacilityInfo = () => {
    SKY.openModal(<FacilityInfo />)
  }
  // ___________________________________________________________________________________________________________
  openPswdInput = () => {
    SKY.openModal(<PswdInput onClickBack={() => SKY.closeModal()} />)
  }
  // ___________________________________________________________________________________________________________
  // 大分類メニューを選んだ時、メニューが1つしかなければ、そのメニューを自動で選択します。
  handleClickHeadMenu = (menuData) => {
    const { menuList } = menuData
    if (menuList.length === 1) {
      this.props.dispatch(replace(menuList[0]['link']))
      this.menu_ref.toggleMenu(false); // サイドメニューを閉じる
    } else {
      this.menu_ref.toggleMenu(true); // サイドメニューを開く
    }
  }
  // ___________________________________________________________________________________________________________


  render() {
    return (
      <TopPage
        setData={this.props.menuList}
        userId={this.props.loginProps["userId"]}
        userName={this.props.loginProps["name"]}
        pathName={this.props.location.pathname}
        logoutEvent={this.handleLogOut}
        onClickHeadMenu={this.handleClickHeadMenu}
        handleMenuSwitch={this.handleMenuSwitch}
        onClickFacInfo={this.openFacilityInfo}
        onClickChgPswd={this.openPswdInput}
        ref={el => this.menu_ref = el}
      >

        <Switch>
          <Route exact path="/" component={lazyLoader(() => import('../qa/qa00/index'))} />
          <Route path='/blank' component={lazyLoader(() => import('./Blank'))} />
          <Route path='(.*)/error' component={lazyLoader(() => import('../../component/Error'))} />
          <Route path='/sessionError' component={lazyLoader(() => import('../../component/SessionError'))} />

          <Route path='/mm/mm01' component={lazyLoader(() => import('../mm/mm01/index'))} />
          <Route path='/mm/mm02' component={lazyLoader(() => import('../mm/mm02/index'))} />
          <Route path='/mm/mm03' component={lazyLoader(() => import('../mm/mm03/index'))} />
          <Route path='/mm/mm04' component={lazyLoader(() => import('../mm/mm04/index'))} />
          <Route path='/mm/mm05' component={lazyLoader(() => import('../mm/mm05/index'))} />
          <Route path='/mm/mm06' component={lazyLoader(() => import('../mm/mm06/index'))} />
          <Route path='/mm/mm07' component={lazyLoader(() => import('../mm/mm07/index'))} />
          <Route path='/mm/mm08' component={lazyLoader(() => import('../mm/mm08/index'))} />
          <Route path='/mm/mm09' component={lazyLoader(() => import('../mm/mm09/index'))} />
          <Route path='/mm/mm10' component={lazyLoader(() => import('../mm/mm10/index'))} />

          <Route path='/sy/sy01' component={lazyLoader(() => import('../sy/sy01/index'))} />
          <Route path='/sy/sy02' component={lazyLoader(() => import('../sy/sy02/index'))} />
          <Route path='/sy/sy03' component={lazyLoader(() => import('../sy/sy03/index'))} />
          <Route path='/sy/sy04' component={lazyLoader(() => import('../sy/sy04/index'))} />
          <Route path='/sy/sy05' component={lazyLoader(() => import('../sy/sy05/index'))} />
          <Route path='/sy/sy06' component={lazyLoader(() => import('../sy/sy06/index'))} />
          <Route path='/sy/sy09' component={lazyLoader(() => import('../sy/sy09/index'))} />
          <Route path='/sy/sy99' component={lazyLoader(() => import('../sy/sy99/index'))} />

          <Route path='/an/an01' component={lazyLoader(() => import('../an/an01/index'))} />
          <Route path='/an/an02' component={lazyLoader(() => import('../an/an02/index'))} />
          <Route path='/an/an03' component={lazyLoader(() => import('../an/an03/index'))} />
          <Route path='/an/an04' component={lazyLoader(() => import('../an/an04/index'))} />
          <Route path='/an/an05' component={lazyLoader(() => import('../an/an05/index'))} />

          <Route path='/qa/qa00' component={lazyLoader(() => import('../qa/qa00/index'))} />
          <Route path='/qa/qa01' component={lazyLoader(() => import('../qa/qa01/index'))} />
          <Route path='/qa/qa02' component={lazyLoader(() => import('../qa/qa02/index'))} />
          <Route path='/qa/qa04' component={lazyLoader(() => import('../qa/qa04/index'))} />
          <Route path='/qa/qa05' component={lazyLoader(() => import('../qa/qa05/index'))} />

          <Route path='/ma/ma01' component={lazyLoader(() => import('../ma/ma01/index'))} />
          <Route path='/ma/ma04' component={lazyLoader(() => import('../ma/ma04/index'))} />

          <Route path='/fx/fx01' component={lazyLoader(() => import('../fx/fx01/index'))} />

        </Switch>
      </TopPage>
    )
  }
}
const mapStateToProps = (state, ownPropS) => {
  return {
    //stateの範囲を縮めるとRender回数は減ります
    ...state.router,
    loginProps: state.COMMON.Login
  }
}
export default connect(mapStateToProps)(RouterContainer);