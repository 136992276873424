import React from 'react';
import { formatNumber } from '../../util/commonInput';
import { table as Colors } from '../Colors';

export default class IndexCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
  }
  // _________________________________________________________________________

  render() {
    const { children, value, color, onClick, fixedWidth, style } = this.props;
    const {
      rowSpan,
      colSpan,
      format,
      width,
      minWidth,
      align,
      fixed,
    } = children
    let setValue = format ? formatNumber(value, format) : value
    const td_style = {
      border: "1px solid" + Colors.border,
      // fontSize: '1.1rem',
      height: 25,
      padding: '0px 4px',
      verticalAlign: "middle",
      width: width,
      minWidth: minWidth,
      textAlign: align || "center",
      backgroundColor: color,
      position:
        fixedWidth || fixedWidth === 0 ? 'sticky' :
          null,
      left: fixedWidth,

    }
    const link_style = {
      color: '#3168B2',
      textDecoration: 'underline',
      cursor:
        setValue ? "pointer" :
          null,
    }
    const text_style = {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    // _________________________________________________________________________

    return (
      <td
        style={Object.assign({}, td_style, style)}
        rowSpan={rowSpan === 'max' ? 1000 : rowSpan}
        colSpan={colSpan}
      >
        <a style={link_style} onClick={onClick}>
          <pre style={text_style}>{setValue}</pre>
        </a>
      </td>
    )
  }
}
