import React from 'react';
import * as Icons from "./Icons";
import Button from './Button';
import Circle from 'react-circle';
import { axiosPost, server } from '../comRedux/axiosPost';
import { getExcel } from '../util/outputUtil'

export default class FileUploaderMulti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      checkProgress: 0,
      uploadProgress: 0,
      errorMsg: null,
      showCheck: false,
      showUpload: false,
      showError:false,
      checkfinished:false,
      uploadfinished:false,
    }
    this.wrap_style = {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding:24,
    }
  }
  //__________________________________________________________________________________________
  // プログレスタイマー
  progressTimer = (type) => {
    if(type === 'check') {
      this.setState({showCheck: true})
    }else if(type === 'upload'){
      this.setState({showUpload: true,})
    }

    const interval = setInterval(() => {
      axiosPost.post(server + '/progress/getProcessing').then((res) => {
        if (res) {
          const { errorMsg, percentage, status, } = res.data
          console.log(status.name,res)
          switch (status.name) {
            case 'running': {
              if(type === 'check'){
                this.setState({checkProgress: percentage,})
              }else if(type === 'upload'){
                this.setState({ uploadProgress: percentage,})
              }
              break;
            }
            case 'checkfinished': {
              this.setState({ 
                checkProgress: 1,
                checkfinished: true,
              })
              clearInterval(interval);
              break;
            }
            case 'finished': {
              this.setState({ 
                uploadProgress: 1,
                uploadfinished: true,
              })
              clearInterval(interval);
              break;
            }
            case 'error': {
              this.setState({ 
                showError:true,
                errorMsg: errorMsg, 
              })
              clearInterval(interval);
              break;
            }
            default: { break; }
          }
          // this.props.setProgressInfo(data)
        }
      }).catch((error) => { console.log(error) })
    }, 500);
  }

  //___________________________________________________________________
  // 一回目のファイル取込ボタンを押したとき（チェック）
  handleUploadCheck = (files) => {
    const { checkUrl, pgmId } = this.props;
    let form = new FormData();
    form.append('id', pgmId);
    for( let i in files){
      form.append('excel', files[i]);
    }
    axiosPost.post(server + checkUrl, form, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res) => {
      if (res) {
        this.progressTimer('check'); // checkのプログレスタイマーを開始
      }
    }).catch((error) => {
      clearInterval(this.progressTimer())
      console.log(error)
    })
    this.setState({files: files})
  }
  //___________________________________________________________________
  // チェック後のアップロードボタンを押した時
  handleOnUpload = async () => {
    const { uploadUrl } = this.props;
    axiosPost.post(server + uploadUrl).then(
      (res) => {
        this.progressTimer('upload');　// uploadのプログレスタイマーを開始
      }).catch(err => {
        clearInterval(this.progressTimer())
        console.log(err)
      });
  }
  //___________________________________________________________________
  // キャンセルボタンを押した時
  handleOnCancelUpload = () => {
    // stateを初期値に戻す
    this.setState({
      files: [],
      checkProgress: 0,
      uploadProgress: 0,
      errorMsg: null,
      showCheck: false,
      showUpload: false,
      showError:false,
      checkfinished:false,
      uploadfinished:false,
    })
  }
  //___________________________________________________________________
  // 詳細ボタンを押した時
  handleOnDownloadError = () => {
    const {files} = this.state
    for( let i in files){
      getExcel('/master/errorExcel', {}, 'ERROR_' + files[i].name);　//　エラーExcelのダウンロード
    }
  }
  //___________________________________________________________________

  render() {
    const { accept, onFinish } = this.props;
    const { 
      files, 
      checkProgress,
      uploadProgress, 
      errorMsg,
      showCheck,
      showUpload,
      showError,
      checkfinished, 
      uploadfinished,
    } = this.state
    const _checkProgress = parseInt(checkProgress*100, 10)
    const _uploadProgress = parseInt(uploadProgress*100, 10)
    let renderType = null

    if (showError && !checkfinished){renderType = 'checkError'}
    else if (showError && checkfinished){renderType = 'UploadError'}
    else if(files.length<=0 && !showCheck){ renderType = 'willInput'}
    else if(showCheck && !checkfinished){renderType = 'nowChecking'}
    else if(checkfinished && !showUpload){renderType = 'willUpload'}
    else if(showUpload && !uploadfinished){renderType = 'nowUploading'}
    else if(uploadfinished){renderType = 'complete'}
    switch(renderType){
      case 'willInput':{
        return(
          <div style={this.wrap_style}>
            <WillInputFile files={files} accept = {accept} onUpload={this.handleUploadCheck}/>
          </div>
        )
      }
      case 'nowChecking':{
        return(
          <div style={this.wrap_style}>
            <ProgressComponent label='Checking...' progress={_checkProgress}/>
          </div>
        )
      }
      case 'willUpload':{
        return(
          <div style={this.wrap_style}>
            <ProgressComponent label='OK' progress={_checkProgress}/>
            <UploadOrCancel files={files} onCancel={this.handleOnCancelUpload} onUpload={this.handleOnUpload}/>
          </div>
        )
      }
      case 'nowUploading':{
        return(
          <div style={this.wrap_style}>
            <ProgressComponent label='OK' progress={_checkProgress} />
            <ProgressComponent label='Uploading...' progress={_uploadProgress}/>
          </div>
        )
      }
      case 'complete':{
        return(
          <div style={this.wrap_style}>
            <DidUpdate onClick={onFinish}/>
          </div>
        )
      }
      case 'checkError':{
        return(
          <div style={this.wrap_style}>
            <ErrorComponent 
              onClick={this.handleOnCancelUpload} 
              onDownload={this.handleOnDownloadError}
              errorMsg={errorMsg}
            />
          </div>
        )
      }
      case 'UploadError':{
        return(
          <div style={this.wrap_style}>
            <ErrorComponent 
              onClick={this.handleOnCancelUpload} 
              errorMsg={errorMsg}
            />
          </div>
        )
      }
      default:{return null}
    }
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////
class WillInputFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: this.props.files || []
    }
    this.wrap_style = {
      border: '2px solid #92A9C7',
      borderRadius: 2,
      backgroundColor: '#DDECFF',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
    this.heading_style = {
      fontSize: 13,
      letterSpacing: 0.65,
      color: '#557298',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
      fontWeight: 'bold',
    }
    this.inputWrap_style = {
      backgroundColor: '#557298',
      borderRadius: 2,
      letterSpacing: 0.6,
      color: '#FFFFFF',
      fontSize: 12,
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
    }
    this.cancel_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#CB5252',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }

    this.decide_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#557298',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    this.btnHover_style = {
      opacity: 0.8,
    }
  }
  // _________________________________________________________
  // 選択したファイルを正式リスト化するためのファンクション
  makeFileList = (files) => {
    const files_copy = Object.assign([],this.state.files)
    const fileNames_arr = []
    // 現在選択中のファイル名を配列化するための一覧
    for (let i in files_copy){
      fileNames_arr.push(files_copy[i]['name']) //　次のループ内の条件分岐で同じファイルが重複しないようにnameを格納
    }
    // ファイルを格納していくためのループ
    for (let i in files){
      const { name } = files[i]
      // keyが数字のみのものだけ処理（'length'と'Item'を省略）
      if(isNaN(i) === false){
        if(fileNames_arr.indexOf(name) === -1){
          files_copy.push(files[i])
        }
      }
    }
    return files_copy
  }
  // _________________________________________________________
  // ファイルをドラッグ&ドロップしたとき
  handleOnDrop = (e) => {
    e.preventDefault();
    // ファイルのコピーを渡すようにする
    e.dataTransfer.dropEffect = 'copy'; // マウスカーソルを変更
    const fileList = this.makeFileList(e.dataTransfer.files)
    this.setState({files: fileList})
  }
  // _________________________________________________________
  // ファイルを選択したとき
  handleOnChangeFiles = (e) => {
    const fileList = this.makeFileList(e.target.files)
    this.setState({files: fileList})
  }
  // __________________________________________________________
  //
  render() {
    const { accept, onUpload } = this.props;
    const { files } = this.state;
  
    let _accept = null;
    for(let i = 0; i < accept.length; i++){
      switch (accept[i]) {
        case 'xls':{
          _accept += 'application/vnd.ms-excel,'
          break;
        }
        case 'xlsx':{
          _accept += 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,'
          break;
        }
        default:{
          _accept += accept[i]
          break;
        }
      }
    }

    const files_arr = []
    for (let i in files){
      files_arr.push(
        <p key={i}>
          {files[i].name}
        </p>
      )
      if(Number(i) === files.length - 1){
        files_arr.push(
          <Button
            label='decide'
            style={this.decide_style}
            hoveredStyle={this.btnHover_style}
            onClick={()=> onUpload && onUpload(files)}
           />
        )
      }
    }
    return (
      <div
        style={this.wrap_style}
        onDragOver={(e) => e.preventDefault()}
        onDrop={this.handleOnDrop}
      >
        <div style={this.heading_style}>
          <Icons.FileUpload width={24} height={24} color='#557298' />
          <span>ここに登録するファイルをドロップしてアップロードすることができます</span>
        </div>
        <label
          tabIndex="0"
          style={this.inputWrap_style}
          onMouseOver={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          onFocus={() => this.setState({ hover: true })}
          onBlur={() => this.setState({ hover: false })}
        >
          <Icons.File width={15} height={15} color='#FFF' />
          <span>ファイルを選択する</span>
          <input 
            type="file" 
            multiple
            ref={this.fileRef} 
            style={{ display: "none" }} 
            onChange={this.handleOnChangeFiles} 
            accept = {_accept}
          />
        </label>
        <div>選択中のファイル</div>
        {files_arr}
      </div>
    );
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////
class UploadOrCancel extends React.Component {
  constructor(props) {
    super(props);
    this.wrap_style = {
      border: '2px solid #92A9C7',
      borderRadius: 2,
      backgroundColor: '#DDECFF',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
    this.heading_style = {
      fontSize: 13,
      letterSpacing: 0.65,
      color: '#557298',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
      fontWeight: 'bold',
    }
    this.inputWrap_style = {
      backgroundColor: '#557298',
      borderRadius: 2,
      letterSpacing: 0.6,
      color: '#FFFFFF',
      fontSize: 12,
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
    }
    this.buttons_wrap = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
    this.cancel_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#CB5252',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    this.cancelHover_style = {
      opacity: 0.8,
    }
    this.ok_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#557298',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    this.okHover_style = {
      opacity: 0.8,
    }

  }

  render() {
    const { file, onCancel, onUpload, } = this.props;
    return (
      <div
        style={this.wrap_style}
        onDragOver={(e) => e.preventDefault()}
        onDrop={this.handleOnDrop}
      >
        <div style={this.heading_style}>
          <Icons.FileUpload width={24} height={24} color='#557298' />
          {/* <span>ファイル名【{file.name}】の取り込みを開始します。</span> */}
        </div>
        <div style={this.buttons_wrap}>
          <Button
            label='キャンセル'
            style={this.cancel_style}
            hoveredStyle={this.cancelHover_style}
            onClick={onCancel}
          />
          <Button
            label='OK'
            style={this.ok_style}
            hoveredStyle={this.okHover_style}
            onClick={onUpload}
          />
        </div>
      </div>
    );
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////

class ProgressComponent extends React.Component {

  render() {
    const { progress, label, } = this.props;
    const wrap_style = {
      backgroundColor : '#F0F0F0',
      border: '2px solid #BABABA',
      borderRadius: 2,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const label_style = {
      fontSize:13,
      fontWeight:'bold',
      letterSpacing: 0.65,
      color: '#868686',
      marginTop: 8,
    }
    return (
      <div style={wrap_style}>
        <Circle
          size={100}
          progress={progress}
        />
        <p style={label_style}>{label}</p>
      </div>

    )
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////

class DidUpdate extends React.Component {

  render() {
    const { onClick,} = this.props;
    const wrap_style = {
      border: '2px solid #5BBA6A',
      borderRadius: 2,
      backgroundColor: '#CEF5D4',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const check_style = {
      backgroundColor: '#5BBA6A',
      marginBottom:16,
      padding:12,
      borderRadius:'50%',
    }
    const label_style = {
      fontSize: 13,
      letterSpacing: 0.65,
      color: '#5BBA6A',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 32,
      fontWeight: 'bold',
    }
    const ok_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#5BBA6A',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    const okHover_style = {
      opacity: 0.8,
    }

    return (
      <div style={wrap_style}>
        <Icons.Check1 size={32} style={check_style} color='#CEF5D4'/>
        <p style={label_style}>アップロードが完了しました</p>
        <Button
          label='OK'
          style={ok_style}
          hoveredStyle={okHover_style}
          onClick={onClick}
        />
      </div>

    )
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////

class ErrorComponent extends React.Component {

  render() {
    const { onClick, errorMsg, onDownload} = this.props;
    const wrap_style = {
      border: '2px solid #CB5252',
      borderRadius: 2,
      backgroundColor: '#FFEBEB',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const label_style = {
      fontSize: 13,
      letterSpacing: 0.65,
      color: '#CB5252',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 32,
      marginTop:16,
      fontWeight: 'bold',
    }
    const buttons_wrap = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const ok_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#557298',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    const uploadButton_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#CB5252',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    const buttonHover_style = {
      opacity: 0.8,
    }

    return (
      <div style={wrap_style}>
        <Icons.ExclamationTriangle size={64} color='#CB5252'/>
        <p style={label_style}>{errorMsg}</p>
        <div style={buttons_wrap}>
          <Button
            label='OK'
            style={ok_style}
            hoveredStyle={buttonHover_style}
            onClick={onClick}
          />
          <Button
            label='詳細'
            style={uploadButton_style}
            hoveredStyle={buttonHover_style}
            onClick={onDownload}
            hidden={!onDownload}
          />
        </div>
      </div>

    )
  }
}
