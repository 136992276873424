import React from 'react';
import Icon from './Icon';

export default class Calculator extends React.Component {
  render() {
    return (
      <Icon
        {...this.props}
        viewBox="0 0 448 512"
        d="M400,0H48C22.4,0,0,22.4,0,48V464c0,25.6,22.4,48,48,48H400c25.6,0,48-22.4,48-48V48C448,22.4,425.6,0,400,0ZM128,435.2c0,6.4-6.4,12.8-12.8,12.8H76.8c-6.4,0-12.8-6.4-12.8-12.8V396.8c0-6.4,6.4-12.8,12.8-12.8h38.4c6.4,0,12.8,6.4,12.8,12.8Zm0-128c0,6.4-6.4,12.8-12.8,12.8H76.8c-6.4,0-12.8-6.4-12.8-12.8V268.8c0-6.4,6.4-12.8,12.8-12.8h38.4c6.4,0,12.8,6.4,12.8,12.8Zm128,128c0,6.4-6.4,12.8-12.8,12.8H204.8c-6.4,0-12.8-6.4-12.8-12.8V396.8c0-6.4,6.4-12.8,12.8-12.8h38.4c6.4,0,12.8,6.4,12.8,12.8v38.4Zm0-128c0,6.4-6.4,12.8-12.8,12.8H204.8c-6.4,0-12.8-6.4-12.8-12.8V268.8c0-6.4,6.4-12.8,12.8-12.8h38.4c6.4,0,12.8,6.4,12.8,12.8v38.4Zm128,128c0,6.4-6.4,12.8-12.8,12.8H332.8c-6.4,0-12.8-6.4-12.8-12.8V268.8c0-6.4,6.4-12.8,12.8-12.8h38.4c6.4,0,12.8,6.4,12.8,12.8V435.2Zm0-256c0,6.4-6.4,12.8-12.8,12.8H76.8c-6.4,0-12.8-6.4-12.8-12.8V76.8C64,70.4,70.4,64,76.8,64H371.2c6.4,0,12.8,6.4,12.8,12.8Z"
      />
    )
  }
}