import React from 'react';
import Icon from './Icon';

export default class Dictionary extends React.Component {
  render() {
    return (
      <Icon
        {...this.props}
        viewBox="0 0 1024 1024"
        d="M969.4 145V54.4h-86.2v192.8h86.2zM883.2 326.4h54.4v195h-54.4v-195zM0 72.8V934c1 49.8 43.8 90 93.8 90H825v-60H93.8C76.2 964 62 949.8 62 932.2s14.2-31.8 31.8-31.8H825V0H71.2C35.6 7.8 7.6 36.8 0 72.8z m267.6 144.6h416.8v72.4H267.6v-72.4z m0 194.2h416.8V484H267.6v-72.4z m0 194h416.8V678H267.6v-72.4zM126.8 217.4h72.4v72.4h-72.4v-72.4z m0 194.2h72.4V484h-72.4v-72.4z m0 194h72.4V678h-72.4v-72.4zM883.2 599.8H1005v203H883.2v-203z"
      />
    )
  }
}