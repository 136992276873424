//export const baseColor = '#4030d9';
export const baseColor = '#00B7CE';
export const inputBdrColor = '#707070';
export const errorColor = '#CB5252';

export const basicWrap = {
  disabled: 'transparent',
  hover: baseColor,
  focus: '#dfdfff',
  border: inputBdrColor,
}
export const checkBox = {
  check: baseColor,
  border: inputBdrColor,
}
export const calendar = {
  hover: '#D3D7EA',
  items: baseColor,
  icon: baseColor,
}
export const contentsBody = {
  label: 'rgb(52, 64, 126)',
}
export const loading = {
  bg: 'rgba(52, 64, 126, 0.9)',
}
export const loginPage = {
  basic: baseColor,
  btnHover: '#7060d9',
}
export const loadableButton = {
  basic: baseColor,
}
export const modal = {
  header: baseColor,
  icon: 'rgba(0, 0, 0, 0.5)',
  close: '#CB5252',
  window: '#FFFFFF',
  wrap: 'rgba(0, 0, 0, 0.3)'
}
export const options = {
  hover: '#D3EAD7',
  selected: '#D9D9D9',
  font: '#404040',
}
export const progress = {
  navBar: baseColor,
  selectedHeaderMenu: baseColor,
  selectedHeaderMenuFont: '#555555',
  sideBar: '#347E40',
  toggleBtnLabel: '#22572A',
  hoveredToggleBtnLabel: '#448C50',
  hoveredSubMenuFont: '#619069',
  subMenuFont: '#FFFFFF',
  hoveredSubMenu: '#CCE0CE',
  selectedSubMenu: baseColor,
}

export const radioList = {
  btn: baseColor,
}
export const select = {
  icon: baseColor
}
export const systemBtn = {
  basic: baseColor,
  add: '#E87694',
  back: '#C19A6B',
  submit: '#8B75D5',
  search: '#3797DD',
  confirm: '#1C4486',
  delete: '#CB5252',
  calc: '#5ABEB2',
  import: '#E0855D',
  mail: '#5ABEF0',
  font: '#FFF',
  hover: 'rgba(255, 255, 255, 0.4)',
  invHover: 'rgba(0, 0, 0, 0.05)',
}
export const systemError = {
  heading: '#65A36F',
  icon: baseColor,
  btn: baseColor,
  btnhover: '#7DD48A',
}
export const table = {
  addRow: baseColor,
  basic: baseColor,
  border: '#dddddd',
  calendar: baseColor,
  cellBorder: baseColor,
  counterFont: baseColor,
  check: baseColor,
  detail: baseColor,
  errorBg: '#FFEBEB',
  errorBoder: '#CB5252',
  evenRows: '#EAF6FF',
  errorIcon: '#CB5252',
  header: '#F5F5F5',
  hoverRow: 'rgb(233, 235, 245)',
  modal: baseColor,
  mustCell: '#FFF7E0',
  paging: baseColor,
  selectIcon: baseColor,
  th: '#ddd',
}

export const textFile = {
  hover: '#dfdfff',
  icon: baseColor,
  close: "#FFF",
  closeHover: "#F00",
  imgFooter: "#f5f5f5",
  imgBorder: "#cccccc",
  imgCover: 'rgba(0,0,0,0.9)',
  downloadHover: '#E0855D',
  trashHover: '#F00',
  header: baseColor,
}
export const textPass = {
  icon: baseColor,
}
export const textWrapper = {
  label: baseColor,
  font: '#FFFFFF',
  back: '#FaFaFa',
  border: '#BFBFBF',
  must: '#f01111',
}
export const topPage = {
  basic: baseColor,
  header: '#FFF',
  navBar: baseColor,
  selectedHeaderMenu: baseColor,
  selectedHeaderMenuFont: '#555555',
  sideBar: '#347EF0',
  toggleBtnLabel: '#2257F0',
  hoveredToggleBtnLabel: '#448CF0',
  hoveredSubMenuFont: '#6190F0',
  subMenuFont: '#FFFFFF',
  hoveredSubMenu: '#CCE0F0',
  selectedSubMenu: baseColor,
}
export const wideSelect = {
  btnBg: baseColor,
}