
import React, { Fragment, Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { history } from '../../comRedux/createStore';
import RouterContainer from './RouterContainer';
import * as SKY from "../../component/SmartComponent";
import { logOut, menu } from '../../comRedux/Actions';
import { axiosPost, server } from '../../comRedux/axiosPost';
// import { push } from 'connected-react-router';　//Logout用
// import { delCookie } from '../../util/cookie';

class ContainedButtons extends Component {

  // 画面ロード時
  async componentDidMount() {
    if (!this.props.didSet) {
      var returnJson = {
      };
      // rev = Math.random()は、初期化のため実装
      axiosPost.post(server + '/menu/search', returnJson)
        .then((res) => {
          if (res.data != null) {
            console.log(res.data);
            this.props.dispatch(menu(res.data));
          }
        })
        .catch(err => {
          alert(err);
          console.log('JSON読み込みエラー')
          console.log(err)
          return
        })
    }
  }
  // handleLogOut = async (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   const result = await SKY.confirm({ message: "LogOutしますか?" });
  //   if (result) {
  //     //backendでSessionClearのRequestを投げます
  //     axiosPost.get(server + '/session/remove').then(res => { console.log(res.data) }).catch(err => { console.log(err) });
  //     delCookie('sSessionId');
  //     delCookie('compCode');
  //     this.props.dispatch(push('/', { showPrompt: false }))
  //     this.props.dispatch(logOut());
  //   }
  // }

  // select_menu = (menuId) => {
  //   var returnJson = {
  //     menuId: menuId
  //   };
  //   //e.preventDefault();
  //   axiosPost.post(server + '/menu/select', returnJson)
  //     .then((res) => {
  //       if (res.data === true) {
  //         //this.props.dispatch(login());
  //       } else {
  //         //this.props.dispatch(error('LOGIN','ログインできません'));
  //       }
  //       //return res.data;
  //     })
  //     .catch(err => {
  //       alert(err);
  //       console.log('JSON読み込みエラー')
  //       return
  //     })

  // }

  render() {
    // JSONデータの読み込みが完了しているか？
    if (!this.props.didSet) {
      return <SKY.Loading style={{ margin: 'auto', }} />
    }
    return (
      <ConnectedRouter history={history}>
        <RouterContainer {...this.props} />
      </ConnectedRouter>
    );
  }
}
const mapStateToProps = (state, ownPropS) => {
  return {
    //stateの範囲を縮めるとRender回数は減ります
    ...state.COMMON.Menu
  }
}
export default connect(mapStateToProps)(ContainedButtons);
