import React from 'react';
import { getPMasterName } from '../util/commonInput';
import Button from './Button';
import * as Icons from "./Icons";
import {closeModal} from './Modal'

export default class ModalBody extends React.Component {
  constructor(props) {
    super(props);
    this.wrap_style = {
      backgroundColor: this.props.color || 'rgb(230, 230, 230)',
      minHeight: '100%',
    }
    this.inner_style = {
      padding: "8px 16px ",
    }
    this.menuTitle_style = {
      letterSpacing: 0.8,
      color: '#262626',
      padding: "8px 16px ",
      // fontSize: 13,
      fontWeight:'bold',
      backgroundColor: '#FFF',
      borderBottom: '1px solid #BFBFBF',
    }
  }
  render() {
    const {
      children,
      label,
      plabel,
      plabel2,
      errorMsg
    } = this.props
    if(errorMsg) {
      return(
        <ModalError errorMsg={errorMsg}/>
      )
    }
    let Plabel = plabel && getPMasterName(plabel);
    let Plabel2 = plabel2 && getPMasterName(plabel2);
    if (Plabel === 'NONE') { return null }
    return (
      <div style={this.wrap_style}>
        <div style={this.menuTitle_style}>
          {Plabel}{Plabel2}{label}
        </div>
        <div style={this.inner_style}>
          {children}
        </div>
      </div>
    )
  }
}



class ModalError extends React.Component {
  render() {
    const { errorMsg} = this.props;
    const wrap_style = {
      border: '2px solid #CB5252',
      borderRadius: 2,
      backgroundColor: '#FFEBEB',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const label_style = {
      fontSize: 13,
      letterSpacing: 0.65,
      color: '#CB5252',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 32,
      marginTop:16,
      fontWeight: 'bold',
    }
    const buttons_wrap = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const decide_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#CB5252',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
      fontSize: 12,
      fontWeight:'bold',
    }
    const detail_style = {
      borderRadius: 2,
      border: '1px solid #CB5252',
      backgroundColor: '#FFF',
      color: '#CB5252',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
      fontSize: 12,
      fontWeight:'bold',
    }
    const buttonHover_style = {
      opacity: 0.8,
    }

    return (
      <div style={wrap_style}>
        <Icons.ExclamationTriangle size={64} color='#CB5252'/>
        <p style={label_style}>{errorMsg}</p>
        <div style={buttons_wrap}>
          <Button
            label='decide'
            style={decide_style}
            hoveredStyle={buttonHover_style}
            onClick={()=>closeModal()}
          />
          <Button
            label='detail'
            style={detail_style}
            hoveredStyle={buttonHover_style}
          />
        </div>
      </div>

    )
  }
}
