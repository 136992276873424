import React, { Fragment } from 'react';
import * as Parts from './TableComponent';
import * as Icons from "../Icons";
import { table as Colors } from '../Colors';
import * as smartUtil from '../../util/commonInput';

export default class TablePaging extends React.Component {
  constructor(props) {
    super(props);
    this.pagingArea_style = {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '8px 0px',
      backgroundColor: '#E6E6E6'
    }
    this.pagingWrap_style = {
      display: 'flex',
    }
    this.counter_style = {
      display: 'flex',
      alignItems: 'center',
      color: '#535353',
      letterSpacing: 0.55,
      // fontSize: '1.4rem',
      // fontWeight: 'bold',
    }
    this.pageCountWrap_style = {        // <div> ページングの外枠のスタイル
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: '100%',
      color: '#535353',
      letterSpacing: 0.55,
      // fontSize: '1.2rem',
      // fontWeight: 'bold',
      marginLeft: 8,
    }
    this.pageCount_style = {
      color: '#65A36F',
      // fontSize: '1.6rem',
      margin: '0px 8px',
    }

  }
  // ___________________________________________________________________________________________________________

  render() {
    const { pageCount, maxRows, setData, children, onClickPrePageBtn, onClickNextPageBtn, hidden } = this.props;
    if (hidden) { return null }
    return (
      <div style={this.pagingArea_style}>
        <div style={this.counter_style}>件数：{setData.length}</div>
        {/* {setData.length > 0 && <div style={this.pagingWrap_style}> */}
        <div style={this.pagingWrap_style}>
          {children}
          {setData.length > 0 && maxRows &&
            <Fragment>
              <PrePageBtn {...this.props} onClick={onClickPrePageBtn} />
              <div style={this.pageCountWrap_style}>
                {smartUtil.getPMasterName("page")}
                <span style={this.pageCount_style}>{pageCount}</span>/{Math.ceil(setData.length / maxRows)}
              </div>
              <NextPageBtn {...this.props} onClick={onClickNextPageBtn} />
            </Fragment>
          }
        </div>
      </div>
    );
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class PrePageBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }

    this.wrap_style = {           // <button> 全件表示ボタン
      height: 40,
      paddingRight: 16,
      paddingLeft: 10,
      borderRadius: 2,
      cursor: "pointer",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      marginLeft: 8,
    }
    this.label_style = {
      // fontSize: '1.2rem',
      // fontWeight: 'bold',
      letterSpacing: 0.6,
      marginLeft: 8,
      color: '#FFF',
    }
  }
  // ___________________________________________________________________________________________________________

  render() {
    const { pageCount, maxRows, onClick, } = this.props;
    if (!maxRows) { return null };
    const { hover } = this.state;
    const disabled = (pageCount <= 1)
    // ___________________________________________________________________________________________________________

    Object.assign(this.wrap_style, {
      backgroundColor:
        disabled ? 'transparent' :
          Colors.paging,
      cursor:
        disabled ? null :
          'pointer',
      opacity:
        hover ? 0.7 :
          null,
    })
    return (
      <button
        style={Object.assign({}, this.wrap_style)}
        onClick={onClick}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
        disabled={disabled}
      >
        <Icons.AngleLeft width={12} height={12} color={'#FFF'} />
        <span style={Object.assign({}, this.label_style)}>前へ</span>
      </button>
    );
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class NextPageBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }

    this.wrap_style = {           // <button> 全件表示ボタン
      height: 40,
      paddingRight: 10,
      paddingLeft: 16,
      borderRadius: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      marginLeft: 8,
    }
    this.label_style = {
      // fontSize: '1.2rem',
      // fontWeight: 'bold',
      letterSpacing: 0.6,
      marginRight: 8,
      color: '#FFF',
    }
  }
  // ___________________________________________________________________________________________________________

  render() {
    const { pageCount, maxRows, setData, onClick, } = this.props;
    if (!maxRows) { return null };
    const { hover } = this.state;
    const disabled = (setData.length - maxRows * pageCount < 1)

    // ___________________________________________________________________________________________________________

    Object.assign(this.wrap_style, {
      backgroundColor:
        disabled ? 'transparent' :
          Colors.paging,
      cursor:
        disabled ? null :
          'pointer',
      opacity:
        hover ? 0.7 :
          null,
    })
    return (
      <button
        style={Object.assign({}, this.wrap_style)}
        onClick={onClick}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
        disabled={disabled}
      >
        <span style={Object.assign({}, this.label_style)}>次へ</span>
        <Icons.AngleRight width={12} height={12} color={'#FFF'} />
      </button>
    );
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
