import React, { Fragment } from 'react';
import { table as Colors } from '../Colors'
import { funcAdapter } from '../../util/commonInput';

export default class BasicTD extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      hover: false,
    }
  }
  //______________________________________________________________________________________________________
  handleOnBlur = () => {
    this.setState({ focus: false });
    // const { onBlur, index, name} = this.props.config
    // const { value } = this.props;
    // const fowardData = { 'index': index, 'name': name, 'value': value } //　一番親に送るデータ
    const { onBlur } = this.props;
    onBlur && onBlur() // column設定用のonBlur
  }
  // ________________________________________________________________________________________________________________
  handleOnFocus = () => {
    this.setState({ focus: true });
    // const { onFocus, index, name} = this.props.config
    // const { value } = this.props;
    // const fowardData = { 'index': index, 'name': name, 'value': value } //　一番親に送るデータ
    const { onFocus } = this.props;
    onFocus && onFocus()
  }
  // ________________________________________________________________________________________________________________
  render() {
    const { disabled, children, must, color, cellStyle = {}, config, rowStyle = {}, setData, value } = this.props
    const { focus, hover } = this.state;
    const {
      rowSpan,
      colSpan,
      style = {},
      fixed,
      fixedWidth,
      width,
      minWidth,
      height,
      align,
    } = config

    const td_style = {
      // width: "100%",

      verticalAlign: "middle",
      // fontSize: '1.1rem',
      // height: 25,
      minHeight: 25,
      transition: "box-shadow 0.3s",
      color: "#000",
      border:
        disabled ? "1px solid" + Colors.border :
          focus ? "2px solid" + Colors.basic :
            "3px ridge" + color,
      boxShadow:
        disabled ? null :
          hover ? "0px 0px 5px 0px inset" + Colors.basic :
            null,
      backgroundColor:
        disabled ? color :
          must ? Colors.mustCell :
            this.props.zIndex ? color : null,
      cursor:
        disabled ? null :
          "pointer",
      pointerEvents: "auto",
      zIndex: this.props.zIndex || null,
    }
    const setRowStyle = funcAdapter(rowStyle, setData);
    const columnStyle = {
      width: width,
      minWidth: minWidth,
      textAlign: align ? align :
        typeof (value) === 'number' ? 'right' :
          null,
      position: fixed ? 'sticky' : 'relative',
      // left: fixed,
      left:
        fixed ? (fixedWidth ? fixedWidth : fixed) :
          null,
      height:
        disabled ? 30 :
          height ? height :
            30,
      zIndex: fixed ? 1 :
        null,
    }

    //______________________________________________________________________________________________________
    return (
      <td
        style={Object.assign({}, td_style, setRowStyle, columnStyle, cellStyle, style)}
        rowSpan={rowSpan === 'max' ? 1000 : rowSpan}
        colSpan={colSpan}
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        {children}
      </td>
    )
  }
}

