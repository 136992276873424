import React, { Fragment } from 'react';
import * as Icons from "./Icons";
import BasicWrap from './BasicWrap';
import { select as Colors } from './Colors'
import styled from 'styled-components';
import { funcAdapter } from 'util/commonInput';
export default class Select extends React.Component {
  constructor(props) {
    super(props);
    const {
      value,
      setData,
    } = this.props;

    this.state = {
      value: value || '',    // 選択されているアイテムのvalue
      setData: setData || [],　// セットされたデータ
    }
    this.wrap_style = {
      width: null,
      minWidth: 80,
    }
    this.select_style = {
      fontSize: 'inherit',
      height: '100%',
      width: '100%',
      paddingLeft: 4,
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      paddingRight: 20,
      position: 'relative',
      appearance: 'none',
    }
    this.icon_style = {
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
    }
    this.option_style = {
      fontSize: 12,
    }
    this.Select = styled.select`::-ms-expand {display: none;}`;

  }
  // ________________________________________________________________________________________________________________
  shouldComponentUpdate(nextProps) {
    const { value, setData } = this.props;
    if (value !== nextProps.value) {
      this.setState({ value: nextProps.value || '' })
    }
    if (setData !== nextProps.setData) {
      this.setState({ setData: nextProps.setData || [] })
    }
    return true;
  }
  //______________________________________________________________________________________________________

  handleOnChange = (e) => {
    const { onChange, name } = this.props
    this.setState({ value: e.target.value })
    onChange && onChange(name, e.target.value)
  }
  // ________________________________________________________________________________________________________________
  handleOnKeyDown = (e) => {
    if (e.keyCode === 40 || e.keyCode === 38) {
      e.preventDefault()
    }
  }
  // ________________________________________________________________________________________________________________

  render() {
    // state と props の省略宣言
    const {
      setData,  //  ArrayObject型 [{ value : ... , label : ... }, { value : ... , label : ... }]で設定
      value
    } = this.state
    const { disabled, disabledOption } = this.props

    Object.assign(this.select_style, {            // <td> 各セル
      cursor:
        disabled ? null :
          "pointer",
    })

    const option_arr = []
    for (let i in setData) {
      const { value, label } = setData[i]
      option_arr.push(
        <option key={'option' + i} value={value} style={this.option_style} disabled={funcAdapter(disabledOption, setData[i])}>
          {label}
        </option>
      )
    }
    // ________________________________________________________________________________________________________________
    return (
      <BasicWrap {...this.props} style={this.wrap_style} value={value}>
        <Icons.TriangleDown style={this.icon_style} width={20} height={20} color={Colors.icon} hidden={disabled} />
        <this.Select
          style={Object.assign({}, this.select_style)}
          onChange={this.handleOnChange}
          value={value}
          disabled={disabled}
          onKeyDown={this.handleOnKeyDown}
        >
          {option_arr}
        </this.Select>
      </BasicWrap>
    )
  }
}

/*
const testData=[
  { value :" " , label :" " },
  { value :1 , label :"test1" },
  { value :2 , label :"test2" },
  { value :3 , label :"test3" },
  { value :4 , label :"test4" },
]

*/