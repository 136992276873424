import React from 'react';
import BasicWrap from './BasicWrap';
import { textFile as Colors } from './Colors';
import { getPMasterName } from '../util/commonInput';
import * as Icons from "./Icons";


export default class InputImg extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      hover: false,
      value: value || {},
      src: null,
      showImg: false,
    }
  }
  // ______________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const { value } = nextProps;
    if (this.props.value !== value) {
      this.setState({ value: value })
      var reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ src: e.target.result })
      }
      reader.readAsDataURL(value)
    }
    return true;
  }
  // ______________________________________________________________________________

  importFile = (e) => {
    if (!e.target.files[0]) { return }
    console.log(e.target.files[0])
    const { name, onChange } = this.props;
    this.setState({ value: e.target.files[0] })
    var reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ src: e.target.result })
    }
    reader.readAsDataURL(e.target.files[0])

    onChange && onChange(name, e.target.files[0])

  }
  // ______________________________________________________________________________

  resetFile = () => {
    const { onChange, name } = this.props;
    this.setState({
      value: {},
      src: null,
    })
    onChange && onChange(name, {})
  }
  // ______________________________________________________________________________
  render() {
    const { hidden, disabled, plabel, label } = this.props
    const { hover, value, src, showImg } = this.state
    let Plabel = plabel && getPMasterName(plabel);
    if (Plabel === 'NONE') { return null }
    if (hidden) { return null }

    const wrap_style = {
      display: "flex",
      alignItems: "center",
      justifyContent: 'center',
      fontSize: 11,
    }
    const file_style = {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      backgroundColor:
        disabled ? null :
          hover ? Colors.hover :
            "transparent",
      fontWeight: "bold",
      fontSize: 'inherit',
      cursor:
        disabled ? null :
          "pointer",
      transition: "0.3s",
      padding: '0px 8px',
    }
    const icon_style = {
      margin: '0px 4px',
    }

    // ______________________________________________________________________________

    return (
      <div style={wrap_style}>
        <BasicWrap style={{ width: null }}>
          <label
            tabIndex="0"
            style={file_style}
            onMouseOver={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
            onFocus={() => this.setState({ hover: true })}
            onBlur={() => this.setState({ hover: false })}
          >
            <Icons.FileDownload height={20} width={20} color={Colors.icon} />
            <span style={{ paddingLeft: 8 }}>{label}{Plabel}</span>
            <input type="file" style={{ display: "none" }} onChange={this.importFile} disabled={disabled} accept="image/*" />
          </label>
        </BasicWrap>

        <Icons.Eye
          color={Colors.icon}
          style={icon_style}
          width={20}
          height={20}
          hidden={!src}
          onClick={() => this.setState({ showImg: true })}
        />
        <Icons.Times
          color={Colors.icon}
          style={icon_style}
          width={20}
          height={20}
          hidden={!src}
          onClick={this.resetFile}
        />
        <span>{value.name}</span>
        <UploadedImg src={src} hidden={!showImg} onClick={() => this.setState({ showImg: false })} />
      </div>
    )
  }
}

class UploadedImg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFlag: false,
    }
  }
  // ______________________________________________________________________________
  render() {
    const { src, hidden, onClick } = this.props;
    if (!src || hidden) { return null }
    const wrap_style = {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 100,
      display: 'flex',
      width: '100vw',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.75)',
    }
    const imgWrap_style = {
      position: 'relative',
    }
    const times_style = {
      position: 'absolute',
      bottom: '100%',
      left: '100%',
    }
    const img_style = {
      maxWidth: 800,
      maxHeight: 600,
    }
    return (
      <div style={wrap_style}>
        <div style={imgWrap_style}>
          <Icons.Times style={times_style} color='#FFFFFF' color2='#CCCCCC' onClick={onClick} />
          <img alt='upload_image' src={src} style={img_style} />
        </div>
      </div>
    )
  }
}
