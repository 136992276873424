import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import COMMON, { initialState } from '../pgm/entry/LoginReducer';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { makeAllReducer } from './reducerUtils';

export const history = createBrowserHistory();

export default () => {
  const middlewares = [routerMiddleware(history), thunk];

  const enhancers = [];

  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.devToolsExtension;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }
  const store = createStore(
    makeAllReducer({ 'COMMON': COMMON }),
    compose(
      applyMiddleware(...middlewares),
      ...enhancers
    )
  );
  store.asyncReducers = { 'COMMON': COMMON };
  return store;
}