import React, { Fragment } from 'react';
import { connect } from 'react-redux';

class ErrorMsgBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMsg: true, // メッセージの表示/非表示
    }
  }
  componentDidMount() {
    const { currentPage, searchErrMsg, inputErrMsg } = this.props;
    this.hasModalAncestor();
    switch (currentPage) {
      case 1: this.msg_ref.innerHTML = searchErrMsg; break;
      case 2: this.msg_ref.innerHTML = inputErrMsg; break;
      case 3: this.msg_ref.innerHTML = inputErrMsg; break;
      default: this.msg_ref.innerHTML = searchErrMsg; break;
    };
  }
  //_______________________________________________________________________________________________
  shouldComponentUpdate(nextProps) {
    const { currentPage, searchErrMsg, inputErrMsg } = this.props;
    if (nextProps.searchErrMsg !== searchErrMsg || nextProps.inputErrMsg !== inputErrMsg) {
      this.hasModalAncestor();

      switch (currentPage) {
        case 1: this.msg_ref.innerHTML = nextProps.searchErrMsg; break;
        case 2: this.msg_ref.innerHTML = nextProps.inputErrMsg; break;
        case 3: this.msg_ref.innerHTML = nextProps.inputErrMsg; break;
        default: this.msg_ref.innerHTML = nextProps.searchErrMsg; break;
      };
    }
    return true;
  }
  //_______________________________________________________________________________________________

  // 画面上にmodalが存在し、上位htmlタグにid="modal"があるかどうかを判定する。
  // modal内のコンポーネントの場合にメッセージを表示し、modal外の画面の場合はメッセージを非表示にするため
  hasModalAncestor = () => {
    let node = this.wrap_ref; // 現在のコンポーネントのrefを使用して、node変数を初期化する
    const isModal = document.getElementById('modal') ? true : false // idが"modal"の要素があるかどうかを判定し、isModal変数に結果を格納する
    if (isModal) { // isModalがtrueの場合、上位htmlタグにid="modal"があるかどうかを調べる
      while (node) { // 親要素をたどりながら、idが"modal"である要素があるかどうかを判定する
        if (node.id === "modal") {
          this.setState({ showMsg: true }) // idが"modal"である要素が見つかった場合、エラーメッセージを表示する
          return;
        }
        node = node.parentNode; // nodeを次の親要素に更新する
      }
      this.setState({ showMsg: false }) // idが"modal"である要素が見つからなかった場合、エラーメッセージを表示しない
      return;
    }
    this.setState({ showMsg: true }) // modalが存在しない場合、エラーメッセージを表示する
  };
  //_______________________________________________________________________________________________
  render() {
    const { searchErrMsg, inputErrMsg } = this.props;
    const { showMsg } = this.state;

    const err_style = {
      backgroundColor: "#FFEBEB",
      border: "1px solid #CB5252",
      borderRadius: 2,
      color: '#CB5252',
      // fontSize: 12,
      fontWeight: 'bold',
      padding: "8px",
      margin: "4px 0px",
      width: '100%',
      display:
        showMsg === false ? 'none' :
          !inputErrMsg && !searchErrMsg ? 'none' :
            null
    };

    return (
      <div style={err_style} ref={(el) => this.wrap_ref = el}>
        <div ref={(el) => this.msg_ref = el} />
      </div>
    );
  };
}
// ______________________________________________________________________________

const mapStateToProps = (state, props) => {
  return {
    searchErrMsg: state.COMMON.Common.searchErrMsg,
    inputErrMsg: state.COMMON.Common.inputErrMsg,
    currentPage: state.COMMON.Common.currentPage,
  }
}
export default connect(mapStateToProps, null)(ErrorMsgBar);
