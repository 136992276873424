import loadable from '@loadable/component'
import { store } from '../index'

/*
*menu: eg.'MM01_search' Componentファイル名
*/
export default (menu) => {
  // sessionStorage に保存した施設コードを削除する
  sessionStorage.removeItem('facilityCode');

  //順位三:標準画面
  switch (menu) {
    case 'QA00_search': return loadable(() => import('../pgm/qa/qa00/QA00_search'));
    case 'QA00_searchList': return loadable(() => import('../pgm/qa/qa00/QA00_searchList'));
    case 'QA01_input': return loadable(() => import('../pgm/qa/qa01/QA01_input'));
    case 'QA01_input2': return loadable(() => import('../pgm/qa/qa01/QA01_input2'));
    case 'QA01_input3': return loadable(() => import('../pgm/qa/qa01/QA01_input3'));
    case 'QaDicision': return loadable(() => import('../pgm/qa/qa01/QaDicision'));
    case 'QA02_input': return loadable(() => import('../pgm/qa/qa02/QA02_input'));
    case 'QA04_input': return loadable(() => import('../pgm/qa/qa04/QA04_input'));
    case 'QA05_input': return loadable(() => import('../pgm/qa/qa05/QA05_input'));

    case 'MM01_search': return loadable(() => import('../pgm/mm/mm01/MM01_search'));
    case 'MM01_searchList': return loadable(() => import('../pgm/mm/mm01/MM01_searchList'));
    case 'MM01_input': return loadable(() => import('../pgm/mm/mm01/MM01_input'));
    case 'MM02_search': return loadable(() => import('../pgm/mm/mm02/MM02_search'));
    case 'MM02_searchList': return loadable(() => import('../pgm/mm/mm02/MM02_searchList'));
    case 'MM02_input': return loadable(() => import('../pgm/mm/mm02/MM02_input'));
    case 'MM03_search': return loadable(() => import('../pgm/mm/mm03/MM03_search'));
    case 'MM03_searchList': return loadable(() => import('../pgm/mm/mm03/MM03_searchList'));
    case 'MM03_input': return loadable(() => import('../pgm/mm/mm03/MM03_input'));
    case 'MM04_search': return loadable(() => import('../pgm/mm/mm04/MM04_search'));
    case 'MM04_searchList': return loadable(() => import('../pgm/mm/mm04/MM04_searchList'));
    case 'MM04_input': return loadable(() => import('../pgm/mm/mm04/MM04_input'));
    case 'MM05_search': return loadable(() => import('../pgm/mm/mm05/MM05_search'));
    case 'MM05_searchList': return loadable(() => import('../pgm/mm/mm05/MM05_searchList'));
    case 'MM05_input': return loadable(() => import('../pgm/mm/mm05/MM05_input'));
    case 'MM06_search': return loadable(() => import('../pgm/mm/mm06/MM06_search'));
    case 'MM06_searchList': return loadable(() => import('../pgm/mm/mm06/MM06_searchList'));
    case 'MM06_input': return loadable(() => import('../pgm/mm/mm06/MM06_input'));
    case 'MM07_search': return loadable(() => import('../pgm/mm/mm07/MM07_search'));
    case 'MM07_searchList': return loadable(() => import('../pgm/mm/mm07/MM07_searchList'));
    case 'MM07_input': return loadable(() => import('../pgm/mm/mm07/MM07_input'));
    case 'MM08_search': return loadable(() => import('../pgm/mm/mm08/MM08_search'));
    case 'MM08_searchList': return loadable(() => import('../pgm/mm/mm08/MM08_searchList'));
    case 'MM08_input': return loadable(() => import('../pgm/mm/mm08/MM08_input'));
    case 'MM09_search': return loadable(() => import('../pgm/mm/mm09/MM09_search'));
    case 'MM09_searchList': return loadable(() => import('../pgm/mm/mm09/MM09_searchList'));
    case 'MM09_input': return loadable(() => import('../pgm/mm/mm09/MM09_input'));
    case 'MM10_search': return loadable(() => import('../pgm/mm/mm10/MM10_search'));
    case 'MM10_searchList': return loadable(() => import('../pgm/mm/mm10/MM10_searchList'));
    case 'MM10_input': return loadable(() => import('../pgm/mm/mm10/MM10_input'));
    case 'MM10_pop': return loadable(() => import('../pgm/mm/mm10/pop/MM10Pop'));

    case 'SY01_search': return loadable(() => import('../pgm/sy/sy01/SY01_search'));
    case 'SY01_searchList': return loadable(() => import('../pgm/sy/sy01/SY01_searchList'));
    case 'SY01_input': return loadable(() => import('../pgm/sy/sy01/SY01_input'));
    case 'SY02_search': return loadable(() => import('../pgm/sy/sy02/SY02_search'));
    case 'SY02_searchList': return loadable(() => import('../pgm/sy/sy02/SY02_searchList'));
    case 'SY02_input': return loadable(() => import('../pgm/sy/sy02/SY02_input'));
    case 'SY03_search': return loadable(() => import('../pgm/sy/sy03/SY03_search'));
    case 'SY03_searchList': return loadable(() => import('../pgm/sy/sy03/SY03_searchList'));
    case 'SY03_input': return loadable(() => import('../pgm/sy/sy03/SY03_input'));
    case 'SY04_search': return loadable(() => import('../pgm/sy/sy04/SY04_search'));
    case 'SY04_searchList': return loadable(() => import('../pgm/sy/sy04/SY04_searchList'));
    case 'SY04_input': return loadable(() => import('../pgm/sy/sy04/SY04_input'));
    case 'SY05_search': return loadable(() => import('../pgm/sy/sy05/SY05_search'));
    case 'SY05_searchList': return loadable(() => import('../pgm/sy/sy05/SY05_searchList'));
    case 'SY05_input': return loadable(() => import('../pgm/sy/sy05/SY05_input'));
    case 'SY06_search': return loadable(() => import('../pgm/sy/sy06/SY06_search'));
    case 'SY06_searchList': return loadable(() => import('../pgm/sy/sy06/SY06_searchList'));
    case 'SY06_input': return loadable(() => import('../pgm/sy/sy06/SY06_input'));
    case 'SY09_search': return loadable(() => import('../pgm/sy/sy09/SY09_search'));
    case 'SY09_searchList': return loadable(() => import('../pgm/sy/sy09/SY09_searchList'));
    case 'SY09_input': return loadable(() => import('../pgm/sy/sy09/SY09_input'));
    case 'SY99_search': return loadable(() => import('../pgm/sy/sy99/SY99_search'));
    case 'SY99_searchList': return loadable(() => import('../pgm/sy/sy99/SY99_searchList'));
    case 'SY99_input': return loadable(() => import('../pgm/sy/sy99/SY99_input'));

    case 'AN01_search': return loadable(() => import('../pgm/an/an01/AN01_search'));
    case 'AN02_search': return loadable(() => import('../pgm/an/an02/AN02_search'));
    case 'AN03_search': return loadable(() => import('../pgm/an/an03/AN03_search'));
    case 'AN04_search': return loadable(() => import('../pgm/an/an04/AN04_search'));
    case 'AN05_search': return loadable(() => import('../pgm/an/an05/AN05_search'));

    case 'MA01_search': return loadable(() => import('../pgm/ma/ma01/MA01_search'));
    case 'MA01_searchList': return loadable(() => import('../pgm/ma/ma01/MA01_searchList'));
    case 'MA01_input': return loadable(() => import('../pgm/ma/ma01/MA01_input'));
    case 'MA04_search': return loadable(() => import('../pgm/ma/ma04/MA04_search'));
    case 'MA04_searchList': return loadable(() => import('../pgm/ma/ma04/MA04_searchList'));

    case 'FX01_search': return loadable(() => import('../pgm/fx/fx01/FX01_search'));
    case 'FX01_searchList': return loadable(() => import('../pgm/fx/fx01/FX01_searchList'));
    case 'FX01_input': return loadable(() => import('../pgm/fx/fx01/FX01_input'));

    default: return loadable(() => import('../pgm/entry/Blank'));
  }

}
