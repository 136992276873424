import React, { Fragment } from 'react';

export default class LoginText extends React.Component {
  constructor(props) {
    super(props);
    const {
      value,
      type,
      disabled,
    } = this.props;
    this.state = {
      value: value || '',
      focus: false,
      hover: false,
    };
    this.text_style = {
      borderRadius: 4,
      height: 44,
      width: '100%',
      boxSizing: "border-box",
      transition: "0.3s",
      border: "1px solid #bfbfbf",
      paddingLeft: 14,
      paddingRight:
        type === "number" ? 14 :
          null,
      pointerEvents:
        disabled ? "none" :
          null,
      textAlign:
        type === "number" ? 'right' :
          null,
      outline: "none",
    }
  }
  // _____________________________________________________________________

  componentDidMount() {
    const { type } = this.props;

    switch (type) {
      case "password": {
        this.setState({ type: "password" });
        break;
      }
      case "number": case "decimal": case "alphanum": {
        this.setState({ type: "tel" });
        break
      }
      default: {
        this.setState({ type: "text" });
        break;
      }
    }
  }
  // ______________________________________________________________________

  shouldComponentUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      const fixedValue = nextProps.value ? nextProps.value : ""; // null や undifinedを''に修正
      this.setState({ value: fixedValue });
    }
    return true
  }
  // ______________________________________________________________________

  handleOnBlur = e => {
    this.setState({ focus: false })
  }
  // ______________________________________________________________________________________________________

  handleOnChange = (e) => {
    const { type, name, onChange } = this.props;
    let replaceValue = null;
    // typeによって文字列変換をするためのswitch文です
    switch (type) {
      case "number": {
        replaceValue = e.target.value.replace(/[^\d]/g, '');
        break;
      }
      case "decimal": {
        replaceValue = e.target.value.replace(/[^\d||.]/g, '');
        break;
      }
      case "alphanum": {
        replaceValue = e.target.value.replace(/[^a-zA-Z0-9!#$%&\(\)*+/:;=?@\[\]^_{}-]/g, '');
        break;
      }
      default: {
        replaceValue = e.target.value;
        break;
      }
    }
    this.setState({ value: replaceValue })

    onChange && onChange(name, replaceValue);
  }
  // _______________________________________________________________________________________________________

  render() {
    const {
      disabled,
      maxLength,
      autoFocus,
      onKeyDown,
      placeholder
    } = this.props
    const {
      value,    // 入力されている値
      focus,    // boolean型 スタイル変更
      hover,    // boolean型 スタイル変更
      type,
    } = this.state
    // _______________________________________________________________________________________________________

    // 状態によって変更されるスタイルの記述
    Object.assign(this.text_style, {
      boxShadow:
        disabled ? null :
          hover ? "0px 0px 3px 0px #00f" :
            null,
      backgroundColor: //　優先順位順に背景色を設定
        disabled ? "transparent" :
          focus ? "#dfdfff" :
            value ? "#fff" :
              null,
    })
    // _______________________________________________________________________________________________________

    return (
      <input
        // autoComplete = "off"
        autoFocus={autoFocus === 'true'}
        type={type}
        style={Object.assign({}, this.text_style)}
        maxLength={maxLength}
        value={value}
        onChange={this.handleOnChange}
        onBlur={this.handleOnBlur}
        onKeyDown={onKeyDown}
        disabled={disabled}
        placeholder={placeholder}
        onFocus={() => this.setState({ focus: true })}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      />
    )
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
