import React from 'react';
import { connect } from 'react-redux';
import * as SKY from "../../component/SmartComponent";
import { loginPage as Colors } from "../../component/Colors";
import loginBgImg from '../../image/tablet.png';
import ErrorMsgBar from '../../component/ErrorMsgBar';

import { login, loginEdit, searchErr, logOut } from '../../comRedux/Actions';
import { axiosPost, server } from '../../comRedux/axiosPost';
import LoginPageInfo from '../../constant/LoginPageInfo';


class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.wrap_style = {
      height: '100vh',
      width: '100%',
      minWidth: '100vh',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: `url(${loginBgImg}) no-repeat`,
      backgroundPosition: "center center",
      backgroundSize: "auto 100%",
    }
    this.form_style = {
      display: 'flex',
      flexDirection: "column",
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderRadius: 20,
      padding: 64,
    }
    this.sysytemName_style = {
      fontSize: 72,
      fontWeight: 'bold',
      color: Colors.basic,
      marginBottom: 24,
      fontFamily: "'Georgia', serif",
    }
    this.sysytemTitle_style = {
      fontSize: 24,
      fontWeight: 'bold',
      color: Colors.basic,
      marginBottom: 64,
    }
  }
  // ____________________________________________________________________

  componentDidMount() {
    // Enterキーのイベントをリッスンし、Enterが押されたときにログイン処理を実行する
    window.addEventListener('keypress', this.handleOnEnter, true);
  }

  componentWillUnmount() {
    // コンポーネントがアンマウントされる際に、イベントリスナーを削除する
    window.removeEventListener('keypress', this.handleOnEnter, true);
  }

  // ____________________________________________________________________

  // Enterキーが押されたときに呼び出され、ログイン処理を実行するメソッド
  handleOnEnter = e => {
    if (e && e.keyCode === 13) {
      e.preventDefault(); // ここでフォームの既定の送信動作をキャンセル
      this.handleOnClick(e); // Enterキーが押されたらログイン処理を実行する
    }
  }

  // ____________________________________________________________________

  handleOnClick = (e) => {
    if (!this.props.userCode) {
      this.props.dispatch(searchErr('ユーザー名を入力してください!!'));
      return;
    }
    var returnJson = {
      userCode: this.props.userCode,
      password: this.props.password,
      langName: LoginPageInfo.lang[this.props.langName],
    };
    this.props.dispatch(searchErr(''));
    this.props.dispatch(loginEdit({ 'didSet': false }));
    axiosPost.post(server + '/login/login', returnJson)
      .then((res) => {
        let member = res.data;
        if (member) {
          if (member.loginSuccessed) {
            this.props.dispatch(login(member));
          } else {
            this.props.dispatch(searchErr('ユーザー名/パスワードが一致しておりません'));
          }
        } else {
          this.props.dispatch(searchErr('ログインできません'));
        }
        this.props.dispatch(loginEdit({ 'didSet': true }));
      })
      .catch(err => {
        alert(err);
        this.props.dispatch(loginEdit({ 'didSet': true }));
        return
      })
  }

  // テキストボックスの内容が変更された時にstateを更新
  handleOnChangeValue = (name, value) => {
    this.props.dispatch(loginEdit({ [name]: value }));
  }

  render() {

    // JSONデータの読み込みが完了しているか？
    if (!this.props.didSet) {
      return <SKY.Loading style={{ margin: 'auto', }} />
    }
    return (
      <div style={this.wrap_style}>
        <div style={this.form_style}>
          <SKY.Row style={this.sysytemName_style} center>WAKOCSS</SKY.Row>
          <SKY.Row style={this.sysytemTitle_style} center>顧客管理システム</SKY.Row>
          <SKY.Row marginBottom={20}>
            <ErrorMsgBar />
          </SKY.Row>
          <SKY.Row marginBottom={20} center>
            <SKY.LoginText placeholder="ユーザー名を入力してください" name="userCode" must="true" value={this.props.userCode} onChange={(name, value) => { this.handleOnChangeValue(name, value) }} style={{ width: "100%", margin: null, }} className="login_text" autoFocus="true" />
          </SKY.Row>
          <SKY.Row marginBottom={52} center>
            <SKY.LoginText placeholder="パスワードを入力してください" name="password" type="password" must="true" value={this.props.password} onChange={(name, value) => { this.handleOnChangeValue(name, value) }} style={{ width: "100%", margin: null, }} className="login_text" />
          </SKY.Row>
          <SKY.Row center>
            <SKY.LoginBtn label='ログイン' onClick={this.handleOnClick} />
          </SKY.Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownPropS) => {
  return {
    ...state.COMMON.Login,
  }
}

export default connect(mapStateToProps)(LoginPage);