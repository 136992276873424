import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import LoginPage from './LoginPage';
import Blank from '../../component/Blank';
import Menu from './Menu';

class Entry extends Component {

  render() {
    // メニューの選択状態
    let Content = null;
    let { entryType } = this.props;
    console.log("entryType:", entryType)
    switch (entryType) {
      case 0:
        // 一覧画面にページ移動
        Content = (() => <LoginPage />);
        break;
      case 1:
        // 入力画面にページ移動
        Content = (() => <Menu />);
        break;
      default:
        Content = (() => <Blank />);
        break;
    };
    return (
      <Content />
    );
  }
}
const mapStateToProps = (state, ownPropS) => {
  return {
    entryType: state.COMMON.Login.entryType,
  }
}
export default connect(mapStateToProps)(Entry);