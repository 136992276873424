import * as types from './ActionConst';
import { checkProps, toJson, selectImportantData } from '../util/commonInput';
import { getExcel, getPdf, getCsv } from '../util/outputUtil';
import { axiosPost, server } from './axiosPost';
import SkyConfirm from '../component/Confirm';

//システム状態関係
export const login = (payload) => {
  payload['userType'] = '';
  // payload['itemWidth'] = 15;
  // payload['itemLength'] = 15; //デフォールトは15
  if (payload.muserAccountModel && payload.muserAccountModel.hasOwnProperty('userType')) {
    payload['userType'] = payload.muserAccountModel['userType'] || '';
  }
  return ({ type: types.LOGIN, payload: payload })
};
export const loginEdit = (payload) => ({ type: types.LOGINEDIT, payload: payload });
export const logOut = () => ({ type: types.LOGOUT });
export const menu = (menuList) => ({ type: types.MENU, menuList })
/*************************************共通Action*************************************/

/*
getReducerData
共通Reducer（その他のReducer）の内容を取得するためのActionです
Parameter：　key　→　取得したDataはKeyのReducerにあげます
       orgReducer　→　取得するデータの居場所
               例えば：　共通ReducerのLogin区分の内容を取得したい場合
                    orgReducerは'COMMON.Login'になる
Function：　selectImportantData　→　orgReducerを条件として、Stateの中にデータを抽出するFunction
            dispatch,getState　→　Redux　内部定義のFunction
*/
export const getReducerData = (key, orgReducer) => (dispatch, getState) => {
  const state = getState();
  const data = selectImportantData(state, orgReducer);
  return dispatch({ type: types.GET_REDUCER_DATA + key, data });
}
//KeyのReducerに自由にデータを更新する
export const updateReducer = (key, payload) => ({ type: types.UPDATEREDUCER + key, payload });

export const back = (key, page, mode) => ({ type: types.BACK + key, page: page, mode: mode }); // 戻る(引数はKEYと戻り先にしたいCurrentPage番号)
//入力画面の離脱確認//reduxのMiddlewareを利用して　Dispatchの内部転送やっています
export const checkBeforeBack = (key, page, mode) => async (dispatch, getState) => {
  //画面の項目保存
  dispatch(setDidSet(key, 'inputValues', true))
  //※getStateというFunctionはReduxのStoreの値を取れます
  if (getState()[key].inputValues.isChanged) {
    await SkyConfirm('入力された項目がありますが、本当に戻りますか').then(
      res => {
        if (res === true) {
          dispatch(back(key, page, mode))
          dispatch(pageMove({ currentPage: page, mode: mode }));
          dispatch(inputErr("")); //ErrMsgClear
        } else {
          return;
        }
      },
      err => { return }
    )
  } else {
    dispatch(back(key, page, mode))
    dispatch(pageMove({ currentPage: page, mode: mode }));
    dispatch(inputErr("")); //ErrMsgClear
  }
}
//コードで名称を自動引っ張るロジック
//Java側は全部Keyという名で値を受けるように固定
//FrontEnd側はRes.data.labelという形で名称を取る(議論：名称を直で返すことはよいか
export const getName = (key, props, url, prefix, lang) => (dispatch) => {
  let para = prefix
  let path = server + url + '?key=' + props[para].value;
  if (lang && lang !== '') path += '&lang=' + lang;
  axiosPost.get(path)
    .then((res) => {
      if (res.data != null) {
        dispatch(formEdit(key, prefix.slice(0, -4) + 'Name', res.data.label))
        dispatch(setDidSet(key, 'inputValues', true))
      }
    }).catch(
      err => {
        console.log(err)
      }
    );
}

export const setDidSet = (key, name, didSet) => ({ type: types.SETDIDSET + key, name, didSet });

export const tableState = (key, newTableState) => ({ type: types.TABLESTATE + key, newTableState }); //検索リスト現在頁の記憶

// 必須チェック(検索画面)
export const doCheck = (key, props) => (dispatch) => {
  dispatch(setDidSet(key, 'searchListValues', false))
  if (!checkProps(props)) {
    //dispatch(check(key));
    dispatch(searchErr("必須項目を入力してください"));
    dispatch(show(key, false));
    dispatch(setDidSet(key, 'searchListValues', true));
  } else {
    dispatch(search(key, props.selectValues));
    dispatch(searchErr("")); //ErrMsgClear
    dispatch(loadSearchList(key, '/' + key + '/searchList', toJson(props)));
  }
};
// 必須チェック(登録画面)
export const doConfirm = (key, props, url, json, backFunc) => async (dispatch) => {
  dispatch(setDidSet(key, 'inputValues', false))
  if (!checkProps(props)) {
    dispatch(inputErr("必須項目を入力してください"));
    dispatch(setDidSet(key, 'inputValues', true));
  } else {
    dispatch(inputErr("")); //ErrMsgClear
    await dispatch(confirm(key, url, json, backFunc));
  }
};
// 登録処理
export const doUpdate = (key, props, url, json, backFunc) => async (dispatch) => {
  dispatch(setDidSet(key, 'inputValues', false))
  //削除モード以外の時，必須Checkします
  if (props.mode !== '3' && !checkProps(props)) {
    dispatch(inputErr("必須項目を入力してください"));
    dispatch(setDidSet(key, 'inputValues', true));
  } else {
    dispatch(inputErr("")); //ErrMsgClear
    await dispatch(update(key, url, json, backFunc));
  }
};
// 必須チェック(登録画面)
export const checkBeforeUpdate = (key, props, url, json) => (dispatch) => {
  dispatch(inputErr("")); //ErrMsgClear
  if (!checkProps(props)) {
    dispatch(inputErr("必須項目を入力してください"));
  } else {
    dispatch(update(key, url, json));
  }
};

//エラー関係
export const error = (errMsg) => ({ type: types.ERR, errMsg }); // メッセージ表示エラー
export const searchErr = (errMsg) => ({ type: types.SEARCH_ERR, errMsg }); // メッセージ表示エラー
export const inputErr = (errMsg) => ({ type: types.INPUT_ERR, errMsg }); // メッセージ表示エラー
export const fatalError = (errMsg) => ({ type: types.FATALERR, errMsg }); // エラー画面に遷移
export const sessionError = () => ({ type: types.SESSIONERR }); // セッションエラー画面に遷移
export const clearError = () => ({ type: types.CLEARERR }); //(Input/Search)エラーメッセージをクリア
//DetailList
export const getDetailList = (key, payload) => ({ type: types.DETAIL + key, payload });

export const loadDetailList = (key, url, json) => (dispatch) => {
  axiosPost.post(server + url, json)
    .then((res) => {
      if (res.data != null) {
        if (res.data.formErrorMsg && res.data.formErrorMsg !== '') {
          dispatch(searchErr(res.data.formErrorMsg))
        } else {
          // dispatch(pageMove({ currentPage: 3 }));
          dispatch(loadDetail(key, res.data));
        }
      }
    }).catch(err => {
      // セッションエラー
      if (err && err.response) {
        if (err.response.status === 200) {
          return dispatch(sessionError(err))
        } else {
          return dispatch(fatalError(err.response.data.exception + '\n' + err.response.data.message))
        }
      } else {
        //FataErrorのNetWorkNotExist
        return dispatch(fatalError('unknown error'))
      }
    })
}

// 検索画面の入力値をjava側から受け取って更新する
export const loadSearch = (key, url, json) => (dispatch) => { // 編集・削除・複写時にデータを取得する
  axiosPost.post(server + url, json).then(
    function (res) {
      try {
        if (res.data != null) {
          if (res.data.formErrorMsg != null) {
            dispatch(searchErr(res.data.formErrorMsg))
          } else {
            dispatch(setSearch(key, res.data, json.mode));

          }
        }
      } catch (err) {
        // セッションエラー
        if (err.response) {
          if (err.response.status === 200) {
            return dispatch(sessionError(err))
          } else {
            return dispatch(fatalError(err.response.data.exception + '\n' + err.response.data.message))
          }
        } else {
          //FataErrorのNetWorkNotExist
          return dispatch(fatalError('unknown error'))
        }
      }
    }
  )
}


//Name　命名ルール　「.」で階層を表示「.」がない時、当該Reducerの直下の意味と認識
export const loadDetail = (key, value) => ({ type: types.LOADDETAIL + key, value });


//SearchList関係　Start
export const show = (key, value) => ({ type: types.SHOW_SEARCHLIST + key, showFlg: value }); // searchListを出す
export const searchEdit = (key, name, value, shouldRender) => ({ type: types.SEARCHEDIT + key, name, value, shouldRender }); // 検索画面の入力情報の編集(値変更時にstateに更新)
export const searchListEdit = (key, name, value, shouldRender) => ({ type: types.SEARCHLISTEDIT + key, name, value, shouldRender }); // 検索画面の入力情報の編集(値変更時にstateに更新)

export const loadSearchList = (key, url, json) => (dispatch) => { // 検索し、検索結果を取得する
  console.log("検索URL：", url)
  console.log("検索postData：", json)
  axiosPost.post(server + url, json)
    .then((res) => {
      if (res.data != null) {
        console.log("検索resData：", res.data)

        if (res.data.formErrorMsg && res.data.formErrorMsg !== '') {
          dispatch(show(key, false));
          dispatch(openSearchArea(key, true));
          dispatch(searchErr(res.data.formErrorMsg))
        } else {

          if (res.data.searchList === null || res.data.searchList.length === 0) {
            dispatch(show(key, false));
            dispatch(openSearchArea(key, true));
            dispatch(searchErr('データは存在しません!!'))
          } else {
            dispatch(openSearchArea(key, false));
            dispatch(setSearchList(key, res.data));
          }
        }
        dispatch(setDidSet(key, "searchValues", true));
        dispatch(setDidSet(key, "searchListValues", true));
        dispatch(setDidSet(key, "inputValues", true));
      }
    }).catch(err => {
      // セッションエラー
      if (err && err.response) {
        if (err.response.status === 200) {
          return dispatch(sessionError(err))
        } else {
          return dispatch(fatalError(err.response.data.exception + '\n' + err.response.data.message))
        }
      } else {
        //FataErrorのNetWorkNotExist
        return dispatch(fatalError('unknown error'))
      }
    })
}
export const openSearchArea = (key, value) => ({ type: types.OPENSEARCHAREA + key, value }); // 
export const openInputArea = (key, value) => ({ type: types.OPENINPUTAREA + key, value }); // 

export const clearSearchList = (key) => ({ type: types.CLEARSEARCHLIST + key });
//SearchList関係　End

//input画面関係　　Start
export const checkOn = (key) => ({ type: types.CHECKON + key }); // 
export const checkOff = (key) => ({ type: types.CHECKOFF + key }); // 
export const formEdit = (key, name, value) => ({ type: types.FORMEDIT + key, name, value }); // 入力画面の入力情報の編集(値変更時にstateに更新)
export const formPropEdit = (key, name, propaty, value) => ({ type: types.FORMPROPEDIT + key, name, propaty, value }); // 入力画面の入力情報の編集(値変更時にstateに更新)
export const detail = (key, payload) => (dispatch) => {
  switch (payload.mode) {
    case '2':
      dispatch(edit(key, payload));
      break; // 編集
    case '3':
      dispatch(del(key, payload));
      break; // 削除
    case '4':
      dispatch(copy(key, payload));
      break; // 複写
    default:
      return
  }
}
export const add = (key) => ({ type: types.ADD + key }); // 新規登録ボタン押した時
export const initInput = (key) => ({ type: types.INITINPUT + key });

export const confirm = (key, url, json, backFunc) => async (dispatch) => { // 確認ボタン押した時
  console.log('confirm post', json)
  dispatch(setDidSet(key, 'inputValues', false))
  await axiosPost.post(server + url, json)
    .then(async (res) => {
      console.log('confirm res', res)
      if (res.data != null) {
        // 明細エラーがあればdetailListに格納する
        const { formErrorMsg } = res.data
        if (formErrorMsg != null) {
          dispatch(inputErr(formErrorMsg))
        }
        // java側から受け取ったhasErrorがtrueなら、確認画面へ移動しないようにする
        if (res.data.hasError !== true) {
          Object.assign(res.data, { "checked": true });
          // dispatch(checkOn(key));
        }
        res.data['doNotUpdateHistory'] = true
        // Storeの更新
        await dispatch(setInput(key, res.data));
        // リターンデータ取得処理
        backFunc && await backFunc(res.data);

      }
    }).catch(err => {
      // セッションエラー
      if (err.response) {
        if (err.response.status === 200) {
          return dispatch(sessionError(err))
        } else {
          return dispatch(fatalError(err.response.data.exception + '\n' + err.response.data.message))
        }
      } else {
        //FataErrorのNetWorkNotExist
        return dispatch(fatalError('unknown error'))
      }
    })
  dispatch(setDidSet(key, 'inputValues', true))
}


export const update = (key, url, json, backFunc) => async (dispatch) => { // 登録ボタン押した時
  console.log('complete post', json)
  await axiosPost.post(server + url, json)
    .then(async (res) => {
      if (res.data != null) {
        console.log('complete res', res.data)
        if (res.data.formErrorMsg != null) {
          dispatch(inputErr(res.data.formErrorMsg))
          dispatch(setDidSet(key, 'inputValues', true))
        } else {
          if (!!backFunc && typeof backFunc === 'function') {
            await backFunc(res.data);
            return;
          } else {
            dispatch(back(key, 1, json.mode));
            dispatch(pageMove({ currentPage: 1, mode: json.mode })); //共通アリアのCurrentPage,mode更新
          }
        }
      }
    }).catch(err => {
      // セッションエラー
      if (err.response) {
        if (err.response.status === 200) {
          return dispatch(sessionError(err))
        } else {
          return dispatch(fatalError(err.response.data.exception + '\n' + err.response.data.message))
        }
      } else {
        //FataErrorのNetWorkNotExist
        return dispatch(fatalError('unknown error'))
      }
    })
}


export const updateFromSearch = (key, url, json, returnPage, stayFlg) => (dispatch) => { // 登録ボタン押した時
  axiosPost.post(server + url, json)
    .then((res) => {
      if (res.data != null) {
        if (res.data.formErrorMsg != null) {
          //Search画面から直で更新する機能
          dispatch(searchErr(res.data.formErrorMsg));
          dispatch(setDidSet(key, 'searchListValues', true));
        } else {
          // dispatch(pageMove({currentPage:returnPage,mode:returnPage === 1?'0':json.mode}));//共通アリアのCurrentPage,mode更新
          if (!stayFlg) {
            dispatch(clearSearchList(key));
            dispatch(show(key, false));
            dispatch(setDidSet(key, 'searchValues', true));
          }
          dispatch(setDidSet(key, 'searchListValues', true));
        }
      }
    }).catch(err => {
      // セッションエラー
      if (err.response) {
        if (err.response.status === 200) {
          return dispatch(sessionError(err))
        } else {
          return dispatch(fatalError(err.response.data.exception + '\n' + err.response.data.message))
        }
      } else {
        //FataErrorのNetWorkNotExist
        return dispatch(fatalError('unknown error'))
      }
    })
}


export const loadInput = (key, url, json) => async (dispatch) => { // 編集・削除・複写時にデータを取得する
  console.log('入力画面postdata:', json)
  dispatch(clearError()); // エラーメッセージのクリア
  await axiosPost.post(server + url, json).then(
    function (res) {
      try {
        if (res.data != null) {
          console.log('入力画面resdata:', res.data)
          // エラーがあれば、Input画面用エラーメッセージを表示して処理を中断
          if (res.data.hasError === true) {
            dispatch(inputErr(res.data.formErrorMsg))

          } else {
            // Input画面用エラーメッセージを表示
            if (res.data.formErrorMsg != null) {
              dispatch(inputErr(res.data.formErrorMsg))
            }
            // java側からmode（追加、修正、削除、複写）の指定があれば優先して画面を表示。なければReactの前画面より受け取り
            const mode = res.data.mode || json.mode
            // Java側から受け取った値をStoreにセット
            dispatch(setInput(key, res.data, mode));
          }
        }
      } catch (err) {
        // セッションエラー
        if (err.response) {
          if (err.response.status === 200) {
            return dispatch(sessionError(err))
          } else {
            return dispatch(fatalError(err.response.data.exception + '\n' + err.response.data.message))
          }
        } else {
          //FataErrorのNetWorkNotExist
          return dispatch(fatalError('unknown error'))
        }
      }
    }
  )
}
//input画面関係　　End

// 出力関係
export const excel = (key, url, json, fileName, btnID) => (dispatch) => {
  dispatch(buttonStatus(btnID, true));
  getExcel(url, json, fileName).then(
    res => {
      if (!!res.headers.error) {
        dispatch(searchErr('ERROR:' + decodeURIComponent(res.headers.msg)))
      }
      dispatch(buttonStatus(btnID, false));
    }
  )
};
export const pdf = (key, url, json, fileName, btnID) => (dispatch) => {
  dispatch(buttonStatus(btnID, true));
  getPdf(url, json, fileName).then(
    res => dispatch(buttonStatus(btnID, false))
  )
};
export const csv = (key, url, json, fileName, btnID) => (dispatch) => {
  dispatch(buttonStatus(btnID, true));
  getCsv(url, json, fileName).then(
    res => dispatch(buttonStatus(btnID, false))
  )
};

export const buttonStatus = (id, addWhenTAndDelWhenF) => ({ type: types.BUTTONSTATUS, id: id, value: addWhenTAndDelWhenF });
// 出力関係　END

//action内部用DispatchはExportしません
const check = (key) => ({ type: types.CHECK + key }); // チェック
export const search = (key) => ({ type: types.SEARCH + key }); // 検索
export const setSearchList = (key, value) => ({ type: types.LOADSEARCHLIST + key, value }); // 一覧表示
export const setSearch = (key, search) => ({ type: types.LOADSEARCH + key, search }); //searchをセット（主に初期値セット）
const setInput = (key, json, mode) => ({ type: types.LOADINPUT + key, json, mode }); // 入力

//payload=必要条件のObject
const del = (key, payload) => ({ type: types.DELETE + key, payload }); // 削除
const copy = (key, payload) => ({ type: types.COPY + key, payload }); // 複写
const edit = (key, payload) => ({ type: types.EDIT + key, payload }); // 修正
export const pageMove = (payload) => ({ type: types.PAGEMOVE, payload }); //currentPageの移動
export const setPMaster = (payload) => ({ type: types.SETPMASTER, payload }); //currentPageの移動
export const setProgress = (value) => ({ type: types.SET_PROGRESS, value }); //ProgressComponentのトリガー
export const setProgressInfo = (payload) => ({ type: types.SET_PROGRESS_INFO, payload }); //Progress情報をセット
export const clearProgressInfo = (progressing) => ({ type: types.CLEAR_PROGRESS_INFO, progressing });