import React, { Fragment } from 'react';
import PMaster from './PMaster';
import * as Icons from "./Icons";
import * as Parts from './TableComponents/TableComponent';
import { table as Colors } from './Colors';
import { formatNumber, funcAdapter, checkProps, connectStr } from '../util/commonInput';
import * as tableFunc from './TableComponents/tableFunction';

export default class ListTable extends React.Component {
  constructor(props) {
    super(props);
    const {
      setData,
      children,
      maxRows,
      startPage,
      initialState, // テーブルの初期Stateの設定
      detailOpen = false, // 明細型テーブルの、明細を初期表示するか否か
      headerFixedLength, // ヘッダーを止める高さ
    } = this.props;

    this.state = {
      setData: setData,   // セットされたデータ
      initSetData: [],   // 初期状態保存のデータ
      maxRows: maxRows,   // 一度に表示する行の最大数
      pageCount: startPage || 1,   // 現在のページ数
      startRows: (startPage - 1) * maxRows || 0,      // 表示中の開始行数
      changeRow: "",     // 表示件数を変更するテキストボックスの値
      detailOpen: detailOpen || false, // 明細全てを表示/非表示

      sortKey: null,   // データをソートするときに使用されるkeyの管理
      sortType: "0",  // ソートの種類　0：ソート無し、1：降順、２：昇順
      sortIndex: null,  // ソートする配列データの配列番号

      filterKey: null,    // フィルタリングするデータのkey
      filterConnecter: "", // フィルタリングするデータのconnecter
      filterValue: null,  // フィルタリングの入力値
      filterIndex: null,  // フィルタリングする配列データの配列番号

      // filterLists: tableFunc.initFilterLists(setData, children),
      filterLists: {}, //　フィルターリスト
      inUseFilterLists: {}, //　使用中のフィルターリスト（チェックされている値があるもののみ） 
    }

    this.wrapper = {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: 'column',
    }

    this.header_style = {
      position: "sticky",
      zIndex: 1,
      width: "100%",
      top:
        headerFixedLength ? headerFixedLength :
          document.getElementById("body").getAttribute("name") === "modal" ? 0 : // modal画面なら
            'calc(var(--topMenuHeight) - 1px)',
    }
    this.table_style = {             // <table> テーブル
      width: "100%",
      tableLayout: "fixed",
      borderCollapse: "separate",
      borderRadius: 5,
      backgroundColor: "#fff",
    }
    if (initialState) {
      Object.assign(this.state, initialState) // 初期値の設定
    }
  }
  // _______________________________________________________________________________________________________________
  async componentDidMount() {
    const { children, setData = [] } = this.props;
    if (!setData) { return }
    const setData_copy = setData.map(obj => Object.assign({}, obj))  // 配列stateのコピーを作成
    // valueを結合したリストを新しく生成します。
    for (let i in setData_copy) {
      for (let j in children) {// childrenで設定されている数だけループ
        const { name, value, type, secondConf, array } = children[j] // 省略宣言
        const setName = funcAdapter(name, setData_copy[i]);
        const setValue = funcAdapter(value, setData_copy[i]);
        if (setName && setValue) {
          Object.assign(setData_copy[i], { [setName]: setValue })  // Object.assign()メソッドでJson結合
        }

        // 2行型テーブルの場合
        if (type === 'secondRow') {
          for (let k in secondConf) {
            const { name, value } = secondConf[k]
            const setName = funcAdapter(name, setData_copy[i]);
            const setValue = funcAdapter(value, setData_copy[i]);
            if (setName && setValue) {
              Object.assign(setData_copy[i], { [setName]: setValue })  // Object.assign()メソッドでJson結合
            }
          }
        }
      }
    }
    console.log(setData_copy)
    this.setState({ initSetData: setData_copy });

    // フィルター初期値の設定
    if (this.props.initialState) {
      const { sortType, sortKey, sortIndex, inUseFilterLists } = this.props.initialState;
      console.log("initialState", this.props.initialState);
      // フィルター初期値の反映
      const filterdData = tableFunc.doFilter(setData_copy, inUseFilterLists) // フィルタリングされたsetDataを取得
      // ソート初期値の反映
      if (sortKey && sortType === '1') {
        this.orderByAsc(sortKey, sortIndex, filterdData);
      } else if (sortKey && sortType === '2') {
        this.orderByDesc(sortKey, sortIndex, filterdData);
      } else {
        this.setState({ setData: filterdData });
      }

    } else {
      this.setState({
        setData: setData_copy,
        filterLists: tableFunc.initFilterLists(setData_copy, children),
      });
    }
  }
  // ________________________________________________________________________________________________________________
  shouldComponentUpdate(nextProps, nextState) {
    //setData変更時
    // update when children change
    const { children, setData } = nextProps;     // 省略宣言
    if (this.props.children !== children) {
      const setData_copy = setData ? setData.slice() : []  // 配列stateのコピーを作成
      for (let i in setData) {        // 新たに送られてくる配列の文だけループ
        for (let j in children) {      // childrenで設定されている数だけループ
          const { name } = children[j]                    // 省略宣言
          if (setData[i] && setData[i][name]) {                                          // nameが設定されていたら
            Object.assign(setData_copy[i], { [name]: setData[i][name] })  // Object.assign()メソッドでJson結合
          }
        }
      }
      this.setState({ setData: setData_copy }) // コピーした配列をセット
      return true
    } else {
      // update when state's setData change
      const { setData } = nextState;     // 省略宣言
      if (this.state.setData !== setData) {   // setDataが変化したら
        return true
      }
    }

    const { maxRows, pageCount, startRows, detailOpen } = this.state
    if (nextState.maxRows !== maxRows) { return true }
    if (nextState.pageCount !== pageCount) { return true }
    if (nextState.startRows !== startRows) { return true }
    if (nextState.detailOpen !== detailOpen) { return true }
    return false;
  }
  // ________________________________________________________________________________________________________________
  // 
  handleOnCheckFilter = (list, key) => {
    const { maxRows, pageCount, sortKey, sortType, sortIndex, initSetData } = this.state;

    console.log(list, key)
    // 更新用フィルターリストを作成
    const filterLists_copy = Object.assign({}, this.state.filterLists);
    Object.assign(filterLists_copy, { [key]: list });

    const inUseFilterLists = tableFunc.getUseFilterLists(filterLists_copy) // フィルタリングされたsetDataを取得
    const filterdData = tableFunc.doFilter(initSetData, inUseFilterLists) // フィルタリングされたsetDataを取得

    this.setState({
      setData: filterdData, // 絞り込み後のデータをセット
      filterLists: filterLists_copy, // フィルターリストを更新
      inUseFilterLists: inUseFilterLists,  // 使用中のフィルターリストを更新
    })
    // 絞り込み件数が表示件数より少なければ、ページ数カウントを初期化。（データがないのにページがそのままにならないように）
    if (filterdData.length <= maxRows * (pageCount - 1)) {
      this.setState({
        startRows: 0,          // ページの開始行をリセット。
        pageCount: 1
      })
    }

    // 並び替えの再反映
    if (sortKey && sortType === '1') {
      this.orderByAsc(sortKey, sortIndex, filterdData);
    } else if (sortKey && sortType === '2') {
      this.orderByDesc(sortKey, sortIndex, filterdData);
    }
  }
  // _______________________________________________________________________________________________________

  // ソート用のボタンを押した時（データをソートする処理）
  orderByAsc = (key, arrIndex, orgData) => {
    const { setData } = this.state    // state省略宣言
    const setData_copy = orgData || setData.slice()
    let sortedData = tableFunc.orderByAsc(key, arrIndex, setData_copy)

    // 並び替え後のデータをセット
    this.setState({
      setData: sortedData,
      sortKey: key,
      sortType: "1",
    })
  }
  // _______________________________________________________________________________________________________
  // ソート用のボタンを押した時（データをソートする処理）Z -> A
  orderByDesc = (key, arrIndex, orgData) => {
    const { setData } = this.state    // state省略宣言
    const setData_copy = orgData || setData.slice()
    let sortedData = tableFunc.orderByDesc(key, arrIndex, setData_copy)

    // 並び替え後のデータをセット
    this.setState({
      setData: sortedData,
      sortKey: key,
      sortType: "2",
    })
  }
  // _______________________________________________________________________________________________________
  // ページングの"次へ"ボタンを押した時
  nextPaging = () => {
    const { pageCount, maxRows, startRows } = this.state
    this.setState({
      pageCount: pageCount + 1,           // ページ数カウントを＋１して
      startRows: startRows + maxRows,  // 現在の開始行に、現在の表示件数を追加             
    })
  }
  // ________________________________________________________________________________________________________________
  // ページングの"前へ"ボタンを押した時
  prePaging = () => {
    const { pageCount, maxRows, startRows } = this.state
    this.setState({
      pageCount: pageCount - 1,             // ページ数カウントを-１して
      startRows: startRows - maxRows,    // 現在の開始行から現在の表示件数を引く         
    })
  }
  // ________________________________________________________________________________________________________________
  // 【全件を表示する】ボタン押した時
  allView = () => {
    if (this.state.setData.length >= 500) {
      alert('データの全件表示は最大500件までです。')
      return;
    }
    this.setState({
      maxRows: null,  // 最大表示件数をnull
      startRows: 0,     // 表示開始行を初期化
      pageCount: 1,     // ページ数を初期化
      changeRow: "",    // テキストボックスを初期化
    })
  }
  // ________________________________________________________________________________________________________________
  // 【初期状態に戻す】ボタンを押した時
  resetView = () => {
    this.setState({
      maxRows: this.props.maxRows,   // 最大表示件数を初期化
      startRows: 0,                    // 表示開始行を初期化
      pageCount: 1,                    // ページ数を初期化
      changeRow: "",                   // テキストボックスを初期化
      setData: this.state.initSetData,   // フィルタリングメニューを閉じる

      sortKey: null,   // データをソートするときに使用されるkeyの管理
      sortType: "0",  // ソートの種類　0：ソート無し、1：降順、２：昇順
      sortIndex: null,  // ソートする配列データの配列番号

      filterKey: null,    // フィルタリングするデータのkey
      filterValue: null,  // フィルタリングの入力値
      filterIndex: null,  // フィルタリングする配列データの配列番号
    })
  }
  // ________________________________________________________________________________________________________________
  // 【表示件数を入力】テキストボックスに入力したとき
  countView = (value) => {
    let replaceValue = value.replace(/[^\d]/g, "");  // 0~9以外の入力値を空値に変換
    replaceValue = Number(replaceValue)                    // 数値型に変換( "" は 0 になる）
    this.setState({ changeRow: replaceValue })              // テキストボックスに表示
    if (replaceValue !== 0) {
      this.setState({
        maxRows: replaceValue,  // 最大表示件数を変更 
        startRows: 0,             // 表示開始行を初期化
        pageCount: 1,             // ページ数を初期化
      })
    }
  }
  // ________________________________________________________________________________________________________________
  // ヘッダーのチェックボックスをクリックしたとき
  handleOnAllCheck = (value, name, detail) => {
    const setData_copy = this.state.setData.slice();            // 配列をコピー
    const { children } = this.props;
    const checkData = tableFunc.allCheck(value, name, detail, children, setData_copy);
    this.setState({ setData: checkData })        // 変更後のデータをセット
  }
  // ________________________________________________________________________________________________________________
  // ヘッダーの明細表示スイッチをクリックしたとき
  handleOnDetailSwitch = () => {
    this.setState({ detailOpen: !this.state.detailOpen })
  }
  // ________________________________________________________________________________________________________________

  //　行をクリックしたとき
  rowOnClick = (rowData) => {
    // const { rowOnClick } = this.props;
    if (window.getSelection().type === "Range") { return; }
    this.props.rowOnClick(rowData, this.state)
  }
  // ________________________________________________________________________________________________________________
  handleOnChange = (obj) => {
    const { index, name, value, detailIndex } = obj;
    const setData_copy = this.state.setData.slice();            // 配列をコピー
    if (detailIndex || detailIndex === 0) {
      setData_copy[index]['detailVModels'][detailIndex][name] = value  // true or false を反転
    } else {
      setData_copy[index][name] = value  // true or false を反転
    }
    this.setState({ setData: setData_copy })        // 変更後のデータをセット
  }
  // ________________________________________________________________________________________________________________
  //　外部使用 function （　ref = { (el) = this.listTable_ref = el } の形で宣言できるようになります　)
  //return all the datas
  getTableData = () => {
    const setData_copy = Object.assign([], this.state.setData);
    return setData_copy;
  }
  //return checked records only
  //行をCheckする時、CheckのBindは'ediChecked'に設定してください
  getCheckedTableData = () => {
    const { setData } = this.state;
    let returnArr = [];
    for (let i in setData) {
      if (this.state.setData[i]['ediChecked'] === true) { returnArr.push(this.state.setData[i]); }
    }
    return returnArr;
  }
  // ______________________________________________________________________________________________________
  /////////////////////////////////////////////// ここからレンダー　///////////////////////////////////////////////////
  render() {
    const {
      children,       // (親から) <Table>{[ {...},{...} ]}</Table>　{...}の部分のこと
      addBtns,
      addTHeader,
      hiddenPageing,
      hideTheader,
      rowOnClick,
    } = this.props

    const {
      maxRows,        // 一度に表示する最大件数を設定する時に使用
      pageCount,
      setData,         // 親から渡されたデータの集合体
      startRows,
      detailOpen,
      filterLists,
      inUseFilterLists
    } = this.state
    // render関数内で使用される変数
    const tBodyList_arr = [];
    let firstIndex = ''
    if (!setData) {
      return <div>データがありません</div>
    }
    // _____________________________________________________________________________________________________
    // 親から受け取った値に対してデータを行に当てはめていく処理。
    for (let i = startRows; i < setData.length; i++) {
      tBodyList_arr.push(
        <Parts.TBodyListL
          {...this.props}
          state={this.state}
          key={'tbodyList' + i}
          rowOnClick={rowOnClick ? this.rowOnClick : null}
          onChange={this.handleOnChange}
          setData={setData[i]}
          children={children}
          color={i % 2 === 0 ? '#FFF' : Colors.evenRows}
          index={i}
          detailOpen={detailOpen}
        />
      )
      // ヘッダーのindex列の幅を調節するために、行頭のindexを取得して、Theaderタグに渡します
      if (i === startRows) firstIndex = startRows;
      // （親から）maxRowsで指定した行でループを抜ける
      if (maxRows && i + 1 >= maxRows * pageCount) break;
    }
    // ___________________________________________________________________________________________________________________

    return (
      <div style={this.wrapper}>
        <div style={this.header_style}>
          {hiddenPageing !== true && <Parts.TablePaging
            {...this.props}
            {...this.state}
            onClickPrePageBtn={this.prePaging}
            onClickNextPageBtn={this.nextPaging}
          >
            <Parts.ChangeRowsBtn
              countView={this.countView}
              allView={this.allView}
              resetView={this.resetView}
            />
            {addBtns}
          </Parts.TablePaging>}
          {/* テーブル */}
          {addTHeader}
          {!hideTheader &&
            <Parts.THeaderL
              {...this}
              setData={setData[0]}
              children={children}
              detailOpen={detailOpen}
              filterLists={filterLists}
              inUseFilterLists={inUseFilterLists}
              firstIndex={firstIndex}
            />}
        </div>
        <table style={this.table_style}>
          {tBodyList_arr}
        </table>
      </div>
    );

  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
