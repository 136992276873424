import React from 'react';
import * as Icons from "./Icons";
import { systemBtn as Colors } from './Colors'

import { getPMasterName } from '../util/commonInput';

export default class CreatePassBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false,
    }
  }
  // ______________________________________________________________________________

  // 「自動生成」ボタンを押した時
  handleOnClick = () => {
    const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";  // 生成する文字列に含める文字セット
    const num = "0123456789"; // 生成する文字列に含める文字セット
    const mark = "!*?#$%&^-'" + '"'; // 生成する文字列に含める文字セット
    let c = alpha + num + mark; // 生成する文字列に含める文字セット
    let cl = c.length;  // セットした文字数
    let pass = "";
    for(let i = 0; i < 8; i++ ){
      pass += c[Math.floor(Math.random()*cl)];
      let foundedAlpha = pass.match(/[a-zA-Z]/);  // アルファベットがあるかどうか
      let foundedNum = pass.match(/[0-9]/);       // 数字があるかどうか
      let foundedMark = pass.match(/[!?#%&\-\\`$^*'"\`]/);       // 記号があるかどうか
      if(i === 7 && (!foundedAlpha || !foundedNum || !foundedMark)) {
        pass = "";  // パスワード文字列をリセット
        i = -1;      //　再ループ
      }
    }
    
    const { onClick } = this.props;
    console.log(pass)
    onClick && onClick(pass)
  }
  // ______________________________________________________________________________

  render() {
    const {
      onKeyDown,
      label,
      children,
      hidden,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      plabel,
      plabel2,
      color,
    } = this.props
    const { hover, focus } = this.state
    let typeIcon = <Icons.Hammer width={12} height={12} color="#FFF" />;
    let btnColor = Colors.calc;
    let Plabel = plabel && getPMasterName(plabel);
    let Plabel2 = plabel2 && getPMasterName(plabel2);
    if (Plabel === 'NONE') { return null }
    if (hidden) { return null }
    let reverse = false

    const wrap_style = {
      border: 'none',
      padding: 0,
      height: 32,
      borderRadius: 2,
      letterSpacing: 0.6,
      fontWeight: "bold",
      fontSize: 12,
      cursor: "pointer",
      userSelect: 'none',
      backgroundColor: color || btnColor, // propsでカラーが設定されていたら優先
      marginLeft: marginLeft,
      marginRight: marginRight,
      marginTop: marginTop,
      marginBottom: marginBottom,
    }
    const inner_style = {
      padding:
        reverse ? '0px 12px 0px 16px' :
          '0px 16px 0px 12px',
      width: '100%',
      height: '100%',
      borderRadius: 'inherit',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: "0.2s",
      flexDirection:
        reverse ? 'row-reverse' :
          null,
      backgroundColor:
        hover || focus? 'rgba(255, 255, 255, 0.4)' :
          null,
    }
    const label_style = {
      color: '#fff',
      margin:
        reverse ? '0px 8px 0px 0px' :
          '0px 0px 0px 8px',
    }
    // ______________________________________________________________________________

    // ______________________________________________________________________________

    return (
      <button
        style={wrap_style}
        onClick={e => this.handleOnClick(e)}
        onKeyDown={onKeyDown}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >
        <div style={inner_style}>
          {typeIcon}
          <span style={label_style}>{Plabel}{Plabel2}{label}</span>
          {children}
        </div>
      </button>
    )
  }
}

