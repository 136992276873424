import React from 'react';
import Icon from './Icon';

export default class ArrowRightCircle extends React.Component {
  render() {
    return (
      <Icon
        {...this.props}
        viewBox="0 0 512 512"
        d="M256,0C114.608,0,0,114.616,0,256s114.608,256,256,256c141.376,0,256-114.616,256-256S397.376,0,256,0z
		        M376.224,265.783l-131.912,95.231c-3.71,2.399-8.522,2.305-12.131-0.246c-3.623-2.55-5.333-7.036-4.333-11.348l10.898-46.623
		        h-89.334c-8.652,0-15.681-7.014-15.681-15.68v-62.233c0-8.666,7.028-15.68,15.681-15.68h89.334l-10.898-46.623
		        c-1-4.312,0.71-8.798,4.333-11.348c3.609-2.551,8.42-2.638,12.131-0.24l131.912,95.225c3.146,2.276,5.001,5.913,5.001,9.783
		        S379.37,263.514,376.224,265.783z"
      />
    )
  }
}