import React from 'react';
import Icon from './Icon';

export default class SearchBoard extends React.Component {
  render() {
    return (
      <Icon
        {...this.props}
        viewBox="0 0 512 428.33"
        d="M41.8,367.8V83.6H178.3v66.9H273V83.6H409.5v98.5a139.82,139.82,0,0,1,41.8,25.5V41.8H0V409.6H249.4a141.89,141.89,0,0,1-25.5-41.8Z M503.6,421.5l-45.1-45.1a118.44,118.44,0,1,0-40.3,40.3l45.1,45.1a28.5,28.5,0,0,0,40.3-40.3Zm-94.9-54.6a72.66,72.66,0,0,1-51.3,21.3,71.82,71.82,0,0,1-51.3-21.3,72.66,72.66,0,0,1-21.3-51.3,71.82,71.82,0,0,1,21.3-51.3A72.66,72.66,0,0,1,357.4,243a71.65,71.65,0,0,1,51.3,21.3,72.43,72.43,0,0,1,0,102.6Z"
      />
    )
  }
}