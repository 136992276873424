import React, { Fragment } from 'react';
import * as SKY from "component/SmartComponent";
import { axiosPost, server } from 'comRedux/axiosPost';
import * as smartUtil from 'util/commonInput';

export default class FacilitySearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableOpen: false,
      loading: true,
      //ItemFormBeanの必要項目をstateで一時管理
      facilityCodeSrch: '',
      facilityNameSrch: '',
      kenCodeSrch: ''
    };
    this.kenCodes = [];
  }
  // ______________________________________________________________________________________________________________________
  // 画面ロード時
  async componentDidMount() {
    //コンボのOptions準備
    await smartUtil.getOptions("/MName/getCombo?key=KEN").then(res => this.kenCodes = res, err => console.log(err));

    if (this.props.setData) {
      this.setState({ ...this.state, ...this.props.setData });
    }
    this.setState({ loading: false });

  }
  // _______________________________________________________________________________________
  // 一覧を選択した時
  click_select = (rowData) => {
    this.props.onClick && this.props.onClick(rowData);
  };
  // _______________________________________________________________________________________
  // テキストボックスの内容が変更された時にstateを更新
  handleOnChangeValue = (name, value) => {
    this.setState({ [name]: value });
  };
  // ______________________________________________________________________________________________________________________
  // 検索ボタンを押した時
  click_search = () => {
    let json = this.state;
    this.setState({ loading: true, tableOpen: false });
    console.log(json);
    axiosPost.post(server + '/facility/searchList', json)
      .then((res) => {
        if (res.data != null) {
          this.setData = res.data.searchList; // ここでデータが変わる
          // データ処理
          this.setState({ tableOpen: true });
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ loading: false });
        // セッションエラー
        console.log(err);
      });
  };
  // ______________________________________________________________________________________________________________________
  render() {
    const { tableOpen, loading } = this.state;
    return (
      <Fragment>
        {loading && <SKY.Loading popup />}
        <SKY.ModalBody label='施設参照'>
          <SKY.HideableTitle popup label='検索条件' col={24}>
            <SKY.SystemBtn type='search' label="検索" onClick={this.click_search} />
          </SKY.HideableTitle>

          <SKY.HideableArea container>
            <SKY.TextWrapper label="施設" colL={6} colR={18}>
              <SKY.Text type='alphanum' placeholder='施設コード' name="facilityCodeSrch" value={this.state.facilityCodeSrch} col={4} onChange={this.handleOnChangeValue} maxLength={10} />
              <SKY.Text name="facilityNameSrch" placeholder='施設名' value={this.state.facilityNameSrch} col={8} onChange={this.handleOnChangeValue} maxLength={30} />
            </SKY.TextWrapper>
            <SKY.TextWrapper label="都道府県" colL={6} colR={18}>
              <SKY.Select name="kenCodeSrch" value={this.state.kenCodeSrch} onChange={this.handleOnChangeValue} setData={this.kenCodes} />
            </SKY.TextWrapper>
          </SKY.HideableArea>

          <SKY.Row hidden={!tableOpen}>
            <SKY.ListTable
              setData={this.setData}
              rowOnClick={this.click_select}
              maxRows={15}
              initialState={this.props.searchListState}
              headerFixedLength='0px'
            >
              {[
                { type: "index", },
                { type: "label", label: "施設コード", name: "facilityCode", width: 100 },
                { type: "label", label: "施設名", name: "facilityName", width: 400 },
                { type: "label", label: "都道府県", name: "kenName", align: 'center', width: 100 },
              ]}
            </SKY.ListTable>
          </SKY.Row>
        </SKY.ModalBody>
      </Fragment>
    );
  }
}
