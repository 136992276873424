import React from 'react';
import * as Icons from './Icons';

export default class IconsList extends React.Component {
  render() {
    const wrap_style = {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: '#ffffff'
    }
    const iconWrap_style = {
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'center',
      alignItems: 'center',
      padding: 8,
      width: 150,
    }
    const text_style = {
      marginTop: 8,
      fontSize: 10,
      fontWeight: 'bold',
    }
    return (
      <div style={wrap_style}>
        <div style={iconWrap_style}>
          <Icons.AngleDoubleDown />
          <p style={text_style}>AngleDoubleDown</p>
          <p style={text_style}>直角、二つ、下向き</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.AngleDoubleLeft />
          <p style={text_style}>AngleDoubleLeft</p>
          <p style={text_style}>直角、二つ、左向き</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.AngleDoubleRight />
          <p style={text_style}>AngleDoubleRight</p>
          <p style={text_style}>直角、二つ、右向き</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.AngleDoubleUp />
          <p style={text_style}>AngleDoubleUp</p>
          <p style={text_style}>直角、二つ、上向き</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.AngleDown />
          <p style={text_style}>AngleDown</p>
          <p style={text_style}>直角、下向き</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.AngleLeft />
          <p style={text_style}>AngleLeft</p>
          <p style={text_style}>直角、左向き</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.AngleRight />
          <p style={text_style}>AngleRight</p>
          <p style={text_style}>直角、右向き</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.ArrowLeftCircle /><p style={text_style}>ArrowLeftCircle</p>
          <p style={text_style}>矢印、左向き、円</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.ArrowRightCircle />
          <p style={text_style}>ArrowRightCircle</p>
          <p style={text_style}>矢印、右向き、円</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.ArrowRightCurve />
          <p style={text_style}>ArrowRightCurve</p>
          <p style={text_style}>矢印、右向き、カーブ</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Barcode />
          <p style={text_style}>Barcode</p>
          <p style={text_style}>バーコード</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Bars />
          <p style={text_style}>Bars</p>
          <p style={text_style}>バー、三本線、ハンバーガー</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Bullhorn /><p style={text_style}>Bullhorn</p>
          <p style={text_style}>拡声器</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Calendar />
          <p style={text_style}>Calculator</p>
          <p style={text_style}>カレンダー</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Calculator />
          <p style={text_style}>Calendar</p>
          <p style={text_style}>電卓</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.ChartBar />
          <p style={text_style}>ChartBar</p>
          <p style={text_style}>棒グラフ</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.ChartPie />
          <p style={text_style}>ChartPie</p>
          <p style={text_style}>円グラフ、切り抜き</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Check1 />
          <p style={text_style}>Check1</p>
          <p style={text_style}>チェック</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Circle />
          <p style={text_style}>Circle</p>
          <p style={text_style}>円</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.ClipboardCheck />
          <p style={text_style}>ClipboardCheck</p>
          <p style={text_style}>クリップボード、チェック</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.ClipboardList /><p style={text_style}>ClipboardList</p>
          <p style={text_style}>クリップボード、リスト、一覧</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Clock1 /><p style={text_style}>Clock1</p>
          <p style={text_style}>時計、4時、白抜き</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Cog />
          <p style={text_style}>Cog</p>
          <p style={text_style}>歯車</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Copy />
          <p style={text_style}>Copy</p>
          <p style={text_style}>コピー</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Database />
          <p style={text_style}>Database</p>
          <p style={text_style}>データベース</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Edit1 />
          <p style={text_style}>Edit1</p>
          <p style={text_style}>編集、紙とペン</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Eye />
          <p style={text_style}>Eye</p>
          <p style={text_style}>目</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.FileDownload />
          <p style={text_style}>FileDownload</p>
          <p style={text_style}>ファイル、ダウンロード</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Globe />
          <p style={text_style}>Globe</p>
          <p style={text_style}>地球、グローバル</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Home1 />
          <p style={text_style}>Home1</p>
          <p style={text_style}>家、ホーム</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.List />
          <p style={text_style}>List</p>
          <p style={text_style}>一覧</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Meadow />
          <p style={text_style}>Meadow</p>
          <p style={text_style}> 草むら</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.MinusSquare /><p style={text_style}>MinusSquare</p>
          <p style={text_style}>－、マイナス、四角形</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.PdfFile /><p style={text_style}>PdfFile</p>
          <p style={text_style}>ファイル、PDF</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.PlusCircle />
          <p style={text_style}>PlusCircle</p>
          <p style={text_style}>＋、プラス、円</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.PlusSquare />
          <p style={text_style}>PlusSquare</p>
          <p style={text_style}>＋、プラス、四角形</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Receipt />
          <p style={text_style}>Receipt</p>
          <p style={text_style}>伝票、レシート</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Search1 />
          <p style={text_style}>Search1</p>
          <p style={text_style}>虫メガネ</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.SearchBoard />
          <p style={text_style}>SearchBoard</p>
          <p style={text_style}>掲示板と虫メガネ</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.SignOutAlt />
          <p style={text_style}>SignOutAlt</p>
          <p style={text_style}>サインアウト、ログアウト、太い</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Sort />
          <p style={text_style}>Sort</p>
          <p style={text_style}>ソート、上下、三角2つ</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Star1 />
          <p style={text_style}>Star1</p>
          <p style={text_style}>★、星</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.SyncAlt />
          <p style={text_style}>SyncAlt</p>
          <p style={text_style}>更新、二つの矢印で作られた円</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Table1 />
          <p style={text_style}>Table1</p>
          <p style={text_style}>テーブル</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Times />
          <p style={text_style}>Times</p>
          <p style={text_style}>✖、掛ける</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.TimesCircle />
          <p style={text_style}>TimesCircle</p>
          <p style={text_style}>✖、掛ける、円</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.Trash />
          <p style={text_style}>Trash</p>
          <p style={text_style}>ゴミ箱 </p>
        </div>
        <div style={iconWrap_style}>
          <Icons.TriangleDown /><p style={text_style}>TriangleDown</p>
          <p style={text_style}>▼、三角形、下向き</p>
        </div>
        <div style={iconWrap_style}>
          <Icons.User1 />
          <p style={text_style}>User1</p>
          <p style={text_style}>ユーザー</p>
        </div>
      </div>
    )
  }
}
