import React, { Fragment } from 'react';
import PMaster from '../PMaster';
import * as Icons from "../Icons";
import * as Parts from './TableComponent';
import { table as Colors } from '../Colors';
import { formatNumber, funcAdapter, getPMasterName, connectStr } from '../../util/commonInput';
import * as tableFunc from './tableFunction';

export default class TDL extends React.Component {
  constructor(props) {
    super(props);
    this.td_style = {                // <td> 各セル
      width: "100%",
      border: "1px solid" + Colors.border,
      textAlign: "center",
      verticalAlign: "middle",
      // fontSize: '1.1rem',
      padding: "2px 5px",
      minHeight: 25,
      // lineHeight: 1.4,
      wordWrap: "break-word",
    }
  }
  // ________________________________________________________________________________________________________________
  cellOnClick = (e) => {
    const { setData, children, state, index } = this.props;
    const { onClick, arrayIndex } = children // column設定のonClick
    e.stopPropagation(); // 親にイベントが伝播するのを止めている。rowOnClickと処理が被らないように
    // onClick && onClick(setData, state, e) && onClick(setData, state, arrayIndex) // clickされた行のデータと、現在のstateを親に返している
    onClick && onClick(setData, state, arrayIndex, index)
  }
  // ________________________________________________________________________________________________________________
  render() {
    const { setData, children } = this.props;
    const {     //（親から）設定オプションの省略宣言
      name,
      type,
      rowSpan,
      colSpan,
      tagName,
      tagName2,
      disabled,
      checkBox = true,
      array,
      unit,
      // connecter,
      color,
      fontColor,
      width,
      align,
      minWidth,
      style,
      label,
      value,
      format,
      zero,
      modalType,
      arrayIndex,
      br = true,
      option,
    } = children

    let setName = funcAdapter(name, setData);
    // valueが設定されている時の処理。デフォルトはnameより
    let setValue = "";
    setValue = funcAdapter(value, setData) || setData[setName];

    if (array) { setValue = value } // 配列型は親から送られてきたvalueを使用する。setData[name]では配列内の値が全て表示されてしまう。
    let setType = funcAdapter(type, setData);
    let setDisabled = funcAdapter(disabled, setData);
    let cellItem = '';
    let default_align = null;
    let default_fontColor = null;
    let setRowSpan = funcAdapter(rowSpan, setData);
    let setFontColor = array ? funcAdapter(children.fontColor, { 'row': setData, 'arrayIndex': arrayIndex }) : funcAdapter(children.fontColor, setData)
    let setModalType = array ? funcAdapter(modalType, { 'row': setData, 'arrayIndex': arrayIndex }) : funcAdapter(modalType, setData)
    let setColor = array ? funcAdapter(children.color, { 'row': setData, 'arrayIndex': arrayIndex }) : funcAdapter(children.color, setData)
    let setZero = array ? funcAdapter(children.zero, { 'row': setData, 'arrayIndex': arrayIndex }) : funcAdapter(children.zero, setData) || children.zero
    let setStyle = array ? funcAdapter(children.style, { 'row': setData, 'arrayIndex': arrayIndex }) : funcAdapter(children.style, setData)
    let setIcon = array ? funcAdapter(children.icon, { 'row': setData, 'arrayIndex': arrayIndex }) : funcAdapter(children.icon, setData)
    const setUnit = funcAdapter(unit, setData);

    // 設定情報typeによってセル内に配置する要素を設定しています
    switch (setType) {
      case ("text"): {                                           //　テキストボックス
        cellItem = setValue
        break;
      }
      case ("number"): {                                                //　テキストボックス(数値)
        if (setValue < 0) { default_fontColor = 'red' } // マイナス数値の文字色を変える処理
        if (setValue === 0 && setZero === false) {
          setValue = ''
        } else {
          setValue = formatNumber(setValue, format)
        }
        cellItem = setValue
        default_align = 'right';
        break;
      }
      case ("date"): {                                                //　テキストボックス(数値)
        if (setValue && new Date(setValue) !== "Invalid Date") { // もしデータが日付として正しいなら
          const dayjs = require('dayjs')
          setValue = String(setValue)
          setValue = setValue.replace(/-/g, "/")  // "-"(ハイフン)を"/"(スラッシュ)に置換
          setData[name] = setValue;
          if (format) {
            setValue = dayjs(setValue).format(format || 'YYYY/MM/DD', 'ja')
          }
        }
        cellItem = setValue;
        default_align = 'center';
        break;
      }
      case ("index"): {
        return <Parts.IndexCell {...this.props} color={setColor} />
      }
      case ("check"): { // チェックボックス
        let setCheckBox = funcAdapter(checkBox, setData);
        return <Parts.CheckCell {...this.props} value={setValue} disabled={setDisabled} checkBox={setCheckBox} />
      }
      case ('link'): {
        return <Parts.LinkCell {...this.props} color={setColor} style={setStyle} tagName={funcAdapter(tagName, setData) || setData[tagName]} value={setValue} onClick={this.cellOnClick} disabled={setDisabled} />
      }
      case ("copy"): {                                              // 行削除ボタン
        return <Parts.CopyCell onClick={this.cellOnClick} disabled={setDisabled} />
      }
      case ("delete"): {                                              // 行削除ボタン
        return <Parts.DeleteCell {...this.props} color={setColor} disabled={setDisabled} onClick={this.cellOnClick} />

      }
      case ("up"): {                                              // 行削除ボタン
        return <Parts.UpCell onClick={this.cellOnClick} disabled={setDisabled} />
      }
      case ("down"): {                                              // 行削除ボタン
        return <Parts.DownCell onClick={this.cellOnClick} disabled={setDisabled} />
      }
      case ("icon"): {
        return <Parts.IconCell {...Object.assign({}, this.props, { children: { ...children, 'icon': setIcon } })} color={setColor} onClick={this.cellOnClick} disabled={setDisabled} />
      }
      case ("input"): {
        return <Parts.InputCell text {...this.props} color={setColor} value={setValue} />
      }
      case ("input-date"): {// 日付けカレンダー
        return <Parts.CalendarCell {...this.props} value={setValue} disabled={setDisabled} onClick={this.cellOnClick} />
      }
      case ("input-decimal"): {
        return <Parts.InputCell decimal {...this.props} color={setColor} value={setValue} disabled={setDisabled} />
      }
      case ("labelModal"): {
        return <Parts.LabelModalCell {...Object.assign({}, this.props, { children: { ...children, 'zero': setZero, 'style': setStyle } })} color={setColor} fontColor={setFontColor} modalType={setModalType} value={setValue} onClick={this.cellOnClick} />
      }
      case ("input-area"): { //　テキストボックス
        return <Parts.TextAreaCell {...Object.assign({}, this.props, { children: { ...children, 'rowSpan': setRowSpan, 'style': setStyle } })} value={setValue} color={setColor} disabled={setDisabled} />
      }
      case ("radio"): {
        const _option = funcAdapter(option, setData);
        return <Parts.RadioCell {...this.props} option={_option} value={setValue} disabled={setDisabled} />
      }
      case ("select"): {
        const _option = funcAdapter(option, setData);
        return <Parts.SelectCell {...this.props} color={setColor} option={_option} value={setValue} disabled={setDisabled} />
      }
      case ('blank'): {
        cellItem = <span></span>
        break;
      }
      default: {                                                    // デフォルト
        cellItem = setValue
        break;
      }
    }
    // ______________________________________________________________________

    const column_style = {
      width: width,
      minWidth: minWidth,
      textAlign: align || default_align,
      color: setFontColor || default_fontColor,
      textOverflow: "ellipsis",
    }
    if (setColor) {
      Object.assign(column_style, {
        backgroundColor: setColor
      })
    }
    const text_style = {
      whiteSpace: "pre-wrap",
    }
    if (!br) {
      Object.assign(text_style, {
        overflow: "hidden",
        whiteSpace: null,
        textOverflow: "ellipsis",
      })
    }
    const pUnit = getPMasterName(setUnit) || setUnit;
    const setTagName = setData[tagName] || funcAdapter(tagName, setData);
    const setTagName2 = setData[tagName2] || funcAdapter(tagName2, setData);
    return (
      <td
        style={Object.assign({}, this.td_style, column_style, setStyle)}
        rowSpan={setRowSpan === 'max' ? 1000 : setRowSpan}
        colSpan={colSpan}
      >
        <pre style={text_style}>{cellItem}{pUnit}</pre>
        {setTagName && <span>{setTagName}</span>}
        {setTagName2 && <span><br></br>{setTagName2}</span>}
      </td>

    )
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
