import request from 'axios';
import { setCookie } from '../util/cookie'
import { push } from 'connected-react-router';　//Logout用
import { getCurrentPath, getToken } from '../util/commonInput';
import { closeModal } from '../component/Modal'
import { store } from '../index'

// import { error } from './Actions';

export const axiosPost = request.create({
  xsrfHeaderName: 'X-CSRF-Token',
  withCredentials: true
})
axiosPost.defaults.timeout = 60000;

axiosPost.interceptors.request.use(
  config => {
    //Java側にCompCodeとSessioonIdを渡す
    setCookie('compCode', window.store.getState()['COMMON']['Login'].compCode || '', 1000 * 60);
    config.headers['sSessionId'] = window.store.getState()['COMMON']['Login'].sSessionId || null;
    // config.headers['sSessionId'] = getToken() || null;

    //setCookie('sSessionId', window.store.getState()['COMMON']['Login'].sSessionId || null, 1000 * 60);
    // 全てのRequestに必須Paraを追加する場合、下記のよう
    // let defaultParams = {
    //   compCode: store.getState()['COMMON']['Login'].compCode||'01',
    // };
    // config.data = Object.assign(defaultParams, config.data);
    // headerのカスタマイズ
    //   config.headers['B-Token'] = store.state.token;
    return config;
  },
  error => {
    return Promise.reject(error);
  });

// 400 error時　Login画面に戻る
axiosPost.interceptors.response.use(
  response => response,
  error => {
    closeModal()
    // errorがある時　Session error 画面に飛ぶ
    // HttpCode===400の時、強制的にLogin画面に戻る
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          store.dispatch(push('/sessionError'));
          break;
        default:
          return new Promise(() => {
            window.store.dispatch(push(`${getCurrentPath()}/error`, { errorValue: !error.response.data ? error.response.headers.msg : error.response.data.message }));
          });
      }
    } else {
      return new Promise(() => {
        window.store.dispatch(push(`${getCurrentPath()}/error`, { errorValue: 'セッション情報がタイムアウトしましたので、再ログインしてください。', forward: 'login' }));
      });
    }
    //return Promise.reject(error.response);
  }
);

//　開発時にはローカルURLを使用
const serverUrl = 'https://wakocss.skysystem.jp:8080/wakocssJava-0.0.1'; // SKY側　テスト環境
//const serverUrl = 'http://10.214.192.2:8080'; // Wako側 本番環境
const localUrl = "http://localhost:8080";
export const server = process.env.NODE_ENV === 'development' ? localUrl : serverUrl;
//export const server = "http://10.214.192.2:8080/wakocssJava-0.0.1";
//export const server = "http://10.214.192.2:8080/wakocssJava-0.0.1";