import React from 'react';
import BasicWrap from './BasicWrap';
import { formatNumber } from '../util/commonInput'

export default class TextLabel extends React.Component {
  render() {
    const { value, align, format, dateFormat, style, alignItems, col } = this.props
    let val = value
    if (format) {
      val = formatNumber(value, format)
    } else if (dateFormat) {
      const dayjs = require('dayjs')
      val = dayjs(value).format(dateFormat)
    }

    const wrap_style = {
      border: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      minWidth: "auto",
    }
    const label_style = {
      fontSize: 'inherit',
      height: '100%',
      width: '100%',
      padding: '0px 4px',
      display: 'flex',
      alignItems: alignItems || 'center',
      justifyContent:
        align === 'right' ? 'flex-end' :
          align === 'center' ? 'center' :
            typeof (value) === 'number' ? 'flex-end' :
              null,
    }

    return (
      <BasicWrap col={col || 'auto'} {...this.props} style={{ ...wrap_style, ...style }}  >
        <div style={label_style}>
          <pre style={{ whiteSpace: "pre-wrap" }}>{val}</pre>
        </div>
      </BasicWrap >
    )
  }
}
