import React from 'react';

export default class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false
    }
    this.el = React.createRef()
  }
  //___________________________________________________________
  shouldComponentUpdate(nextProps){
    const {hidden} = this.props
    if( hidden !== nextProps.hidden && !nextProps.hidden){
      this.setState({
        hover: false,
        focus: false,
      })
    }
    return true
  }
  //___________________________________________________________
  handleOnMouseOver = (e) => {
    const { color2, onMouseOver } = this.props;
    onMouseOver && onMouseOver(this.el)
    if (!color2) { return }
    this.setState({ hover: true })
  }
  //___________________________________________________________
  handleOnMouseLeave = () => {
    const { color2, onMouseLeave } = this.props;
    onMouseLeave && onMouseLeave()
    if (!color2) { return }
    this.setState({ hover: false })
  }
  //___________________________________________________________
  handleOnFocus = () => {
    const { color2, onFocus } = this.props;
    onFocus && onFocus()
    if (!color2) { return }
    this.setState({ focus: true })
  }
  //___________________________________________________________
  handleOnBlur = () => {
    const { color2, onBlur } = this.props;
    onBlur && onBlur()
    if (!color2) { return }
    this.setState({ focus: false })
  }
  //___________________________________________________________
  handleOnKeyDown = (e) => {
    const { onClick, onKeyDown } = this.props;
    onKeyDown && onKeyDown()
    if (e.keyCode === 13 || e.keyCode === 32) {
      onClick && onClick(e)
    }
  }
  //___________________________________________________________
  render() {
    const {
      children,
      color,
      color2,
      width,
      height,
      size,
      style,
      hidden,
      onClick,
      viewBox,
      d,
      disabled,
      tabStop = true,
      className,
      title
    } = this.props;
    const { hover, focus } = this.state;
    if (hidden) { return null };
    const wrap_style = {
      display: 'inline-flex',
      cursor:
        !disabled && onClick ? 'pointer' :
          null,
    }
    const svg_style = {
      width:
        width ? width :
          size ? size :
            30,
      height:
        height ? height :
          size ? size :
            30,
    }
    const fill_style = {
      fill:
        hover || focus ? color2 :
          color,
      transition: '0.2s'
    }
    //___________________________________________________________
    return (
      <i
        ref={this.el}
        className={className}
        style={Object.assign({}, wrap_style, style)}
        onMouseOver={this.handleOnMouseOver}
        onMouseLeave={this.handleOnMouseLeave}
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
        onKeyDown={this.handleOnKeyDown}
        onClick={disabled? null: onClick}
        tabIndex={
          !tabStop ? -1 :
            !disabled && onClick ? 0 :
              null
        }
        disabled={disabled}
        title={title}
      >
        <svg className={className} style={svg_style} xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
          <path style={fill_style} d={d} />
          {children && children(fill_style)}
        </svg>
      </i>
    )
  }
}