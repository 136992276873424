import React from 'react';
import ArrowRightCircle from '../image/SVGIcons/ArrowRightCircle'
import { loginPage as Colors } from "./Colors";

export default class LoginBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
    this.btn_style = {
      font: 'Bold 16px/24px Meiryo',
      height: 50,
      borderRadius: 25,
      cursor: "pointer",
      border: "none",
      color: "#fff",
      transition: "0.3s",
      userSelect: 'none',
      width: 296,
      position: 'relative',
      outline: 'none',
    }
    this.icon_style = {
      position: 'absolute',
      right: 20,
      top: 0,
      bottom: 0,
      display: 'inline-flex',
      alignItems: 'center',
    }
  }
  // ______________________________________________________________________________
  render() {
    const { color, label, children, onClick } = this.props
    const { hover } = this.state

    // 状態によって変更されるスタイルの記述
    Object.assign(this.btn_style, {
      backgroundColor: //　優先順位順に背景色を設定　
        hover ? Colors.btnHover :
          Colors.basic,
    })
    // _______________________________________________________________________________________________________

    return (
      <button
        style={Object.assign({}, this.btn_style)}
        onClick={onClick}
        onKeyDown={this.props.onKeyDown}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ hover: true })}
        onBlur={() => this.setState({ hover: false })}
      >
        {label}
        {children}
        <ArrowRightCircle
          style={this.icon_style}
          color='#FFF'
          width={16}
          height={16}
        />
      </button>
    )
  }
}
