import React from 'react';
import * as Icons from "./Icons";
import { checkBox as Colors } from './Colors'
import { getPMasterName } from '../util/commonInput';
import BasicWrap from './BasicWrap';

export default class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || "",//true:'1' false:''
    }
    this.checkSize = "20px";

  }
  // _______________________________________________________________________________
  shouldComponentUpdate(nextProps) {
    const { onChange, name, value } = this.props
    if (nextProps.value !== value) {
      this.setState({ value: nextProps.value })
      onChange && onChange(name, nextProps.value);
    }
    return true;
  }
  // _______________________________________________________________________________
  handleOnChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { onChange, name, checkedValue = '1' } = this.props
    let inputValue = (this.state.value !== checkedValue) ? checkedValue : '';  // チェックボックスの値が 1 だったなら''にする
    this.setState({ value: inputValue })
    console.log(inputValue)
    onChange && onChange(name, inputValue);
  }
  // _______________________________________________________________________________
  render() {
    const {
      disabled,
      label,
      plabel,
      plabel2,
      checkedValue = '1',
      fontSize,
    } = this.props
    let Plabel = plabel && getPMasterName(plabel);
    let Plabel2 = plabel2 && getPMasterName(plabel2);
    if (Plabel === 'NONE') { return null }

    const { value } = this.state
    // ________________________________________________________________________________
    const wrap_style = {
      fontSize: fontSize || null,
      display: 'inline-flex',
      alignItems: 'center',
      border: 'none',
      backgroundColor: 'transparent',
      padding: 0,
      cursor: disabled ? null : 'pointer',
      margin: 4,
    }
    const checkWrap_style = {
      height: this.checkSize,
      width: this.checkSize,
      minHeight: this.checkSize,
      minWidth: this.checkSize,
      border: `1px solid ${Colors.border}`,
      backgroundColor: '#FFFFFF',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 4,
    }

    return (
      <button style={wrap_style} tabIndex={0} onClick={this.handleOnChange} disabled={disabled} >
        <div style={checkWrap_style} >
          <Icons.Check1 width={this.checkSize} height={this.checkSize} color={Colors.check} hidden={value !== checkedValue} />
        </div>
        <span style={{ display: 'inline-flex', textAlign: 'left' }}>{Plabel}{Plabel2}{label}</span>
      </button>
    );
  }
}

