import React from 'react';
import Icon from './Icon';

export default class Info extends React.Component {
  render() {
    return (
      <Icon
        {...this.props}
        viewBox="0 0 1024 1024"
        d="M369 433c0 27.1-21.9 49-49 49s-49-21.9-49-49 21.9-49 49-49 49 21.9 49 49z m143-49c-27.1 0-49 21.9-49 49s21.9 49 49 49 49-21.9 49-49-21.9-49-49-49z m192 0c-27.1 0-49 21.9-49 49s21.9 49 49 49 49-21.9 49-49-21.9-49-49-49z m256-192v496c0 39.8-32.2 72-72 72h-80v98.7c0 22.7-10.7 44.1-28.9 57.7-12.6 9.4-27.7 14.3-43.1 14.3-6.8 0-13.7-1-20.5-3L115.5 757C85 748 64 719.9 64 688V192c0-39.8 32.2-72 72-72h752c39.8 0 72 32.2 72 72z m-72 0H136v496l600 170.7V688h152V192z"
      />
    )
  }
}