import React, { Fragment } from 'react';
import PMaster from '../PMaster';
import * as Icons from "../Icons";
import * as Parts from './TableComponent';
import { table as Colors } from '../Colors';
import { formatNumber, funcAdapter, checkProps, connectStr } from '../../util/commonInput';
import * as tableFunc from './tableFunction';

export default class THeaderL extends React.Component {
  constructor(props) {
    super(props);
    this.theader_style = {         // <table> テーブルヘッダー
      width: "100%",
      tableLayout: "fixed",
      borderCollapse: "separate",
      borderRadius: '4px 4px 0px 0px',
      backgroundColor: "#F5F5F5",
    }
  }
  // _________________________________________________________________________________________________________

  render() {
    const {
      children,
      detailOpen,
      setData,
      firstIndex,
      orderByAsc,
      orderByDesc,
      handleOnFilter,
      handleOnCheckFilter,
      handleOnAllCheck,
      handleOnDetailSwitch,
      filterLists,
      inUseFilterLists = {},
    } = this.props;
    const th_arr = [];
    let detailTr = null;
    const detailTh_arr = [];
    let secondTr = null;
    let secondTrIndex = 0;
    const secondTh_arr = [];
    let endTr = null;
    const endTh_arr = [];
    // テーブルヘッダーの設定用ループ
    for (let i in children) {
      let {      //（親から）設定オプションの省略宣言
        name,
        label,
        type,
        hidden,
        array,
        filter = true,
        sortName
      } = children[i]
      if (hidden) { continue; } // カラム設定にhiddenが設定されていたら処理をスキップ

      // 明細付きテーブルタイプの明細行を作成
      if (type === 'detailVModels') {
        for (let j in children[i]['detailConf']) {
          const { name, sortName } = children[i]['detailConf'][j]
          let setName = sortName || funcAdapter(name, setData);
          detailTh_arr.push(
            <Parts.TH
              children={children[i]['detailConf'][j]}
              key={'detailTh' + j}
              onChangeCheck={(value) => handleOnAllCheck(value, setName, true)}
            />
          )
        }
        detailTr = <tr>{detailTh_arr}</tr>
      }
      // 2行テーブルタイプの2行目を作成
      else if (type === 'secondRow') {
        secondTrIndex++;
        for (let j in children[i]['secondConf']) {
          const { name, sortName } = children[i]['secondConf'][j]
          let setName = sortName || funcAdapter(name, setData);
          secondTh_arr.push(
            <Parts.TH
              children={children[i]['secondConf'][j]}
              key={'secondTh' + secondTrIndex + j}
              orderByAsc={() => orderByAsc(setName)}
              orderByDesc={() => orderByDesc(setName)}
              filterable={filter && setName}
              // filterOnChange={(value) => handleOnFilter(value, setName)}
              onChangeCheck={(value) => handleOnAllCheck(value, setName)}
              filterList={filterLists?.[setName]}
              inUse={inUseFilterLists.hasOwnProperty([setName]) === true ? true : false}
              filterOnResult={(data) => handleOnCheckFilter(data, setName,)}
            />
          )
        }
        secondTr = <tr>{secondTh_arr}</tr>
      }
      // 最終行を作成
      else if (type === 'endRow') {
        for (let j in children[i]['detailConf']) {
          const { name, sortName } = children[i]['detailConf'][j]
          let setName = sortName || funcAdapter(name, setData);
          endTh_arr.push(
            <Parts.TH
              children={children[i]['detailConf'][j]}
              key={'endTh' + j}
              orderByAsc={() => orderByAsc(setName)}
              orderByDesc={() => orderByDesc(setName)}
              filterable={filter && setName}
              // filterOnChange={(value) => handleOnFilter(value, setName)}
              onChangeCheck={(value) => handleOnAllCheck(value, setName)}
              filterList={filterLists[setName]}
              inUse={inUseFilterLists.hasOwnProperty([setName]) === true ? true : false}
              filterOnResult={(data) => handleOnCheckFilter(data, setName,)}
            />
          )
        }
        endTr = <tr>{endTh_arr}</tr>
      }
      // 配列付きテーブルタイプの配列列を作成
      else if (array) {
        let setName = sortName || funcAdapter(name, setData);

        for (let j in label) {
          th_arr.push(
            <Parts.TH
              children={Object.assign({}, children[i], { "label": label[j] })}
              key={'arrayTh' + j}
              orderByAsc={() => orderByAsc(setName, j)}
              orderByDesc={() => orderByDesc(setName, j)}
              filterable={filter && setName}
              // filterOnChange={(value) => handleOnFilter(value, setName, j)}
              onChangeCheck={(value) => handleOnAllCheck(value, setName)}
              filterList={filterLists[setName]}
              inUse={inUseFilterLists.hasOwnProperty([setName]) === true ? true : false}
              filterOnResult={(data) => handleOnCheckFilter(data, setName, j)}
            />
          )
        }
      }
      // 各タイプの1行目を作成
      else {
        let setName = sortName || funcAdapter(name, setData);
        // console.log(filterLists, setName)
        th_arr.push(                                                     // <th>テーブルヘッダーを作成
          <Parts.TH
            children={children[i]}
            key={'th' + i}
            firstIndex={firstIndex}
            orderByAsc={() => orderByAsc(setName)}
            orderByDesc={() => orderByDesc(setName)}
            filterable={filter && setName}
            // filterOnChange={(value) => handleOnFilter(value, setName, connecter)}
            onChangeCheck={(value) => handleOnAllCheck(value, setName)}
            filterList={filterLists[setName]}
            inUse={inUseFilterLists.hasOwnProperty([setName]) === true ? true : false}
            filterOnResult={(data) => handleOnCheckFilter(data, setName,)}
          />
        )
      }
    }
    // _________________________________________________________________________________________________________

    return (
      <table style={this.theader_style} >
        <tbody>
          <tr>
            <Parts.DetailSwitchTH
              render={detailTh_arr.length >= 1}
              onClick={handleOnDetailSwitch}
              detailOpen={detailOpen}
            />
            {th_arr}
          </tr>
          {secondTr}
          {detailTr}
          {endTr}
        </tbody>
      </table>
    )
  }
}
