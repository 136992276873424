import { axiosPost, server } from '../comRedux/axiosPost';
import { getPMasterName } from './commonInput'

async function getExcel(url, json, fileName) {
  var mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  //<PMaster/>で対応
  if (typeof fileName === 'object' && fileName.props) {
    if (fileName.props.name) {
      fileName = getPMasterName(fileName.props.name)
    }
  } else {
    fileName = getPMasterName(fileName) || fileName
  }
  return axiosPost.post(server + url, json, { responseType: 'arraybuffer' })
    .then((res) => {
      if( !res.data || res.data.length === 0 || !!res.headers.error)return res;
      
      // ダウンロードしたいコンテンツ、MIMEType、ファイル名
      var name = fileName;
      
      // BOMは文字化け対策
      // var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      var blob = new Blob([res.data], { type: mimeType });

      var a = document.createElement('a');
      a.download = name;
      a.target = '_blank';
      if (window.navigator.msSaveBlob) {
        // for IE
        window.navigator.msSaveBlob(blob, name)
      } else if (window.webkitURL && window.webkitURL.createObjectURL) {
        a.href = window.webkitURL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.webkitURL.revokeObjectURL(a.href);
      }
      else if (window.URL && window.URL.createObjectURL) {
        a.href = window.URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(a.href);
      } else {
        // for Safari
        window.open('data:' + mimeType + ';base64,' + window.Base64.encode(res.data), '_blank');
      }
      return res;
    })
    .catch(err => {
      alert('エラーです' + err);
      console.log('JSON読み込みエラー')
      return
    })
}
async function getPdfByte(url, json){
  return axiosPost.post(server + url, json, { responseType: 'arraybuffer' })
  .then((res) => {
    return res.data;
  })
}
async function getPdf(url, json, fileName) {
  //<PMaster/>で対応
  if (typeof fileName === 'object' && fileName.props) {
    if (fileName.props.name) {
      fileName = getPMasterName(fileName.props.name)
    }
  } else {
    fileName = getPMasterName(fileName) || fileName
  }
  return axiosPost.post(server + url, json, { responseType: 'arraybuffer' })
    .then((res) => {
      if( !res.data || res.data.length === 0 || !!res.headers.error)return res;

      // ダウンロードしたいコンテンツ、MIMEType、ファイル名
      var mimeType = 'application/pdf';
      var name = fileName + '.pdf';

      // BOMは文字化け対策
      // var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      var blob = new Blob([res.data], { type: mimeType });

      var a = document.createElement('a');
      a.download = name;
      a.target = '_blank';

      if (window.navigator.msSaveBlob) {
        // for IE
        window.navigator.msSaveBlob(blob, name)
      }
      else if (window.URL && window.URL.createObjectURL) {
        // for Firefox
        a.href = window.URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      else if (window.webkitURL && window.webkitURL.createObject) {
        // for Chrome
        a.href = window.webkitURL.createObjectURL(blob);
        a.click();
      }
      else {
        // for Safari
        window.open('data:' + mimeType + ';base64,' + window.btoa(res.data), '_blank');
      }
    })
    .catch(err => {
      alert(err);
      console.log('JSON読み込みエラー')
      return
    })
}

async function getCsv(url, json, fileName) {
  //<PMaster/>で対応
  if (typeof fileName === 'object' && fileName.props) {
    if (fileName.props.name) {
      fileName = getPMasterName(fileName.props.name)
    }
  } else {
    fileName = getPMasterName(fileName) || fileName
  }
  return axiosPost.post(server + url, json, { responseType: 'blob' })
    .then((res) => {
      if( !res.data || res.data.length === 0 || !!res.headers.error)return res;

      if (window.navigator.msSaveOrOpenBlob) {
        // for IE,Edge
        window.navigator.msSaveOrOpenBlob(res.data, fileName + '.csv');
      } else {
        // for chrome, firefox
        const url = URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
        const linkEl = document.createElement('a');
        linkEl.href = url;
        linkEl.setAttribute('download', fileName + '.csv');
        document.body.appendChild(linkEl);
        linkEl.click();

        URL.revokeObjectURL(url);
        linkEl.parentNode.removeChild(linkEl);
      }
    })
    .catch(err => {
      alert(err);
      console.log('JSON読み込みエラー')
      return
    })
}

export {
  getExcel,
  getPdf,
  getCsv,
  getPdfByte
};