import React from 'react';
import * as Icons from "../Icons";
import { table as Colors } from '../Colors'

export default class DeleteCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false,
    }
  }
  render() {
    const { onClick, disabled, color, children, fixedWidth, minWidth = 30, rowSpan, colSpan } = this.props;
    const { hover, focus } = this.state;
    const td_style = {                // <td> 各セル
      width: 30,
      height: children?.height || 25,
      minWidth: minWidth,
      border: "1px solid" + Colors.border,
      textAlign: "center",
      verticalAlign: "middle",
      paddingTop: 4,
      transition: '0.2s',
      backgroundColor:
        hover || focus ? "#Cf8080" :
          color,
      pointerEvents:
        disabled ? "none" :
          "auto",
      cursor:
        disabled ? null : 'pointer',
      position:
        fixedWidth || fixedWidth == 0 ? 'sticky' :
          null,
      left: fixedWidth,
    }
    const iconColor = hover || focus ? "#FFFFFF" : "#Cf8080";
    return (
      <td
        style={td_style}
        rowSpan={rowSpan || 1000}
        colSpan={colSpan}
        onClick={onClick}
        tabIndex={!disabled ? 0 : null}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >
        <Icons.TimesCircle width={16} height={16} color={iconColor} hidden={disabled} />
      </td>
    )
  }
}
