import React from 'react';
import Icon from './Icon';

export default class Locked extends React.Component {
  render() {
    return (
      <Icon
        {...this.props}
        viewBox="0 0 1024 1024"
        d="M592 448h-16V256c0-105.856-86.144-192-192-192H256C150.144 64 64 150.144 64 256v192H48a48.192 48.192 0 0 0-48 48v480c0 26.368 21.632 48 48 48h544c26.368 0 48-21.632 48-48V496a48.192 48.192 0 0 0-48-48zM192 256c0-35.264 28.736-64 64-64h128c35.264 0 64 28.736 64 64v192H192V256z"
      />
    )
  }
}