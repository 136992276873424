import React from 'react';
import * as Icons from "../Icons";
import { table as Colors } from '../Colors'

export default class EditCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false,
    }
  }
  //_____________________________________________________________________
  render() {
    const { onClick, disabled, children, color } = this.props;
    const { hover, focus } = this.state;
    const {
      rowSpan,
      colSpan,
      width,
      title,
    } = children
    const td_style = {                // <td> 各セル
      width: width,
      border: "1px solid #ddd",
      textAlign: "center",
      verticalAlign: "middle",
      paddingTop: 4,
      transition: '0.2s',
      backgroundColor:
        disabled ? color :
          hover || focus ? Colors.modal :
            color,
      pointerEvents:
        disabled ? "none" :
          "auto",
      cursor:
        disabled ? null : 'pointer',
      // fontSize: '1.2rem',
      // fontWeight: 'bold',
      color:
        hover || focus ? '#FFFFFF' :
          null,
    }
    const iconColor = hover || focus ? "#FFFFFF" : Colors.modal;
    return (
      <td
        style={td_style}
        rowSpan={rowSpan === 'max' ? 1000 : rowSpan}
        colSpan={colSpan}
        onClick={onClick}
        tabIndex={!disabled ? 0 : null}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >
        <Icons.Edit2 width={16} height={16} color={iconColor} hidden={disabled} />
        {!disabled && title}
      </td>
    )
  }
}
