import React from 'react';
import ReactDOM from 'react-dom';

export default class HiddenBox extends React.Component{
  shouldComponentUpdate(nextProps){
    if(nextProps.render) {
      if(!document.getElementById('hiddenBox')) {
        const wrapper = document.body.appendChild(document.createElement('div')); // div要素を作成するための変数を宣言
        wrapper.id = 'hiddenBox'  // そのdiv要素のidに'modal'を設定
        const {x, y, style, children} = nextProps;
        const wrap_style = {
          position: "fixed",
          top: y,
          left : x,
          zIndex:1,
        }
        //______________________________________________________________________

        ReactDOM.render(
          <div style={Object.assign({},wrap_style,style)}>
            {children}
          </div>
          ,wrapper
        );
      }
    }else {
      const hiddenBox = document.getElementById('hiddenBox'); // 作ったid('modal')のdiv要素を取得
      if (hiddenBox) {
        ReactDOM.unmountComponentAtNode(hiddenBox);       // 作ったreactDomを削除
        hiddenBox.parentNode.removeChild(hiddenBox);            // 一度親に戻って子供を参照して作ったdiv要素を削除（IE対応）
      }
    }
    return true;
  }
  render(){
    return null;
  }
}
// ________________________________________________________________________________________________________________
