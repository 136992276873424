import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { contentsBody as Colors } from './Colors'
import { Helmet } from 'react-helmet';
import * as Icons from "./Icons";
import * as SKY from "./SmartComponent";
import * as smartUtil from 'util/commonInput';

class ContentsBody extends React.Component {
  constructor(props) {
    super(props);
    this.inner_style = {
      padding: "8px 16px ",
    }
    this.menuTitle_style = {
      letterSpacing: 0.8,
      color: '#262626',
      backgroundColor: '#FFFFFF',
      padding: '8px 16px',
      fontWeight: 'bold',
      whiteSpace: 'pre-line',
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }
    this.label_style = {
      color: Colors.label,
      borderLeft: Colors.label + '8px solid',
      paddingLeft: 8,
    }
    console.log('storeログ', this.props.store)
  }
  // ___________________________________________________________________________________\
  handleOnClick = (remark) => {
    const textarea_style = {
      height: "300px",
    }
    SKY.openModal({
      color: "#FFF",
      component:
        <SKY.ModalBody label='機能説明' color="#FFF">
          <SKY.Textarea value={remark} disabled style={textarea_style} />
          <SKY.Row center >
            <SKY.SystemBtn label="閉じる" onClick={() => SKY.closeModal()} />
          </SKY.Row>
        </SKY.ModalBody>
    })
  }
  // ___________________________________________________________________________________\
  render() {
    const { children, label, menuList, menuIndex, currentMenu } = this.props

    // menuIdの取得: MM01
    const menuId = currentMenu.substring(currentMenu.lastIndexOf("/") + 1).toUpperCase();
    // menuIdから下2桁を切り取って、カテゴリーの取得: MM01 → MM 
    let menuCate = menuId.substring(0, menuId.length - 2);

    // 表示中のメニュー情報を取得
    const currentCateList = menuList[menuIndex[menuCate]] ? menuList[menuIndex[menuCate]]['menuList'] : [];
    let title = label;
    let remark = null;
    currentCateList.forEach(item => {
      if (item.menuId && item.menuId === menuId) {
        title = item.title;
        // remark = item.remark; 機能説明は未実装
      }
    })
    // タイトルが順当な処理では取得できない場合の対処（ルーティングの関係で機能IDとPathが合わない場合など）
    if (!title) {
      menuList.forEach(mainItem => {
        mainItem.menuList.forEach(subItem => {
          // メニューに与えられたリンクが現在のパスと一致する場合
          if (smartUtil.getCurrentPath() === subItem.link) {
            title = subItem.title;
            return;
          }
        })
        // titleがセットされたらループを終了
        if (title) {
          return
        }
      })
    }

    return (
      <div style={this.wrap_style}>
        <div style={this.menuTitle_style}>
          <span style={this.label_style} dangerouslySetInnerHTML={{ __html: label || title || 'unknown menu' }} />
          <Helmet title={title} />
          <Icons.QuestionCircle height={25} width={25} hidden={!remark} color={Colors.label} onClick={() => this.handleOnClick(remark)} />
        </div>
        <div style={this.inner_style}>
          {children}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    menuList: state.COMMON.Menu.menuList,
    menuIndex: state.COMMON.Menu.menuIndex,
    currentMenu: state.router.location.pathname,
    store: state,
  }
}
export default connect(mapStateToProps, null)(ContentsBody)