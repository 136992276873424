/* コンポーネントの説明
ベースとなるコンポーネント
（テキストやカレンダー、セレクト等の類）を
囲うための外郭のコンポーネントです。
余白のサイズや、横幅、高さ等をまとめて調整できるようにここで設定します
*/

import React, { Fragment } from 'react';
import { basicWrap as Colors } from './Colors'

export default class BasicWrap extends React.Component {
  constructor(props) {
    super(props);
    const {
      col,
      width,
    } = this.props;
    this.state = {
      focus: false,
      hover: false,
    };

    this.size = null;
    if (width) {
      this.size = width;
    } else if (col === "auto") {
      this.size = 'auto';
    } else if (col) {
      this.size = col / 12 * 100 + 'vw';
    } else {
      this.size = '13.5vw'
    }

  }
  // _____________________________________________________________________
  handleFocus = () => {
    // const {onFocus} = this.props;
    this.setState({ focus: true });
    // onFocus && onFocus();
  }
  // _____________________________________________________________________
  handleBlur = () => {
    // const {onBlur} = this.props;
    this.setState({ focus: false });
    // onBlur && onBlur();
  }
  // _____________________________________________________________________

  render() {
    const {
      children,
      style,
      disabled,
      value,
      must,
      onClick,
      height,
    } = this.props
    const { hover, focus } = this.state;
    const wrap_style = {
      display: this.size === 'auto' ? 'flex' : 'inline-flex',
      alignItems: 'center',
      maxWidth: '100%',
      minWidth: 112,
      width: this.size,
      margin: '0px 4px',
      height: height || 32,
      maxHeigt: '100%',
      transition: "0.3s",
      position: 'relative',
      backgroundColor:
        disabled ? Colors.disabled :
          focus ? Colors.focus :
            // error && !value ? '#FF0000' :
            must && !value ? 'rgb(255, 235, 235)' :
              '#FFFFFF',
      border:
        disabled ? 'none' :
          hover ? `1px solid ${Colors.hover}` :
            // must && !value ? "1px solid rgb(203, 82, 82)" :
            `1px solid ${Colors.border}`,
      boxShadow:
        disabled ? null :
          hover ? `0px 0px 3px 0px ${Colors.shadow}` :
            null,
    }
    // _____________________________________________________________________
    return (
      <div
        style={Object.assign({}, wrap_style, style)}
        onClick={onClick}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        {children}
      </div>
    )
  }
}