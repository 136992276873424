import React from 'react';
import * as Icons from "./Icons";
import Button from './Button';
import Circle from 'react-circle';
import { axiosPost, server } from '../comRedux/axiosPost';
import { getExcel } from '../util/outputUtil'

export default class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      checkProgress: 0,
      uploadProgress: 0,
      errorMsg: null,
      showCheck: false,
      showUpload: false,
      showError: false,
      checkfinished: false,
      uploadfinished: false,
      checkProcessName: '',
      uploadProcessName: '',
      res: null,
    }
    this.wrap_style = {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 16,
    }
  }
  // _____________________________________________________________________________
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  // _____________________________________________________________________________

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state !== nextState || this.props !== nextProps) return true
  //   return false
  // }
  // プログレスタイマー
  progressTimer = (type) => {
    if (type === 'check') {
      this.setState({ showCheck: true })
    } else if (type === 'upload') {
      this.setState({ showUpload: true, })
    }

    // do something when started
    const { onStart } = this.props
    if (onStart && (type === 'check' || type === 'upload' || type === 'update')) {
      onStart();
    }

    this.interval = setInterval(() => {
      axiosPost.post(server + '/progress/getProcessing').then((res) => {
        if (res) {
          console.log(res);
          const { errorMsg, percentage, processName, status, } = res.data;
          console.log(res.data);
          if (type === 'check') {
            this.setState({ checkProcessName: processName, })
          } else if (type === 'update' || type === 'upload') {
            this.setState({ uploadProcessName: processName, })
          }
          switch (status.name) {
            case 'running': {
              if (type === 'check') {
                this.setState({ checkProgress: percentage, })
              } else if (type === 'upload') {
                this.setState({ uploadProgress: percentage, })
              }
              break;
            }
            case 'checkfinished': {
              this.setState({
                checkProgress: 1,
                checkfinished: true,
                res: res,
              })
              clearInterval(this.interval);
              break;
            }
            case 'finished': {
              this.setState({
                uploadProgress: 1,
                uploadfinished: true,
                res: res,
              })
              clearInterval(this.interval);
              break;
            }
            case 'error': {
              this.setState({
                showError: true,
                errorMsg: errorMsg,
              })
              clearInterval(this.interval);
              break;
            }
            default: {
              if (type === 'check') {
                this.setState({ checkProgress: percentage, })
              } else if (type === 'upload') {
                this.setState({ uploadProgress: percentage, })
              }
              break;
            }
            //default: { break; }
          }
        }
      }).catch((error) => {
        clearInterval(this.interval)
        this.setState({
          showError: true,
          errorMsg: 'response error',
        })
        console.log(error)
      })
    }, 1000);
  }

  //___________________________________________________________________
  // 一回目のファイル取込ボタンを押したとき（チェック）
  handleUploadCheck = () => {
    const { file } = this.state;
    const { checkUrl, pgmId, fileType = '', formData = {}, } = this.props;
    //　拡張子エラーチェック
    const fileType_arr = fileType.split(',')
    let matchError = true;
    for (let i = 0; i < fileType_arr.length; i++) {
      const FILETYPE = fileType_arr[i].toUpperCase() // 大文字に変換
      const FILENAME = file.name.toUpperCase() // 大文字に変換
      const regExp = new RegExp('\.(' + FILETYPE + ')$', "i");
      if (FILENAME.match(regExp)) {
        matchError = false;
        break;
      }
    }
    if (fileType_arr.length >= 1 && matchError) {
      this.setState({
        showError: true,
        errorMsg: 'extension error',
      })
      return;
    }

    // formDateセット
    let form = new FormData();
    let _formData = formData
    if (typeof formData === 'function') {
      _formData = formData()
    }
    // 送られてきたformData文ループを回し、postする
    for (let key in _formData) {
      form.append(key, _formData[key]);
    }
    form.append('id', pgmId);
    form.append('excel', file);
    axiosPost.post(server + checkUrl, form, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res) => {
      if (!!res.data) {
        this.progressTimer('check'); // checkのプログレスタイマーを開始
      }
    }).catch((error) => {
      clearInterval(this.interval)
      this.setState({
        showError: true,
        errorMsg: 'response error',
      })
      console.log(error)
    })
  }
  //___________________________________________________________________
  // チェック後のアップロードボタンを押した時
  handleOnUpload = async () => {
    const { uploadUrl } = this.props;
    axiosPost.post(server + uploadUrl).then(
      (res) => {
        this.progressTimer('upload');　// uploadのプログレスタイマーを開始
      }).catch(err => {
        clearInterval(this.interval)
        console.log(err)
      });
  }
  //___________________________________________________________________
  // キャンセルボタンを押した時
  handleOnCancelUpload = () => {
    const { onCancel } = this.props;
    this.setState({
      file: null,
      checkProgress: 0,
      uploadProgress: 0,
      errorMsg: null,
      showCheck: false,
      showUpload: false,
      showError: false,
      checkfinished: false,
      uploadfinished: false,
      checkProcessName: '',
      uploadProcessName: '',
    })
    onCancel && onCancel();
  }
  //___________________________________________________________________
  // 詳細ボタンを押した時
  handleOnDownloadError = () => {
    const { file } = this.state;
    let { outputErrorUrl, errFileName = `ERROR_${file.name}` } = this.props;
    if (!!outputErrorUrl) {
      if (errFileName.split(".")[1] === "csv") {
        errFileName = errFileName.split(".")[0] + ".xlsx";
      }
      getExcel(outputErrorUrl, {}, errFileName);
    }
  }
  //___________________________________________________________________
  handleOnFinish = () => {
    const { res } = this.state;
    const { onFinish } = this.props;
    onFinish && onFinish(res)
  }
  //___________________________________________________________________
  render() {
    const { fileType, onFinish, uploadUrl, outputErrorUrl } = this.props;
    const {
      file,
      checkProgress,
      uploadProgress,
      errorMsg,
      showCheck,
      showUpload,
      showError,
      checkfinished,
      uploadfinished,
      checkProcessName,
      uploadProcessName,
    } = this.state
    const _checkProgress = parseInt(checkProgress * 100, 10)
    const _uploadProgress = parseInt(uploadProgress * 100, 10)
    let renderType = null

    if (showError && !checkfinished) { renderType = 'checkError' }
    else if (showError && checkfinished) { renderType = 'UploadError' }
    else if (!file) { renderType = 'willInput' }
    else if (file && !showCheck) { renderType = 'willCheck' }
    else if (showCheck && !checkfinished) { renderType = 'nowChecking' }
    else if (checkfinished && !uploadUrl) { renderType = 'complete' } // 一つ目のレスポンスのみ
    else if (checkfinished && !showUpload) { renderType = 'willUpload' }
    else if (showUpload && !uploadfinished) { renderType = 'nowUploading' }
    else if (uploadfinished) { renderType = 'complete' }
    switch (renderType) {
      case 'willInput': {
        return (
          <div style={this.wrap_style}>
            <WillInputFile file={file} fileType={fileType} onInput={(file) => this.setState({ file: file })} />
          </div>
        )
      }
      case 'willCheck': {
        return (
          <div style={this.wrap_style}>
            <UploadOrCancel file={file} onCancel={() => this.setState({ file: null })} onUpload={this.handleUploadCheck} />
          </div>
        )
      }
      case 'nowChecking': {
        return (
          <div style={this.wrap_style}>
            <ProgressComponent label={checkProcessName} progress={_checkProgress} />
          </div>
        )
      }
      case 'willUpload': {
        return (
          <div style={this.wrap_style}>
            <ProgressComponent label={checkProcessName} progress={_checkProgress} />
            <UploadOrCancel file={file} onCancel={this.handleOnCancelUpload} onUpload={this.handleOnUpload} />
          </div>
        )
      }
      case 'nowUploading': {
        return (
          <div style={this.wrap_style}>
            <ProgressComponent label={checkProcessName} progress={_checkProgress} />
            <ProgressComponent label={uploadProcessName} progress={_uploadProgress} />
          </div>
        )
      }
      case 'complete': {
        return (
          <div style={this.wrap_style}>
            <DidUpdate onClick={this.handleOnFinish} />
          </div>
        )
      }
      case 'checkError': {
        return (
          <div style={this.wrap_style}>
            <ErrorComponent
              onClick={this.handleOnCancelUpload}
              onDownload={!outputErrorUrl ? null : this.handleOnDownloadError}
              errorMsg={errorMsg}
            />
          </div>
        )
      }
      case 'UploadError': {
        return (
          <div style={this.wrap_style}>
            <ErrorComponent
              onClick={this.handleOnCancelUpload}
              errorMsg={errorMsg}
            />
          </div>
        )
      }
      default: { return null }
    }
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////
class WillInputFile extends React.Component {
  constructor(props) {
    super(props);
    this.wrap_style = {
      border: '2px solid #92A9C7',
      borderRadius: 2,
      backgroundColor: '#DDECFF',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
    this.heading_style = {
      fontSize: 13,
      letterSpacing: 0.65,
      color: '#557298',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
      fontWeight: 'bold',
    }
    this.inputWrap_style = {
      backgroundColor: '#557298',
      borderRadius: 2,
      letterSpacing: 0.6,
      color: '#FFFFFF',
      fontSize: 12,
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',

    }

  }
  handleOnDrop = (e) => {
    const { onInput } = this.props;
    e.preventDefault();
    // ファイルのコピーを渡すようにする
    e.dataTransfer.dropEffect = 'copy'; // マウスカーソルを変更
    const file = e.dataTransfer.files[0]
    if (file) {
      onInput && onInput(file)
    }
  }
  handleOnChangeFile = (e) => {
    const { onInput } = this.props;
    const file = e.target.files[0]

    if (file) {
      onInput && onInput(file)
    }

  }

  render() {
    const { fileType } = this.props;
    let accept = '';
    const fileType_arr = fileType.split(',')
    for (let i = 0; i < fileType_arr.length; i++) {
      switch (fileType_arr[i]) {
        case 'xls': {
          accept += 'application/vnd.ms-excel,'
          break;
        }
        case 'xlsx': {
          accept += 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,'
          break;
        }
        case 'csv': {
          accept += '.csv,'
          break;
        }
        default: {
          accept += fileType_arr[i]
          break;
        }
      }
    }

    return (
      <div
        style={this.wrap_style}
        onDragOver={(e) => e.preventDefault()}
        onDrop={this.handleOnDrop}
      >
        <div style={this.heading_style}>
          <Icons.FileUpload width={24} height={24} color='#557298' />
          <span>こちらに登録するファイルをドロップしてアップロードすることができます</span>
        </div>
        <label
          tabIndex="0"
          style={this.inputWrap_style}
          onMouseOver={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          onFocus={() => this.setState({ hover: true })}
          onBlur={() => this.setState({ hover: false })}
        >
          <Icons.File width={15} height={15} color='#FFF' />
          <span>ファイルを選択する</span>
          <input
            type="file"
            id="fileId"
            ref={this.fileRef}
            style={{ display: "none" }}
            onChange={this.handleOnChangeFile}
            accept={accept}
          />
        </label>
      </div>
    );
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////
class UploadOrCancel extends React.Component {
  constructor(props) {
    super(props);
    this.wrap_style = {
      border: '2px solid #92A9C7',
      borderRadius: 2,
      backgroundColor: '#DDECFF',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
    this.heading_style = {
      fontSize: 13,
      letterSpacing: 0.65,
      color: '#557298',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
      fontWeight: 'bold',
    }
    this.inputWrap_style = {
      backgroundColor: '#557298',
      borderRadius: 2,
      letterSpacing: 0.6,
      color: '#FFFFFF',
      fontSize: 12,
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
    }
    this.buttons_wrap = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
    this.cancel_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#CB5252',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    this.cancelHover_style = {
      opacity: 0.8,
    }
    this.ok_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#557298',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    this.okHover_style = {
      opacity: 0.8,
    }

  }

  render() {
    const { file, onCancel, onUpload, } = this.props;
    return (
      <div
        style={this.wrap_style}
        onDragOver={(e) => e.preventDefault()}
        onDrop={this.handleOnDrop}
      >
        <div style={this.heading_style}>
          <Icons.FileUpload width={24} height={24} color='#557298' />
          <span>ファイル名【{file.name}】の取り込みを開始します。</span>
        </div>
        <div style={this.buttons_wrap}>
          <Button
            label='キャンセル'
            style={this.cancel_style}
            hoveredStyle={this.cancelHover_style}
            onClick={onCancel}
          />
          <Button
            label='OK'
            style={this.ok_style}
            hoveredStyle={this.okHover_style}
            onClick={onUpload}
          />
        </div>
      </div>
    );
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////

class ProgressComponent extends React.Component {

  render() {
    const { progress, label, } = this.props;
    const wrap_style = {
      backgroundColor: '#F0F0F0',
      border: '2px solid #BABABA',
      borderRadius: 2,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const label_style = {
      fontSize: 13,
      fontWeight: 'bold',
      letterSpacing: 0.65,
      color: '#868686',
      marginTop: 8,
    }
    return (
      <div style={wrap_style}>
        <Circle
          size={100}
          progress={progress}
        />
        <p style={label_style}>{label}</p>
      </div>

    )
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////

class DidUpdate extends React.Component {

  render() {
    const { onClick, } = this.props;
    const wrap_style = {
      border: '2px solid #5BBA6A',
      borderRadius: 2,
      backgroundColor: '#CEF5D4',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const check_style = {
      backgroundColor: '#5BBA6A',
      marginBottom: 16,
      padding: 12,
      borderRadius: '50%',
    }
    const label_style = {
      fontSize: 13,
      letterSpacing: 0.65,
      color: '#5BBA6A',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 32,
      fontWeight: 'bold',
    }
    const ok_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#5BBA6A',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    const okHover_style = {
      opacity: 0.8,
    }

    return (
      <div style={wrap_style}>
        <Icons.Check1 size={32} style={check_style} color='#CEF5D4' />
        <p style={label_style}>アップロードが完了しました</p>
        <Button
          label='OK'
          style={ok_style}
          hoveredStyle={okHover_style}
          onClick={onClick}
        />
      </div>

    )
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////

class ErrorComponent extends React.Component {

  render() {
    const { onClick, errorMsg, onDownload } = this.props;
    const wrap_style = {
      border: '2px solid #CB5252',
      borderRadius: 2,
      backgroundColor: '#FFEBEB',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const label_style = {
      fontSize: 13,
      letterSpacing: 0.65,
      color: '#CB5252',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 32,
      marginTop: 16,
      fontWeight: 'bold',
    }
    const buttons_wrap = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const ok_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#557298',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    const uploadButton_style = {
      borderRadius: 2,
      border: 'none',
      backgroundColor: '#CB5252',
      color: '#FFF',
      height: 30,
      width: 120,
      padding: '0px 16px',
      cursor: 'pointer',
      margin: '0px 4px',
    }
    const buttonHover_style = {
      opacity: 0.8,
    }

    return (
      <div style={wrap_style}>
        <Icons.ExclamationTriangle size={64} color='#CB5252' />
        <p style={label_style}>{errorMsg}</p>
        <div style={buttons_wrap}>
          <Button
            label='OK'
            style={ok_style}
            hoveredStyle={buttonHover_style}
            onClick={onClick}
          />
          <Button
            label='詳細'
            style={uploadButton_style}
            hoveredStyle={buttonHover_style}
            onClick={onDownload}
            hidden={!onDownload}
          />
        </div>
      </div>

    )
  }
}
