import React, { Fragment } from 'react';
import * as Icons from "./Icons";
import ErrorMsgBar from './ErrorMsgBar';
import { getPMasterName } from '../util/commonInput';

export default class HideableTitle extends React.Component {
  constructor(props) {
    super(props);
    const { col } = this.props;
    let colspan = 12 // 初期値
    if (col === 0 || col) { colspan = col }　// propsから指定
    const width = colspan / 24 * 100 + '%';

    this.wrap_style = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 4,
      width: width,
      // justifyContent: 'flex-end',
    }
    this.rightArea_style = {
      position: 'sticky',
      right: 8,
    }
    this.toggle_style = {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      outline: 'none',
    }
    this.label_style = {
      fontWeight: 'bold',
      fontSize: 12,
      letterSpacing: 0.7,
      color: '#262626',
    }
    this.icon_style = {
      marginRight: 8,
    }
  }
  //___________________________________________________________________________
  render() {
    const {
      children,
      popup,
      mode,
      label,
    } = this.props;
    let _label = ""
    switch (mode) {
      case "0": {
        _label = "検索画面";
        break;
      }
      case "1": {
        _label = "追加画面";
        break;
      }
      case "2": {
        _label = "修正画面";
        break;
      }
      case "3": {
        _label = "削除画面";
        break;
      }
      case "4": {
        _label = "複写画面";
        break;
      }
      default: {
        _label = label
        break;
      }
    }
    // labelが設定されていたら優先
    return (
      <Fragment>
        {!popup && <ErrorMsgBar />}
        <div style={this.wrap_style}>
          <div>{label || _label}</div>
          <div style={this.rightArea_style}>{children}</div>
        </div>
      </Fragment>
    )
  }
}