import React from 'react';
import BasicWrap from './BasicWrap';
import { textFile as Colors } from './Colors';
import * as Icons from "./Icons";


export default class TextFile extends React.Component {
  constructor(props) {
    super(props);
    const { fileName, src } = this.props;
    this.state = {
      hover: false,
      fileName: fileName,
      src: src,
    }
  }
  // ______________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const { label, fileName } = nextProps;
    if (this.props.label !== label) {
      this.setState({ label: label })
    }
    if (this.props.fileName !== fileName) {
      this.setState({ fileName: fileName })
    }
    return true;
  }
  // ______________________________________________________________________________

  importFile = (e) => {
    const { onChange, name, name2 } = this.props;
    const file = e.target.files[0];
    if (!file) { return } // ファイルが選択されていなかったら処理しない
    // file.name = fileName;
    const fileName = file.name; // ファイル名を取得
    this.setState({
      file: file,
      fileName: fileName,
    })
    //  FileReaderオブジェクト作成し、選択されたファイルを読み込む
    let reader = new FileReader();
    reader.onload = (file) => {
      // バイナリデータを取得
      const binary = file.target.result.split(",")[1];
      console.log(file.target.result.split(","))
      onChange && onChange(name, fileName, name2, binary)
    }
    reader.readAsDataURL(file)

  }
  // ______________________________________________________________________________

  resetFile = () => {
    const { onChange, } = this.props;
    this.setState({
      fileName: '',
      src: '',
    })
    onChange && onChange({})
  }
  ______________________________________________________________________________

  render() {
    const { hidden, disabled, label } = this.props
    const { hover, fileName } = this.state
    if (hidden) { return null }

    const wrap_style = {
      display: "flex",
      alignItems: "center",
      justifyContent: 'center',
      // fontSize: 11,
    }
    const basicWrap_style = {
      width: null,
      minWidth: null,
    }
    const file_style = {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      backgroundColor:
        disabled ? null :
          hover ? Colors.hover :
            "transparent",
      fontWeight: "bold",
      fontSize: 'inherit',
      cursor:
        disabled ? null :
          "pointer",
      transition: "0.3s",
      padding: '0px 8px',
    }
    const label_style = {
      paddingLeft: label ? 8 :
        null,
    }

    // ______________________________________________________________________________

    return (
      <div style={wrap_style}>
        <BasicWrap style={basicWrap_style} hidden={disabled}>
          <label
            tabIndex="0"
            style={file_style}
            onMouseOver={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
            onFocus={() => this.setState({ hover: true })}
            onBlur={() => this.setState({ hover: false })}
          >
            <Icons.FileDownload height={20} width={20} color={Colors.icon} />
            <span style={label_style}>{label}</span>
            <input type="file" style={{ display: "none" }} onChange={this.importFile} disabled={disabled} />
          </label>
        </BasicWrap>
        <span style={{ marginLeft: 8 }}>{fileName}</span>
      </div>
    )
  }
}
