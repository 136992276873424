import React from 'react';
import * as Icons from './Icons';
import { baseColor } from './Colors'

export default class SelectMenuIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null || this.props.value
    }
    this.setData = [
      { type: 'Edit' },
      { type: 'Table' },
      { type: 'List' },
      { type: 'FileDownload' },
      { type: 'PdfFile' },
      { type: 'Database' },
      { type: 'Cog' },
      { type: 'Calendar' },
      { type: 'ChartBar' },
      { type: 'ChartPie' },
      { type: 'Clock' },
      { type: 'Globe' },
      { type: 'Check' },
      { type: 'ClipboardCheck' },
      { type: 'ClipboardList' },
      { type: 'Receipt' },
      { type: 'Search' },
      { type: 'SearchBoard' },
      { type: 'SyncAlt' },
      { type: 'Barcode' },
    ]
  }
  //________________________________________________________________
  handleOnChange = (type) => {
    const { onChange, name } = this.props;
    this.setState({ value: type })
    onChange && onChange(name, type)
  }
  //________________________________________________________________

  render() {
    const { value } = this.state;
    const { disabled } = this.props;
    const wrap_style = {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: '#ffffff'
    }
    const iconList_arr = []
    for (let i in this.setData) {
      const { type } = this.setData[i]
      iconList_arr.push(
        <IconList
          key={'icon' + i}

          type={type}
          select={type === value}
          onClick={() => this.handleOnChange(type)}
        />
      )
    }
    return (
      <div style={wrap_style}>
        {iconList_arr}
      </div>
    )
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////
class IconList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    }
  }
  // _____________________________________________________
  handleIconClick = () => {
    const { disabled, onClick } = this.props;
    if (disabled === true) return;
    onClick && onClick();
  }
  // _____________________________________________________

  render() {
    const { type, select, disabled } = this.props;
    const { hover } = this.state;
    const icon_style = {
      margin: 4,
      cursor:
        !disabled ? 'pointer' :
          null,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 40,
      width: 40,
      borderRadius: 4,
      backgroundColor:
        select || hover ? baseColor :
          null,
      transition: '0.2s',
    }
    const iconColor =
      select || hover ? '#FFF' :
        'rgb(168, 168, 168)'
    let icon = null

    switch (type) {
      case 'Barcode': { icon = <Icons.Barcode color={iconColor} />; break; }
      case 'Calendar': { icon = <Icons.Calendar color={iconColor} />; break; }
      case 'ChartBar': { icon = <Icons.ChartBar color={iconColor} />; break; }
      case 'ChartPie': { icon = <Icons.ChartPie color={iconColor} />; break; }
      case 'Check': { icon = <Icons.Check1 color={iconColor} />; break; }
      case 'ClipboardCheck': { icon = <Icons.ClipboardCheck color={iconColor} />; break; }
      case 'ClipboardList': { icon = <Icons.ClipboardList color={iconColor} />; break; }
      case 'Clock': { icon = <Icons.Clock1 color={iconColor} />; break; }
      case 'Cog': { icon = <Icons.Cog color={iconColor} />; break; }
      case 'Database': { icon = <Icons.Database color={iconColor} />; break; }
      case 'Edit': { icon = <Icons.Edit1 color={iconColor} />; break; }
      case 'FileDownload': { icon = <Icons.FileDownload color={iconColor} />; break; }
      case 'Globe': { icon = <Icons.Globe color={iconColor} />; break; }
      case 'List': { icon = <Icons.List color={iconColor} />; break; }
      case 'PdfFile': { icon = <Icons.PdfFile color={iconColor} />; break; }
      case 'Receipt': { icon = <Icons.Receipt color={iconColor} />; break; }
      case 'Search': { icon = <Icons.Search1 color={iconColor} />; break; }
      case 'SearchBoard': { icon = <Icons.SearchBoard color={iconColor} />; break; }
      case 'SyncAlt': { icon = <Icons.SyncAlt color={iconColor} />; break; }
      case 'Table': { icon = <Icons.Table1 color={iconColor} />; break; }
      default: icon = null; break;;
    }
    return (
      <div
        style={icon_style}
        onClick={this.handleIconClick}
        onMouseOver={() => !disabled && this.setState({ hover: true })}
        onMouseLeave={() => !disabled && this.setState({ hover: false })}
        onFocus={() => !disabled && this.setState({ hover: true })}
        onBlur={() => !disabled && this.setState({ hover: false })}
      >
        {icon}
      </div>
    )
  }
}
